let profilePictureDirective = ($compile, userApiService) => {
    return {
        restrict: 'E',
        scope: {
            user: '<'
        },
        link: (scope, element) => {
            let cancellationToken = {
                isCancelled: false
            };
            scope.$on('$destroy', function () {
                cancellationToken.isCancelled = true;
            });
            let watch = scope.$watch('user', (newVal, oldVal) => {
                if (newVal) {
                    const noImage =
                            'iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAIAAAABc2X6AAAABnRSTlMAAAAAAABupgeRAAAAKklEQVR4nO3BAQ0AAADCoPdPbQ43oAAAAAAAAAAAAAAAAAAAAAAAAODXAEtQAAGPJpE5AAAAAElFTkSuQmCC',
                        html = `<img class="rym-profile-picture ${
                            element.hasClass('small') ? 'small' : 'left(0-2) right(0-2) '
                        } " ng-src="data:image/JPEG;base64,{{::result}}"/>`;

                    let user = scope.user;

                    if (user && !user.initials) {
                        if (!user.name) {
                            return;
                        }

                        let names = user.name.replace(/\s+/g, ' ').trim().split(' ');

                        let initials = names[0][0];
                        if (names.length > 1) {
                            initials += names[names.length - 1][0];
                        }

                        if (initials) {
                            element[0].setAttribute('initials', initials.toUpperCase());
                        }
                    } else {
                        element[0].setAttribute('initials', user.initials);
                    }

                    if (user.isDeleted) {
                        element.addClass('deleted');
                        watch();
                        return;
                    }

                    userApiService.getProfilePhotoForUser(user.email, cancellationToken).then((result) => {
                        if (result.data === noImage) {
                            watch();
                            return;
                        }

                        scope.result = result.data;

                        let createdElement = $compile(html)(scope);
                        element.replaceWith(createdElement);
                        watch();
                    });
                }
            });
        }
    };
};

profilePictureDirective.$inject = ['$compile', 'userApiService'];

export default profilePictureDirective;
