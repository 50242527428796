class CacheService {
    constructor($cacheFactory, eventEmitterService, locationService) {
        Object.assign(this, { $cacheFactory, eventEmitterService, locationService });
    }

    init() {
        this.eventEmitterService.subscribe('clearCacheAndReload', () => this.clearCacheAndReload());
    }

    clearCache() {
        _.forEach(this.$cacheFactory.info(), (ob, key) => {
            this.$cacheFactory.get(key).removeAll();
        });
    }

    clearCacheAndReload() {
        this.clearCache();
        this.locationService.reload();
    }
}

CacheService.$inject = ['$cacheFactory', 'eventEmitterService', 'locationService'];

export default CacheService;
