import controller from './actions.controller';
import template from './actions.html';
import './actions.less';

let navigationComponent = {
    template,
    controller
};

export default navigationComponent;
