import template from './roleSettingsDialog.html';
import controller from './roleSettingsDialog.controller';

let roleSettingsDialogComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onOk: '&',
        onCancel: '&'
    }
};

export default roleSettingsDialogComponent;
