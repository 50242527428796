import template from './meetingSeriesDialog.html';
import controller from './meetingSeriesDialog.controller';
import './meetingSeriesDialog.less';

let meetingSeriesDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default meetingSeriesDialogComponent;
