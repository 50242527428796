import { SUPPORTED_FILE_MIME_TYPES, SUPPORTED_OFFICE_FILE_MIME_TYPES } from './mime';
const MAX_FILE_SIZE_VIEW = 10485760;

class DocumentController {
    constructor(
        $rootScope,
        $timeout,
        $scope,
        $state,
        $document,
        $q,
        $filter,
        meetingApiService,
        dialogService,
        authService,
        Upload,
        toastService,
        eventEmitterService,
        fileService,
        translationService,
        documentService,
        menuService,
        signalRService,
        stringService,
        userService,
        documentViewService
    ) {
        Object.assign(this, {
            $rootScope,
            $timeout,
            $scope,
            $state,
            $document,
            $q,
            $filter,
            meetingApiService,
            dialogService,
            authService,
            Upload,
            toastService,
            eventEmitterService,
            fileService,
            translationService,
            documentService,
            menuService,
            signalRService,
            stringService,
            userService,
            documentViewService
        });
        this.selected = false;
    }

    $doCheck() {
        if ((!!this.documentViewService.getInfo() || (this.agendaId && this.meetingId && angular.isFunction(this.closeFunc))) && !this.initialized) {
            this.initialized = true;
            if (!this.meetingId && !this.agendaId) {
                let documentInfo = this.documentViewService.getInfo();
                this.agendaId = documentInfo.agendaId;
                this.meetingId = documentInfo.meetingId;
            }
            this.documents = [];

            this._getDocuments();
        }
    }

    _getDocuments() {
        this.documentService.getDocuments(this.meetingId, this.agendaId).then((documents) => {
            _.forEach(documents, (document) => (document.canDelete = this.allowUpload));
            this.documents = documents;

            angular.element(window).bind('dragover', function (e) {
                e.preventDefault();
            });
            angular.element(window).bind('drop', function (e) {
                e.preventDefault();
            });
        });
    }

    $onChanges(changes) {
        if (changes.agendaId && changes.agendaId.currentValue) {
            this._getDocuments();
        }
    }

    isInSeriesDialog() {
        return angular.isFunction(this.closeFunc);
    }

    $onInit() {
        this.$rootScope.$on('$stateChangeStart', (e, toState) => {
            if (toState.name !== 'meeting') {
                this.agendaId = null;
                this.meetingId = null;
                this.documents = null;
            }
        });
        this.isIE11 = this.userService.isIE11();
        this.eventEmitterService.subscribe('documentStatusUpdated', this.updateDocumentStatus.bind(this));
        this.eventEmitterService.subscribe('documentsUpdated', this._getDocuments.bind(this));
        this.eventEmitterService.subscribe('addedDocument' + this.agendaId, this.updateDocument.bind(this));
        this.shouldCloseOnBlur = !angular.isFunction(this.closeFunc);
    }

    $onDestroy() {
        this.eventEmitterService.unSubscribe('notifyDocumentProtocolLock');
    }

    handleOpen() {
        this.selectingFiles = true;
        this.$timeout(
            () => {
                this.selectingFiles = false;
            },
            200,
            false
        );
    }

    updateDocumentStatus(info) {
        if (this.documents) {
            let document = _.find(this.documents, (doc) => {
                if (this.isIE11) {
                    return btoa(doc.name) === btoa(info.name);
                }
                return doc.name.normalize() === info.name || doc.name === info.name;
            });

            if (document) {
                this.$scope.$applyAsync(() => {
                    document.uploadedSize = info.uploadedSize;
                    document.percentage = info.progress;
                });
            }
        }
    }

    updateDocument(updatedDocument) {
        let document = _.find(this.documents, (doc) => {
            if (this.isIE11) {
                return btoa(doc.name) === btoa(updatedDocument.name);
            }
            return doc.name.normalize() === updatedDocument.name || doc.name === updatedDocument.name;
        });

        if (document) {
            document.canDelete = true;
            document.isLoading = false;
            document.uploaded = updatedDocument.uploaded;
            document.id = updatedDocument.id;
        }
    }

    close() {
        if (!this.selectingFiles && !this.menuOpen && !this.documentDialogOpen) {
            if (this.isInSeriesDialog()) {
                this.closeFunc();
            } else {
                this.documentViewService.close();
            }

            this.meetingId = null;
            this.agendaId = null;
            this.documents = null;
            this.initialized = false;
        }
    }

    showDocument(document) {
        if (document.isLoading || this.documentDialogOpen) {
            return;
        }

        this.documentDialogOpen = true;

        if (SUPPORTED_OFFICE_FILE_MIME_TYPES.indexOf(document.contentType) !== -1 && document.size >= MAX_FILE_SIZE_VIEW) {
            this.dialogService
                .confirm({
                    id: 'file too large to open dialog',
                    title: this.translationService.translate('client_FileTooLarge'),
                    ok: this.translationService.translate('download'),
                    description: this.translationService.translate('client_FileTooLargeDescription')
                })
                .then(() => {
                    this.downloadDocument(document, this.meetingId);
                })
                .finally(() => {
                    this.documentDialogOpen = false;
                });
        } else if (
            SUPPORTED_OFFICE_FILE_MIME_TYPES.indexOf(document.contentType) !== -1 ||
            SUPPORTED_FILE_MIME_TYPES.indexOf(document.contentType) !== -1
        ) {
            this.loadingId = document.id;
            this.meetingApiService.clearCache(`meetings/${this.meetingId}/document/${document.id}`);
            return this.meetingApiService
                .getDocument(this.meetingId, document.id)
                .then((result) => {
                    let options = {
                        id: 'document dialog',
                        zIndex: 'z-index: 30;',
                        fileURL: result,
                        document: document,
                        meetingId: this.meetingId
                    };

                    return this.dialogService.custom('rym-document-dialog', options).finally(() => {
                        this.documentDialogOpen = false;
                    });
                })
                .catch(() => (this.documentDialogOpen = false));
        } else {
            this.documentDialogOpen = false;
            return this.downloadDocument(document);
        }
    }

    uploadFiles(files) {
        if ((!files || files.length === 0) && !this.selectingFiles) {
            return;
        }

        if (this.allowUpload) {
            this.documentService.uploadFiles(files, this.meetingId, this.agendaId);
        }
    }

    deleteDocument(document) {
        if (this.allowUpload) {
            this.documentService.deleteDocument(this.meetingId, this.agendaId, document);
        }
    }

    downloadDocument(document) {
        if (document.isLoading) {
            return;
        }
        this.documentService.downloadDocument(this.meetingId, document);
    }

    getClass(document) {
        if (!document.contentType) {
            return 'fill(on) fill-color(stratsys-teal)';
        } else if (
            document.contentType.includes('image') ||
            document.contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            return 'fill(on) fill-color(stratsys-teal)';
        } else if (
            document.contentType.includes('pdf') ||
            document.contentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ) {
            return 'fill(on) fill-color(coral-pink)';
        }
        return 'fill(on) fill-color(stratsys-teal)';
    }

    getIcon(document) {
        if (!document.contentType) {
            return 'mdi-attachment';
        } else if (document.contentType.includes('image')) {
            return 'mdi-image';
        } else if (document.contentType.includes('pdf')) {
            return 'mdi-file-pdf-box';
        } else if (document.contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            return 'mdi-file-word-box';
        } else if (document.contentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            return 'mdi-file-powerpoint-box';
        } else if (document.contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            return 'mdi-file-excel-box';
        } else if (document.contentType.includes('text')) {
            return 'mdi-file-document-box';
        }
        return 'mdi-attachment';
    }

    showMenu($event, document) {
        this.menuOpen = true;
        $event.stopImmediatePropagation();
        let options = {
            items: [],
            onClose: () => {
                this.$timeout(
                    () => {
                        this.menuOpen = false;
                    },
                    200,
                    false
                );
            }
        };

        this.translationService.translateBatch(['download', 'delete', 'cancel']).then((translations) => {
            if (document.isLoading) {
                options.items.push({
                    id: 'menu option - cancel download',
                    icon: 'cancel',
                    text: translations.cancel,
                    onClick: () => this.documentService.cancelActiveUpload(document.name)
                });
            } else {
                options.items.push({
                    id: 'menu option - download document',
                    icon: 'file_download',
                    text: translations.download,
                    onClick: () => this.downloadDocument(document, this.meetingId)
                });

                if (document.canDelete) {
                    options.items.push({
                        id: 'menu option - delete document',
                        icon: 'delete_outline',
                        text: translations.delete,
                        onClick: () => {
                            this.translationService
                                .translateBatch(['meetings_Delete', 'client_Delete', 'client_DeleteDocument'], { documentName: document.name })
                                .then((translations) => {
                                    this.dialogService
                                        .confirm({
                                            id: 'delete document dialog',
                                            title: translations.meetings_Delete,
                                            ok: translations.client_Delete,
                                            description: translations.client_DeleteDocument
                                        })
                                        .then(() => {
                                            this.deleteDocument(document);
                                        });
                                });
                        }
                    });
                }
            }
            let menuButton = $event.currentTarget;
            this.menuService.actionMenu(options, angular.element(menuButton), false);
        });
    }
}

DocumentController.$inject = [
    '$rootScope',
    '$timeout',
    '$scope',
    '$state',
    '$document',
    '$q',
    '$filter',
    'meetingApiService',
    'dialogService',
    'authService',
    'Upload',
    'toastService',
    'eventEmitterService',
    'fileService',
    'translationService',
    'documentService',
    'menuService',
    'signalRService',
    'stringService',
    'userService',
    'documentViewService'
];

export default DocumentController;
