class BreadcrumbsController {
    constructor($breadcrumb, meetingFilterService) {
        this.$breadcrumb = $breadcrumb;
        this.meetingFilterService = meetingFilterService;
    }

    $onInit() {
        this.breadcrumbs = this.$breadcrumb.getStatesChain().map((state) => {
            if (state.url === '/meetings?category') {
                let categoryId = this.meetingFilterService.getCategoryId();
                if (categoryId) {
                    state.customLink = `${state.ncyBreadcrumbLink}?category=${categoryId}`;
                } else {
                    state.customLink = '/meetings';
                }
            }
            return state;
        });
    }
}

BreadcrumbsController.$inject = ['$breadcrumb', 'meetingFilterService'];

export default BreadcrumbsController;
