import controller from './subscriptionFormSummary.controller';
import template from './subscriptionFormSummary.html';

let subscriptionFormSummaryComponent = {
    template,
    controller,
    bindings: {
        options: '<',
        hideButton: '<',
        buttonText: '@',
        disableButton: '<',
        buttonType: '<',
        onClick: '&'
    }
};

export default subscriptionFormSummaryComponent;
