class DocumentViewService {
    constructor($state) {
        this.documentInfo = null;
        this.$state = $state;
    }

    open(agendaId, meetingId, readOnly) {
        this.documentInfo = {
            meetingId: meetingId,
            agendaId: agendaId,
            readOnly: readOnly
        };
        this.$state.go(
            'meeting.documents',
            { agendaId: agendaId, meetingId: meetingId },
            {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            }
        );
    }

    close() {
        this.documentInfo = null;
        this.$state.go(
            'meeting',
            {},
            {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            }
        );
    }

    nullInfo() {
        this.documentInfo = null;
    }

    getInfo() {
        return this.documentInfo;
    }
}

DocumentViewService.$inject = ['$state'];

export default DocumentViewService;
