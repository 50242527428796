class FullScreenDialogController {
    constructor($timeout, eventEmitterService) {
        this.$timeout = $timeout;
        this.eventEmitterService = eventEmitterService;
    }

    $onInit() {
        this.closeIconEventId = `close icon in ${this.options.id}`;
    }

    $postLink() {
        this.isVisible = true;

        this.$timeout(() => {
            this.isFullyOpened = true;
            if (this.dialogId) {
                this.eventEmitterService.subscribe('closeFullscreenDialog' + this.dialogId, this.close.bind(this));
            }
        }, 400);
    }

    $onDestroy() {
        if (this.dialogId) {
            this.eventEmitterService.unSubscribe('closeFullscreenDialog' + this.dialogId);
        }
    }

    close() {
        this.isVisible = false;
        this.$timeout(() => {
            this.onCancel();
        }, 200);
    }
}

FullScreenDialogController.$inject = ['$timeout', 'eventEmitterService'];

export default FullScreenDialogController;
