import controller from './actionStatusIcon.controller';
import template from './actionStatusIcon.html';
import './actionStatusIcon.less';

let actionStatusIconComponent = {
    template,
    controller,
    bindings: {
        action: '='
    }
};

export default actionStatusIconComponent;
