import BaseApiService from './baseApi.service';

class MeetingApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        eventEmitterService,
        applicationInsightsService,
        appcuesService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'meetings'
        );
        this.eventEmitterService = eventEmitterService;
        this.eventEmitterService.subscribe('toggleOffice365Integration', () => this.clearMeetingsCache());
    }

    getMeetingsForUser() {
        return super.GET('meetings');
    }

    getProviderMeetingsForUser(skip) {
        return super.GET(`meetings/providerMeetings?skip=${skip}`);
    }

    create(data) {
        this.appcuesService.track('Meeting Created');
        return super.POST('meetings', data);
    }

    update(id, data) {
        return super.PUT(`meetings/${id}`, data);
    }

    remove(id) {
        return super.DELETE('meetings', `${id}`);
    }

    get(id, ignoreErrorHandling) {
        return super.GET(`meetings/${id}`, false, ignoreErrorHandling);
    }

    putMeetingName(id, meetingName) {
        return super.PUT(`meetings/${id}/meetingname`, meetingName);
    }

    putDescription(id, description) {
        return super.PUT(`meetings/${id}/description`, description);
    }

    putLocation(id, location) {
        return super.PUT(`meetings/${id}/location`, location);
    }

    putStartDate(id, startDate) {
        return super.PUT(`meetings/${id}/startDate`, startDate);
    }

    putStartTime(id, startTime) {
        return super.PUT(`meetings/${id}/startTime`, startTime);
    }

    putEndTime(id, endTime) {
        return super.PUT(`meetings/${id}/endTime`, endTime);
    }

    putCategory(id, categoryId) {
        if (categoryId) {
            return super.PUT(`meetings/${id}/category`, categoryId);
        } else {
            return super.PUT(`meetings/${id}/category/none`);
        }
    }

    putEmptyCategory(id) {
        return super.PUT(`meetings/${id}/category/none`);
    }

    mergeSeries(seriesAId, seriesBId) {
        return super.PUT(`meetings/series/${seriesAId}/merge`, seriesBId);
    }

    exportToCalendar(id, message) {
        return super.POST(`meetings/${id}/export/calendar`, message);
    }

    getMeetingsForMeetingSeries(meetingSeriesId) {
        return super.GET(`meetings/series/${meetingSeriesId}/all`);
    }

    lockProtocol(meetingId, lock) {
        return super.PUT(`meetings/${meetingId}/lockprotocol/${lock}`);
    }

    getPreview(id) {
        return super.GET(`meetings/${id}/preview`, true);
    }

    getICalUId(eventId) {
        return super.GET(`meetings/office365/uid?eventId=${eventId}`);
    }

    // Agendas

    getAgendas(meetingId) {
        return super.GET(`meetings/${meetingId}/agendas`);
    }

    getAgenda(meetingId, agendaId) {
        return super.GET(`meetings/${meetingId}/agendas/${agendaId}`);
    }

    applyTemplate(meetingId, templateId) {
        return super.PUT(`meetings/${meetingId}/template/${templateId}`);
    }

    createAgenda(meetingId, parentId) {
        this.appcuesService.track('Agenda Item Created');
        return super.POST(`meetings/${meetingId}/agendas`, { parentId: parentId, title: '' });
    }

    putAgendaTitle(meetingId, agendaId, title) {
        return super.PUT(`meetings/${meetingId}/agendas/${agendaId}/title`, title);
    }

    putAgendaDescription(meetingId, agendaId, description) {
        return super.PUT(`meetings/${meetingId}/agendas/${agendaId}/description`, { description: description });
    }

    putAgendaProtocol(meetingId, agendaId, protocol) {
        return super.PUT(`meetings/${meetingId}/agendas/${agendaId}/protocol`, { protocol: protocol });
    }

    removeAgenda(meetingId, agendaId) {
        return super.DELETE(`meetings/${meetingId}/agendas`, agendaId);
    }

    postponeAgenda(meetingId, agendaId) {
        return super.PUT(`meetings/${meetingId}/agendas/${agendaId}/postpone`);
    }

    getPostponedAgendasForMeetingSeries(meetingseriesId) {
        return super.GET(`meetings/series/${meetingseriesId}/agendas/postponed`);
    }

    addPostponedAgendaToMeeting(meetingSeriesId, agendaId, meetingId) {
        return super.POST(`meetings/series/${meetingSeriesId}/agendas/${agendaId}/postponed/move`, { meetingId: meetingId });
    }

    sendProtocol(meetingId, data) {
        this.appcuesService.track('Protocol Sent');
        return super.POST(`meetings/${meetingId}/sendprotocol`, data);
    }

    exportProtocol(meetingId) {
        return super.GET_BUFFER(`meetings/${meetingId}/export/protocol`);
    }

    exportWithOptions(meetingId, options) {
        return super.GET_BUFFER(`meetings/${meetingId}/export/protocol`, options);
    }

    exportAgenda(meetingId) {
        return super.GET_BUFFER(`meetings/${meetingId}/export/agenda`);
    }

    exportDecisions(context) {
        return super.GET_BUFFER('meetings/decisions/export', context);
    }

    sendAgenda(meetingId, data) {
        this.appcuesService.track('Agenda Sent');
        return super.POST(`meetings/${meetingId}/sendagenda`, data);
    }

    putAgendaPosition(meetingId, agenda) {
        return super.PUT(`meetings/${meetingId}/agendas/position`, agenda);
    }

    moveAgendaChildren(meetingId, agendaId) {
        return super.POST(`meetings/${meetingId}/agendas/${agendaId}/movechildren`);
    }

    // Decisions

    createDecision(meetingId, agendaId, text) {
        this.appcuesService.track('Decision Created');
        return super.POST(`meetings/${meetingId}/decision`, { agendaId: agendaId, text: text });
    }

    removeDecision(meetingId, decisionId) {
        return super.DELETE(`meetings/${meetingId}/decision`, decisionId);
    }

    putDecisionText(meetingId, decisionId, text) {
        return super.PUT(`meetings/${meetingId}/decision/${decisionId}/text`, text);
    }

    getDecisionsInSeries(meetingseriesId) {
        return super.GET(`meetings/series/${meetingseriesId}/all/decisions`);
    }

    // Events
    getEventsForMeetingSeries(meetingseriesId) {
        return super.GET(`meetings/series/${meetingseriesId}/all/events`, true);
    }

    //Search

    getSearchResults(searchQuery) {
        const searchTerm = encodeURIComponent(searchQuery);
        return super.GET(`meetings/search?Term=${searchTerm}`, true);
    }

    // Templates

    getTemplates() {
        return super.GET('templates');
    }

    hasTemplates() {
        return super.GET('templates/any');
    }

    postTemplate(dto) {
        this.appcuesService.track('Template Created');
        return super.POST('templates', dto);
    }

    deleteTemplate(id) {
        return super.DELETE('templates', `${id}`);
    }

    // Participant

    getParticipantsForMeeting(id) {
        return super.GET(`meetings/${id}/participants`);
    }

    putParticipants(id, participants) {
        return super.PUT(`meetings/${id}/participants`, participants);
    }

    putParticipantPresence(id, userId, isPresent) {
        return super.PUT(`meetings/${id}/participant/${userId}/presence/${isPresent}`);
    }

    putParticipantToAllMeetings(meetingSeriesId, userId, dto) {
        return super.PUT(`meetings/series/${meetingSeriesId}/all/participants/${userId}`, dto);
    }

    isParticipantParticipating(id, userId) {
        return super.GET(`meetings/${id}/participant/${userId}/participating`);
    }

    getOrganizer(meetingId) {
        return super.GET(`meetings/${meetingId}/organizer`);
    }

    // Series

    getMeetingSeries(searchTerm) {
        return super.GET(`meetings/series/search/?term=${searchTerm}`);
    }

    // Document

    getDocument(meetingId, documentId) {
        return super.GET(`meetings/${meetingId}/document/${documentId}`);
    }

    downloadDocument(meetingId, documentId) {
        return super.GET(`meetings/${meetingId}/document/${documentId}/download`);
    }

    getDocumentLegacy(meetingId, documentId) {
        return super.GET_BUFFER(`meetings/${meetingId}/document/${documentId}/legacy`);
    }

    removeDocument(meetingId, documentId) {
        return super.DELETE(`meetings/${meetingId}/document`, documentId);
    }

    // Cache

    clearCache(key) {
        return super.removeCache(key);
    }

    removeCacheObject() {
        return super.removeCacheObject();
    }

    clearMeetingsCache() {
        return super.removeResourceCache();
    }
}

MeetingApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'eventEmitterService',
    'applicationInsightsService',
    'appcuesService',
    'envService'
];

export default MeetingApiService;
