import controller from './templates.controller';
import template from './templates.html';
import './templates.less';

let templatesComponent = {
    template,
    controller
};

export default templatesComponent;
