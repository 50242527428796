import controller from './meetings.controller';
import template from './meetings.html';
import './meetings.less';

let meetingsComponent = {
    template,
    controller
};

export default meetingsComponent;
