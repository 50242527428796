class agendaNavController {
    constructor(
        $filter,
        $timeout,
        $scope,
        $element,
        meetingApiService,
        eventEmitterService,
        signalRService,
        translationService,
        agendaBuildingService
    ) {
        Object.assign(this, {
            $filter,
            $timeout,
            $scope,
            $element,
            meetingApiService,
            eventEmitterService,
            signalRService,
            translationService,
            agendaBuildingService
        });
    }

    $onInit() {
        this._selectedItemTitle = '';
        this._selectedItemId = 0;
        this._orderItems(this.items);
        this.eventEmitterService.subscribe('updateAgendaSortOrder', this.updateAgendaSortOrder.bind(this));
        this.options = {
            beforeDrag: (nodeScope) => {
                if (nodeScope.$modelValue.title && nodeScope.$modelValue.title.length > 20) {
                    this._selectedItemTitle = angular.copy(nodeScope.$modelValue.title);
                    nodeScope.$modelValue.title = nodeScope.$modelValue.title.substring(0, 20) + '...';
                }
                this._selectedItemId = nodeScope.$modelValue.id;
                nodeScope.$modelValue.hide = true;

                return true;
            },
            beforeDrop: (e) => {
                if (this._selectedItemId == e.source.nodeScope.$modelValue.id && this._selectedItemTitle.length > 0) {
                    e.source.nodeScope.$modelValue.title = angular.copy(this._selectedItemTitle);
                }
                e.source.nodeScope.$modelValue.hide = false;
                this._selectedItemId = 0;
                this._selectedItemTitle = '';
                return true;
            },
            dropped: (e) => {
                let source = e.source,
                    dest = e.dest;

                if (!(dest.index === source.index && source.nodesScope === dest.nodesScope)) {
                    this.onDragDrop({ moveEvent: { source: source, dest: dest } }).then((agendaObj) => {
                        this.$timeout(() => {
                            this.agendaBuildingService.mergeTree(this.items, agendaObj.meetingAgenda);
                        }, 1);

                        this.meetingApiService.putAgendaPosition(agendaObj.agendaToMove.meetingId, agendaObj.agendaToMove).finally(() => {
                            this.signalRService.updateAgendaPosition(agendaObj.agendaToMove.meetingId);
                        });
                    });
                }
            }
        };

        this.hasUnsavedChanges = false;

        this.$scope.$on('SavedChanges', (event, data) => {
            this.hasUnsavedChanges = false;
            this.setDisabledStatus();
        });

        this.$scope.$on('UnsavedChanges', (event, data) => {
            this.hasUnsavedChanges = true;
            this.setDisabledStatus();
        });

        this.$scope.$on('RevertedChanges', (event, data) => {
            this.hasUnsavedChanges = false;
            this.setDisabledStatus();
        });
    }

    updateAgendaSortOrder(items) {
        this.$scope.$applyAsync(() => {
            this._orderItems(items);
        });
    }

    _orderItems(items) {
        this.items = _.orderBy(items, ['sortOrder'], ['asc']);
        _.forEach(this.items, (item) => {
            if (item.children) {
                item.children = _.orderBy(item.children, ['sortOrder'], ['asc']);
            } else {
                item.children = [];
            }
        });
    }

    scrollTo(event, agendaId) {
        event.stopImmediatePropagation();
        this.goTo({ agendaId: agendaId });
    }

    setDisabledStatus() {
        this.isReorderDisabled = !this.handleHidden && this.hasUnsavedChanges;
    }
}

agendaNavController.$inject = [
    '$filter',
    '$timeout',
    '$scope',
    '$element',
    'meetingApiService',
    'eventEmitterService',
    'signalRService',
    'translationService',
    'agendaBuildingService'
];

export default agendaNavController;
