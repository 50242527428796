import template from './404.html';
import controller from './404.controller';
import './404.less';

let four04Component = {
    template,
    controller
};

export default four04Component;
