import template from './documentDialog.html';
import controller from './documentDialog.controller';

let documentDialogComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onOk: '&',
        onCancel: '&'
    }
};

export default documentDialogComponent;
