class MeetingFilterService {
    constructor(eventEmitterService) {
        Object.assign(this, { eventEmitterService });

        this.searchTerm = '';
        this.maxDate = new Date();
    }

    setMaxDate(date) {
        this.maxDate = date;
    }

    resetFilter() {
        this.searchTerm = '';
        this.categoryId = undefined;
    }

    filterMeetings(meetings, isOffice365, noMoreOffice365MeetingsToFetch) {
        return _(meetings)
            .filter((x) => this._isMatchingFilter(x, isOffice365, noMoreOffice365MeetingsToFetch))
            .sortBy(['startDate'])
            .value();
    }

    setSearchTerm(searchTerm) {
        this.searchTerm = searchTerm;
    }

    getSearchTerm() {
        return this.searchTerm;
    }

    setCategory(categoryId) {
        this.categoryId = categoryId;
    }

    getCategoryId() {
        return this.categoryId;
    }

    _isMatchingFilter(meeting, isOffice365, noMoreOffice365MeetingsToFetch) {
        let noFilterIsActive = !angular.isDefined(this.categoryId) && !this.searchTerm;

        if (meeting.isToday) {
            return true;
        } else if (noFilterIsActive && isOffice365 && !noMoreOffice365MeetingsToFetch) {
            let isBeforeMaxDateAndNoFilterIsActive = new Date(meeting.startDate) <= this.maxDate;
            return isBeforeMaxDateAndNoFilterIsActive;
        } else {
            let isMatchingSearchTerm =
                !this.searchTerm || (meeting && meeting.meetingName && meeting.meetingName.toLowerCase().includes(this.searchTerm));
            let isMatchingCategoryFilter = this.categoryId === null || !angular.isDefined(this.categoryId) || meeting.categoryId === this.categoryId;
            return isMatchingSearchTerm && isMatchingCategoryFilter;
        }
    }
}

MeetingFilterService.$inject = ['eventEmitterService'];
export default MeetingFilterService;
