class HeaderController {
    constructor($element, $timeout, $transclude, $rootScope, navigationService, toastService) {
        Object.assign(this, { $element, $timeout, $transclude, $rootScope, navigationService, toastService });
    }

    $onInit() {
        this.allowNavigation = this.$rootScope.allowNavigation;
    }

    handleSearchTermChange() {
        this.onSearch({
            $event: {
                searchTerm: this.searchTerm
            }
        });
    }

    showMenu() {
        this.navigationService.expand(true);
    }
}

HeaderController.$inject = ['$element', '$timeout', '$transclude', '$rootScope', 'navigationService', 'toastService'];

export default HeaderController;
