class agendaController {
    constructor(
        $timeout,
        $scope,
        $state,
        $q,
        $rootScope,
        meetingApiService,
        actionApiService,
        dialogService,
        $sanitize,
        responsive,
        menuService,
        toastService,
        keyCode,
        stringService,
        eventEmitterService,
        domService,
        actionService,
        translationService,
        documentService,
        actionDetailService,
        signalRService,
        userService,
        documentViewService
    ) {
        Object.assign(this, {
            $timeout,
            $scope,
            $state,
            $q,
            $rootScope,
            meetingApiService,
            actionApiService,
            dialogService,
            $sanitize,
            responsive,
            menuService,
            toastService,
            keyCode,
            stringService,
            eventEmitterService,
            domService,
            actionService,
            translationService,
            documentService,
            actionDetailService,
            signalRService,
            userService,
            documentViewService
        });
        this.protocolIsLocked = false;
    }

    $onInit() {
        this.userService.getCurrentUser().then((currentUser) => {
            this.currentUser = currentUser;
        });
        if (this.isDisabled || this.lock) {
            this.removeEmptyActionAndDecision();
        }
        this.inlineEdit = true;

        this.eventEmitterService.subscribe('duplicateAction', (data) => {
            let index = _.findIndex(this.actions, (action) => {
                return action.id === data.id;
            });
            if (index !== -1) {
                this.duplicateAction(data);
            }
        });

        this.eventEmitterService.subscribe('deleteAction', (data) => {
            let index = _.findIndex(this.actions, (action) => {
                return action.id === data.id;
            });
            if (index !== -1) {
                this.removeAction(data);
            }
        });

        this.$scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if (!toParams.actionId) {
                this.selectedAction = null;
            } else {
                this.selectedAction = toParams.actionId;
            }
            if (!toParams.agendaId || !this.$state.includes('meeting.documents', { agendaId: this.agenda.id })) {
                this.showingAttachments = false;
            }
        });

        if (this.$state.includes('meeting.documents', { agendaId: this.agenda.id })) {
            this.showAttachments();
        }

        this.getActions();
    }

    $onDestroy() {
        let info = this.documentViewService.getInfo();
        if (info && info.agendaId === this.agenda.id) {
            this.documentViewService.nullInfo();
        }
        this.eventEmitterService.unSubscribe('deleteAction');
    }

    $doCheck() {
        if (!this.eventsInitialized && !this.isInDialog && this.agenda && this.agenda.id) {
            this.eventsInitialized = true;
            this.eventEmitterService.subscribe('agendaChangedElsewhere' + this.agenda.id, this.copyExternalChange.bind(this));
            this.eventEmitterService.subscribe('agendaDecisionRemoved' + this.agenda.id, this.decisionHasBeenDeleted.bind(this));
            this.eventEmitterService.subscribe('agendaIsBeingChanged' + this.agenda.id, this.updateEditingStatus.bind(this));
            this.eventEmitterService.subscribe('agendaActionRemoved' + this.agenda.id, this.actionHasBeenDeleted.bind(this));
            this.eventEmitterService.subscribe('agendaActionAdded' + this.agenda.id, this.actionHasBeenAdded.bind(this));
            this.eventEmitterService.subscribe('addedDocument' + this.agenda.id, this.updateDocuments.bind(this));
            this.eventEmitterService.subscribe('deletedDocument' + this.agenda.id, this.deleteDocument.bind(this));
            this.eventEmitterService.subscribe('agendaNotesChanged' + this.agenda.id, this.updateNotes.bind(this));
            this.eventEmitterService.subscribe('lockMeeting', this.setShowAgendaOptions.bind(this));
            this._checkSavedState();
        }
    }

    setShowAgendaOptions(protocolIsLocked) {
        this.protocolIsLocked = protocolIsLocked;
    }

    shouldShowOptions() {
        if ((!this.protocolIsLocked && !this.isDisabled) || (!this.protocolIsLocked && this.isDisabled && this.showAgendaOptions)) {
            return true;
        }

        return false;
    }

    _checkSavedState() {
        var item = localStorage.getItem(`savedState-${this.agenda.id}`);

        if (!!item) {
            localStorage.removeItem(`savedState-${this.agenda.id}`);
            let data = JSON.parse(item);
            this.meetingApiService.putAgendaProtocol(this.agenda.meetingId, this.agenda.id, data.protocol).then(() => {
                this.$scope.$applyAsync(() => {
                    this.agenda.protocol = data.protocol;
                    this.signalRService.updateAgendaNotes(this.agenda.meetingId, this.agenda.id, data.protocol, true);
                });
            });
        }
    }

    updateEditingStatus(user) {
        this.$scope.$applyAsync(() => {
            this.editing = true;
            this.userEditing = user;
        });
    }

    updateDocuments(document) {
        this.meetingApiService.clearCache(`${this.agenda.meetingId}/agendas`);
        this.documentService.updateCache(this.agenda.id, document);
        this.$scope.$applyAsync(() => {
            let index = _.findIndex(this.agenda.documents, (doc) => doc.name === document.name);
            if (index !== -1) {
                this.agenda.documents.splice(index, 1);
            }
            this.agenda.documents.push(document);
        });
    }

    deleteDocument(document) {
        this.meetingApiService.clearCache(`${this.agenda.meetingId}/agendas`);
        this.documentService.deleteDocumentFromCache(this.agenda.id, document);
        this.$scope.$applyAsync(() => {
            let index = _.findIndex(this.agenda.documents, (doc) => doc.id === document.id);
            if (index !== -1) {
                this.agenda.documents.splice(index, 1);
            }
        });
    }

    updateNotes(event) {
        this.meetingApiService.clearCache(`${this.agenda.meetingId}/agendas`);
        this.$scope.$applyAsync(() => {
            if (event.notes) {
                this.agenda.protocol = event.notes;
            }
            if (event.blur) {
                this.editing = false;
            }
        });
    }

    getActions() {
        this.actions = this.agenda.actions;

        if (!this.actions) {
            this.actions = [];
        }
        let indexOfLastAction = this.actions.length - 1;
        let hasEmpty = this.actions.length && (!this.actions[indexOfLastAction].name || this.actions[indexOfLastAction].name === '');
        if (this.inlineEdit && !this.isDisabled && !this.lock && !hasEmpty) {
            this.actions.push({});
        }
        if (this.$state.is('meeting.action')) {
            let action = _.find(this.actions, (action) => {
                return action.id === parseInt(this.$state.params.actionId);
            });
            if (action) {
                this.detail(action);
            }
        }
    }

    copyExternalChange(agenda) {
        this.$scope.$applyAsync(() => {
            if (agenda) {
                angular.extend(this.agenda, agenda);
                this.removeEmptyActionAndDecision();
                if (this.inlineEdit) {
                    this.addEmptyActionAndDecision();
                }
            }
        });
    }

    $onChanges(change) {
        if (
            (change.lock && change.lock.isFirstChange() && !change.lock.currentValue) ||
            (change.isDisabled && change.isDisabled.isFirstChange() && !change.isDisabled.currentValue)
        ) {
            this.addEmptyActionAndDecision();
        } else if (
            (change.lock && change.lock.currentValue !== change.lock.previousValue) ||
            (change.isDisabled && change.isDisabled.currentValue !== change.isDisabled.previousValue)
        ) {
            (change.lock && change.lock.currentValue) || (change.isDisabled && change.isDisabled.currentValue)
                ? this.removeEmptyActionAndDecision()
                : this.addEmptyActionAndDecision();
        }
    }

    isActionDisabled(action) {
        return (
            !this.currentUser ||
            (this.isDisabled && action.responsibleEmail !== this.currentUser.email && this.currentUser.email !== action.createdByEmail) ||
            this.agenda.isPostponed
        );
    }

    showAttachments($event) {
        if (this.agenda.isPostponed) {
            this.eventEmitterService.publishEvent('showPostponedAgendaAttachments', this.agenda);
            this.documentService.setCache(this.agenda.id, this.agenda.documents);
        } else {
            if (this.isInDialog) {
                this.eventEmitterService.publishEvent('showAgendaAttachments', this.agenda);
            } else {
                this.documentService.setCache(this.agenda.id, this.agenda.documents);
                this.documentViewService.open(this.agenda.id, this.meeting.id);
            }
        }
    }

    addEmptyActionAndDecision() {
        if (!this.isDisabled) {
            if (_.isEmpty(this.agenda.decisions)) {
                this.agenda.decisions.push({});
            } else {
                !angular.equals(this.agenda.decisions[this.agenda.decisions.length - 1], {}) ? this.agenda.decisions.push({}) : angular.noop;
            }

            if (this.actions) {
                !angular.equals(this.actions[this.actions.length - 1], {}) ? this.actions.push({}) : angular.noop;
            }
        }
    }

    removeEmptyActionAndDecision() {
        _.remove(this.agenda.decisions, (decision) => {
            return decision === {} || !decision.text;
        });

        if (this.actions) {
            _.remove(this.actions, (action) => {
                return this.stringService.isUndefinedOrEmpty(action.name);
            });
        }
    }

    hasContent() {
        return this.hasNotes() || this.hasActions() || this.hasDecisions();
    }

    hasNotes() {
        return !!this.agenda.protocol || this.hasFocusOnNotes;
    }

    hasActions() {
        return this.actions && this.actions.length > 0 && this.actions[0].name;
    }

    hasDecisions() {
        return this.agenda.decisions && this.agenda.decisions.length && this.agenda.decisions[0].text;
    }

    updateActions(actions) {
        this.actions = actions;
    }

    detail(action) {
        this.actionDetailService.open(action, 'meeting');
    }

    updateTitle() {
        return this._createWatcherForAgendaId().then(() => {
            return this.meetingApiService.putAgendaTitle(this.agenda.meetingId, this.agenda.id, this.agenda.title).then((agenda) => {
                this.signalRService.updateAgenda(this.agenda.meetingId, this.agenda);
            });
        });
    }

    updateDescription() {
        return this._createWatcherForAgendaId().then(() => {
            return this.meetingApiService.putAgendaDescription(this.agenda.meetingId, this.agenda.id, this.agenda.description).then((agenda) => {
                this.signalRService.updateAgenda(this.agenda.meetingId, this.agenda);
            });
        });
    }

    _createWatcherForAgendaId() {
        let deferred = this.$q.defer();
        let watcher;
        if (!this.agenda.id || this.agenda.id === 0) {
            watcher = this.$scope.$watch(
                () => {
                    return this.agenda.id;
                },
                () => {
                    if (this.agenda.id && this.agenda.id !== 0) {
                        deferred.resolve();
                    }
                }
            );
        } else {
            deferred.resolve();
        }

        return deferred.promise.then(() => {
            if (watcher) {
                watcher();
            }
        });
    }

    updateProtocol(event) {
        this.hasFocusOnNotes = false;
        let blur = event.sender === 'blur';
        return this.meetingApiService
            .putAgendaProtocol(this.agenda.meetingId, this.agenda.id, this.$sanitize(event.value))
            .then(() => {
                this.protocolForm.$setPristine();
                this.signalRService.updateAgendaNotes(this.agenda.meetingId, this.agenda.id, this.$sanitize(event.value), blur);
            })
            .catch(() => {
                blur = true;
                if (!this.isResetting) {
                    this.isResetting = true;
                    let data = {
                        protocol: this.$sanitize(event.value)
                    };
                    localStorage.setItem(`savedState-${this.agenda.id}`, JSON.stringify(data));
                    this.$scope.$emit('RevertedChanges');
                    window.location.reload();
                }
            });
    }

    showCreateActionDialog() {
        this.dialogService
            .custom('rym-create-action-dialog', {
                id: 'create action dialog',
                meetingId: this.agenda.meetingId
            })
            .then((data) => {
                let action = { name: data.name, endDate: data.date };
                if (data.responsible && data.responsible.id) {
                    action.responsibleId = data.responsible.id;
                }
                this.createAction(action);
            });
    }

    createAction(ac, isInline) {
        if (!ac.name) {
            this.actions.push({});
            return this.$q.reject();
        }

        ac.agendaId = this.agenda.id;
        if (isInline) {
            let newAction = this.actions.find((x) => x.id == null && x.name.length > 0);
            return this.actionApiService.create(ac).then((action) => {
                this.$rootScope.$broadcast('SavedChanges');
                angular.extend(newAction, action);
                this.signalRService.addedAgendaAction(this.agenda.meetingId, this.agenda.id, newAction);
                return { meetingId: this.agenda.meetingId, action: action };
            });
        } else {
            return this.actionApiService.create(ac).then((action) => {
                this.actions.push(action);
                this.signalRService.addedAgendaAction(this.agenda.meetingId, this.agenda.id, action);
            });
        }
    }

    createMultipleActions(action, users) {
        let promises = [];
        _.forEach(users, (user) => {
            let actionCopy = angular.copy(action);

            actionCopy.responsibleEmail = user.email;
            actionCopy.responsible = user.name;
            actionCopy.responsibleId = user.id;
            actionCopy.responsibleIsExternal = user.isExternal;
            promises.push(
                this.actionApiService.create(actionCopy).then((result) => {
                    this.signalRService.addedAgendaAction(this.agenda.meetingId, this.agenda.id, result);
                    return result;
                })
            );
        });

        return this.$q.all(promises).then((results) => {
            this.actions.splice(this.actions.length - 2, 0, ...results);
        });
    }

    newRow(add) {
        add ? this.actions.push({}) : this.actions.pop();
    }

    actionHasBeenDeleted(action) {
        let index = _.findIndex(this.actions, (a) => a.id === action.id);
        if (index !== -1) {
            this.$scope.$applyAsync(() => {
                this.actions.splice(index, 1);
                this.actionService.notifyActionUpdate();
            });
            if (this.$state.includes('meeting.action', { actionId: action.id })) {
                this.$state.go('meeting', {}, { location: 'replace' });
            }
        }
    }

    decisionHasBeenDeleted(decision) {
        let index = _.findIndex(this.agenda.decisions, (d) => d.id === decision.id);
        if (index !== -1) {
            this.agenda.decisions.splice(index, 1);
        }
    }

    actionHasBeenAdded(action) {
        let index = _.findIndex(this.actions, (a) => a.id === action.id);
        if (index === -1) {
            this.$scope.$applyAsync(() => {
                this.actions.push(action);
                this.actionService.notifyActionUpdate();
            });
        }
    }

    removeAction(action) {
        if (!action.id) {
            let index = this.actions.indexOf(action);

            if (index === this.actions.length - 1) {
                return;
            }

            this.actions.splice(index, 1);
            if (this.$state.includes('meeting.action')) {
                this.actionDetailService.close();
            }
            return;
        }

        let index = _.findIndex(this.actions, (a) => a.id === action.id);
        if (action.isDeleted) {
            if (index !== -1) {
                this.actions.splice(index, 1);
            }
        } else {
            this.dialogService
                .confirm({
                    id: 'delete action dialog',
                    title: this.translationService.translate('client_DeleteConfirm'),
                    description: this.translationService.translate('client_DeleteItemConfirm', { itemName: action.name })
                })
                .then((result) => {
                    this.actionApiService
                        .remove(action.id, action.office365Id)
                        .then(() => {
                            this.actions.splice(index, 1);

                            if (this.$state.includes('meeting.action', { actionId: action.id })) {
                                this.$state.go('meeting', {}, { location: 'replace' });
                            }
                            this.eventEmitterService.unSubscribe('agendaActionChanged' + action.id);

                            this.signalRService.removedAgendaAction(this.agenda.meetingId, this.agenda.id, action);
                            this.eventEmitterService.publishEvent('agendaActionRemoved' + this.agenda.id, action);
                            this.actionService.notifyActionUpdate();
                            this.toastService.success({ title: this.translationService.translate('client_ActionDeleted') });
                        })
                        .catch((exception) => {
                            this.toastService.error({ description: exception.data.message });
                        })
                        .finally(() => {
                            this.actionDetailService.close();
                        });
                });
        }
    }

    keyDownShowMenu($event) {
        if ($event.which === this.keyDown.ENTER || $event.which === this.keyDown.DOWN) {
            this.showMenu($event);
        }
    }

    showMenu($event) {
        let menuButton = $event.currentTarget;
        let options = {};
        if (this.isTemplate) {
            options = {
                items: [
                    {
                        id: 'menu option - delete agenda item',
                        icon: 'delete_outline',
                        text: this.translationService.translate('client_Delete'),
                        onClick: () => this.confirmRemoveAgenda()
                    }
                ]
            };
        } else if (this.agenda.isPostponed) {
            options = {
                items: [
                    {
                        id: 'menu option - add postponed agenda item',
                        icon: 'add',
                        text: this.translationService.translate('client_AddPostponeAgenda'),
                        onClick: () => this.addPostponedAgendaToCurrentMeeting()
                    },
                    {
                        id: 'menu option - delete agenda item',
                        icon: 'delete_outline',
                        text: this.translationService.translate('client_Delete'),
                        onClick: () => this.confirmRemoveAgenda()
                    }
                ]
            };
        } else {
            options = {
                items: [
                    {
                        id: 'menu option - postpone agenda item',
                        icon: 'arrow_right_alt',
                        text: this.translationService.translate('client_PostponeAgenda'),
                        title: this.translationService.translate('client_PostponeAgendaTitle'),
                        onClick: () => this.postponeAgenda()
                    },
                    {
                        id: 'menu option - delete agenda item',
                        icon: 'delete_outline',
                        text: this.translationService.translate('client_Delete'),
                        onClick: () => this.confirmRemoveAgenda()
                    }
                ]
            };
        }

        this.menuService.actionMenu(options, angular.element(menuButton), false);
    }

    confirmRemoveAgenda() {
        let data = {
            agenda: this.agenda,
            deleteChildren: true
        };

        if ((!this.agenda.children || this.agenda.children.length === 0) && !this.hasContent()) {
            this.isInactive = true;
            return this.remove({ data: data }).then(
                () => {},
                () => (this.isInactive = false)
            );
        }

        let options = {
            id: 'delete agenda item dialog',
            title: this.translationService.translate('client_DeleteAgendaItem'),
            description: this.translationService.translate('client_DeleteAgendaItemConfirm')
        };

        if (!this.agenda.isPostponed && this.agenda.children && this.agenda.children.length > 0) {
            options.shouldConfirm = true;
            options.confirmTitle = this.translationService.translate('client_RemoveAgendaChildren');
        }

        return this.dialogService.confirm(options).then((options) => {
            data.deleteChildren = options.confirm || !this.agenda.children || this.agenda.children.length === 0;
            this.isInactive = true;
            this.remove({ data: data }).then(
                () => {},
                () => (this.isInactive = false)
            );
        });
    }

    postponeAgenda() {
        let data = {
            agenda: this.agenda,
            deleteChildren: true
        };
        this.isInactive = true;
        this.postpone({ data: data }).then(
            () => {},
            () => (this.isInactive = false)
        );
    }

    addPostponedAgendaToCurrentMeeting() {
        let data = {
            agenda: this.agenda
        };
        this.isInactive = true;
        this.addPostponed({ data: data })
            .then(() => {})
            .finally(() => {
                this.isInactive = false;
                this.toastService.success({
                    title: this.translationService.translate('client_AddedPostponedAgendaToMeeting')
                });
            });
    }

    duplicateAction(action) {
        if (this.creatingAction) {
            return;
        }
        this.creatingAction = true;
        this.actionApiService
            .duplicate(action.id, action.office365Id)
            .then((result) => {
                this.toastService.success({
                    title: this.translationService.translate('client_ActionDuplicated')
                });
                let index = this.actions.indexOf(action);
                this.actions.splice(index + 1, 0, result);

                if (this.$state.includes('meeting.action')) {
                    this.actionDetailService.close();
                }

                if (this.selectedAction) {
                    this.selectedAction = undefined;
                }
                this.signalRService.addedAgendaAction(this.agenda.meetingId, this.agenda.id, result);
                this.eventEmitterService.publishEvent('agendaActionAdded' + this.agenda.id, result);
                result.isNew = true;
            })
            .finally(() => {
                this.creatingAction = false;
            });
    }

    showCreateDecisionDialog() {
        this.dialogService
            .prompt({
                id: 'create decision dialog',
                title: this.translationService.translate('client_NewDecision'),
                placeholder: this.translationService.translate('meetings_Decision') + ' *',
                ok: this.translationService.translate('create')
            })
            .then((data) => {
                this.createDecision(data);
            });
    }

    createDecision(text, isInline) {
        if (!text && !this.isDisabled) {
            this.agenda.decisions.push({});
            return this.$q.reject();
        }

        return this.meetingApiService.createDecision(this.agenda.meetingId, this.agenda.id, text).then((decision) => {
            this.eventEmitterService.publishEvent('invalidateDecisionCache');
            if (isInline) {
                return { meetingId: this.agenda.meetingId, decision: decision };
            } else {
                this.agenda.decisions.push(decision);
                this.$rootScope.$broadcast('SavedChanges');
            }
        });
    }

    updatedDecision() {
        this.signalRService.updateAgenda(this.agenda.meetingId, this.agenda);
    }

    removeDecision(decision, oldText) {
        if (decision.isDeleted === true) {
            return;
        }

        if (!decision.id) {
            let index = this.agenda.decisions.indexOf(decision);

            if (index === this.agenda.decisions.length - 1) {
                return;
            }

            this.agenda.decisions.splice(index, 1);
            return;
        }

        this.dialogService
            .confirm({
                id: 'delete decision dialog',
                title: this.translationService.translate('client_DeleteConfirm'),
                description: this.translationService.translate('client_DeleteDecision')
            })
            .then(() => {
                this.meetingApiService.removeDecision(this.agenda.meetingId, decision.id).then((response) => {
                    decision.isDeleted = true;
                    let index = this.agenda.decisions.indexOf(decision);
                    this.agenda.decisions.splice(index, 1);
                    this.signalRService.updateAgenda(this.agenda.meetingId, this.agenda);
                    this.eventEmitterService.publishEvent('invalidateDecisionCache');
                });
            })
            .catch(() => {
                if (oldText) {
                    decision.text = oldText;
                }
            });
    }

    hasFocus() {
        return this.hasFocusOnNotes || this.hasFocusOnActions || this.hasFocusOnDecisions;
    }

    setFocusOnNotes(focus) {
        this.hasFocusOnNotes = focus;
        if (focus) {
            this.userService.getCurrentUser().then((user) => {
                this.signalRDelay = this.$timeout(() => {
                    this.signalRService.updatingAgenda(this.agenda.meetingId, this.agenda.id, user);
                    this.signalRDelay = undefined;
                }, 3000);
            });
        } else {
            if (this.signalRDelay) {
                this.$timeout.cancel(this.signalRDelay);
            } else {
                this.signalRService.updateAgendaNotes(this.agenda.meetingId, this.agenda.id, this.$sanitize(this.agenda.protocol), true);
            }
        }
    }

    setFocusOnDecision(focus) {
        this.hasFocusOnDecisions = focus;
    }

    hasContent() {
        return this.hasNotes() || this.hasActions() || this.hasDecisions();
    }

    isShowingLabelForNotes() {
        return (this.isDisabled && !this.hasFocusOnNotes) || this.lock ? this.hasNotes() : this.hasContent();
    }

    isShowingLabelForActions() {
        return this.isDisabled || this.lock ? this.hasActions() : this.hasContent();
    }

    isShowingLabelForDecisions() {
        return this.isDisabled || this.lock ? this.hasDecisions() : this.hasContent();
    }

    isShowingLabels() {
        return this.hasContent();
    }

    handleKeyDown(event) {
        if (this.agenda.title && event.keyCode === this.keyCode.ENTER) {
            let nextElement = this.domService.findNextTabStop(event.target);
            this.$timeout(
                () => {
                    nextElement.focus();
                },
                0,
                false
            );
        }
    }

    _notifyPossibleAgendaChange(agenda) {
        if (this.isInDialog) {
            this.eventEmitterService.publishEvent('agendaChangedElsewhere' + this.agenda.id, agenda ? agenda : this.agenda);
        }
    }
}

agendaController.$inject = [
    '$timeout',
    '$scope',
    '$state',
    '$q',
    '$rootScope',
    'meetingApiService',
    'actionApiService',
    'dialogService',
    '$sanitize',
    'responsive',
    'menuService',
    'toastService',
    'keyCode',
    'stringService',
    'eventEmitterService',
    'domService',
    'actionService',
    'translationService',
    'documentService',
    'actionDetailService',
    'signalRService',
    'userService',
    'documentViewService'
];
export default agendaController;
