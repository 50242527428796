import controller from './categoryDialog.controller';
import template from './categoryDialog.html';
import './categoryDialog.less';

let categoryDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default categoryDialogComponent;
