import template from './sendMeetingDialog.html';
import './sendMeetingDialog.less';
import controller from './sendMeetingDialog.controller';

let sendMeetingDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default sendMeetingDialogComponent;
