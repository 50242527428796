class ProfileTabController {
    constructor(
        $q,
        $rootScope,
        $state,
        navigationService,
        userApiService,
        menuService,
        authService,
        toastService,
        organizationService,
        userService,
        organizationApiService,
        eventEmitterService,
        translationService,
        signalRService
    ) {
        Object.assign(this, {
            $q,
            $rootScope,
            $state,
            navigationService,
            userApiService,
            menuService,
            authService,
            toastService,
            organizationService,
            userService,
            organizationApiService,
            eventEmitterService,
            translationService,
            signalRService
        });
    }

    $onInit() {
        this.userService.getCurrentUser().then((user) => {
            this.user = user;
        });

        this.organizationService.getCurrentOrganization().then((organization) => {
            this.currentOrganization = organization;
        });

        this.eventEmitterService.subscribe('organizationNameChange', (org) => (this.currentOrganization = org));
        this.eventEmitterService.subscribe('userChangedName', (user) => (this.user = user));
        this.organizationService.isLoggedInThroughStratsys().then((bool) => {
            this.isLoggedInThroughStratsys = bool;
        });
    }

    _goToOrReloadState() {
        let state = 'meetings';
        if (!this.$rootScope.isFetchingMeeting && !this.$rootScope.isFetchingAction) {
            if (this.$state.is(state)) {
                this.$state.reload();
            } else {
                this.$state.go(state);
            }
        }
    }

    showProfileMenu($event) {
        let target = $event.currentTarget,
            options = {
                direction: 'south-east',
                aside: true,
                offsetX: -10,
                items: [
                    {
                        id: 'menu option - logout',
                        icon: 'exit_to_app',
                        text: this.translationService.translate('logout'),
                        onClick: () => this.logout()
                    }
                ]
            };

        if (this.navigationService.isCollapsed()) {
            options.offsetX = -5;
        }

        this.menuService.actionMenu(options, angular.element(target), false);
    }

    logout() {
        this.toastService.loading({ title: this.translationService.translate('client_SigninOut') });
        this.authService.logout();
    }

    goToSettingsPage() {
        this.$state.go('settings');
    }

    isCollapsed() {
        return this.navigationService.isCollapsed();
    }
}

ProfileTabController.$inject = [
    '$q',
    '$rootScope',
    '$state',
    'navigationService',
    'userApiService',
    'menuService',
    'authService',
    'toastService',
    'organizationService',
    'userService',
    'organizationApiService',
    'eventEmitterService',
    'translationService',
    'signalRService'
];

export default ProfileTabController;
