import controller from './meetingDetails.controller.js';
import template from './meetingDetails.html';
import './meetingDetails.less';

let meetingDetailsComponent = {
    template,
    controller,
    bindings: {
        meeting: '=',
        isOpen: '=',
        previousMeeting: '=',
        isDisabled: '<'
    }
};

export default meetingDetailsComponent;
