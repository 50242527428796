class ScrollbarController {
    constructor($element, $scope, $timeout, eventEmitterService, scrollbarService) {
        this.restrict = 'E';
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.eventEmitterService = eventEmitterService;
        this.scrollbarService = scrollbarService;
    }

    $onInit() {
        this.scrollbarWidth = this.scrollbarService.getScrollBarWidth();
    }

    $postLink() {
        if (this.delay) {
            this.$timeout(
                () => {
                    this.bindScroll();
                },
                this.delay,
                false
            );
        } else {
            this.bindScroll();
        }
    }

    bindScroll() {
        let element = this.$element[0],
            barArea = document.createElement('dynamic-rym-scroll-area-bar-area'),
            bar = document.createElement('dynamic-rym-scroll-area-bar'),
            content = element.querySelector('.rym-scroll-content');

        if (this.SetFocus) {
            content.focus();
        }

        barArea.id = 'id' + new Date().getTime();

        if (this.calc) {
            let updateHeight = new FpsCtrl(1, () => {
                let flexCol = document.querySelector('.rym-view-container');
                if (!flexCol) {
                    updateHeight.pause();
                    return;
                }

                let windowHeight = flexCol.clientHeight - 166;
                let nodes = element.querySelectorAll('.rym-scroll-calc');
                let height = 0;
                _.forEach(nodes, (node) => {
                    height += node.offsetHeight;
                });
                if (height > windowHeight) {
                    height = windowHeight;
                }
                element.style.height = height + 'px';
            });

            updateHeight.start();
        }

        content &&
            angular.element(content).bind('scroll', (event) => {
                if (angular.isDefined(this.onScrollToBottomOffset)) {
                    this._handleScrollEvent(event, content);
                }

                this.eventEmitterService.publishEvent('scroll');
            });

        if (this.scrollbarWidth === 0) {
            this.$element.addClass('activated');
            return;
        }

        content.style.marginRight = '-' + this.scrollbarWidth + 'px';
        this.$element.addClass('activated');

        if (angular.isUndefined(content) || content === null) {
            return this.$scope.$destroy();
        }

        barArea.appendChild(bar);
        element.appendChild(barArea);

        let update = new FpsCtrl(24, () => {
            let top = (100 * content.scrollTop) / content.scrollHeight;
            let height = (100 * content.clientHeight) / content.scrollHeight;
            if (content.clientHeight === content.scrollHeight && !barArea.classList.contains('inactive')) {
                barArea.classList.add('inactive');
            } else if (content.clientHeight !== content.scrollHeight && barArea.classList.contains('inactive')) {
                barArea.classList.remove('inactive');
            }
            bar.style.top = top + '%';
            bar.style.height = height + '%';
        });

        let leaveFocus = (ev) => {
            bar.classList.remove('focused');
        };

        let leaveArea = (ev) => {
            bar.classList.remove('active');
            update.pause();
        };

        if (this.autoHide) {
            element.addEventListener('mouseover', (event) => {
                bar.classList.add('active');
                update.start();
            });

            element.addEventListener('mouseleave', leaveArea);
        } else {
            this.$timeout(() => {
                bar.classList.add('active');
                update.start();
            }, 300);
        }

        barArea.addEventListener('mouseover', (event) => {
            bar.classList.add('focused');
        });

        barArea.addEventListener('mouseleave', leaveFocus);

        if (document.onmousewheel !== undefined) {
            barArea.addEventListener(
                'mousewheel',
                (event) => {
                    content.scrollTop += -event.wheelDelta;
                },
                { passive: true }
            );
        } else {
            barArea.addEventListener(
                'wheel',
                (event) => {
                    content.scrollTop += event.deltaY * 40;
                },
                { passive: true }
            );
        }

        bar.addEventListener('mousedown', (event) => {
            event.preventDefault();
            barArea.removeEventListener('mouseleave', leaveFocus);
            if (this.autoHide) {
                element.removeEventListener('mouseleave', leaveArea);
            }

            let startY = event.clientY,
                factorY = content.scrollHeight / barArea.clientHeight,
                startScrollTop = content.scrollTop;
            barArea.setAttribute('active', '');

            let mouseMove = (ev) => {
                let dY = (ev.clientY - startY) * factorY;
                content.scrollTop = startScrollTop + dY;
            };

            let mouseUp = (ev) => {
                let isInPath = (ev.target && ev.target.querySelector(barArea.id)) || ev.target.id === barArea.id || barArea.children[0] === ev.target;

                if (!isInPath) {
                    bar.classList.remove('focused');
                }
                document.removeEventListener('mousemove', mouseMove);
                document.removeEventListener('mouseup', mouseUp);
                barArea.addEventListener('mouseleave', leaveFocus);

                if (this.autoHide) {
                    element.addEventListener('mouseleave', leaveArea);
                }

                barArea.removeAttribute('active');
            };
            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
        });

        function FpsCtrl(fps, callback) {
            let delay = 1000 / fps,
                time = null,
                frame = -1,
                tref;

            function loop(timestamp) {
                if (time === null) time = timestamp;
                let seg = Math.floor((timestamp - time) / delay);
                if (seg > frame) {
                    frame = seg;
                    callback({
                        time: timestamp,
                        frame: frame
                    });
                }
                tref = requestAnimationFrame(loop);
            }

            this.isPlaying = false;

            this.frameRate = function (newfps) {
                if (!arguments.length) return fps;
                fps = newfps;
                delay = 1000 / fps;
                frame = -1;
                time = null;
            };

            this.start = function () {
                if (!this.isPlaying) {
                    this.isPlaying = true;
                    tref = requestAnimationFrame(loop);
                }
            };

            this.pause = function () {
                if (this.isPlaying) {
                    cancelAnimationFrame(tref);
                    this.isPlaying = false;
                    time = null;
                    frame = -1;
                }
            };
        }
    }

    _handleScrollEvent($event, content) {
        let scrollPosition = content.scrollTop;
        let bottomScrollPosition = content.clientHeight + scrollPosition;

        if (bottomScrollPosition === content.scrollHeight) {
            content.scrollTop--;
        }

        if (content.scrollHeight - this.onScrollToBottomOffset <= bottomScrollPosition) {
            this.onScrollToBottom({ $event: $event });
        } else {
            this.onScrollOverBottom({ $event: $event });
        }
    }
}

ScrollbarController.$inject = ['$element', '$scope', '$timeout', 'eventEmitterService', 'scrollbarService'];

export default ScrollbarController;
