class tagsInputController {
    constructor(menuService, $timeout, translationService, $element, userService) {
        Object.assign(this, {
            menuService,
            $timeout,
            translationService,
            $element,
            userService
        });
    }

    $doCheck() {
        if (!this.hasFocused && this.autoFocus && this.$element[0].getElementsByTagName('input')[0]) {
            this.hasFocused = true;
            this.$element[0].getElementsByTagName('input')[0].focus();
        }
    }

    $onInit() {
        this.isLoading = true;
        this.userService
            .getCurrentUser()
            .then((user) => {
                this.currentUser = user;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    handleClickAtSelected($event, user) {
        if (this.allowChange) {
            this.showMenu($event);
            return;
        }
        $event.user = user;
        this.onClickAtSelected({
            $event: $event
        });
    }

    showMenu($event) {
        this.menuService.userMenu(
            {
                direction: this.lockMenu,
                disableSearch: this.disableSearch,
                placeholder: this.searchPlaceholder,
                createText: this.createText,
                searchTerm: this.searchTerm,
                selectedUsers: this.selectedItems,
                suggestedUsers: this.suggestedItems,
                suggestedUsersText: this.suggestedUsersText,
                onClick: (result) => {
                    this.add(result.user);
                },
                onInvite: (email) => {
                    this.onCreate({
                        $event: {
                            item: {
                                email: email
                            }
                        }
                    });
                }
            },
            angular.element($event.target),
            false
        );
        this.searchTerm = '';
    }

    add(item) {
        this.onSelect({
            $event: {
                item: item
            }
        });
    }
}

tagsInputController.$inject = ['menuService', '$timeout', 'translationService', '$element', 'userService'];

export default tagsInputController;
