class FileService {
    constructor($window) {
        Object.assign(this, { $window });
    }

    tryDownloadUsingBlob(data, contentType, contentSize, filename) {
        let success = false;
        try {
            let blob;
            if (contentSize) {
                blob = new Blob(data, { type: contentType, size: contentSize });
            } else {
                blob = new Blob(data, { type: contentType });
            }

            if (this.$window.navigator.msSaveOrOpenBlob && contentSize) this.$window.navigator.msSaveOrOpenBlob(blob, filename);
            else {
                // Try using other saveBlob implementations, if available
                var saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                if (saveBlob === undefined) throw 'Blob not supported';
                saveBlob(blob, filename);
            }
            success = true;
        } catch (ex) {}

        return success;
    }

    useLink(url, filename, contentSize) {
        let success = false,
            link = document.createElement('a'),
            isIFramed = this.$window.self !== this.$window.top;

        if ('download' in link && (!!contentSize || !isIFramed)) {
            // Try to simulate a click
            try {
                link.setAttribute('href', url);
                // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                link.setAttribute('download', filename);
                // Simulate clicking the download link
                let event = document.createEvent('MouseEvents');
                event.initMouseEvent('click', true, true, this.$window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                link.dispatchEvent(event);
                success = true;
            } catch (ex) {
                console.log(ex);
            }
        }

        return success;
    }

    tryDownloadUsingLink(byteArray, contentType, contentSize, filename) {
        let success = false;
        var urlCreator = this.$window.URL || this.$window.webkitURL || this.$window.mozURL || this.$window.msURL;
        if (urlCreator) {
            let blob = new Blob([byteArray], { type: contentType, size: contentSize });
            let url = urlCreator.createObjectURL(blob);

            success = this.useLink(url, filename, contentSize);

            if (!success) {
                // Fallback to window.location method
                try {
                    let base64string = this._byteArrayToBase64(byteArray),
                        base64url = `data:${contentType};base64, ${base64string}`;

                    let win = this.$window.open(base64url, '_blank');
                    if (win) {
                        win.focus();
                        success = true;
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
        }

        return success;
    }

    tryDownloadBase64Data(byteArray, contentType) {
        let dataUri = `data:${contentType};base64,${byteArray}`;
        // Fallback to window.open method
        this.$window.open(dataUri, '_blank', '');
    }

    _byteArrayToBase64(bytes) {
        var binary = '';
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) binary += String.fromCharCode(bytes[i]);
        return window.btoa(binary);
    }
}

FileService.$inject = ['$window'];

export default FileService;
