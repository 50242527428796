import { HTTP_STATUS_CODES } from '../../../app.constants.js';
const STATUS_ROW = `<rym-row row="::$ctrl.statusRow" ng-click="::$ctrl.toggleStatus($event)"><rym-row-icon><i class="material-icons {{::$ctrl.action.isFinished ? 'green' : ''}} left(0-2) right(0-4)">{{::$ctrl.statusRow.icon}}</i></rym-row-icon></rym-row>`;
const RESPONSIBLE_ROW = `<rym-row row="::$ctrl.assignRow" ng-click="::$ctrl.showSelectResponsible($event)"><rym-row-icon><rym-picture class="rym-profile-picture left(0-2) right(0-2)" user="::$ctrl.assignRow.user"></rym-picture></rym-row-icon></rym-row>`;

class ActionDetailController {
    constructor(
        $scope,
        $timeout,
        $element,
        $state,
        $filter,
        $rootScope,
        actionApiService,
        meetingApiService,
        dateService,
        userService,
        menuService,
        recompileService,
        rowService,
        eventEmitterService,
        actionService,
        translationService,
        dialogService,
        actionDetailService,
        signalRService,
        userApiService,
        locationService
    ) {
        Object.assign(this, {
            $scope,
            $timeout,
            $element,
            $state,
            $filter,
            $rootScope,
            actionApiService,
            meetingApiService,
            dateService,
            userService,
            menuService,
            recompileService,
            rowService,
            eventEmitterService,
            actionService,
            translationService,
            dialogService,
            actionDetailService,
            signalRService,
            userApiService,
            locationService
        });
        this.selected = false;
        this.allowNavigation = $rootScope.allowNavigation;
    }

    $doCheck() {
        if (this.actionDetailService.getAction() && this.action !== this.actionDetailService.getAction()) {
            this.initialized = false;
            this.showMeetingInfo = false;
            this.action = this.actionDetailService.getAction();
            this.showMeetingInfo = !this.$state.includes('meeting') && this.action.isParticipantInMeeting && !this.action.isPostponed;
            this.buildRowData();
            this.initialized = true;
        }
    }

    $onInit() {
        if (this.$state.is('meeting.action')) {
            this.actionApiService
                .get(this.$state.params.actionId)
                .then((action) => {})
                .catch((error) => {
                    if (error.status === HTTP_STATUS_CODES.NOTFOUND || error.status === HTTP_STATUS_CODES.FORBIDDEN) {
                        this.dialogService.alert({
                            id: 'action not found dialog',
                            title: this.translationService.translate('client_ActionNotFound'),
                            description: this.translationService.translate('client_ActionNotFoundDescription')
                        });
                    }
                });

            this.meetingId = this.$state.params.meetingId;
        }

        if (this.$state.is('meeting')) {
            this.meetingId = this.$state.params.id;
        }

        this.$rootScope.$on('$stateChangeStart', (e, toState, fromState) => {
            if (toState.name !== 'actions') {
                this.action = null;
                this.actionDetailService.nullAction();
            }
        });

        this.eventEmitterService.subscribe('toggleStatus', this.onToggleStatus.bind(this));
        this.eventEmitterService.subscribe('updateActionDetail', this.onUpdateActionDetail.bind(this));
        this.eventEmitterService.subscribe('actionDetailClose', () => (this.action = null));
    }

    emitChange() {
        this.eventEmitterService.publishEvent(this.action.id, { action: this.action, source: 'actionDetails' });
    }

    onUpdateActionDetail(action) {
        if (this.action && this.action.id === action.id) {
            this.$timeout(() => {
                this.action = {
                    ...this.action,
                    ...action
                };

                this.buildRowData();
                this.recompileService.recompile(this.$scope, this._getResponsibleRow(), RESPONSIBLE_ROW);
                this.recompileService.recompile(this.$scope, this._getEndDateRow());
                this.recompileService.recompile(this.$scope, this._getStatusRow(), STATUS_ROW);
            });
        }
    }

    onToggleStatus(action) {
        if (parseInt(this.$state.params.actionId) === action.id) {
            this.action = action;
            this.statusRow.icon = this.action.isFinished ? 'check_box' : 'check_box_outline_blank';

            this.buildRowData();
            this.activeElement = this._getStatusRow();
            this._recompile(STATUS_ROW, true);
            this.recompileService.recompile(this.$scope, this._getEndDateRow());
        }
    }

    _getResponsibleRow() {
        return this.$element.find('rym-row')[1];
    }

    _getEndDateRow() {
        return this.$element.find('rym-row')[3];
    }

    _getStatusRow() {
        return this.$element.find('rym-row')[2];
    }

    openMenu(event) {
        let menuButton = angular.element(event.currentTarget);
        this.overflowMenuOpen = true;
        let options = {
            items: [
                {
                    id: 'menu option - duplicate action',
                    icon: 'content_copy',
                    text: this.translationService.translate('client_Duplicate'),
                    onClick: () => this.duplicate()
                },
                {
                    id: 'menu option - delete action',
                    icon: 'delete_outline',
                    text: this.translationService.translate('client_Delete'),
                    onClick: () => this.remove()
                }
            ]
        };

        this.menuService.actionMenu(options, menuButton, false, false).finally(() => {
            this.overflowMenuOpen = false;
        });
    }

    buildRowData() {
        this.assignRow = {
            title: this.translationService.translate('client_Responsible'),
            description: this.action.responsible ? this.action.responsible : '---',
            user: { name: this.action.responsible, email: this.action.responsibleEmail, isExternal: this.action.responsibleIsExternal }
        };

        this.createdByRow = {
            title: this.translationService.translate('client_CreatedBy'),
            description: this.action.createdBy,
            user: { name: this.action.createdBy, email: this.action.createdByEmail },
            isDisabled: true
        };

        this.statusRow = {
            title: this.translationService.translate('client_Status'),
            description: this.action.isFinished
                ? this.translationService.translate('client_ActionFinished')
                : this.translationService.translate('client_ActionUnfinished'),
            icon: this.action.isFinished ? 'check_box' : 'check_box_outline_blank'
        };

        this.endDateRow = {
            title: this.translationService.translate('client_Deadline'),
            description: this.action.endDate ? this.$filter('date')(this.action.endDate, 'rymDate') : '---',
            icon: 'update',
            cssClass: this._getCssClassForEndDate()
        };

        if (this.action.from && this.$state.includes('actions')) {
            this.fromMeetingRow = {
                title: this.action.from,
                description: this.$filter('date')(new Date(this.action.fromDate), 'rymDate'),
                icon: 'event_note'
            };
        }

        this.initialized = true;
    }

    _isDelayed() {
        return !this.action.isFinished && this.action.endDate && new Date(this.action.endDate) < new Date();
    }

    _getCssClassForEndDate() {
        return this._isDelayed() ? 'attention(on)' : '';
    }

    goToMeeting() {
        if (!this.action.isParticipantInMeeting || this.action.isPostponed) {
            return;
        }
        let meetingId = this.action.meetingId;
        let actionId = this.action.id;
        this.action = null;
        this.actionDetailService.nullAction();
        this.locationService.goToMeetingAction(meetingId, actionId);
    }

    updateActionName() {
        if (this.action.name) {
            this.oldActionName = this.action.name;
            return this.actionApiService.putName(this.action.id, this.action.name).then((result) => {
                this._notifyActionUpdate(this.action);
            });
        }

        this.action.name = this.oldActionName;
    }

    updateEndDate(date) {
        this.rowService.playLoadingAnimation(this.activeElement);
        this.actionApiService
            .updateEndDate(this.action.id, date)
            .then((result) => {
                this.action.endDate = result.endDate;
                this.dateService.convertEndDateToString(this.action);
                this.endDateRow = {
                    ...this.endDateRow,
                    description: this.$filter('date')(this.action.endDate, 'rymDate'),
                    cssClass: this._getCssClassForEndDate()
                };
                this._notifyActionUpdate(this.action);
            })
            .finally(() => {
                this.endDateMenuOpen = false;
                this._recompile();
            });
    }

    _recompile(custom, skipFocusEvent) {
        let newElement = this.recompileService.recompile(this.$scope, this.activeElement, custom);
        this.rowService.playDoneAnimation(newElement, skipFocusEvent);
        this.emitChange();
    }

    updateResponsible(user) {
        this.rowService.playLoadingAnimation(this.activeElement);
        this.actionApiService
            .putResponsible(this.action.id, user)
            .then((result) => {
                this.action.responsibleEmail = result.responsibleEmail;
                this.action.responsible = result.responsible;
                this.action.responsibleIsExternal = result.responsibleIsExternal;
                this.action.responsibleId = result.responsibleId;
                this.action.sortOrder = 0;
                if (this.action.meetingId && !this.action.isParticipantInMeeting && !this.$state.is('meeting.action')) {
                    this.$state.go(this.$state.params.parentUrl);
                    this.$scope.$emit('spliceActions', this.action);
                }
                this.assignRow = {
                    title: this.translationService.translate('client_Responsible'),
                    description: this.action.responsible ? this.action.responsible : '---',
                    user: { name: this.action.responsible, email: this.action.responsibleEmail, isExternal: this.action.responsibleIsExternal }
                };
            })
            .finally(() => {
                this.responsibleMenuOpen = false;
                this._recompile(RESPONSIBLE_ROW);
                this._notifyActionUpdate(this.action);
            });
    }

    updateComment() {
        return this.actionApiService.putComment(this.action.id, this.action.comment).then((result) => {
            this._notifyActionUpdate(result);
        });
    }

    remove() {
        this.eventEmitterService.publishEvent('deleteAction', this.action);
    }

    duplicate() {
        this.eventEmitterService.publishEvent('duplicateAction', this.action);
    }

    toggleStatus($event) {
        this._setActiveElement($event);
        this.rowService.playLoadingAnimation(this.activeElement);
        this.actionApiService
            .toggleStatus(this.action)
            .then((result) => {
                this.action.isFinished = result.isFinished;
                this.action.hasToggled = true;
                this.buildRowData();
                if (result.isFinished) {
                    this.userService.shouldRateRYM();
                }
            })
            .finally(() => {
                this._recompile(STATUS_ROW);
                this.recompileService.recompile(this.$scope, this._getEndDateRow());
                this._notifyActionUpdate(this.action);
            });
    }

    showDate($event) {
        this._setActiveElement($event);
        this.endDateMenuOpen = true;

        let options = {
            date: this.action.endDate
        };

        this.menuService
            .dateMenu(options, angular.element(this.activeElement), false, false)
            .then((date) => {
                this.updateEndDate(date);
            })
            .catch(() => (this.endDateMenuOpen = false));
    }

    showSelectResponsible($event) {
        if (this.responsibleMenuOpen) {
            return;
        }
        this._setActiveElement($event);

        this.responsibleMenuOpen = true;
        $event.preventDefault();
        $event.stopImmediatePropagation();

        let options = {
            selectedUsers: [],
            onClick: (result) => {
                this.updateResponsible(result.user);
            },
            onInvite: (email) => {
                this.updateResponsible({ email: email, isExternal: true });
            }
        };

        if (this.meetingId) {
            this.meetingApiService.getParticipantsForMeeting(this.meetingId).then((result) => {
                let suggestedUsers = this.$filter('filter')(result, { isDeleted: false });
                options.suggestedUsers = suggestedUsers;
                this.menuService.userMenu(options, angular.element(this.activeElement), false).catch(() => (this.responsibleMenuOpen = false));
            });
        } else {
            this.userApiService.getSuggestedParticipants().then((suggestedUsers) => {
                options.suggestedUsers = suggestedUsers;
                this.menuService.userMenu(options, angular.element(this.activeElement), false).catch(() => (this.responsibleMenuOpen = false));
            });
        }
    }

    _setActiveElement($event) {
        this.activeElement = $event.currentTarget;
    }

    _notifyActionUpdate(action) {
        this.actionService.notifyActionUpdate(action);
        this.signalRService.updateAction(action);
    }

    close() {
        if (!this.responsibleMenuOpen && !this.endDateMenuOpen && !this.overflowMenuOpen) {
            this.action = null;
            this.actionDetailService.close();
        }
    }
}

ActionDetailController.$inject = [
    '$scope',
    '$timeout',
    '$element',
    '$state',
    '$filter',
    '$rootScope',
    'actionApiService',
    'meetingApiService',
    'dateService',
    'userService',
    'menuService',
    'recompileService',
    'rowService',
    'eventEmitterService',
    'actionService',
    'translationService',
    'dialogService',
    'actionDetailService',
    'signalRService',
    'userApiService',
    'locationService'
];

export default ActionDetailController;
