import template from './verticalWizard.html';
import controller from './verticalWizard.controller';
import './verticalWizard.less';

let verticalWizardComponent = {
    template,
    controller,
    transclude: {
        step: 'rymVerticalWizardStep'
    },
    bindings: {
        onCancel: '&',
        onOk: '&',
        onSubmit: '&',
        open: '&',
        show: '&',
        options: '=',
        isReady: '='
    }
};

export default verticalWizardComponent;
