import { LANGUAGES, WEBSITE_PRODUCTS, FEATURE_TOGGLES, WEBSITE_BLOG, WEBSITE_TERMS_OF_SERVICE, GetSwarmDomain } from '../app.constants';

class Navigation {
    constructor(
        $state,
        $q,
        $rootScope,
        $element,
        $scope,
        $timeout,
        $window,
        responsive,
        userApiService,
        actionApiService,
        navigationService,
        organizationApiService,
        eventEmitterService,
        contactService,
        menuService,
        toastService,
        organizationService,
        userService,
        dialogService,
        translationService,
        dataLayerService,
        featureToggleService,
        meetingFilterService,
        categoryService,
        appcuesService,
        subscriptionService,
        helpService
    ) {
        Object.assign(this, {
            $state,
            $q,
            $rootScope,
            $element,
            $scope,
            $timeout,
            $window,
            responsive,
            userApiService,
            actionApiService,
            navigationService,
            organizationApiService,
            eventEmitterService,
            contactService,
            menuService,
            toastService,
            organizationService,
            userService,
            dialogService,
            translationService,
            dataLayerService,
            featureToggleService,
            meetingFilterService,
            categoryService,
            appcuesService,
            subscriptionService,
            helpService
        });
    }

    $onInit() {
        this.isVisible = false;
        this.isElevated = false;
        this.enableSeatBased = true;
        this._isCollapsed = !this.responsive.is(['xl']);
        this._initState();
        this._initEventHandlers();
        this._getFeatureFlags();
    }

    $doCheck() {
        this.maxHeightForCategories = this._calcMaxHeight();
    }

    handleClickOnTab() {
        if (this.isElevated && !this.isCollapsed()) {
            if (this.isMeetingsExpanded) {
                this._collapseMeetings();
            }
            this.collapse();
        }
    }

    handleClickOnMeetingsTab() {
        if (this._isMeetingCategoriesAvailible()) {
            if (this.isMeetingsExpanded) {
                this._collapseMeetings();
            } else {
                if (this.isCollapsed()) {
                    this.expand(true);
                }

                this.$timeout(() => this._expandMeetings(), 50);
            }
        } else {
            this.handleClickOnTab();
        }
    }

    pushData() {
        if (this.currentUser) {
            this.dataLayerService.pushData({
                event: 'referral_click',
                email: this.currentUser.email
            });
        }
    }

    isShowingSearch() {
        return this.featureFlag_Search;
    }

    _fetchData() {
        let deferred = this.$q.defer();
        this.categories = [];

        let promises = [
            this.userApiService.getCurrentUser(),
            this.organizationApiService.current(),
            this.actionApiService.getAll(),
            this.categoryService.getAll()
        ];

        this.$q.all(promises).then((results) => {
            this.currentUser = results[0];
            this.currentOrganization = results[1];
            this._setCategories(results[3]);
            this.setUnfinishedActions();
            deferred.resolve();
        });

        return deferred.promise;
    }

    _getFeatureFlags() {
        this.featureToggleService.isEnabled(FEATURE_TOGGLES.SEARCH).then((result) => {
            this.featureFlag_Search = result;
        });
    }

    _updateMoreProducts() {
        let lang = LANGUAGES.ENGLISH_CODE;
        if (this.currentUser) {
            switch (this.currentUser.languageId) {
                case LANGUAGES.ENGLISH:
                    lang = LANGUAGES.ENGLISH_CODE;
                    break;
                case LANGUAGES.SWEDISH:
                    lang = LANGUAGES.SWEDISH_CODE;
                    break;
                case LANGUAGES.NORWEGIAN:
                    lang = LANGUAGES.NORWEGIAN_CODE;
                    break;
                case LANGUAGES.GERMAN:
                    lang = LANGUAGES.GERMAN_CODE;
                    break;
                default:
                    lang = LANGUAGES.ENGLISH_CODE;
            }
        }

        this.productsUrl = lang === LANGUAGES.SWEDISH_CODE ? WEBSITE_PRODUCTS.SWEDISH : WEBSITE_PRODUCTS.ENGLISH;
    }

    _updateAdministrationUrl() {
        let swarmDomain = GetSwarmDomain(window.location.origin);
        let tenantId = this.currentOrganization.stratsysCompanyCode;
        this.administrationUrl = `https://admin.${swarmDomain}/${tenantId}`;
    }

    isCollapsed() {
        return this._isCollapsed;
    }

    toggle() {
        if (this.isCollapsed()) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    _calcMaxHeight() {
        let bottomContainer = document.querySelector('[data-hook="rym-navigation--bottom-container"]');
        let maxHeight = bottomContainer.offsetHeight + 160;
        return `calc(100vh - ${maxHeight}px)`;
    }

    support($event) {
        let blogUrl = WEBSITE_BLOG.ENGLISH;
        let termsOfServiceUrl = WEBSITE_TERMS_OF_SERVICE.ENGLISH;

        if (this.currentUser) {
            blogUrl = this.currentUser.languageId === LANGUAGES.SWEDISH ? WEBSITE_BLOG.SWEDISH : WEBSITE_BLOG.ENGLISH;
            termsOfServiceUrl =
                this.currentUser.languageId === LANGUAGES.SWEDISH ? WEBSITE_TERMS_OF_SERVICE.SWEDISH : WEBSITE_TERMS_OF_SERVICE.ENGLISH;
        }

        let options = {
            direction: 'north-east',
            aside: true,
            items: [
                {
                    id: 'menu option - start welcome guide',
                    icon: 'emoji_people',
                    text: this.translationService.translate('client_WelcomeGuide'),
                    onClick: () => {
                        this.eventEmitterService.publishEvent('showIntroGuide');
                        this.collapse();
                    }
                },
                {
                    id: 'menu option - help',
                    icon: 'videocam',
                    text: this.translationService.translate('client_TutorialVideos'),
                    onClick: () => this.helpService.showTutorialVideoDialog()
                },
                {
                    id: 'menu option - contact support',
                    icon: 'mail_outline',
                    text: this.translationService.translate('client_FeedbackSupport'),
                    onClick: () => this.contactService.support()
                }
            ]
        };

        if (this.navigationService.isCollapsed()) {
            options.offsetX = -5;
        }

        if (this.currentUser && this.currentUser.isAdmin && !this.responsive.is('xs')) {
            options.items.push({
                id: 'menu option - Navigation walk through',
                icon: 'format_list_numbered',
                text: this.translationService.translate('client_StartNavigationWalkThrough'),
                onClick: () => this.showNavigationWalkThrough()
            });
        }

        options.items.push({
            id: 'menu option - blog',
            icon: 'open_in_new',
            text: this.translationService.translate('client_Blog'),
            onClick: () => this._openUrlInNewTab(blogUrl)
        });

        options.items.push({
            id: 'menu option - terms of service',
            icon: 'open_in_new',
            text: this.translationService.translate('client_TheTermsOfService'),
            onClick: () => this._openUrlInNewTab(termsOfServiceUrl)
        });

        this.menuService.actionMenu(options, angular.element($event.currentTarget), false);
    }

    showNavigationWalkThrough() {
        if (this.currentUser) {
            if (this.currentUser.languageId !== LANGUAGES.SWEDISH) {
                this.appcuesService.show('-LeH28tdyvMzEDhqZlHr');
            } else if (this.currentUser.languageId === LANGUAGES.SWEDISH) {
                this.appcuesService.show('-LeHPP99A_wWKYHuMHNL');
            }
        }
    }

    collapse() {
        this.navigationService.collapse(true);
    }

    expand(temporarly) {
        if (temporarly) {
            this.isElevated = true;
            this._temprarlyExpanded = true;
        }

        this.navigationService.expand(true);
    }

    setUnfinishedActions() {
        let currentUserId = this.currentUser ? this.currentUser.id : null;
        if (currentUserId) {
            this.actionApiService.clearActionCache();
            this.actionApiService.numberOfUnfinished(currentUserId).then((result) => {
                this.unfinishedActions = result;
            });
        }
    }

    setSubscriptionRequiresAction() {
        this.subscriptionRequiresAction = this.subscriptionService.getRequiresAction();
        this.hideIndicationCounter = this.subscriptionRequiresAction;
    }

    showMoreProducts() {
        this.handleClickOnTab();
        this.pushData();
    }

    showAdministration() {
        this.handleClickOnTab();
        this.pushData();
    }

    _initState() {
        this._fetchData().then(() => {
            this._updateState();
            this.isVisible = true;
        });
    }

    _updateState() {
        this._updateSelectedTab();
        this._updateMeetingsTab();
        this._updateCategories();
        this._updateMoreProducts();
        this._updateAdministrationUrl();
    }

    _updateMeetingsTab() {
        this.meetingsState = '';
        if (!this._isMeetingCategoriesAvailible()) {
            this._disableCategories();
        } else if (this._isMeetingCategoriesAvailible() && this._isInMeetingsView() && !this.isCollapsed()) {
            this._expandMeetings();
        } else if (this._isMeetingCategoriesAvailible() && !this._isInMeetingsView()) {
            this._collapseMeetings();
        }
    }

    _isInMeetingsView() {
        return this.$state.current.name.includes('meetings');
    }

    _disableCategories() {
        this.isMeetingsExpanded = false;
        this.meetingsSuffixIcon = '';
        this.meetingsState = 'meetings';
    }

    _updateSelectedTab() {
        this.isMeetingsSelected = false;
        this.isActionSelected = false;
        this.isTemplatesSelected = false;
        this.isSubscriptionSelected = false;
        this.isSettingsSelected = false;
        this.isSearchSelected = false;
        this.isCategoriesSelected = false;

        if (this.$state.current.name.includes('meeting')) {
            this.isMeetingsSelected = true;
        }
        if (this.$state.current.name.includes('action')) {
            this.isActionSelected = true;
        }
        if (this.$state.current.name.includes('template')) {
            this.isTemplatesSelected = true;
        }
        if (this.$state.current.name.includes('subscription')) {
            this.isSubscriptionSelected = true;
        }
        if (this.$state.current.name.includes('settings')) {
            this.isSettingsSelected = true;
        }
        if (this.$state.current.name.includes('search')) {
            this.isSearchSelected = true;
        }
        if (this.$state.current.name.includes('categories')) {
            this.isCategoriesSelected = true;
        }
    }

    _updateCategories() {
        if (this._isMeetingCategoriesAvailible()) {
            this.meetingCategories = [];
            let allCategories = {
                name: this.translationService.translate('client_AllCategories'),
                isSelected: false,
                id: 0,
                state: 'meetings({category: ""})'
            };

            this.meetingCategories = this.categories.map((category) => {
                category.state = `meetings({category: '${category.id}'})`;
                category.isSelected = false;
                return category;
            });
            this.meetingCategories.unshift(allCategories);

            let categoryId = parseInt(this.$state.params.category, 10);
            if (categoryId) {
                let index = this.meetingCategories.findIndex((c) => c.id === categoryId);
                this.meetingCategories[index].isSelected = true;
            } else if (this._isInMeetingsView()) {
                let index = this.meetingCategories.findIndex((c) => c.id === 0);
                this.meetingCategories[index].isSelected = true;
            } else {
                //this._collapseMeetings();
            }
        }
    }

    _initEventHandlers() {
        this.responsive.on(['xs', 'sm'], this.$scope, () => this._handleResponsiveStateChange_xs_sm());
        this.responsive.on(['md', 'lg'], this.$scope, () => this._handleResponsiveStateChange_md_lg());
        this.responsive.on(['xl'], this.$scope, () => this._handleResponsiveStateChange_xl());
        this.navigationService.subscribe('collapse', () => this._handleCollapse());
        this.navigationService.subscribe('expand', () => this._handleExpand());
        this.eventEmitterService.subscribe('updateUnfinishedActions', () => this._handleUnfinishedActionsCountChange());
        this.eventEmitterService.subscribe('organizationChanged', () => this._handleOrganizationChange());
        this.eventEmitterService.subscribe('updateSubscriptionRequiresAction', () => this._handleSubscriptionRequiresAction());
        this.$rootScope.$on('$stateChangeSuccess', () => this._handleStateChange());
        this.eventEmitterService.subscribe('categoriesChanged', this._handleCategoriesChanges.bind(this));
    }

    _handleResponsiveStateChange_xs_sm() {
        this.isMobileDevice = true;
        this.isElevated = true;
        this.collapse();
    }

    _handleResponsiveStateChange_md_lg() {
        this.isElevated = true;
        this.isMobileDevice = false;
        this.collapse();
    }

    _handleResponsiveStateChange_xl() {
        this.isMobileDevice = false;
        this.isElevated = false;
        this.expand();
    }

    _handleCollapse() {
        this._hideShortly();
        this.isMeetingsExpanded = false;
        if (this._temprarlyExpanded) {
            this._temprarlyExpanded = false;
            if (this.responsive.is(['xl'])) {
                this.isElevated = false;
            }
        }
        this._isCollapsed = true;
    }

    _handleExpand() {
        this._hideShortly();
        this.$timeout(() => {
            this._isCollapsed = false;
            if (this.$state.current.name.includes('meeting')) {
                this.$timeout(() => this._expandMeetings(), 50);
            }
        });
    }

    _handleUnfinishedActionsCountChange() {
        this.setUnfinishedActions();
    }

    _handleOrganizationChange() {
        this._initState();
    }

    _handleSubscriptionRequiresAction() {
        this.setSubscriptionRequiresAction();
    }

    _handleStateChange() {
        this._updateState();
        if (this.isElevated && !this.isCollapsed()) {
            this.collapse();
        }
    }

    _expandMeetings() {
        if (!this._isMeetingCategoriesAvailible()) {
            return;
        }

        this.meetingsSuffixIcon = 'keyboard_arrow_up';
        this.isMeetingsExpanded = true;
    }

    _collapseMeetings() {
        this.meetingsSuffixIcon = 'keyboard_arrow_down';
        this.isMeetingsExpanded = false;
    }

    _isMeetingCategoriesAvailible() {
        return this.categories && this.categories.length > 0;
    }

    _hideShortly() {
        this.isVisible = false;
        this.$timeout(() => {
            this.isVisible = true;
        }, 400);
    }

    _openUrlInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    _setCategories(categories) {
        let favoriteCategories = categories.filter((category) => category.isFavorite);
        if (favoriteCategories.length > 0) {
            this.categories = favoriteCategories;
        } else {
            this.categories = categories;
        }
    }

    _handleCategoriesChanges(categories) {
        this._setCategories(categories);
        this._updateState();
    }
}

Navigation.$inject = [
    '$state',
    '$q',
    '$rootScope',
    '$element',
    '$scope',
    '$timeout',
    '$window',
    'responsive',
    'userApiService',
    'actionApiService',
    'navigationService',
    'organizationApiService',
    'eventEmitterService',
    'contactService',
    'menuService',
    'toastService',
    'organizationService',
    'userService',
    'dialogService',
    'translationService',
    'dataLayerService',
    'featureToggleService',
    'meetingFilterService',
    'categoryService',
    'appcuesService',
    'subscriptionService',
    'helpService'
];

export default Navigation;
