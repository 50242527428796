import template from './integrationDialog.html';
import controller from './integrationDialog.controller';

let integrationDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default integrationDialogComponent;
