import EventEmitterService from '../common/services/eventEmitter/eventEmitter.service.js';
class NavigationService extends EventEmitterService {
    constructor() {
        super();
        this._isCollapsed = false;
        this._isLocked = false;
    }

    expand(lock) {
        this._setIsCollapsed(false, lock);
        this.publishEvent('expand');
    }

    collapse(lock) {
        this._setIsCollapsed(true, lock);
        this.publishEvent('collapse');
    }

    isCollapsed() {
        return this._isCollapsed;
    }

    _setIsCollapsed(value, lock) {
        if (!this._isLocked || lock) {
            this._isCollapsed = value;
            this._isLocked = lock || false;
        }
    }
}

export default NavigationService;
