function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'subscription_v2',
            config: {
                url: '/v2/subscription',
                template: '<rym-subscription/>',
                ncyBreadcrumb: {
                    label: 'Subscription'
                },
                authorization: {
                    admin: true
                }
            }
        },
        {
            state: 'success',
            config: {
                url: '/success/:sessionGuid',
                template: '<rym-subscription-success/>',
                ncyBreadcrumb: {
                    label: 'Success',
                    parent: 'subscription'
                },
                authorization: {
                    admin: true
                }
            }
        },
        {
            state: 'subscription.transactions',
            config: {
                url: '/transactions?transactionId',
                template: '<rym-subscription/>',
                authorization: {
                    admin: true
                },
                ncyBreadcrumb: {
                    skip: true
                }
            }
        },
        {
            state: 'subscription.plans',
            config: {
                url: '/plans',
                template: '<rym-subscription/>',
                authorization: {
                    admin: true
                },
                ncyBreadcrumb: {
                    skip: true
                }
            }
        },
        {
            state: 'subscription',
            config: {
                url: '/subscription',
                template: '<rym-subscription/>',
                ncyBreadcrumb: {
                    label: 'Subscription'
                },
                authorization: {
                    admin: true
                }
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
