class UserImageController {
    constructor(userApiService) {
        this.userApiService = userApiService;
        this.photo = '';
        this.cancellationToken = {
            isCancelled: false
        };
    }

    $onChanges(obj) {
        if (obj.username) {
            this.username = obj.username.currentValue;
            this.setPhoto();
        }

        if (!this.initials) {
            this.setInitials();
        }
    }

    setPhoto() {
        if (this.username) {
            this.userApiService.getProfilePhotoForUser(this.username, this.cancellationToken).then((result) => {
                this.photo = result.data;
            });
        } else {
            this.photo = null;
        }
    }

    setInitials() {
        if (!this.name || this.name.trim() === '') {
            return;
        }

        let names = this.name.replace(/\s+/g, ' ').trim().split(' ');

        let initials = names[0][0];
        if (names.length > 1) {
            initials += names[names.length - 1][0];
        }

        this.initials = initials.toUpperCase();
    }

    $onDestroy() {
        this.cancellationToken.isCancelled = true;
    }
}

UserImageController.$inject = ['userApiService'];

export default UserImageController;
