class anchorScrollService {
    constructor($document, $q, $timeout, domService) {
        this.$document = $document;
        this.$q = $q;
        this.$timeout = $timeout;
        this.domService = domService;

        this.duration = 500;
        this.offset = 16;
    }

    scrollTo(targetElementId, targetContainerElementId, offset, duration) {
        try {
            let element = angular.element(document.getElementById(targetElementId));
            let containerElement = this.$document;

            if (targetContainerElementId) {
                containerElement = angular.element(document.getElementById(targetContainerElementId));
            }

            return this.scrollToElement(element, containerElement, offset, duration);
        } catch (error) {
            this.$q.reject();
        }
    }

    scrollToElement(element, containerElement, offset, duration) {
        try {
            if (!containerElement) {
                containerElement = this.domService.scrollParent(element);
            }

            if (!containerElement) {
                return this.$q.reject();
            }

            offset = offset != null ? offset : this.offset;
            duration = duration != null ? duration : this.duration;
            if (element[0] && containerElement[0]) {
                if (duration > 0) {
                    return containerElement.scrollToElementAnimated(element, offset, duration);
                } else {
                    return containerElement.scrollToElement(element, offset);
                }
            }
        } catch (error) {}

        return this.$q.reject();
    }

    scrollTop(containerElementId) {
        try {
            var containerElement = angular.element(document.getElementById(containerElementId));

            if (containerElement && containerElement[0]) {
                containerElement.scrollTop(0);
            }
        } catch (error) {}
    }

    scrollTopAnimated(containerElementId, duration) {
        let deferred = this.$q.defer();

        try {
            var containerElement = angular.element(document.getElementById(containerElementId));

            if (containerElement && containerElement[0]) {
                if (containerElement.scrollTop() < 48) {
                    deferred.resolve();
                } else {
                    let duration = Math.round(containerElement.scrollTop() / 5);
                    containerElement.scrollTop(0, duration);
                    this.$timeout(
                        () => {
                            deferred.resolve();
                        },
                        duration + 100,
                        false
                    );
                }
            }
        } catch (error) {
            deferred.reject(error);
        }

        return deferred.promise;
    }
}

anchorScrollService.$inject = ['$document', '$q', '$timeout', 'domService'];
export default anchorScrollService;
