import controller from './createMeetingDialog.controller';
import template from './createMeetingDialog.html';
import './createMeetingDialog.less';

let createMeetingDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default createMeetingDialogComponent;
