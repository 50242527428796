import template from './searchResultItem.html';

let searchResultItemComponent = {
    template,
    bindings: {
        type: '<',
        description: '<',
        date: '<',
        icon: '<',
        name: '<',
        truncate: '<',
        onClick: '&'
    }
};

export default searchResultItemComponent;
