import controller from './subscriptionUpgradeDialog.controller';
import template from './subscriptionUpgradeDialog.html';

let subscriptionUpgradeDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default subscriptionUpgradeDialogComponent;
