import * as signalR from '@microsoft/signalr';
import { BASE_URLS, CONNECTION_STATES } from '../../../app.constants';
const _joinedMeetingFlag = '6a0c216e-7211-46ab-b5c3-fcdd7c02014f';
const _joinedMeetingSeriesFlag = 'd5849d8f-5d53-4feb-91a6-6b42a471d977';

class SignalRService {
    constructor($q, $timeout, $window, $state, eventEmitterService, organizationService, userService, authService, envService) {
        Object.assign(this, { $q, $timeout, $window, $state, eventEmitterService, organizationService, userService, authService, envService });
    }

    initialize() {
        return this.authService.getAccessToken().then((token) => {
            const options = {
                accessTokenFactory: () => {
                    return this.authService.getAccessToken();
                }
            };

            this.connection = new signalR.HubConnectionBuilder().withUrl(`${this.envService.RYM_OLD}/smsr`, options).withAutomaticReconnect().build();
            this.connectionStarted = false;
            this.connectionLost = false;

            this.connection.onreconnected((connectionId) => {
                this.connectionStarted = true;
                this.addConnectionForUser();
                this.joinOrganization();
                if (this.connectionLost) {
                    this.eventEmitterService.publishEvent('signalRReconnected');
                    this.connectionLost = false;
                }
            });

            this.connection.onclose(() => {
                this.connectionStarted = false;
                this.connectionLost = true;
                this.reconnectWithNewToken();
            });

            this.connection.on('updateMeetingName', (id, name) => {
                this.eventEmitterService.publishEvent('updateMeetingName' + id, name);
            });

            this.connection.on('updateMeetingDescription', (id, name) => {
                this.eventEmitterService.publishEvent('updateMeetingDescription' + id, name);
            });

            this.connection.on('updatingAgenda', (id, user) => {
                this.$timeout(() => {
                    this.eventEmitterService.publishEvent('agendaIsBeingChanged' + id, user);
                }, 1000);
            });

            this.connection.on('changeOrganization', (userId) => {
                this.userService.getCurrentUser().then((user) => {
                    if (userId === user.id) {
                        this.$timeout(() => {
                            this.eventEmitterService.publishEvent('changedOrganization');
                        }, 2000);
                    }
                });
            });

            this.connection.on('updateAgenda', (id, agenda) => {
                this.eventEmitterService.publishEvent('agendaChangedElsewhere' + id, agenda);
            });

            this.connection.on('updateAgendaNotes', (id, notes, blur) => {
                this.eventEmitterService.publishEvent('agendaNotesChanged' + id, { notes: notes, blur: blur });
            });

            this.connection.on('updateAction', (action) => {
                this.eventEmitterService.publishEvent('agendaActionChanged' + action.id, action);
            });

            this.connection.on('agendaActionRemoved', (id, action) => {
                this.eventEmitterService.publishEvent('agendaActionRemoved' + id, action);
            });

            this.connection.on('agendaActionAdded', (id, action) => {
                this.eventEmitterService.publishEvent('agendaActionAdded' + id, action);
            });

            this.connection.on('agendaItemAdded', (meetingId, parentId, agenda) => {
                let agendaUpdate = { parentId: parentId, agenda: agenda };
                this.eventEmitterService.publishEvent('agendaItemAdded' + meetingId, agendaUpdate);
            });

            this.connection.on('agendaItemRemoved', (meetingId, agenda) => {
                this.eventEmitterService.publishEvent('agendaItemRemoved' + meetingId, agenda);
            });

            this.connection.on('agendaItemPostponed', (meetingId, meetingSeriesId, agenda) => {
                this.eventEmitterService.publishEvent('meetingAgendaItemPostponed' + meetingId, agenda);
                this.eventEmitterService.publishEvent('meetingSeriesPostponedAgendasChanged' + meetingSeriesId);
            });

            this.connection.on('addPostponedAgendaToMeeting', (meetingId, meetingSeriesId) => {
                this.eventEmitterService.publishEvent('addPostponedAgendaToMeeting' + meetingId);
                this.eventEmitterService.publishEvent('meetingSeriesPostponedAgendasChanged' + meetingSeriesId);
            });

            this.connection.on('addedDocument', (id, document) => {
                this.eventEmitterService.publishEvent('addedDocument' + id, document);
            });

            this.connection.on('deletedDocument', (id, document) => {
                this.eventEmitterService.publishEvent('deletedDocument' + id, document);
            });

            this.connection.on('updateAgendaPosition', (meetingId) => {
                this.eventEmitterService.publishEvent('updateAgendaPosition' + meetingId);
            });

            this.connection.on('joinedMeeting', (meetingId, user) => {
                this.userService.getCurrentUser().then((currentUser) => {
                    if (currentUser.id !== user.id) {
                        this.eventEmitterService.publishEvent('joinedMeeting' + meetingId, user);
                    }
                });
            });

            this.connection.on('trialExceeded', () => {
                this.eventEmitterService.publishEvent('trialExceeded');
            });

            this.connection.on('updatePaymentPlan', () => {
                this.eventEmitterService.publishEvent('updatePaymentPlan');
            });

            this.connection.on('reloadPage', (userId) => {
                this.userService.getCurrentUser().then((user) => {
                    if (parseInt(userId) === user.id) {
                        let reloadUrl = $window.location.href;
                        if (user.isStratsysOrganization && this.$state.is('meeting') && !reloadUrl.includes('?')) {
                            reloadUrl + '?meetingId=' + this.$state.params.id;
                        }
                        $window.location.href = reloadUrl;
                    }
                });
            });
        });
    }

    startConnection() {
        let deferred = this.$q.defer();
        if (this.connectionStarted) {
            deferred.resolve();
        } else {
            this.connection
                .start()
                .then(() => {
                    this.connectionStarted = true;
                    this.addConnectionForUser();
                    this.joinOrganization();
                    if (this.connectionLost) {
                        this.eventEmitterService.publishEvent('signalRReconnected');
                        this.connectionLost = false;
                    }
                    deferred.resolve();
                })
                .catch((err) => {
                    console.error('Failed to connect. ' + err);
                    deferred.reject();
                    setTimeout(() => this.reconnectWithNewToken(), 60000);
                });
        }
        return deferred.promise;
    }

    reconnectWithNewToken() {
        this.authService.renew(false).then(() => {
            this.initialize().then(() => {
                this.startConnection().then(() => {
                    let rejoinMeeting = this.$window.sessionStorage.getItem(_joinedMeetingFlag);
                    if (rejoinMeeting) {
                        this.userService.getCurrentUser().then((user) => {
                            this.joinMeeting(rejoinMeeting, user);
                        });
                        this.$window.sessionStorage.removeItem(_joinedMeetingFlag);
                    }

                    let rejoinMeetingSeries = this.$window.sessionStorage.getItem(_joinedMeetingSeriesFlag);
                    if (rejoinMeetingSeries) {
                        this.joinMeetingSeries(rejoinMeetingSeries);
                        this.$window.sessionStorage.removeItem(_joinedMeetingSeriesFlag);
                    }
                });
            });
        });
    }

    joinOrganization() {
        if (this.connectionStarted) {
            this.organizationService.getCurrentOrganization().then((organization) => {
                this.tryInvoke(() => {
                    this.connection.invoke('joinOrganization', organization.id).catch(() => console.log('Failed to join organization'));
                });
            });
        }
    }

    addConnectionForUser() {
        if (this.connectionStarted) {
            this.userService.getCurrentUser().then((user) => {
                this.tryInvoke(() => {
                    this.connection.invoke('addConnectionForUser', user.id).catch(() => {
                        console.log('Failed to add connection for user');
                    });
                });
            });
        }
    }

    joinMeeting(meetingId, user, reconnecting = false) {
        this.$window.sessionStorage.setItem(_joinedMeetingFlag, meetingId);
        if (this.connectionStarted) {
            return this.tryInvoke(() => {
                return this.connection.invoke('joinMeeting', meetingId, user, reconnecting).catch((err) => {
                    console.log(`Failed to join meeting ${err}`);
                });
            });
        } else {
            return this.$timeout(
                () => {
                    return this.joinMeeting(meetingId, user, reconnecting);
                },
                5000,
                false
            );
        }
    }

    joinMeetingSeries(meetingSeriesId) {
        this.$window.sessionStorage.setItem(_joinedMeetingSeriesFlag, meetingSeriesId);
        if (this.connectionStarted) {
            return this.tryInvoke(() => {
                return this.connection.invoke('joinMeetingSeries', meetingSeriesId).catch((err) => {
                    console.log(`Failed to join meeting series ${err}`);
                });
            });
        } else {
            return this.$timeout(
                () => {
                    return this.joinMeetingSeries(meetingSeriesId);
                },
                5000,
                false
            );
        }
    }

    leaveMeeting(meetingId) {
        this.$window.sessionStorage.removeItem(_joinedMeetingFlag);
        this.$window.sessionStorage.removeItem(_joinedMeetingSeriesFlag);
        this.tryInvoke(() => {
            this.connection.invoke('leaveMeeting', meetingId, true).catch((err) => console.log(`Failed to leave meeting ${err}`));
        });
    }

    updatingAgenda(meetingId, agendaId, user) {
        this.tryInvoke(() => {
            this.connection.invoke('updatingAgenda', meetingId, agendaId, user).catch();
        });
    }

    updateAction(action) {
        this.organizationService.getCurrentOrganization().then((organization) => {
            this.tryInvoke(() => {
                this.connection.invoke('updateAction', organization.id, action).catch((err) => console.log(err));
            });
        });
    }

    updateAgenda(meetingId, agenda) {
        this.tryInvoke(() => {
            this.connection.invoke('updateAgenda', meetingId, agenda.id, agenda).catch(() => console.log('Failed to update agenda'));
        });
    }

    updateAgendaNotes(meetingId, agendaId, notes, blur) {
        if (this.connectionStarted) {
            this.tryInvoke(() => {
                this.$timeout(
                    () => {
                        this.connection.invoke('updateAgendaNotes', meetingId, agendaId, notes, blur);
                    },
                    blur ? 2000 : 0
                );
            });
        } else {
            setTimeout(() => this.updateAgendaNotes(meetingId, agendaId, notes, blur), 500);
        }
    }

    removedAgendaAction(meetingId, agendaId, action) {
        this.tryInvoke(() => {
            this.connection.invoke('removedAgendaAction', meetingId, agendaId, action);
        });
    }

    addedAgendaAction(meetingId, agendaId, action) {
        this.tryInvoke(() => {
            this.connection.invoke('addedAgendaAction', meetingId, agendaId, action);
        });
    }

    addedAgenda(meetingId, parentId, agenda) {
        this.tryInvoke(() => {
            this.connection.invoke('addedAgenda', meetingId, parentId, agenda);
        });
    }

    removedAgenda(meetingId, agenda) {
        this.tryInvoke(() => {
            this.connection.invoke('removedAgenda', meetingId, agenda);
        });
    }

    postponedAgenda(meetingId, meetingSeriesId, agenda) {
        this.tryInvoke(() => {
            this.connection.invoke('postponedAgenda', meetingId, meetingSeriesId, agenda);
        });
    }

    addPostponedAgendaToMeeting(meetingId, meetingSeriesId) {
        this.tryInvoke(() => {
            this.connection.invoke('addPostponedAgendaToMeeting', meetingId, meetingSeriesId);
        });
    }

    updateMeetingName(meetingId, name) {
        this.tryInvoke(() => {
            this.connection.invoke('updateMeetingName', meetingId, name).catch(() => console.log('Failed invoke update meeting name'));
        });
    }

    updateMeetingDescription(meetingId, description) {
        this.tryInvoke(() => {
            this.connection
                .invoke('updateMeetingDescription', meetingId, description)
                .catch(() => console.log('Failed invoke update meeting description'));
        });
    }

    addedDocument(meetingId, agendaId, document) {
        this.tryInvoke(() => {
            this.connection.invoke('addedDocument', meetingId, agendaId, document).catch((err) => console.log(err));
        });
    }

    deletedDocument(meetingId, agendaId, document) {
        this.tryInvoke(() => {
            this.connection.invoke('deletedDocument', meetingId, agendaId, document).catch((err) => console.log(err));
        });
    }

    updateAgendaPosition(meetingId) {
        this.tryInvoke(() => {
            this.connection.invoke('updateAgendaPosition', meetingId).catch((err) => console.log(err));
        });
    }

    reloadPage(userId) {
        let deferred = this.$q.defer();
        if (this.connectionStarted) {
            this.tryInvoke(() => {
                this.connection
                    .invoke('reloadPage', userId)
                    .then((r) => {
                        deferred.resolve();
                    })
                    .catch((err) => {
                        console.log('Failed to send reload page event');
                        deferred.reject();
                    });
            });
        } else {
            deferred.reject();
        }
        return deferred.promise;
    }

    tryInvoke(callback) {
        if (this.connection && this.connectionStarted) {
            return callback();
        } else {
            let deferred = this.$q.defer();
            deferred.reject();
            return deferred.promise;
        }
    }
}

SignalRService.$inject = [
    '$q',
    '$timeout',
    '$window',
    '$state',
    'eventEmitterService',
    'organizationService',
    'userService',
    'authService',
    'envService'
];

export default SignalRService;
