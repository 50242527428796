import template from './exportToCalendarDialog.html';

let exportToCalendarDialogComponent = {
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default exportToCalendarDialogComponent;
