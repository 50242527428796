const INJECT = [
    '$q',
    '$filter',
    'translationService',
    'categoryApiService',
    'dialogService',
    'userService',
    'paymentService',
    'toastService',
    'eventEmitterService'
];

class CategoryService {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    _categoriesChanged() {
        this.eventEmitterService.publishEvent('categoriesChanged', this.categories);
    }

    getAll() {
        if (this.categories && !this.fetchFailed) {
            return this.$q.resolve(this.categories);
        }

        return this._setCategories();
    }

    create(category) {
        return this._checkPermission().then((hasPermission) => {
            if (hasPermission) {
                this.toastService.loading({
                    title: this.translationService.translate('client_CreatingCategory')
                });
                return this.categoryApiService
                    .create(category)
                    .then((result) => {
                        result.canDelete = true;
                        this.categories = this.$filter('orderBy')([...this.categories, result], 'name');
                        this.toastService.success({
                            title: this.translationService.translate('client_CategoryCreated')
                        });

                        this._setLeastUsedCategoryColorId();
                        this._categoriesChanged();
                        return result;
                    })
                    .catch((exception) => {
                        this.toastService.error({
                            title: this.translationService.translate('client_CouldNotCreateCategory'),
                            description: exception.data.message
                        });
                    });
            }
        });
    }

    showCategoryDialog(options) {
        if (!options.data) {
            options.data = { colorId: this.defaultColorId };
        }

        options.id = 'category dialog';

        return this._checkPermission().then((hasPermission) => {
            return hasPermission
                ? this.dialogService.custom('rym-category-dialog', options)
                : this.paymentService.showIsParticipant().then(() => this.$q.reject());
        });
    }

    showCreateDialog() {
        let options = {
            title: this.translationService.translate('client_CreateCategory'),
            ok: this.translationService.translate('client_Create'),
            cancel: this.translationService.translate('cancel')
        };

        this.showCategoryDialog(options).then((category) => this.create(category));
    }

    delete(category) {
        return this._checkPermission().then((hasPermission) => {
            if (hasPermission) {
                let title = `${this.translationService.translate('client_Delete')} ${category.name}?`;

                let content = {
                    title: title,
                    description: this.translationService.translate('client_DeleteCategoryConfirm')
                };

                return this.dialogService.confirm(content).then(() => {
                    return this.categoryApiService.remove(category.id).then(() => {
                        let index = _.findIndex(this.categories, (a) => {
                            return a.id === category.id;
                        });
                        this.categories.splice(index, 1);
                        this._categoriesChanged();
                        this._setLeastUsedCategoryColorId();
                        this.categoryApiService.clearRelatedCaches();

                        this.toastService.success({ title: this.translationService.translate('client_CategoryDeleted') }).catch((exception) => {
                            this.toastService.error({
                                title: this.translationService.translate('client_CouldNotDeleteCategory'),
                                description: exception.data.message
                            });
                        });
                    });
                });
            }

            return this.paymentService.showIsParticipant().then(() => this.$q.reject());
        });
    }

    update(category) {
        return this._checkPermission().then((hasPermission) => {
            if (hasPermission) {
                this.toastService.loading({
                    title: this.translationService.translate('client_UpdatingCategory')
                });

                return this.categoryApiService
                    .update(category)
                    .then((result) => {
                        result.canDelete = category.canDelete;
                        Object.assign(
                            this.categories,
                            this.categories.map((x) => (x.id === category.id ? result : x))
                        );

                        this.categories = this.$filter('orderBy')(this.categories, 'name');
                        this._categoriesChanged();
                        this.toastService.success({
                            title: this.translationService.translate('client_CategoryUpdated')
                        });
                    })
                    .catch((exception) => {
                        this.toastService.error({
                            title: this.translationService.translate('client_CouldNotUpdateCategory'),
                            description: exception.data.message
                        });
                    });
            }
        });
    }

    _setCategories() {
        return this.userService.getCurrentUser().then((user) => {
            return this.categoryApiService
                .getAll()
                .then((categories) => {
                    this.categories = this.$filter('orderBy')(categories, 'name').map((x) => ({
                        ...x,
                        canDelete: x.createdById === user.id
                    }));

                    this._setLeastUsedCategoryColorId();
                    delete this.fetchFailed;
                    return this.categories;
                })
                .catch(() => {
                    this.fetchFailed = true;
                    this.categories = [];
                    return this.categories;
                });
        });
    }

    clearCategories() {
        delete this.categories;
        delete this.fetchFailed;
    }

    _setLeastUsedCategoryColorId() {
        let defaultColorId = 1;

        if (this.categories && this.categories.length > 0) {
            let list = this.categories.map((c) => c.colorId);

            defaultColorId = [...list.reduce((r, n) => r.set(n, (r.get(n) || 0) + 1), new Map())].reduce((r, v) => (v[1] ? v : r))[0];
        }

        this.defaultColorId = defaultColorId;
    }

    _checkPermission() {
        return this.userService.getCurrentUser().then((user) => {
            return !user.isExternal;
        });
    }

    toggleFavorite(category) {
        return this._checkPermission().then((hasPermission) => {
            if (hasPermission) {
                this.toastService.loading({
                    title: this.translationService.translate('client_UpdatingCategory')
                });

                return this.categoryApiService
                    .toggleFavorite(category.id)
                    .then(() => {
                        let index = _.findIndex(this.categories, (a) => {
                            return a.id === category.id;
                        });
                        this.categories[index].isFavorite = !category.isFavorite;
                        this._categoriesChanged();
                        this.toastService.success({
                            title: this.translationService.translate('client_CategoryUpdated')
                        });
                    })
                    .catch((exception) => {
                        this.toastService.error({
                            title: this.translationService.translate('client_CouldNotUpdateCategory'),
                            description: exception.data.message
                        });
                    });
            }
        });
    }
}

CategoryService.$inject = INJECT;

export default CategoryService;
