class TabsController {
    constructor() {}

    $onChanges(changes) {
        if (changes.selected) {
            this.selected = angular.copy(this.selected);
        }
    }

    register(tab) {
        if (this.selected === tab.value) {
            this.currentSelectedTab = tab;
            tab.select();
        }
    }

    select(tab) {
        this.onSelect({
            $event: {
                value: tab.value
            }
        });

        if (this.currentSelectedTab) {
            this.currentSelectedTab.unselect();
        }

        this.currentSelectedTab = tab;
        this.currentSelectedTab.select();
    }
}

TabsController.$inject = [];

export default TabsController;
