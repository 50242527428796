import { LANGUAGES } from '../../../app.constants';

class TranslationService {
    constructor($window, $translate, $q, $filter) {
        Object.assign(this, { $window, $translate, $q, $filter });
    }

    preferredLanguage() {
        return this.$window.localStorage.getItem(LANGUAGES.STORAGE_KEY);
    }

    translate(string, obj) {
        return this.$filter('translate')(string, obj);
    }

    translateBatch(array, obj) {
        return this.$translate(array, obj);
    }
}

TranslationService.$inject = ['$window', '$translate', '$q', '$filter'];

export default TranslationService;
