import verticalWizardComponent from './verticalWizard.component';
import verticalWizardStepComponent from './verticalWizardStep.component';
import verticalWizardService from './verticalWizard.service';
import './verticalWizard.less';

angular
    .module('rym.verticalWizard', [])
    .service('verticalWizardService', verticalWizardService)
    .component('rymVerticalWizard', verticalWizardComponent)
    .component('rymVerticalWizardStep', verticalWizardStepComponent);
