import angular from 'angular';
import _ from 'lodash';

import iconButtonComponent from './iconButton/iconButton.component';
import breadcrumbsComponent from './breadcrumbs/breadcrumbs.component';
import breadcrumbsService from './breadcrumbs/breadcrumbs.service';
import comingSoonComponent from './comingSoon/comingSoon.component';
import profilePictureComponent from './profilePicture/profilePicture.component';
import userImageComponent from './userImage/userImage.component';
import infiniteScrollDirective from './infiniteScroll/infiniteScroll.directive';
import sideNavComponent from './sideNav/sideNav.component';
import filterTabsComponent from './filterTabs/filterTabs.component';
import filterTabComponent from './filterTabs/filterTab.component';
import categoryFilterComponent from './categoryFilter/categoryFilter.component';
import categoryDialogComponent from './categoryFilter/categoryDialog/categoryDialog.component';
import dividerComponent from './divider/divider.component';
import datePickerComponent from './datePicker/datepicker.component';
import userListComponent from './userList/userList.component';
import userListItemComponent from './userListItem/userListItem.component';
import selectedUsersComponent from './selectedUsers/selectedUsers.component';
import inlineEditComponent from './inlineEdit/inlineEdit.component';
import actionStatusIconComponent from './actions/actionStatusIcon/actionStatusIcon.component';
import toastComponent from './toast/toast.component';
import ratingComponent from './rating/rating.component';
import progressCircleComponent from './progressCircle/progressCircle.component';
import dateService from './services/dateService/date.service';
import anchorScrollService from './services/anchorScrollService/anchorScroll.service';
import actionDetailComponent from './actions/actionDetail/actionDetail.component';
import createActionDialogComponent from './actions/createActionDialog/createActionDialog.component';
import formDatePickerComponent from './formDatePicker/formDatePicker.component';
import formUserPickerComponent from './formUserPicker/formUserPicker.component';
import buttonComponent from './button/button.component';
import toastService from './toast/toast.service';
import header from './header/header.component';
import organizationService from './services/organizationService/organization.service';
import focusOnClickDirective from './focusOnClick/focusOnClick.directive';
import stringService from './services/stringService/string.service';
import domService from './services/domService/dom.service';
import userService from './services/userService/user.service';
import keyboardNavigationDirective from './keyboardNavigation/keyboardNavigation.directive';
import selectionService from './services/selectionService/selection.service';
import rymScrollbarComponent from './scrollbar/rymScrollbar.component';
import scrollbarService from './scrollbar/rymScrollbar.service';
import emailValidationDirective from './validation/emailValidation.directive';
import discountValidationDirective from './validation/discountValidation.directive';
import meetingMetadataService from './services/meetingMetadataService/meeting.metadata.service';
import meetingCreationService from './services/meetingCreationService/meeting.creation.service';
import switchComponent from './switch/switch.component';
import timeService from './services/timeService/time.service';
import animationService from './services/animationService/animation.service';
import offlineDialogComponent from './offlineDialog/offlineDialog.component';
import profilePictureDirective from './profilePicture/profilePicture.directive';
import eventEmitterService from './services/eventEmitter/eventEmitter.service';
import recompileService from './services/recompileService/recompile.service';
import meetingBuildingService from './services/meetingBuildingService/meetingBuilding.service';
import agendaBuildingService from './services/agendaBuildingService/agendaBuilding.service';
import actionService from './services/actionService/action.service';
import introGuideComponent from './introGuide/introGuide.component';
import cardComponent from './card/card.component';
import cardGroupComponent from './cardGroup/cardGroup.component';
import tabsComponent from './tabs/tabs.component';
import tabComponent from './tabs/tab.component';
import tagsInputComponent from './tagsInput/tagsInput.component';
import cardListComponent from './cardList/cardList.component';
import noHitsComponent from './noHits/noHits.component';
import sliderComponent from './slider/slider.component';
import radioCardsComponent from './radioCards/radioCards.component';
import categoryFavoriteIconComponent from './favorites/categoryFavoriteIcon.component';

import integrationDialog from './integration/integrationDialog.component';
import contactService from './services/contactService/contact.service';
import exportService from './services/exportService/export.service';
import fileService from './services/fileService/file.service';
import translationService from './services/translationService/translation.service';
import signalRService from './services/signalRService/signalr.service';
import actionDetailService from './actions/actionDetail/actionDetail.service';
import progressIndicator from './progressIndicator/progressIndicator.component';
import documentService from './services/documentService/document.service';
import meetingFilterService from './services/meetingFilterService/meeting.filter.service';
import categoryService from './services/categoryService/category.service';
import scrollToMeDirective from './scrollToMe/scrollToMe.directive';
import autoFocusDirective from './autoFocus/autoFocus.directive';
import applicationInsightsService from './services/applicationInsightsService/applicationInsights.service';
import autoTrackEventService from './services/autoTrackEventService/autoTrackEvent.service';
import ngEnterDirective from './keyboardNavigation/ngEnter.directive';
import paymentPlanService from './services/paymentPlanService/paymentPlan.service';
import localizationService from './services/localizationService/localization.service';
import dataLayerService from './services/dataLayerService/dataLayer.service';
import participantsService from './services/participantsService/participants.service';
import featureToggleService from './services/featureToggleService/featureToggle.service';
import extensionService from './services/extensionService/extension.service';
import teamsService from './services/teamsService/teams.service';
import appcuesService from './services/appcuesService/appcues.service';
import windowService from './services/windowService/window.service';
import calendarService from './services/calendarService/calendar.service';
import platformService from './services/platformService/platform.service';
import locationService from './services/locationService/location.service';
import notificationService from './services/notificationService/notification.service';
import subscriptionService from './services/subscriptionService/subscription.service';
import cacheService from './services/cacheService/cache.service';
import tutorialVideoDialogComponent from './help/tutorialVideoDialog.component';
import helpService from './services/helpService/help.service';
import paymentService from './services/paymentService/payment.service';
import envService from './services/envService/env.service';

import { KeyCode } from './constants/keyCode.constant';

import './subHeader/subheader.less';
import './progressLine/progressLine.less';
import './dialog/dialog.module';
import './textEditor/textEditor.module';
import './menu/menu.module';
import './rows/rows.module';
import './wizard/wizard.module';
import './verticalWizard/verticalWizard.module';
import './meetingSeries/meetingSeries.module';
import './guide/guide.module';
import '../responsive';
import 'angular-translate';
import 'angular-sanitize';
import EnvService from './services/envService/env.service';

angular
    .module('rym.common', [
        'rym.responsive',
        'rym.dialog',
        'rym.textEditor',
        'rym.menu',
        'rym.rows',
        'rym.wizard',
        'rym.series',
        'rym.guide',
        'rym.verticalWizard',
        'pascalprecht.translate',
        'ngSanitize'
    ])
    .constant('keyCode', KeyCode)
    .service('dateService', dateService)
    .service('actionDetailService', actionDetailService)
    .service('exportService', exportService)
    .service('breadcrumbsService', breadcrumbsService)
    .service('dateService', dateService)
    .service('toastService', toastService)
    .service('anchorScrollService', anchorScrollService)
    .service('organizationService', organizationService)
    .service('userService', userService)
    .service('selectionService', selectionService)
    .service('meetingMetadataService', meetingMetadataService)
    .service('meetingCreationService', meetingCreationService)
    .service('meetingFilterService', meetingFilterService)
    .service('timeService', timeService)
    .service('teamsService', teamsService)
    .service('meetingBuildingService', meetingBuildingService)
    .service('agendaBuildingService', agendaBuildingService)
    .service('actionService', actionService)
    .service('fileService', fileService)
    .service('stringService', stringService)
    .service('domService', domService)
    .service('animationService', animationService)
    .service('eventEmitterService', eventEmitterService)
    .service('recompileService', recompileService)
    .service('contactService', contactService)
    .service('translationService', translationService)
    .service('signalRService', signalRService)
    .service('documentService', documentService)
    .service('scrollbarService', scrollbarService)
    .service('applicationInsightsService', applicationInsightsService, appcuesService)
    .service('autoTrackEventService', autoTrackEventService)
    .service('categoryService', categoryService)
    .service('paymentPlanService', paymentPlanService)
    .service('paymentService', paymentService)
    .service('localizationService', localizationService)
    .service('dataLayerService', dataLayerService)
    .service('participantsService', participantsService)
    .service('featureToggleService', featureToggleService)
    .service('extensionService', extensionService)
    .service('appcuesService', appcuesService)
    .service('windowService', windowService)
    .service('calendarService', calendarService)
    .service('platformService', platformService)
    .service('locationService', locationService)
    .service('notificationService', notificationService)
    .service('subscriptionService', subscriptionService)
    .service('cacheService', cacheService)
    .service('helpService', helpService)
    .service('envService', envService)
    .component('rymToast', toastComponent)
    .component('rymProgressCircle', progressCircleComponent)
    .component('rymHeader', header)
    .component('rymIconButton', iconButtonComponent)
    .component('rymBreadcrumbs', breadcrumbsComponent)
    .component('rymComingSoon', comingSoonComponent)
    .component('rymProfilePicture', profilePictureComponent)
    .component('rymUserImage', userImageComponent)
    .component('rymScroll', rymScrollbarComponent)
    .component('rymSideNav', sideNavComponent)
    .component('rymFilterTabs', filterTabsComponent)
    .component('rymFilterTab', filterTabComponent)
    .component('rymCategoryFilter', categoryFilterComponent)
    .component('rymCategoryDialog', categoryDialogComponent)
    .component('rymDivider', dividerComponent)
    .component('rymDatePicker', datePickerComponent)
    .component('rymUserList', userListComponent)
    .component('rymUserListItem', userListItemComponent)
    .component('rymSelectedUsers', selectedUsersComponent)
    .component('rymInlineEdit', inlineEditComponent)
    .component('rymActionStatusIcon', actionStatusIconComponent)
    .component('rymActionDetail', actionDetailComponent)
    .component('rymCreateActionDialog', createActionDialogComponent)
    .component('rymFormDatePicker', formDatePickerComponent)
    .component('rymFormUserPicker', formUserPickerComponent)
    .component('rymSwitch', switchComponent)
    .component('rymOfflineDialog', offlineDialogComponent)
    .component('rymIntroGuide', introGuideComponent)
    .component('rymTagsInput', tagsInputComponent)
    .component('rymTabs', tabsComponent)
    .component('rymTab', tabComponent)
    .component('rymButton', buttonComponent)
    .component('rymCard', cardComponent)
    .component('rymCardGroup', cardGroupComponent)
    .component('rymProgressIndicator', progressIndicator)
    .component('rymCardList', cardListComponent)
    .component('rymRating', ratingComponent)
    .component('rymNoHits', noHitsComponent)
    .component('rymSlider', sliderComponent)
    .component('rymRadioCards', radioCardsComponent)
    .component('rymCategoryFavoriteIcon', categoryFavoriteIconComponent)
    .component('rymIntegrationDialog', integrationDialog)
    .component('rymTutorialVideoDialog', tutorialVideoDialogComponent)
    .directive('rymInfiniteScroll', infiniteScrollDirective)
    .directive('rymEmailValidation', emailValidationDirective)
    .directive('rymDiscountValidation', discountValidationDirective)
    .directive('rymPicture', profilePictureDirective)
    .directive('rymFocusOnClick', focusOnClickDirective)
    .directive('keyboardNavigation', keyboardNavigationDirective)
    .directive('rymScrollToMe', scrollToMeDirective)
    .directive('rymAutofocus', autoFocusDirective)
    .directive('ngEnter', ngEnterDirective);
