class CategoryFilterController {
    constructor(translationService, categoryService) {
        Object.assign(this, { translationService, categoryService });
    }

    $onInit() {
        this._setCategories();
    }

    _setCategories() {
        return this.categoryService.getAll().then((categories) => (this.categories = categories));
    }
}

CategoryFilterController.$inject = ['translationService', 'categoryService'];

export default CategoryFilterController;
