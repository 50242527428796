import { LANGUAGES } from '../../../app.constants';
import jstz from 'jstimezonedetect';

class UserService {
    constructor(
        userApiService,
        $q,
        $window,
        toastService,
        translationService,
        dialogService,
        organizationService,
        eventEmitterService,
        stringService,
        appcuesService
    ) {
        Object.assign(this, {
            userApiService,
            $q,
            $window,
            toastService,
            translationService,
            dialogService,
            organizationService,
            eventEmitterService,
            stringService,
            appcuesService
        });

        this.isShowingRatingToaster = false;
    }

    getCurrentUser() {
        if (this.currentUser) {
            return this.$q.resolve(this.currentUser);
        }

        return this.setCurrentUser();
    }

    getUserLanguage() {
        return this.$window.localStorage.getItem(LANGUAGES.STORAGE_KEY);
    }

    useLanguage(languageCode) {
        this.userApiService.putLanguageForCurrentUser(languageCode).then((user) => {
            this.$window.localStorage.setItem(LANGUAGES.STORAGE_KEY, languageCode);
            this.currentUser = user;
            this._reloadLocation();
        });
    }

    _reloadLocation() {
        this.organizationService.isLoggedInThroughStratsys().then((isStratsys) => {
            if (isStratsys && this.$window.location.href.indexOf('tenantId') === -1 && this.$window.location.href.indexOf('companyCode') === -1) {
                this.organizationService.getCurrentOrganization().then((organization) => {
                    let url = this.$window.location.href;

                    if (this.stringService.isGuid(organization.stratsysCompanyCode)) {
                        url += `?tenantId=${organization.stratsysCompanyCode}`;
                    } else {
                        url += `?companyCode=${organization.stratsysCompanyCode}`;
                    }

                    this.$window.location.href = url;
                });
            } else {
                this.$window.location.reload();
            }
        });
    }

    setUserLanguage() {
        return this.getCurrentUser().then((user) => {
            var userLang = this.getLanguageCode(user.languageId);
            var currentLang = this.$window.localStorage.getItem(LANGUAGES.STORAGE_KEY);
            if (currentLang && userLang === currentLang) {
                return;
            }

            this.$window.localStorage.setItem(LANGUAGES.STORAGE_KEY, userLang);
            this.$window.location.reload();
        });
    }

    getLanguageCode(languageId) {
        if (languageId === LANGUAGES.SWEDISH) {
            return LANGUAGES.SWEDISH_CODE;
        } else if (languageId === LANGUAGES.NORWEGIAN) {
            return LANGUAGES.NORWEGIAN_CODE;
        } else if (languageId === LANGUAGES.GERMAN) {
            return LANGUAGES.GERMAN_CODE;
        } else {
            return LANGUAGES.ENGLISH_CODE;
        }
    }

    hasPreferredLanguage() {
        return this.$window.localStorage.getItem(LANGUAGES.STORAGE_KEY) !== null;
    }

    setCurrentUser() {
        return this.userApiService.getCurrentUser().then(
            (user) => {
                this.currentUser = user;

                if (!this.setUserInfo) {
                    this.setUserInfo = true;
                    this.appcuesService.identify(this.currentUser.id, {
                        name: this.currentUser.name,
                        nrOfLogins: this.currentUser.logins,
                        created_at: this.currentUser.createdDateTime,
                        language_id: this.currentUser.languageId
                    });

                    if (this.$window.profitwell) {
                        profitwell('auth_token', 'af23bd58a881aefe15a0dc78b85cef77');
                        profitwell('user_email', this.currentUser.email);
                        profitwell('cq_get');
                    }
                }

                return this.currentUser;
            },
            (err) => {
                return this.$q.reject(err);
            }
        );
    }

    refreshAndGetCurrentUser() {
        this.userApiService.clearCache('current');
        return this.setCurrentUser();
    }

    getUserBrowser() {
        let browser = {},
            userAgent = navigator.userAgent.toLowerCase();

        let browserFunc = (func) => {
            if (
                /mozilla/.test(userAgent) &&
                /applewebkit/.test(userAgent) &&
                /chrome/.test(userAgent) &&
                /safari/.test(userAgent) &&
                /opr/.test(userAgent)
            ) {
                return 'opera';
            } else if (/webkit/.test(userAgent) && /chrome/.test(userAgent) && !/edge/.test(userAgent)) {
                return 'chrome';
            } else if (/mozilla/.test(userAgent) && /firefox/.test(userAgent)) {
                return 'firefox';
            } else if (/msie/.test(userAgent) || /trident/.test(userAgent) || /edge/.test(userAgent)) {
                return 'msie';
            } else if (/safari/.test(userAgent) && /applewebkit/.test(userAgent) && !/chrome/.test(userAgent)) {
                return 'safari';
            }

            return 'unknown';
        };

        browser.browser = browserFunc();

        let match = userAgent.match(new RegExp('(' + (browser.browser === 'msie' ? 'msie|edge' : browser.browser) + ')( |/)([0-9]+)'));
        if (match) {
            browser.version = match[3];
        } else {
            match = userAgent.match(new RegExp('rv:([0-9]+)'));
            browser.version = match ? match[1] : '';
        }

        return browser;
    }

    getUserOS() {
        let userAgent = this.$window.navigator.userAgent,
            platform = this.$window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad'],
            os = null,
            version = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            version = userAgent.match(/(?:X.\d\d_\d)/g);
            version = version && version.length ? version[0] : '';
            os = 'Mac OS';
            if (version) {
                os = `${os} ${version}`;
            }
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            version = userAgent.match(/(?:NT.[0-9]\S\S)/g);
            version = version && version.length ? version[0] : '';
            os = 'Windows';
            if (version) {
                os = `${os} ${version}`;
            }
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        } else {
            os = 'Something old';
        }

        return os;
    }

    getUserCookie(cookieName) {
        let cookie = `${cookieName}=`,
            decodedCookie = decodeURIComponent(document.cookie),
            ca = decodedCookie.split(';');

        let cookieValue = '';
        _.forEach(ca, (c) => {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookie) === 0) {
                cookieValue = c.substring(cookie.length, c.length);
            }
        });

        return cookieValue;
    }

    shouldRateRYM() {
        if (this.isShowingRatingToaster) {
            return;
        }

        this.isShowingRatingToaster = true;

        const MIN_LOGINS = 7;
        let rating = {
            rating: -1
        };
        this.refreshAndGetCurrentUser().then((user) => {
            if (user.logins >= MIN_LOGINS && !user.hasSubmittedRating) {
                return this.toastService
                    .rateUs()
                    .then((userRating) => {
                        rating = userRating;
                    })
                    .finally(() => {
                        this.userApiService.postRating(rating).then(() => (user.hasSubmittedRating = true));
                    });
            }
        });
    }

    showTermsOfService() {
        return this.$q
            .all([this.organizationService.getCurrentOrganization(), this.organizationService.isLoggedInThroughStratsys()])
            .then((results) => {
                const organization = results[0],
                    isLoggedInThroughStratsys = results[1];

                if (organization.isStandalone && !isLoggedInThroughStratsys) {
                    let dialogData = {
                        title: this.translationService.translate('client_NewTermsOfService'),
                        description: this.translationService.translate('client_ByContinuingToUseRymYouAccept', {
                            theTermsOfServiceLink: `<a target="_blank" rel="noopener" class="mail-to-link" href="https://www.stratsys.com/terms-of-service-meetings/">${this.translationService.translate(
                                'client_TheTermsOfService'
                            )}</a>`
                        }),
                        ok: this.translationService.translate('client_Accept')
                    };

                    return this.dialogService.alert(dialogData).then(() => {
                        let hubspotCookie = this.getUserCookie('hubspotutk');
                        this.userApiService.setHasAcceptedTermsForCurrentUser(hubspotCookie);
                    });
                }
            });
    }

    isIE11() {
        let userBrowser = this.getUserBrowser();

        return userBrowser.browser === 'msie' && userBrowser.version === '11';
    }

    setHidePlaceholderMeetingsForCurrentUser(value) {
        return this.userApiService.setHidePlaceholderMeetingsForCurrentUser(value);
    }

    setHideOffice365IntegrationDialogForCurrentUser(value) {
        return this.userApiService.setHideOffice365IntegrationDialogForCurrentUser(value);
    }

    setHasLoggedIn() {
        let hubspotCookie = this.getUserCookie('hubspotutk');
        return this.userApiService.setHasLoggedIn(hubspotCookie).then(() => this.refreshAndGetCurrentUser());
    }

    setPreferredCulture(preferredFormat) {
        return this.userApiService.putPreferredCulture(preferredFormat).then(() => this.refreshAndGetCurrentUser());
    }

    setTimezone() {
        let currentTimezone = jstz.determine().name();
        this.userApiService.putTimezoneForCurrentUser(currentTimezone).then(() => this.refreshAndGetCurrentUser());
    }
}

UserService.$inject = [
    'userApiService',
    '$q',
    '$window',
    'toastService',
    'translationService',
    'dialogService',
    'organizationService',
    'eventEmitterService',
    'stringService',
    'appcuesService'
];

export default UserService;
