import controller from './verticalWizardStep.controller';
import template from './verticalWizardStep.html';

let verticalWizardStepComponent = {
    controller: controller,
    template,
    link: (scope, element, attrs, verticalWizardCtrl) => {
        verticalWizardCtrl.add(scope);
    },
    require: {
        verticalWizardCtrl: '^rymVerticalWizard'
    },
    transclude: true,
    bindings: {
        stepTitle: '@',
        stepSummary: '=',
        disableCheck: '<',
        isLastStep: '<'
    }
};

export default verticalWizardStepComponent;
