class FormDatePickerController {
    constructor($element, $scope, translationService, menuService, timeService) {
        this.$element = $element;
        this.$scope = $scope;
        this.translationService = translationService;
        this.menuService = menuService;
        this.timeService = timeService;

        this.options = {};
    }

    $onInit() {
        if (!this.placeHolderText) {
            this.placeHolderText = this.translationService.translate('client_SelectDate');
        }
    }

    pickDate(date) {
        this.ngModel.$setViewValue(date);
        this.options.date = date;
    }

    showMenu(event) {
        if (this.lock) {
            event.preventDefault();
            event.stopImmediatePropagation();
            return;
        }

        let menuButton = this.$element[0].firstElementChild;

        this.options = {
            fromDate: this.timeService.getLastSecondOfDayAsDate(this.fromDate),
            date: this.ngModel.$viewValue,
            onClick: this.pickDate.bind(this),
            allowTabs: true
        };

        this.menuService.dateMenu(this.options, angular.element(menuButton), false, true);
    }
}

FormDatePickerController.$inject = ['$element', '$scope', 'translationService', 'menuService', 'timeService'];

export default FormDatePickerController;
