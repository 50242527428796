import { COUNTRIES } from '../../app.constants';

const INJECT = ['$q', '$scope', '$interval', '$timeout', 'JsVatFactory', 'menuService', 'translationService', 'dialogService'];

class SubscriptionContactFormController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this._init();
    }

    _init() {
        this.email = this.contactFormData.email;
        this.name = this.contactFormData.name;
        this.address = this.contactFormData.address;
        this.postalCode = this.contactFormData.postalCode;
        this.city = this.contactFormData.city;
        this.country = this.contactFormData.country;
        this.vat = this.contactFormData.vat;
        this._setPrefilledFormData();
        this._startPoll();
    }

    _setPrefilledFormData() {
        this.preFilledEmail = this.contactFormData.email;
        this.preFilledName = this.contactFormData.name;
        this.preFilledAddress = this.contactFormData.address;
        this.preFilledPostalCode = this.contactFormData.postalCode;
        this.preFilledCity = this.contactFormData.city;
        this.preFilledCountry = this.contactFormData.country;
        this.preFilledVat = this.contactFormData.vat;
    }

    change() {
        this._updateHasChanged();
        this._setFormData();
    }

    _startPoll() {
        this.checkForDomElement = this.$interval(
            () => {
                this.$scope.$evalAsync(() => {
                    if (document.getElementById('country-selector')) {
                        document.getElementById('country-selector').addEventListener('focus', (event) => {
                            this.showCountries(event);
                        });
                        this._cancelPoll();
                    }
                });
            },
            200,
            0,
            false
        );
    }

    _cancelPoll() {
        this.$interval.cancel(this.checkForDomElement);
    }

    showCountries($event) {
        let options = {
            selected: this.countryCodeNumerical || '',
            countries: COUNTRIES,

            searchPlaceholder: this.translationService.translate('search'),
            items: COUNTRIES.map((x) => {
                return {
                    isDefault: this.country && this.country.country === x.country,
                    id: x.countryCodeNumerical,
                    country: x,
                    value: x.country
                };
            }),
            onClick: (item) => this._setCountry(item.country)
        };

        this.menuService.dropdownMenu(options, angular.element($event.currentTarget), false, true);
    }

    _setCountry(country) {
        this.country = country;
        this.JsVatFactory.config = [country.country.toLowerCase().replace(' ', '_')];
        this.change();
        this._setFormData();
        this.$timeout(() => {
            this.update();
        }, 1);
    }

    showVatInfo() {
        this.translationService.translateBatch(['client_WhatIsThis', 'close', 'client_WhatIsThisDescription']).then((translations) => {
            this.dialogService.alert({
                title: translations.client_WhatIsThis,
                ok: translations.close,
                description: translations.client_WhatIsThisDescription
            });
        });
    }

    _updateHasChanged() {
        this.hasChanged =
            this.preFilledEmail !== this.email ||
            this.preFilledName !== this.name ||
            this.preFilledAddress !== this.address ||
            this.preFilledPostalCode !== this.postalCode ||
            this.preFilledCity !== this.city ||
            this.preFilledCountry !== this.country ||
            this.preFilledVat !== this.vat;
    }

    _setFormData() {
        this.contactFormData = {
            email: this.email,
            name: this.name,
            address: this.address,
            postalCode: this.postalCode,
            city: this.city,
            country: this.country,
            vat: this.vat
        };
    }
}

SubscriptionContactFormController.$inject = INJECT;

export default SubscriptionContactFormController;
