import template from './rating.html';
import controller from './rating.controller';

let ratingComponent = {
    template,
    controller,
    bindings: {
        onClose: '&',
        onSubmit: '&'
    }
};

export default ratingComponent;
