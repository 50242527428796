import template from './noHits.html';

let noHitsComponent = {
    template,
    bindings: {
        header: '@',
        description: '@',
        icon: '@'
    }
};

export default noHitsComponent;
