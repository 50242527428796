import template from './subscriptionSummary.html';
import controller from './subscriptionSummary.controller';

export default {
    template,
    controller,
    bindings: {
        numberOfLicenses: '<',
        currency: '<',
        isAnnually: '<'
    }
};
