class GuideStepController {
    constructor(translationService) {
        this.isGoingLeft = false;
        this.isPrevious = false;
        this.isActive = false;
        this.translationService = translationService;
    }

    $onInit() {
        this.guideCtrl.add(this);
    }

    $onDestroy() {
        this.guideCtrl.remove(this);
    }

    done() {
        this.guideCtrl.done();
    }

    isCompleted() {
        return this.guideCtrl.isCompleted();
    }

    isAtLastStep() {
        return this.guideCtrl.isAtLastStep();
    }
}

GuideStepController.$inject = ['translationService'];

export default GuideStepController;
