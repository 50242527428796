import controller from './createActionDialog.controller';
import template from './createActionDialog.html';

let createActionDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default createActionDialogComponent;
