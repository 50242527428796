import template from './formUserPicker.html';
import controller from './formUserPicker.controller';
import './formUserPicker.less';

let formUserPickerComponent = {
    template,
    controller,
    require: {
        ngModel: 'ngModel'
    },
    bindings: {
        placeholderText: '@',
        meetingId: '<'
    }
};

export default formUserPickerComponent;
