import template from './categoryFilter.html';
import controller from './categoryFilter.controller';
import './categoryFilter.less';

let categoryFilterComponent = {
    template,
    controller,
    bindings: {
        value: '=',
        onChange: '&'
    }
};

export default categoryFilterComponent;
