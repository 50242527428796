import template from './sideNav.html';
import controller from './sideNav.controller';
import './sideNav.less';

let sideNavComponent = {
    template,
    controller,
    transclude: {
        footer: '?rymSideNavFooter',
        header: '?rymSideNavHeader',
        headerActions: '?rymSideNavHeaderActions',
        content: 'rymSideNavContent'
    },
    bindings: {
        isOpen: '<',
        isFixed: '<',
        headerTitle: '@',
        scrollerId: '@',
        close: '&',
        closeOnBlur: '<',
        onInit: '&'
    }
};

export default sideNavComponent;
