import template from './switch.html';

let switchComponent = {
    template,
    bindings: {
        isEnabled: '<',
        onChange: '&'
    }
};

export default switchComponent;
