import template from './exportMeetingDialog.html';
import controller from './exportMeetingDialog.controller';
import './exportMeetingDialog.less';

let exportMeetingDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default exportMeetingDialogComponent;
