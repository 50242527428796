const BACK_URI_KEY = 'backUri';
import Oidc from 'oidc-client';

class BaseAuthService {
    constructor($window, $location, platformService, envService) {
        this.$window = $window;
        this.$location = $location;
        this.platformService = platformService;
        this.envService = envService;
    }

    setBackUri() {
        let route = this.$location.url();
        if (route && route !== '/meetings') {
            this.$window.localStorage.setItem(BACK_URI_KEY, route);
        }
    }

    removeBackUri() {
        this.$window.localStorage.removeItem(BACK_URI_KEY);
    }

    getBackUri() {
        this.$window.localStorage.getItem(BACK_URI_KEY);
    }

    getClientConfig() {
        let stratsysAuthorityUrl = this.platformService.getStratsysAuthorityUrl();
        if (stratsysAuthorityUrl) {
            return this._getPlatformIdpConfig(stratsysAuthorityUrl);
        }

        return null;
    }

    _getPlatformIdpConfig(authorityUrl) {
        var config = {
            authority: authorityUrl,
            client_id: this.envService.STRATSYS_CLIENT_ID,
            redirect_uri: window.location.protocol + '//' + window.location.host + '/oauthcallback.html',
            post_logout_redirect_uri: window.location.protocol + '//' + window.location.host + '/index.html',
            response_type: 'id_token token',
            scope: 'openid profile email role meetings user.readwrite helpcenter',
            silent_redirect_uri: window.location.protocol + '//' + window.location.host + '/frame.html',
            automaticSilentRenew: true,
            loadUserInfo: true,
            revokeAccessTokenOnSignout: true,
            client_secret: this.envService.STRATSYS_CLIENT_SECRET,
            popupWindowFeatures: 'location=no,toolbar=no,width=500,height=500,left=100,top=100'
        };
        return config;
    }
}

BaseAuthService.$inject = ['$window', '$location', 'platformService', 'envService'];

export default BaseAuthService;
