import controller from './subscriptionCheckout.controller';
import template from './subscriptionCheckout.html';

let subscriptionCheckoutComponent = {
    template,
    controller,
    bindings: {
        subscriptionInfo: '<',
        isLoading: '=',
        proceedToNextStep: '<',
        finalStep: '=',
        refresh: '&',
        onChange: '&'
    }
};

export default subscriptionCheckoutComponent;
