import template from './currencyMenu.html';
import './currencyMenu.less';

let currencyMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default currencyMenuComponent;
