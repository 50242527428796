import template from './tagsInput.html';
import controller from './tagsInput.controller';

let tagsInputComponent = {
    template,
    controller,
    bindings: {
        lockMenu: '<',
        items: '<',
        suggestedItems: '<',
        selectedItems: '<',
        tagTemplte: '<',
        createText: '<',
        createIcon: '<',
        autoFocus: '<',
        searchPlaceholder: '<',
        onSelected: '&',
        onSelect: '&',
        onCreate: '&',
        onSearch: '&',
        onDeselect: '&',
        onClickAtSelected: '&',
        allowChange: '<',
        onValidate: '&',
        disableSearch: '<',
        max: '<',
        suggestedUsersText: '<',
        isDisabled: '<',
        icon: '@',
        iconTitle: '@'
    }
};

export default tagsInputComponent;
