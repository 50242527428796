class MeetingSeriesActionsController {
    constructor(
        $state,
        $element,
        $scope,
        $document,
        $timeout,
        $filter,
        recompileService,
        meetingApiService,
        eventEmitterService,
        responsive,
        actionApiService,
        rowService,
        dateService,
        anchorScrollService,
        menuService,
        toastService,
        dialogService,
        meetingSeriesService,
        actionService,
        translationService,
        userService,
        exportService,
        participantsService
    ) {
        Object.assign(this, {
            $state,
            $element,
            $scope,
            $document,
            $timeout,
            $filter,
            recompileService,
            meetingApiService,
            responsive,
            eventEmitterService,
            actionApiService,
            rowService,
            dateService,
            anchorScrollService,
            menuService,
            toastService,
            dialogService,
            meetingSeriesService,
            actionService,
            translationService,
            userService,
            exportService,
            participantsService
        });
    }

    $onInit() {
        this.parent.loading = true;
        this.responsive.on(['md', 'lg', 'xl'], this.$scope, () => {
            this.filterOpen = true;
        });
        this.responsive.on(['xs', 'sm'], this.$scope, () => {
            this.filterOpen = false;
        });
        this.meetingSeries = [];
        this.actions = [];
        this.filter = {
            responsible: 'all',
            status: 'all',
            searchTerm: ''
        };
        this.limitTo = 10;

        this.eventEmitterService.subscribe('parentToggleSideNav', (bool) => {
            if (bool) {
                this.closeDetail();
            }
        });

        this.eventEmitterService.subscribe('toggleStatus', (action) => {
            if (this.selectedAction && this.selectedAction.id === action.id) {
                this.selectedAction.isFinished = action.isFinished;
                this.buildRowData(this.selectedAction);
                let statusRow = this.$document[0].getElementById('statusRow');
                let dateRow = this.$document[0].getElementById('endDateRow');
                this.recompileService.recompile(this.$scope, statusRow);
                this.recompileService.recompile(this.$scope, dateRow);
            }
            this.parent.setActionIndicator(this.actions);
        });

        this.meetingSeriesService
            .getActionsInMeetingSeries()
            .then((actions) => {
                this.actions = actions;
                this._initActionGroups(actions);
                this.parent.setActionIndicator(this.actions);
            })
            .finally(() => {
                this.parent.loading = false;
                this.parent.tabLoaded('actions');
            });

        this.eventEmitterService.subscribe('exportList', this._export.bind(this));
    }

    $onDestroy() {
        this.selectedAction = null;
        this.eventEmitterService.unSubscribe('exportList');
    }

    handleOnScrollToBottom($event) {
        if (!this.locked) {
            this.locked = true;

            this.limitTo += 10;

            this.$timeout(() => {
                this.locked = false;
            }, 800);
        }
    }

    _export() {
        let options = {
            id: 'export actions dialog',
            title: this.translationService.translate('client_Actions'),
            ok: this.translationService.translate('client_Export'),
            cancel: this.translationService.translate('cancel'),
            message: {
                subject: this.translationService.translate('client_Actions'),
                message: this.translationService.translate('client_ExportActionDescription', { date: this.$filter('date')(new Date(), 'rymDate') })
            }
        };

        this.dialogService.custom('rym-text-dialog', options).then((message) => {
            let exportContext = {
                title: message.subject,
                description: message.message,
                itemIds: this.$filter('filter')(this.actions, this.isMatchingFilter()).map((a) => a.id)
            };

            return this.exportService.exportActions(exportContext);
        });
    }

    _initActionGroups(actions) {
        actions = this.$filter('orderBy')(actions, '-fromDate');
        let actionGroups = _.groupBy(actions, (action) => action.meetingId);
        let actionGroupsArray = Object.keys(actionGroups).map((key) => {
            return actionGroups[key];
        });
        this.actionGroups = _.sortBy(actionGroupsArray, (actionGroup) => {
            return actionGroup[0].fromDate;
        }).reverse();
    }

    $doCheck() {
        if (this.parent.scrollTo && document.querySelector('#m' + this.parent.scrollTo)) {
            this.anchorScrollService.scrollTo('m' + this.parent.scrollTo, 'meeting-series-actions', 96, 0);
            this.parent.scrollTo = null;
        }
    }

    meetingHasActions(group) {
        return _.some(group, (action) => {
            return this.isMatchingStatusFilter(action) && this.isMatchingResponsibleFilter(action) && this.isMatchingSearchTerm(action);
        });
    }

    groupIsMatchingFilter() {
        return this.meetingHasActions.bind(this);
    }

    buildUser(action) {
        let user = {
            name: action.responsibleName,
            email: action.responsibleEmail,
            isExternal: action.responsibleIsExternal
        };

        return user;
    }

    showDetail(action) {
        if (!action.isEditable) {
            return;
        }

        this.selectedAction = null;
        this.fromAgendaRow = null;
        if (this.parent.sideNavOpen) {
            this.parent.sideNavOpen = !this.parent.sideNavOpen;
        }

        this.$timeout(() => {
            this.buildRowData(action);
            this.selectedAction = action;

            this.oldActionName = this.selectedAction.name;
            if (action.meetingId) {
                this.fromAgendaRow = {
                    title: this.translationService.translate('client_AgendaItem'),
                    icon: 'format_list_numbered',
                    description: action.fromAgenda,
                    meetingId: action.meetingId,
                    isParticipantInMeeting: action.isParticipantInMeeting,
                    isDisabled: false,
                    disabledTitle: this.translationService.translate('client_NotParticipant')
                };

                if (!action.isParticipantInMeeting || action.isPostponed) {
                    this.fromAgendaRow.isDisabled = true;
                    this.fromAgendaRow.disabledTitle = action.isPostponed
                        ? this.translationService.translate('client_ActionIsRelatedToPostponedAgenda')
                        : this.translationService.translate('client_NotParticipant');
                }
            }

            this.disableEdit = !action.isEditable;
            this.actionDetailOpen = true;
        });
    }

    closeDetail() {
        this.actionDetailOpen = false;
        this.selectedAction = null;
    }

    goToAction() {
        if (this.$state.is('meeting') && this.$state.params.id == this.selectedAction.meetingId) {
            const fullscreenDialogToClose = 'meetingseries';
            this.eventEmitterService.publishEvent('closeFullscreenDialog' + fullscreenDialogToClose);
            return;
        }

        this.parent.scrollTo = 'a' + this.selectedAction.id;
        let meeting = _.find(this.meetingSeriesService.getMeetingSeries().meetings, (meet) => meet.id === this.selectedAction.meetingId);
        this.parent.showProtocol(meeting);
    }

    isMatchingFilter() {
        return (action) => {
            return this.isMatchingStatusFilter(action) && this.isMatchingResponsibleFilter(action) && this.isMatchingSearchTerm(action);
        };
    }

    isMatchingSearchTerm(action) {
        let query = this.filter.searchTerm;
        return (
            !query ||
            action.name.toLowerCase().includes(query.toLowerCase()) ||
            (action.responsible && action.responsible.toLowerCase().includes(query.toLowerCase()))
        );
    }

    isMatchingResponsibleFilter(action) {
        let currentUserId = this.parent.user ? this.parent.user.id : null;
        let isMine = action.responsibleId === currentUserId;

        switch (this.filter.responsible) {
            case 'mine':
                return isMine;
            case 'delegated':
                return !isMine;
            default:
                return true;
        }
    }

    isDelayed(action) {
        return action.endDate && new Date(action.endDate).getTime() < _.now() && !action.isFinished;
    }

    updateComment() {
        return this.actionApiService.putComment(this.selectedAction.id, this.selectedAction.comment).then((result) => {
            this._notifyPossibleCopies(result);
        });
    }

    isMatchingStatusFilter(action) {
        let isFinished = action.isFinished;

        switch (this.filter.status) {
            case 'finished':
                if (!this.hasChangedSortOrder) {
                    this.orderBy = '-endDate';
                }
                return isFinished;
            case 'unfinished':
                if (!this.hasChangedSortOrder) {
                    this.orderBy = 'endDate';
                }
                return !isFinished;
            case 'delayed':
                return this.isDelayed(action);
            default:
                return true;
        }
    }

    updateActionName() {
        return this.actionApiService.putName(this.selectedAction.id, this.selectedAction.name).then((result) => {
            this._notifyPossibleCopies(result);
        });
    }

    buildRowData(action) {
        this.translationService
            .translateBatch([
                'meetings_Responsible',
                'client_CreatedBy',
                'status',
                'client_Deadline',
                'done',
                'client_ActionFinished',
                'client_ActionUnfinished'
            ])
            .then((translations) => {
                this.assignRow = {
                    title: translations.meetings_Responsible,
                    description: action.responsible ? action.responsible : '---',
                    user: {
                        name: action.responsible ? action.responsible : '',
                        email: action.responsibleEmail,
                        isExternal: action.responsibleIsExternal
                    }
                };

                this.createdByRow = {
                    title: translations.client_CreatedBy,
                    description: action.createdBy,
                    user: { name: action.createdBy, email: action.createdByEmail }
                };

                this.statusRow = {
                    title: translations.status,
                    description: action.isFinished ? translations.client_ActionFinished : translations.client_ActionUnfinished,
                    icon: action.isFinished ? 'check_box' : 'check_box_outline_blank'
                };

                this.endDateRow = {
                    title: translations.client_Deadline,
                    description: action.endDate ? this.$filter('date')(action.endDate, 'rymDate') : '---',
                    icon: 'update',
                    cssClass: action.endDate && new Date(action.endDate) < _.now() && !action.isFinished ? 'attention(on)' : ''
                };

                this.initialized = true;
            });
    }

    toggleStatus($event) {
        this._loadElement('statusRow');
        this.actionApiService
            .toggleStatus(this.selectedAction)
            .then((result) => {
                this.selectedAction.isFinished = result.isFinished;
                this.buildRowData(this.selectedAction);
                this._notifyPossibleCopies(result);
                if (result.isFinished) {
                    this.userService.shouldRateRYM();
                }
            })
            .finally(() => {
                this.parent.setActionIndicator(this.actions);
                let dateRow = this.$element.find('rym-row')[3];
                this.recompileService.recompile(this.$scope, dateRow);
                this._recompile();
            });
    }

    _setActiveElement($event) {
        this.activeElement = $event.currentTarget;
    }

    _loadElement(elementId) {
        this.activeElement = this.$document[0].getElementById(elementId);
        this.rowService.playLoadingAnimation(this.activeElement);
    }

    updateEndDate(date) {
        this._loadElement('endDateRow');
        this.actionApiService
            .updateEndDate(this.selectedAction.id, date)
            .then((result) => {
                this.selectedAction.endDate = result.endDate;
                this.dateService.convertEndDateToString(this.selectedAction);
                this.endDateRow.description = this.$filter('date')(this.selectedAction.endDate, 'rymDate');
                this.endDateRow.cssClass =
                    this.selectedAction.endDate && new Date(this.selectedAction.endDate) < _.now() && !this.selectedAction.isFinished
                        ? 'attention(on)'
                        : '';
                this._notifyPossibleCopies(result);
            })
            .finally(() => {
                this._recompile();
            });
    }

    showDate($event) {
        this._setActiveElement($event);
        let options = {
            date: this.selectedAction.endDate ? new Date(this.selectedAction.endDate) : new Date(),
            allowTabs: true,
            onClick: this.updateEndDate.bind(this)
        };

        this.menuService.dateMenu(options, angular.element(this.activeElement), false);
    }

    showSelectResponsible($event) {
        this._setActiveElement($event);
        this.userService.getCurrentUser().then((user) => {
            if (this.fromAgendaRow && this.fromAgendaRow.meetingId) {
                this.meetingApiService.getParticipantsForMeeting(this.fromAgendaRow.meetingId).then((result) => {
                    let options = {
                        disableSearch: user.isExternal,
                        suggestedUsers: result,
                        tabToClose: true,
                        onClick: (result) => {
                            this.updateResponsible(result.user);
                        },
                        onInvite: (email) => {
                            this.updateResponsible({ email: email, isExternal: true });
                        }
                    };
                    this.menuService.userMenu(options, angular.element(this.activeElement), false);
                });
            } else {
                let options = {
                    disableSearch: user.isExternal,
                    tabToClose: true,
                    onClick: (result) => {
                        this.updateResponsible(result.user);
                    },
                    onInvite: (email) => {
                        this.updateResponsible({ username: email, isExternal: true });
                    }
                };
                this.menuService.userMenu(options, angular.element(this.activeElement), false).then(() => {});
            }
        });
    }

    showActionMenu($event, action) {
        $event.stopPropagation();
        action = action ? action : this.selectedAction;

        this.$timeout(
            () => {
                this.translationService.translateBatch(['client_Delete', 'client_Duplicate']).then((translations) => {
                    let menuButton = $event.target || $event.currentTarget;

                    let options = {
                        items: []
                    };

                    options.items.push({
                        id: 'menu option - duplicate action',
                        icon: 'content_copy',
                        text: translations.client_Duplicate,
                        onClick: () => this.duplicate(action)
                    });

                    options.items.push({
                        id: 'menu option - delete action',
                        icon: 'delete_outline',
                        text: translations.client_Delete,
                        onClick: () => this.remove(action)
                    });

                    this.menuService.actionMenu(options, angular.element(menuButton), false);
                });
            },
            100,
            false
        );
    }

    remove(action) {
        this.translationService
            .translateBatch(['client_DeleteItemConfirm', 'client_DeleteConfirm', 'client_ActionDeleted'], { itemName: action.name })
            .then((translations) => {
                this.dialogService
                    .confirm({
                        id: 'delete action dialog',
                        title: translations.client_DeleteConfirm,
                        description: translations.client_DeleteItemConfirm
                    })
                    .then(() => {
                        this.actionApiService
                            .remove(action.id, action.office365Id)
                            .then(() => {
                                let actionGroup = _.find(this.actionGroups, (actionGroup) => {
                                    return actionGroup.includes(action);
                                });

                                let actionIndex = this.actions.indexOf(action);
                                this.actions.splice(actionIndex, 1);

                                let index = _.findIndex(actionGroup, (ac) => ac.id === action.id);

                                if (actionGroup && index !== -1) {
                                    actionGroup.splice(index, 1);
                                    if (actionGroup.length === 0) {
                                        let index = this.actionGroups.indexOf(actionGroup);
                                        this.actionGroups.splice(index, 1);
                                    }
                                }

                                this.closeActionDetailAndRemoveSelectedAction();
                                this.toastService.success({ title: translations.client_ActionDeleted });
                                this._invalidateActionsCache();
                                action.isDeleted = true;
                                this._notifyPossibleCopies(action);
                            })
                            .catch((exception) => {
                                this.toastService.error({ description: exception.data.message });
                            })
                            .finally(() => {
                                this.parent.setActionIndicator(this.actions);
                            });
                    });
            });
    }

    duplicate(action) {
        this.actionApiService
            .duplicate(action.id, action.office365Id)
            .then((result) => {
                this.toastService.success({
                    title: this.translationService.translate('client_ActionDuplicated')
                });
                let actionGroup = _.find(this.actionGroups, (actionGroup) => {
                    return actionGroup.includes(action);
                });
                let index = _.findIndex(actionGroup, (ac) => ac.id === action.id);

                if (actionGroup && index !== -1) {
                    actionGroup.splice(index, 0, result);
                }

                this.actions.push(result);
                this.closeActionDetailAndRemoveSelectedAction();
                this._invalidateActionsCache();
                this._notifyAgendaNewAction(result);
            })
            .catch((exception) => {
                toastService.error({ description: exception.data.message });
            })
            .finally(() => {
                this.parent.setActionIndicator(this.actions);
            });
    }

    closeActionDetailAndRemoveSelectedAction() {
        this.actionDetailOpen = false;
        this.selectedAction = null;
    }

    closeSelectResponsible() {
        this.selectResponsible = false;
    }

    closeSelectDate() {
        this.isShowingDate = false;
    }

    closeSideNav() {
        if (this.actionDetailOpen) {
            this.closeDetail();
        }

        if (this.parent.sideNavOpen) {
            this.parent.sideNavOpen = !this.parent.sideNavOpen;
        }
    }

    _recompile(custom, skipFocusEvent) {
        let newElement = this.recompileService.recompile(this.$scope, this.activeElement, custom);
        this.rowService.playDoneAnimation(newElement, skipFocusEvent);
    }

    updateResponsible(user) {
        this._loadElement('responsibleRow');
        this.closeSelectResponsible();
        return this.actionApiService
            .putResponsible(this.selectedAction.id, user)
            .then((result) => {
                this.selectedAction.responsibleEmail = result.responsibleEmail;
                this.selectedAction.responsible = result.responsible;
                this.selectedAction.responsibleIsExternal = result.responsibleIsExternal;
                this.selectedAction.responsibleId = result.responsibleId;

                this.assignRow = {
                    title: this.translationService.translate('meetings_Responsible'),
                    description: this.selectedAction.responsible ? this.selectedAction.responsible : '---',
                    user: {
                        name: this.selectedAction.responsible,
                        email: this.selectedAction.responsibleEmail,
                        isExternal: this.selectedAction.responsibleIsExternal
                    }
                };
                this._notifyPossibleCopies(result);
            })
            .finally(() => {
                this.parent.setActionIndicator(this.actions);
                this._recompile(
                    `<rym-row id="responsibleRow" test-hook="ResponsibleActionDetails" row="::$ctrl.assignRow" ng-click="::$ctrl.showSelectResponsible($event)"><rym-row-icon><rym-picture class="rym-profile-picture  left(0-2)   right(0-2)  " user="::$ctrl.assignRow.user"></rym-picture></rym-row-icon></rym-row>`
                );
            });
    }

    _invalidateActionsCache() {
        this.meetingSeriesService._invalidateActionCache();
    }

    _notifyPossibleCopies(action) {
        this.eventEmitterService.publishEvent('agendaActionChanged' + action.id, action);
        this._updateUnfinishedActions();
    }

    _notifyAgendaNewAction(action) {
        this.eventEmitterService.publishEvent('agendaActionAdded' + action.agendaId, action);
        this._updateUnfinishedActions();
    }

    _updateUnfinishedActions() {
        this.actionService.updateUnfinishedActions();
    }
}

MeetingSeriesActionsController.$inject = [
    '$state',
    '$element',
    '$scope',
    '$document',
    '$timeout',
    '$filter',
    'recompileService',
    'meetingApiService',
    'eventEmitterService',
    'responsive',
    'actionApiService',
    'rowService',
    'dateService',
    'anchorScrollService',
    'menuService',
    'toastService',
    'dialogService',
    'meetingSeriesService',
    'actionService',
    'translationService',
    'userService',
    'exportService',
    'participantsService'
];

export default MeetingSeriesActionsController;
