import controller from './agenda.controller.js';
import template from './agenda.html';
import './agenda.less';

let agendaComponent = {
    template,
    controller,
    bindings: {
        agenda: '=',
        number: '@',
        remove: '&',
        postpone: '&',
        isDisabled: '<',
        setFocus: '<',
        isInDialog: '<',
        isTemplate: '<',
        meeting: '<',
        showAgendaOptions: '<',
        addPostponed: '&'
    }
};

export default agendaComponent;
