import controller from './filterTabs.controller';

let filterTabsComponent = {
    controller,
    bindings: {
        model: '=ngModel',
        shouldScroll: '<',
        onChange: '&',
        allowEqual: '<',
        shouldSearch: '<'
    }
};

export default filterTabsComponent;
