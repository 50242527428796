/* */
"format cjs";
'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
  var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
  function getDecimals(n) {
    n = n + '';
    var i = n.indexOf('.');
    return (i == -1) ? 0 : n.length - i - 1;
  }

  function getVF(n, opt_precision) {
    var v = opt_precision;

    if (undefined === v) {
      v = Math.min(getDecimals(n), 3);
    }

    var base = Math.pow(10, v);
    var f = ((n * base) | 0) % base;
    return {v: v, f: f};
  }

  const formats = {
    rymDate: "EEE, d MMM, y",
    fullDate: "EEEE d MMMM y",
    longDate: "d MMMM y",
    medium: "d MMM y HH:mm:ss",
    mediumDate: "d MMM y",
    mediumTime: "HH:mm:ss",
    short: "y-MM-dd HH:mm",
    shortDate: "y-MM-dd",
    shortTime: "HH:mm"
  };

  $provide.value("$locale", {
    "DATETIME_FORMATS": {
      "AMPMS": [
        "fm",
        "em"
      ],
      "DAY": [
        "s\u00f6ndag",
        "m\u00e5ndag",
        "tisdag",
        "onsdag",
        "torsdag",
        "fredag",
        "l\u00f6rdag"
      ],
      "ERANAMES": [
        "f\u00f6re Kristus",
        "efter Kristus"
      ],
      "ERAS": [
        "f.Kr.",
        "e.Kr."
      ],
      "FIRSTDAYOFWEEK": 0,
      "MONTH": [
        "januari",
        "februari",
        "mars",
        "april",
        "maj",
        "juni",
        "juli",
        "augusti",
        "september",
        "oktober",
        "november",
        "december"
      ],
      "SHORTDAY": [
        "S\u00f6n",
        "M\u00e5n",
        "Tis",
        "Ons",
        "Tors",
        "Fre",
        "L\u00f6r"
      ],
      "SHORTMONTH": [
        "Jan",
        "Feb",
        "Mars",
        "Apr",
        "Maj",
        "Juni",
        "Juli",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec"
      ],
      "STANDALONEMONTH": [
        "januari",
        "februari",
        "mars",
        "april",
        "maj",
        "juni",
        "juli",
        "augusti",
        "september",
        "oktober",
        "november",
        "december"
      ],
      "WEEKENDRANGE": [
        5,
        6
      ],
      ...formats
    },
    "NUMBER_FORMATS": {
      "CURRENCY_SYM": "kr",
      "DECIMAL_SEP": ",",
      "GROUP_SEP": "\u00a0",
      "PATTERNS": [
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 3,
          "minFrac": 0,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "",
          "posPre": "",
          "posSuf": ""
        },
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 2,
          "minFrac": 2,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "\u00a0\u00a4",
          "posPre": "",
          "posSuf": "\u00a0\u00a4"
        }
      ]
    },
    "id": "sv-SE",
    "localeID": "sv",
    "getDateFormats": () => formats,
    "pluralCat": function(n, opt_precision) {  var i = n | 0;  var vf = getVF(n, opt_precision);  if (i == 1 && vf.v == 0) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
  });
}]);
