class CreateMeetingDialog {
    constructor(
        $scope,
        $element,
        $document,
        $q,
        recompileService,
        meetingApiService,
        categoryService,
        stringService,
        timeService,
        menuService,
        keyCode,
        responsive,
        translationService,
        dialogService,
        domService
    ) {
        Object.assign(this, {
            $scope,
            $element,
            $document,
            $q,
            recompileService,
            meetingApiService,
            categoryService,
            stringService,
            timeService,
            menuService,
            keyCode,
            responsive,
            translationService,
            dialogService,
            domService
        });
    }

    $onInit() {
        this._office365MasterICalUId = null;
        this._office365MasterId = null;
        this.isLoading = true;
        this.filteredSeries = [];
        this._initTimeDropDowns();
        this._initResponsiveStates();

        this._fetchData()
            .then(() => {
                this._handleOptions();
            })
            .finally(() => {
                this._isPlaceholderMeeting() ? this._handlePlaceholderMeeting() : this._handleMeeting();

                this.isLoading = false;
            });
    }

    _handlePlaceholderMeeting() {
        this.lockDetails = true;

        let meeting = this.options.meeting;

        this.newMeeting = {
            ...meeting,
            date: new Date(meeting.startDate),
            startDate: new Date(meeting.startDate),
            endDate: new Date(meeting.endDate),
            category: this.uncategorized
        };

        if (this._hasMatchingMeetingSeries()) {
            let meetingInSeries = this._getMeetingSeriesByOfficeIds();
            this.newMeeting.meetingSeriesId = meetingInSeries.meetingSeriesId;
            this._setCategory(meetingInSeries.categoryId);
        }

        this.setStartTimeString();
        this.setDurationString();
    }

    setStartTimeString() {
        this.startTimeString = this.timeService.getTimeAsString(this.newMeeting.startDate);
    }

    setDurationString() {
        let end = this.newMeeting.endDate,
            start = this.newMeeting.startDate;

        let diff = new Date(end) - new Date(start);

        let diffDays = Math.floor(diff / 86400000),
            diffHours = Math.floor((diff % 86400000) / 3600000) + diffDays * 24,
            diffMinutes = Math.round(((diff % 86400000) % 3600000) / 60000);

        this.durationString = `${diffHours > 0 ? diffHours + 'h ' : ''}${diffMinutes > 0 ? diffMinutes + 'm' : ''}`;
    }

    _handleMeeting() {
        if (this.options.meeting) {
            return this.selectMeeting(this.options.meeting);
        }

        this.selectMeeting();
    }

    _isPlaceholderMeeting() {
        let meeting = this.options.meeting;

        return meeting && meeting.isPlaceholder;
    }

    _handleOptions() {
        if (this.options.meeting && this.options.meeting.categoryId) {
            this._setCategory(this.options.meeting.categoryId);
        }

        if (this.options.template) {
            this.template = this.options.template;
        }
    }

    _fetchData() {
        let defer = this.$q.defer(),
            isPlaceholder = this._isPlaceholderMeeting();

        let promises = [this.meetingApiService.getMeetingsForUser(), this.categoryService.getAll()];

        let meeting = this.options.meeting;
        if (isPlaceholder && meeting) {
            this._setOfficeMasterIds(meeting);
        }

        this.$q.all(promises).then((results) => {
            this._addMeetingSeries(results[0]);
            this._addCategories(results[1]);
            defer.resolve();
        });

        return defer.promise;
    }

    _setOfficeMasterIds(meeting) {
        this._office365MasterICalUId = meeting.office365MasterICalUId;
        this._office365MasterId = meeting.office365MasterId;
    }

    _getMeetingSeriesByOfficeIds() {
        return _.find(
            this.meetingSeries,
            (series) => series.office365MasterId === this._office365MasterId || series.office365MasterICalUId === this._office365MasterICalUId
        );
    }

    _initResponsiveStates() {
        this.responsive.on(['xs'], this.$scope, () => (this.isXS = true));
        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$scope, () => (this.isXS = false));
    }

    _initTimeDropDowns() {
        this.startTimes = this.timeService.getMeetingTimesArray();
        this.durations = this.timeService.getMeetingDurationArray();

        this._setTimeMenu();
    }

    _addCategories(categories) {
        this.categories = angular.copy(categories);

        this.uncategorized = {
            id: null,
            name: this.translationService.translate('client_Uncategorized'),
            colorId: 0
        };

        this.categories.unshift(this.uncategorized);
    }

    _addMeetingSeries(meetingSeries) {
        this.meetingSeries = _.chain(meetingSeries.filter((x) => x.isExampleMeeting === false && x.meetingSeriesId && x.isCurrentUserEditor))
            .orderBy(['startDate'], ['desc'])
            .uniqBy('meetingSeriesId')
            .value();

        this.limitToIncreaseValue = 10;
        this.limitTo = Math.min(this.meetingSeries.length, 10 + 6);
    }

    selectMeeting(meeting) {
        this.previousMeeting = meeting;

        if (!this._isPlaceholderMeeting()) {
            this.newMeeting = {
                meetingName: meeting ? meeting.meetingName : '',
                location: meeting ? meeting.location : '',
                date: new Date(),
                startDate: new Date()
            };

            this.newMeeting.endDate = new Date();
            this.newMeeting.endDate.setHours(this.newMeeting.startDate.getHours() + 1);

            this._setStartAndEndDateFromPreviousMeeting();
            this.setStartTimeString();
            this.setDurationString();
        }

        if (!meeting && this.options.template) {
            let t = this.options.template;
            this.newMeeting.meetingName = t.meetingName;
            this.newMeeting.description = t.description;
        }

        this.newMeeting = {
            ...this.newMeeting,
            meetingSeriesId: meeting ? meeting.meetingSeriesId : null,
            previousMeetingId: meeting ? meeting.id : null,
            description: this.template
                ? this.template.description
                    ? this.template.description
                    : ''
                : this.previousMeeting
                ? this.previousMeeting.description
                : ''
        };

        this._setCategory(meeting ? meeting.categoryId : this.uncategorized.id);
        this._setTimeMenu();

        this._recompileActiveElement();
    }

    _recompileActiveElement() {
        this.activeElement = this.$document[0].querySelector('#meeting-details');
        if (this.activeElement) {
            this.$scope.$evalAsync(() => {
                this.recompileService.recompile(this.$scope, this.activeElement);
            });
        }
    }

    _setCategory(categoryId) {
        this.category = _.find(this.categories, (category) => category.id === categoryId);
        this.newMeeting.category = this.category;
    }

    updateStartValue() {
        this.limitTo = Math.min(this.meetingSeries.length, this.limitTo + this.limitToIncreaseValue);
    }

    _setStartAndEndDateFromPreviousMeeting() {
        if (!this.previousMeeting || !this.previousMeeting.startDate) {
            return this._setStartAndEndTimeForNewMeeting();
        }

        const millisecondsInOneMinute = 60000;

        this.newMeeting.startDate = new Date(this.previousMeeting.startDate);

        this.newMeeting.startDate.setDate(this.newMeeting.startDate.getDate() + 7);

        if (this.newMeeting.startDate < new Date()) {
            this.newMeeting.startDate = new Date();
            this.newMeeting.startDate.setHours(
                new Date(this.previousMeeting.startDate).getHours(),
                new Date(this.previousMeeting.startDate).getMinutes()
            );

            this.newMeeting.endDate = new Date();
            let previousEndDate = new Date(this.previousMeeting.endDate);
            if (this.previousMeeting.endDate && previousEndDate.getTime() !== 0) {
                this.newMeeting.endDate.setHours(previousEndDate.getHours(), previousEndDate.getMinutes());
            } else {
                this.newMeeting.endDate.setTime(this.newMeeting.startDate.getTime() + 60 * millisecondsInOneMinute);
            }
        } else {
            this.newMeeting.endDate = new Date(this.previousMeeting.endDate);
            if (this.newMeeting.endDate && this.newMeeting.endDate.getTime() !== 0) {
                this.newMeeting.endDate.setDate(this.newMeeting.endDate.getDate() + 7);
            } else {
                this.newMeeting.endDate = new Date(this.newMeeting.startDate);
                this.newMeeting.endDate.setTime(this.newMeeting.startDate.getTime() + 60 * millisecondsInOneMinute);
            }
        }

        this.newMeeting.date = this.newMeeting.startDate;

        this.newMeeting.startDate.setFullYear(this.newMeeting.date.getFullYear(), this.newMeeting.date.getMonth(), this.newMeeting.date.getDate());

        let hours = this.newMeeting.startDate.getHours();
        let minutes = this.newMeeting.startDate.getMinutes();

        if (!(minutes === 0 || minutes === 15 || minutes === 30 || minutes === 45)) {
            minutes = minutes > 30 ? 45 : 15;
        }

        this._setStartTime(hours, minutes);
    }

    _setStartAndEndTimeForNewMeeting() {
        let hours = this.newMeeting.startDate.getHours();
        let minutes = this.newMeeting.startDate.getMinutes();

        let m = minutes > 30 ? 0 : 30;
        let h = m === 0 ? (hours === 23 ? 0 : ++hours) : hours;
        this._setStartTime(h, m);

        this.newMeeting.startDate.setHours(h, m);
        this.newMeeting.endDate.setHours(h + 1, m);
    }

    _setTimeMenu() {
        this.startTimeMenu = {
            selected: this.startTime,
            times: this.startTimes,
            onClick: (data) => {
                this.startTime = data.time;
                this.updateStartTime(data.time, true);
            }
        };

        this.durationMenu = {
            selected: this.duration,
            times: this.durations,
            onClick: (data) => {
                this.durationString = data.time.time;
                this.updateEndTime();
            }
        };

        this.setDuration();
        if (this.timeForm) {
            this.timeForm.end.$setPristine();
            this.timeForm.start.$setPristine();
        }
    }

    showStartTimeMenu() {
        if (this._isPlaceholderMeeting()) {
            return;
        }

        this.menuService.timeMenu(
            this.startTimeMenu,
            angular.element(this.$element[0].querySelector('.start-time-input').parentElement),
            false,
            true
        );
    }

    showEndTimeMenu() {
        if (this._isPlaceholderMeeting()) {
            return;
        }

        this.menuService.timeMenu(this.durationMenu, angular.element(this.$element[0].querySelector('.end-time-input').parentElement), false, true);
    }

    showCategoryMenu($event) {
        if (this.newMeeting.meetingSeriesId || (this._isPlaceholderMeeting() && this._hasMatchingMeetingSeries())) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            return;
        }

        let el = this.domService.getElementFromEvent($event);

        let options = {
            searchPlaceholder: this.translationService.translate('search'),
            items: this.categories.map((x) => {
                return {
                    isDefault: this.newMeeting.category.id === x.id,
                    id: x.id,
                    value: x.name,
                    icon: `<div class="height(0-3) width(0-3) rounded(small) left(0-2) bg-color-${x.colorId}"></div>`
                };
            }),
            onClick: (data) => {
                this._setCategory(data.id);
            },
            onSecondaryActionText: this.translationService.translate('client_CreateCategory'),
            onSecondaryAction: () => {
                let options = {
                    title: this.translationService.translate('client_CreateCategory'),
                    ok: this.translationService.translate('client_Create'),
                    cancel: this.translationService.translate('cancel')
                };
                return this.categoryService.showCategoryDialog(options).then((category) =>
                    this.categoryService.create(category).then((createdCategory) =>
                        this.categoryService.getAll().then((categories) => {
                            this._addCategories(categories);
                            this._setCategory(createdCategory.id);
                        })
                    )
                );
            }
        };

        this.menuService.dropdownMenu(options, angular.element(el), false);
    }

    handleStartInputKeyDownEvent($event) {
        if ($event.keyCode === this.keyCode.DOWN && !this.menuService.isOpen()) {
            $event.stopImmediatePropagation();
            $event.preventDefault();
            if (this.timeForm.start.$dirty) {
                this.setStartTime();
            }
            this.showStartTimeMenu();
        } else if ($event.keyCode === this.keyCode.ENTER && this.timeForm.start.$dirty) {
            this.setStartTime();
        }
    }

    handleEndInputKeyDownEvent($event) {
        if ($event.keyCode === this.keyCode.DOWN && !this.menuService.isOpen()) {
            $event.stopImmediatePropagation();
            $event.preventDefault();

            this.showEndTimeMenu();
        } else if ($event.keyCode === this.keyCode.ENTER && this.timeForm.start.$dirty) {
            this.showEndTimeMenu();
        }
    }

    _setStartTime(h, m) {
        this.startTime = _.find(this.startTimes, (t) => {
            return t.hours === h && t.minutes === m;
        });
    }

    setStartTime() {
        if (!this.startTimeString) {
            return;
        }

        let timeString = this.stringService.tryParseTimeString(this.startTimeString);
        let newTime = _.find(this.startTimes, (t) => {
            return t.time === timeString;
        });

        if (!newTime) {
            let h = parseInt(timeString.slice(0, 2));
            let m = parseInt(timeString.slice(3, 5));
            newTime = this.timeService.createMeetingTime(h, m);
        }

        this.updateStartTime(newTime, this.timeForm.start.$dirty);
    }

    updateStartTime(startTime, isDirty) {
        if (isDirty) {
            this.startTimeMenu.selected = _.findLast(this.startTimes, (t) => {
                return t.hours === startTime.hours && t.minutes <= startTime.minutes;
            });

            this.startTimeString = angular.copy(startTime.time);
            this.newMeeting.startDate.setHours(startTime.hours);
            this.newMeeting.startDate.setMinutes(startTime.minutes);
        }
    }

    setDuration() {
        if (!this.durationString) {
            return;
        }

        this.duration = _.find(this.durations, (d) => {
            return d.time.includes(this.durationString);
        });

        if (!this.duration) {
            let h2 = parseInt(this.durationString.slice(0, 2));
            let m2 = parseInt(this.durationString.slice(3, 5));
            let t2 = h2 * 60 + m2;

            this.duration = _.findLast(this.durations, (d) => {
                let h = parseInt(d.time.slice(0, 2));
                let m = parseInt(d.time.slice(3, 5));
                let t = h * 60 + m;

                return t <= t2;
            });
        }

        this.durationMenu.selected = this.duration;
    }

    updateEndTime() {
        if (this.timeForm.end.$valid) {
            this.newMeeting.endDate = this.timeService.parseDurationFromString(this.newMeeting.startDate, this.durationString);
        }

        this.setDuration();
    }

    shouldShowMeetingSeriesColumn() {
        let template = this.options.template,
            series = this.meetingSeries,
            meeting = this.options.meeting;

        if (meeting && meeting.meetingSeriesId && meeting.meetingSeriesId > 0) {
            return false;
        }

        return (!!template || !this._hasMatchingMeetingSeries()) && !(series && series.length === 0);
    }

    _hasMatchingMeetingSeries() {
        if (this._office365MasterICalUId || this._office365MasterId) {
            return this._getMeetingSeriesByOfficeIds();
        }

        if (this.previousMeeting) {
            return _.find(this.meetingSeries, (series) => series.meetingSeriesId === this.previousMeeting.meetingSeriesId);
        }

        return false;
    }

    canSelectCategories() {
        return !this._hasMatchingMeetingSeries();
    }

    create() {
        if (this.category && this.category.id) {
            this.newMeeting.categoryId = this.category.id;
            this.newMeeting.categoryName = this.category.name;
            this.newMeeting.colorId = this.category.colorId;
        }

        if (this.template && this.template.id !== null) {
            this.newMeeting.templateId = this.template.id;
        }

        this.newMeeting.startDate.setFullYear(this.newMeeting.date.getFullYear(), this.newMeeting.date.getMonth(), this.newMeeting.date.getDate());
        this.newMeeting.endDate = this.timeService.parseDurationFromString(this.newMeeting.startDate, this.durationString);

        this.onOk({ data: this.newMeeting });
    }
}

CreateMeetingDialog.$inject = [
    '$scope',
    '$element',
    '$document',
    '$q',
    'recompileService',
    'meetingApiService',
    'categoryService',
    'stringService',
    'timeService',
    'menuService',
    'keyCode',
    'responsive',
    'translationService',
    'dialogService',
    'domService'
];

export default CreateMeetingDialog;
