class VerticalWizardService {
    constructor() {}

    isLastStep() {
        return this.lastStep;
    }

    setIsLastStep(isLast) {
        this.lastStep = isLast;
    }
}

export default VerticalWizardService;
