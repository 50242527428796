class CardGroupController {
    constructor($timeout) {
        this.$timeout = $timeout;
    }

    $onInit() {
        this.cards = this.cardsData.map((cardData) => this.cardModelTransformer.transform(cardData));
        this.hasPlaceholders = this._hasPlaceholders();
        this.isEmpty = this._isEmpty();
        this.numberOfPlaceholders = this._getNumberOfPlaceholders();
    }

    $onChanges(changes) {
        if (changes.showPlaceholders) {
            this.showPlaceholders = angular.copy(this.showPlaceholders);
            this.showPlaceholderToggleButton = !angular.copy(this.showPlaceholders) && this._hasPlaceholders();
        }
    }

    handleClickOnCard($event, card) {
        $event.card = card;
        this.onClickOnCard({ $event });
    }

    handleClickOnMore($event, card) {
        $event.card = card;
        this.onClickOnMore({ $event });
    }

    _hasPlaceholders() {
        return this.cardsData && this.cardsData.some((cardData) => this.cardModelTransformer._isPlaceholder(cardData));
    }

    _isEmpty() {
        return !this.cardsData || this.cardsData.length === 0;
    }

    _getNumberOfPlaceholders() {
        return this.cardsData && this.cardsData.filter((cardData) => this.cardModelTransformer._isPlaceholder(cardData)).length;
    }
}

CardGroupController.$inject = ['$timeout'];

export default CardGroupController;
