class DevelopersController {
    constructor(dialogService) {
        this.dialogService = dialogService;
    }

    $onInit() {
        this.selectedTab = 'style-guide';
        this.texts = ['headline-xl', 'headline-lg', 'headline-md', 'headline-sm', 'body-xl', 'body-md', 'body-md-italic', 'button', 'caption'];

        this.colors = ['stratsys-blue', 'stratsys-teal', 'coral-pink', 'pacific-blue', 'light-gray', 'snow-white', 'yellow-delight', 'deco-green'];

        this.shadows = [1, 2, 3, 4, 6, 8, 9, 12, 16, 24];

        this.date = new Date();

        this.switchStatus = false;
    }

    goToTab($event) {
        this.selectedTab = $event.value;
    }

    showAlert() {
        this.dialogService.alert({
            title: 'Title',
            description: 'Description'
        });
    }

    showPrompt() {
        this.dialogService.prompt({
            title: 'Title',
            description: 'Description'
        });
    }

    showConfirm() {
        this.dialogService.confirm({
            title: 'Title',
            description: 'Description'
        });
    }
}

DevelopersController.$inject = ['dialogService'];

export default DevelopersController;
