const INJECT = ['domService'];
const EVENTS = ['click', 'submit'];
const UNKNOWN_EVENT_ID = 'unknown';
const EVENT_ID_ATTRIBUTE = 'event-id';

class AutoTrackEventService {
    constructor() {
        this._callbacks = [];
        var obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    startAutoTrack() {
        EVENTS.forEach((event) => {
            document.addEventListener(event, this._handleEvent.bind(this), true);
        });
        return this;
    }

    onEvent(callback) {
        this._callbacks.push(callback);
    }

    _executeCallbacks(name, data, $event) {
        this._callbacks.forEach((callback) => callback(name, data, $event));
    }

    _handleEvent($event) {
        let element = this._getElement($event);

        let properties = {
            eventId: this._getEventId(element, properties),
            eventType: this._getEventType($event),
            targetText: this.domService.getTargetText(element),
            selector: this.domService.getSelector(element),
            uuid: this._createUUID()
        };

        this._executeCallbacks(properties, $event);
    }

    _getEventType($event) {
        if ($event) {
            return $event.type || '';
        }
        return '';
    }

    _getElement($event) {
        let element = $event.target;
        let eventNameElement = this.domService.closestWithAttribute(element, EVENT_ID_ATTRIBUTE);
        if (eventNameElement) {
            element = eventNameElement;
        }

        return element;
    }

    _getEventId(element) {
        if (element) {
            return element.getAttribute(EVENT_ID_ATTRIBUTE) || '';
        }
        return '';
    }

    _createUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
    }
}

AutoTrackEventService.$inject = INJECT;

export default AutoTrackEventService;
