class AgendaBuildingService {
    constructor($q) {
        Object.assign(this, {
            $q
        });
    }

    _flatAgendaToList(agendas, list) {
        _.forEach(agendas, (x) => {
            if (x.children && x.children.length > 0) {
                this._flatAgendaToList(x.children, list);
                x.children = [];
                list.push(x);
            } else {
                x.children = [];
                list.push(x);
            }
        });
    }

    _buildAgendaFromList(list, parent) {
        _.forEach(list, (x) => {
            if (parent.id === x.parentId) {
                if (!parent.children) {
                    parent.children = [];
                }

                parent.children.push(x);
                this._buildAgendaFromList(list, x);
            }
        });
    }

    _sortChildren(children) {
        _.forEach(children, (a, index) => {
            if (a.children && a.children.length > 0) {
                a.children = _.orderBy(a.children, ['sortOrder'], ['asc']);
                this._sortChildren(a.children);
            }
        });

        return _.orderBy(children, ['sortOrder'], ['asc']);
    }

    _setSortOrderOnChildren(children) {
        _.forEach(children, (a, index) => {
            if (a.children && a.children.length > 0) {
                this._setSortOrderOnChildren(a.children);
            }
            a.sortOrder = index;
            if (a.hide) {
                a.hide = false;
            }
        });
    }

    _getNewParentId(source, dest) {
        if (source.nodesScope === dest.nodesScope) {
            //same parent
            return null;
        } else {
            //new parent
            let newParentId = 0;
            if (dest.nodesScope.$modelValue && dest.nodesScope.$modelValue.length > 0) {
                return dest.nodesScope.$modelValue[0].parentId;
            } else {
                return dest.nodesScope.$parent.item.id;
            }
        }
    }

    updateTree(moveEvent, meetingAgenda, meetingRootAgenda) {
        let source = moveEvent.source,
            dest = moveEvent.dest;
        let deferred = this.$q.defer();

        //make a flat list of the complete agenda
        let agendaList = [];
        let agendasCopy = angular.copy(meetingAgenda);
        this._flatAgendaToList(agendasCopy, agendaList);

        //set data on the agenda which is moved
        let agendaId = source.nodeScope.$modelValue.id;
        let agendaToMove = _.find(agendaList, (ag) => ag.id === agendaId);
        agendaToMove.previousSortOrder = agendaToMove.sortOrder;
        agendaToMove.sortOrder = dest.index;
        agendaToMove.oldParentId = agendaToMove.parentId;
        let newParentId = this._getNewParentId(source, dest);
        if (newParentId) {
            agendaToMove.parentId = newParentId;
        }

        //if agenda is moved inside same parent, check where to put it compared to rest of elements
        let placeOtherElementsAfter = true;
        if (agendaToMove.oldParentId === agendaToMove.parentId) {
            placeOtherElementsAfter = agendaToMove.previousSortOrder > agendaToMove.sortOrder;
        }

        //set new sort order on old element with same parent and sortorder
        _.forEach(agendaList, (a, index) => {
            if (a.parentId == agendaToMove.parentId && a.id !== agendaToMove.id && a.sortOrder == agendaToMove.sortOrder) {
                if (placeOtherElementsAfter) {
                    a.sortOrder = a.sortOrder + 0.1;
                } else {
                    a.sortOrder = a.sortOrder - 0.1;
                }
            }
        });

        //move agenda children if agenda is moved to new parent
        if (agendaToMove.oldParentId !== agendaToMove.parentId) {
            _.forEach(agendaList, (a, index) => {
                if (a.parentId == agendaToMove.id) {
                    agendaToMove.hadChildren = true;
                    a.sortOrder = agendaToMove.sortOrder + 0.001 * (a.sortOrder + 1);
                    a.parentId = agendaToMove.parentId;
                }
            });
        }

        //rebuild the agenda and reorder children
        let rootAgenda = angular.copy(meetingRootAgenda);
        rootAgenda.children = [];
        this._buildAgendaFromList(agendaList, rootAgenda);
        rootAgenda.children = this._sortChildren(rootAgenda.children);
        this._setSortOrderOnChildren(rootAgenda.children);

        //copy agenda children to new meeting agenda
        let newMeetingAgenda = angular.copy(rootAgenda.children);
        deferred.resolve({ agendaToMove: agendaToMove, meetingAgenda: newMeetingAgenda });

        return deferred.promise;
    }

    mergeTree(meetingAgenda, newMeetingAgenda) {
        let removedAgendas = [];

        //update agenda tree items
        _.forEach(meetingAgenda, (oldAgenda, index) => {
            oldAgenda.hide = false;
            let updatedAgenda = _.find(newMeetingAgenda, (newAgenda) => {
                return newAgenda.id === oldAgenda.id;
            });

            if (updatedAgenda) {
                oldAgenda.parentId = updatedAgenda.parentId;
                oldAgenda.sortOrder = updatedAgenda.sortOrder;
                if (oldAgenda.children && oldAgenda.children.length > 0 && updatedAgenda.children.length > 0) {
                    this.mergeTree(oldAgenda.children, updatedAgenda.children);
                } else {
                    oldAgenda.children = updatedAgenda.children;
                }
            } else {
                removedAgendas.push(oldAgenda);
            }
        });

        //remove agendas which is removed from tree
        _.forEach(removedAgendas, (removedAgenda, index) => {
            _.remove(meetingAgenda, (a) => a.id === removedAgenda.id);
        });

        //add new agendas to tree
        _.forEach(newMeetingAgenda, (newAgenda, index) => {
            newAgenda.hide = false;
            let existingAgenda = _.find(meetingAgenda, (oldAgenda) => {
                return oldAgenda.id === newAgenda.id;
            });

            if (!existingAgenda) {
                meetingAgenda.push(newAgenda);
            }
        });
    }
}

AgendaBuildingService.$inject = ['$q'];

export default AgendaBuildingService;
