class ActionDetailService {
    constructor($state, eventEmitterService) {
        this.action = null;
        this.$state = $state;
        this.eventEmitterService = eventEmitterService;
    }

    open(action, state, meetingId) {
        if (this.action !== action) {
            this.action = action;
        }

        this.state = state;

        this.$state.go(
            this.state + '.action',
            { actionId: action.id, action: action, meetingId: meetingId },
            {
                notify: false,
                reload: false,
                inherit: true
            }
        );
    }

    close() {
        this.action = null;
        if (this.state) {
            this.$state.go(
                this.state,
                {},
                {
                    notify: false,
                    reload: false,
                    location: 'replace',
                    inherit: true
                }
            );
        }

        this.eventEmitterService.publishEvent('actionDetailClose');
    }

    nullAction() {
        this.action = null;
    }

    getAction() {
        return this.action;
    }
}

ActionDetailService.$inject = ['$state', 'eventEmitterService'];

export default ActionDetailService;
