class MeetingSeriesService {
    constructor($q, meetingApiService, actionApiService, eventEmitterService) {
        Object.assign(this, { $q, meetingApiService, actionApiService, eventEmitterService });
        this.eventEmitterService.subscribe('invalidateMeetingCache', this._invalidateMeetingCache.bind(this));
        this.eventEmitterService.subscribe('invalidateActionCache', this._invalidateActionCache.bind(this));
        this.eventEmitterService.subscribe('invalidateDecisionCache', this._invalidateDecisionCache.bind(this));
        this.eventEmitterService.subscribe('invalidatePostponedAgendaCache', this._invalidatePostponedAgendaCache.bind(this));
        this.eventEmitterService.subscribe('invalidateMeetingSeriesCache', this.invalidateCache.bind(this));
        this.meetingSeries = {};
    }

    getActionsInMeetingSeries() {
        let deferred = this.$q.defer();

        this.actionApiService.getActionsForMeetingSeries(this.meetingSeries.id).then((actions) => {
            if (this.meetingSeries.actions) {
                angular.extend(this.meetingSeries.actions, actions);
                deferred.resolve(this.meetingSeries.actions);
            } else {
                this.meetingSeries.actions = actions;
                deferred.resolve(this.meetingSeries.actions);
            }
        });

        return deferred.promise;
    }

    getPostponedAgendasInMeetingSeries() {
        return this.meetingApiService.getPostponedAgendasForMeetingSeries(this.meetingSeries.id);
    }

    getEventsInMeetingSeries() {
        return this.meetingApiService.getEventsForMeetingSeries(this.meetingSeries.id);
    }

    getMeetingsInMeetingSeries() {
        let deferred = this.$q.defer();
        this.meetingApiService.getMeetingsForMeetingSeries(this.meetingSeries.id).then((meetings) => {
            if (this.meetingSeries.meetings) {
                angular.merge(this.meetingSeries.meetings, meetings);
                deferred.resolve(this.meetingSeries.meetings);
            } else {
                this.meetingSeries.meetings = meetings;
                deferred.resolve(this.meetingSeries.meetings);
            }
        });

        return deferred.promise;
    }

    getDecisionsInMeetingSeries() {
        let deferred = this.$q.defer();
        this.meetingApiService.getDecisionsInSeries(this.meetingSeries.id).then((decisions) => {
            if (this.meetingSeries.decisions) {
                angular.merge(this.meetingSeries.decisions, decisions);
                deferred.resolve(this.meetingSeries.decisions);
            } else {
                this.meetingSeries.decisions = decisions;
                deferred.resolve(this.meetingSeries.decisions);
            }
        });

        return deferred.promise;
    }

    setMeetingSeriesId(id) {
        if (!this.meetingSeries.id || this.meetingSeries.id !== id) {
            this.meetingSeries = {
                id: id
            };
        }
    }

    getMeetingSeries() {
        return this.meetingSeries;
    }

    _reloadUnfinishedActions() {
        this.eventEmitterService.publishEvent('updateUnfinishedActions');
    }

    invalidateCache() {
        this._invalidateMeetingCache();
        this._invalidateActionCache();
        this._invalidateDecisionCache();
        this._invalidatePostponedAgendaCache();
    }

    _invalidateMeetingCache() {
        this.meetingApiService.clearCache(`series/${this.meetingSeries.id}/all`);
        this.meetingSeries.meetings = [];
    }

    _invalidateActionCache() {
        this.actionApiService.clearCache(`series/${this.meetingSeries.id}/all`);
        this.meetingSeries.actions = [];
    }

    _invalidateDecisionCache() {
        this.meetingApiService.clearCache(`series/${this.meetingSeries.id}/all/decisions`);
        this.meetingSeries.decisions = [];
    }

    _invalidatePostponedAgendaCache() {
        this.meetingApiService.clearCache(`series/${this.meetingSeries.id}/agendas/postponed`);
    }
}

MeetingSeriesService.$inject = ['$q', 'meetingApiService', 'actionApiService', 'eventEmitterService'];

export default MeetingSeriesService;
