const HIDE_ANIMATION_DURATION = 500;
const THANK_YOU_SCREEN_DURATION = 5000;
const SHOW_DELAY = 1000;

class RatingController {
    constructor(translationService, $scope, $timeout) {
        Object.assign(this, { translationService, $scope, $timeout });
        this.ratingText = '';

        this.ratings = [
            { isHighlighted: false },
            { isHighlighted: false },
            { isHighlighted: false },
            { isHighlighted: false },
            { isHighlighted: false }
        ];

        this.$timeout(() => {
            this.isVisible = true;
        }, SHOW_DELAY);
    }

    handleMouseOverIcon(index) {
        this._highlightStars(index);
    }

    handleMouseLeaveIcon() {
        let indexOfSelectedStar = this.rating ? this.rating - 1 : -1;
        this._highlightStars(indexOfSelectedStar);
    }

    _highlightStars(index) {
        for (let i = 0; i < this.ratings.length; i++) {
            this.ratings[i].isHighlighted = i <= index ? true : false;
        }
    }

    handleClickOnIcon(index) {
        this.commentVisible = true;
        this.rating = index + 1;
        this.ratingText = this._getRatingText(this.rating);
        this._highlightStars(index);
    }

    _getRatingText(rating) {
        let ratingTextLow = this.translationService.translate('client_RatingTextLow');
        let ratingTextMid = this.translationService.translate('client_RatingTextMid');
        let ratingTextHigh = this.translationService.translate('client_RatingTextHigh');

        switch (rating) {
            case 1:
                return ratingTextLow;
            case 2:
                return ratingTextLow;
            case 3:
                return ratingTextMid;
            case 4:
                return ratingTextHigh;
            case 5:
                return ratingTextHigh;
        }
    }

    handleSubmit() {
        let rating = {
            rating: this.rating,
            comment: this.comment
        };

        this.hasSubmittedRating = true;

        this.$timeout(() => (this.isVisible = false), THANK_YOU_SCREEN_DURATION).then(() => {
            return this.$timeout(() => this.onSubmit({ rating }), HIDE_ANIMATION_DURATION);
        });
    }

    handleClose() {
        this.isVisible = false;
        this.$timeout(() => this.onClose(), HIDE_ANIMATION_DURATION);
    }
}

RatingController.$inject = ['translationService', '$scope', '$timeout'];

export default RatingController;
