const INJECT = ['translationService'];

class tutorialVideoDialogController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {}
}

tutorialVideoDialogController.$inject = INJECT;

export default tutorialVideoDialogController;
