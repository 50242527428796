import 'ng-wig';
import textEditorDirective from './textEditor.directive';
import textEditorConfig from './textEditor.config';
import { LANGUAGES } from '../../app.constants';

angular
    .module('rym.textEditor', ['ngWig'])
    .config(textEditorConfig)
    .directive('rymTextEditor', textEditorDirective)
    .component('nwBtnBold', {
        template: `<button class="nw-button {{::$ctrl.buttonStyle}}" title="{{::$ctrl.title}}" ng-class="{ 'nw-button--active': $ctrl.isActive() }" ng-click="::$ctrl.click()"></button>`,
        controller: [
            'translationService',
            '$scope',
            '$rootScope',
            'eventEmitterService',
            function (translationService, $scope, $rootScope, eventEmitterService) {
                this.translationService = translationService;
                this.eventEmitterService = eventEmitterService;
                this.$scope = $scope;
                this.format = 'bold';

                let commandTip = $rootScope.isMACOS ? '(⌘Cmd+B)' : '(Ctrl+B)';
                this.title = `${this.translationService.translate('client_ButtonBold')} ${commandTip}`;

                this.$onInit = () => {
                    const lang = this.translationService.preferredLanguage();
                    if (lang && (lang === LANGUAGES.SWEDISH_CODE || lang === LANGUAGES.NORWEGIAN_CODE)) {
                        this.buttonStyle = 'fet';
                    } else {
                        this.buttonStyle = this.format;
                    }

                    this.eventEmitterService.subscribe('text-editor:focusChanged', (hasFocus) => {
                        this.textEditorHasFocus = hasFocus;
                    });
                };

                this.click = () => {
                    this.$scope.$parent.$ctrl.execCommand(this.format);
                };

                this.isActive = () => {
                    if (!this.textEditorHasFocus) {
                        return false;
                    }
                    let currentNode = window.getSelection().focusNode;
                    if (currentNode) {
                        while (currentNode.id !== 'ng-wig-editable') {
                            if (currentNode.tagName === 'B') {
                                return true;
                            }
                            currentNode = currentNode.parentNode;
                        }
                        return false;
                    }
                };
            }
        ]
    })
    .component('nwBtnItalic', {
        template: `<button class="nw-button {{::$ctrl.buttonStyle}}" title="{{::$ctrl.title}}" ng-class="{ 'nw-button--active': $ctrl.isActive() }" ng-click="::$ctrl.click($event)"></button>`,
        controller: [
            'translationService',
            '$scope',
            '$rootScope',
            'eventEmitterService',
            function (translationService, $scope, $rootScope, eventEmitterService) {
                this.translationService = translationService;
                this.eventEmitterService = eventEmitterService;
                this.$scope = $scope;
                this.format = 'italic';
                let commandTip = $rootScope.isMACOS ? '(⌘Cmd+I)' : '(Ctrl+I)';
                this.title = `${this.translationService.translate('client_ButtonItalic')} ${commandTip}`;
                this.$onInit = () => {
                    const lang = this.translationService.preferredLanguage();
                    if (lang && (lang === LANGUAGES.SWEDISH_CODE || lang === LANGUAGES.NORWEGIAN_CODE)) {
                        this.buttonStyle = 'kursiv';
                    } else {
                        this.buttonStyle = this.format;
                    }

                    this.eventEmitterService.subscribe('text-editor:focusChanged', (hasFocus) => {
                        this.textEditorHasFocus = hasFocus;
                    });
                };

                this.click = () => {
                    this.$scope.$parent.$ctrl.execCommand(this.format);
                };

                this.isActive = () => {
                    if (!this.textEditorHasFocus) {
                        return false;
                    }

                    let currentNode = window.getSelection().focusNode;
                    if (currentNode) {
                        while (currentNode.id !== 'ng-wig-editable') {
                            if (currentNode.tagName === 'I') {
                                return true;
                            }
                            currentNode = currentNode.parentNode;
                        }
                        return false;
                    }
                };
            }
        ]
    })
    .component('nwBtnUnderline', {
        template: `<button class="nw-button underline" title="{{::$ctrl.title}}" ng-class="{ 'nw-button--active': $ctrl.isActive() }" ng-click="::$ctrl.click()"></button>`,
        controller: [
            'translationService',
            '$scope',
            '$rootScope',
            'eventEmitterService',
            function (translationService, $scope, $rootScope, eventEmitterService) {
                this.translationService = translationService;
                this.eventEmitterService = eventEmitterService;
                this.$scope = $scope;
                this.format = 'underline';

                this.$onInit = () => {
                    this.eventEmitterService.subscribe('text-editor:focusChanged', (hasFocus) => {
                        this.textEditorHasFocus = hasFocus;
                    });
                };
                let commandTip = $rootScope.isMACOS ? '(⌘Cmd+U)' : '(Ctrl+U)';
                this.title = `${this.translationService.translate('client_ButtonUnderlined')} ${commandTip}`;

                this.click = () => {
                    this.$scope.$parent.$ctrl.execCommand(this.format);
                };

                this.isActive = () => {
                    if (!this.textEditorHasFocus) {
                        return false;
                    }

                    let currentNode = window.getSelection().focusNode;
                    if (currentNode) {
                        while (currentNode.id !== 'ng-wig-editable') {
                            if (currentNode.tagName === 'U') {
                                return true;
                            }
                            currentNode = currentNode.parentNode;
                        }
                        return false;
                    }
                };
            }
        ]
    })
    .component('nwBtnBullet', {
        template: `<button class="nw-button list-ul" title="{{::$ctrl.translationService.translate('client_ButtonBulletedList')}}" ng-class="{ 'nw-button--active': $ctrl.isActive() }" ng-click="::$ctrl.click()"></button>`,
        controller: [
            'translationService',
            '$scope',
            'eventEmitterService',
            function (translationService, $scope, eventEmitterService) {
                this.translationService = translationService;
                this.eventEmitterService = eventEmitterService;
                this.$scope = $scope;
                this.format = 'insertunorderedlist';

                this.$onInit = () => {
                    this.eventEmitterService.subscribe('text-editor:focusChanged', (hasFocus) => {
                        this.textEditorHasFocus = hasFocus;
                    });
                };

                this.click = () => {
                    this.$scope.$parent.$ctrl.execCommand(this.format);
                };

                this.isActive = () => {
                    if (!this.textEditorHasFocus) {
                        return false;
                    }

                    let currentNode = window.getSelection().focusNode;
                    if (currentNode) {
                        while (currentNode.id !== 'ng-wig-editable') {
                            if (currentNode.tagName === 'UL') {
                                return true;
                            }
                            currentNode = currentNode.parentNode;
                        }
                        return false;
                    }
                };
            }
        ]
    })
    .component('nwBtnNumber', {
        template: `<button class="nw-button list-ol" title="{{::$ctrl.translationService.translate('client_ButtonNumericList')}}" ng-class="{ 'nw-button--active': $ctrl.isActive() }" ng-click="::$ctrl.click()"></button>`,
        controller: [
            'translationService',
            '$scope',
            'eventEmitterService',
            function (translationService, $scope, eventEmitterService) {
                this.translationService = translationService;
                this.eventEmitterService = eventEmitterService;
                this.$scope = $scope;
                this.format = 'insertorderedlist';

                this.$onInit = () => {
                    this.eventEmitterService.subscribe('text-editor:focusChanged', (hasFocus) => {
                        this.textEditorHasFocus = hasFocus;
                    });
                };

                this.click = () => {
                    this.$scope.$parent.$ctrl.execCommand(this.format);
                };

                this.isActive = () => {
                    if (!this.textEditorHasFocus) {
                        return false;
                    }

                    let currentNode = window.getSelection().focusNode;
                    if (currentNode) {
                        while (currentNode.id !== 'ng-wig-editable') {
                            if (currentNode.tagName === 'OL') {
                                return true;
                            }
                            currentNode = currentNode.parentNode;
                        }
                        return false;
                    }
                };
            }
        ]
    });
