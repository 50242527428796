import { FEES, FEES_WITH_DISCOUNT, NUMBER_OF_FREE_LICENSES } from '../../app.constants';
const INJECT = ['translationService'];
class SubscriptionSummaryController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onChanges() {
        this._update();
    }

    _update() {
        this._updateHasExtraLicenses();
        this._updateFees();
        this._updatePriceFormat();
        this._updateExtraOrganizerLicensesDescription();
        this._updateTotalOrganizerLicensesText();
        this._updateTotalMontlyCostText();
        this._updateBilledAnnuallyText();
        this._updateStarterPackFeeText();
        this._updateMonthlyDiscountText();
        this._updateTotalMonthlyCostForExtraLicensesText();
    }

    _updateHasExtraLicenses() {
        this.hasExtraLicenses = this.numberOfLicenses > NUMBER_OF_FREE_LICENSES;
    }

    _updateFees() {
        let fees = FEES;

        if (this.isAnnually) {
            fees = FEES_WITH_DISCOUNT;
        }

        this._fees = this.currency === 'USD' ? fees.USD : fees.SEK;
    }

    _updatePriceFormat() {
        this._priceFormat = this.currency === 'USD' ? 'client_PriceInUsd' : 'client_PriceInSek';
    }

    _updateStarterPackFeeText() {
        let fees = this.currency === 'USD' ? FEES.USD : FEES.SEK;
        let price = this._widthCurency(fees.START_FEE);
        this.starterPackFeeText = this.translationService.translate('client_PerMonth', { price });
    }

    _updateTotalMontlyCostText() {
        let price = this._widthCurency(this._calcTotalMontlyCost());
        this.totalMonthlyCostText = this.translationService.translate('client_PerMonth', { price });
    }

    _calcTotalMontlyCost() {
        return (this.numberOfLicenses - NUMBER_OF_FREE_LICENSES) * this._fees.MONTHLY_LICENSE_FEE + this._fees.START_FEE;
    }

    _calcTotalMontlyCostWithoutDiscount() {
        let fees = this.currency === 'USD' ? FEES.USD : FEES.SEK;
        return (this.numberOfLicenses - NUMBER_OF_FREE_LICENSES) * fees.MONTHLY_LICENSE_FEE + fees.START_FEE;
    }

    _updateBilledAnnuallyText() {
        let price = this._widthCurency(12 * this._calcTotalMontlyCost());
        this.billedAnnuallyText = this.translationService.translate('client_BilledAnnually', { price });
    }

    _updateTotalOrganizerLicensesText() {
        this.totalOrganizerLicensesText = this.translationService.translate('client_XOrganizerLicensesFor', { count: this.numberOfLicenses });
    }

    _updateMonthlyDiscountText() {
        let monthlyDiscount = this._calcMonthlyDiscount();
        let price = this._widthCurency(monthlyDiscount);
        this.monthlyDiscountText = this.translationService.translate('client_PerMonth', { price });
    }

    _calcMonthlyDiscount() {
        return this._calcTotalMontlyCost() - this._calcTotalMontlyCostWithoutDiscount();
    }

    _updateExtraOrganizerLicensesDescription() {
        let fees = this.currency === 'USD' ? FEES.USD : FEES.SEK;
        this.extraOrganizerLicensesDescription = this.translationService.translate('client_PerMonth', {
            price: `${this.numberOfLicenses - NUMBER_OF_FREE_LICENSES} x ${this._widthCurency(fees.MONTHLY_LICENSE_FEE)}`
        });
    }

    _updateTotalMonthlyCostForExtraLicensesText() {
        let fees = this.currency === 'USD' ? FEES.USD : FEES.SEK;
        let price = this._widthCurency(fees.MONTHLY_LICENSE_FEE * (this.numberOfLicenses - NUMBER_OF_FREE_LICENSES));
        this.totalMonthlyCostForExtraLicensesText = this.translationService.translate('client_PerMonth', { price });
    }

    _widthCurency(price) {
        let result = this.translationService.translate(this._priceFormat, {
            price: Math.abs(price)
        });

        if (price < 0) {
            result = `-${result}`;
        }

        return result;
    }
}

SubscriptionSummaryController.$inject = INJECT;

export default SubscriptionSummaryController;
