class WizardController {
    constructor(translationService) {
        this.translationService = translationService;
        this.steps = [];
        this.currentStep = 0;
    }

    add(step) {
        this.steps.push(step);
        this.steps = _.sortBy(this.steps, (step) => step.index);
        this.view = this.steps[0].stepInfo.view;
    }

    remove(step) {
        let index = this.steps.indexOf(step);
        this.steps.splice(index, 1);
    }

    goToStep(index) {
        if (this.steps.length > index && index > -1) {
            this.view = this.steps[index].stepInfo.view;

            this.currentStep = index;
        }
    }

    isMultiStep() {
        return this.steps && this.steps.length > 1;
    }

    nextStep() {
        if (this.steps.length > this.currentStep + 1) {
            this.view = this.steps[this.currentStep + 1].stepInfo.view;
        }

        if (this.currentStep === this.steps.length - 1) {
            this.onOk();
        }

        this.currentStep++;
    }

    previousStep() {
        if (this.currentStep !== 0) {
            this.currentStep--;
            this.view = this.steps[this.currentStep].stepInfo.view;
        }
    }

    getMainButtonTitle() {
        if (this.currentStep === this.steps.length - 1) {
            return this.options.ok;
        }

        return this.translationService.translate('next');
    }
}

WizardController.$inject = ['translationService'];

export default WizardController;
