export const SUPPORTED_OFFICE_FILE_MIME_TYPES = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];
export const SUPPORTED_FILE_MIME_TYPES = [
    'image/jpeg',
    'image/jpg',
    'image/bmp',
    'image/gif',
    'image/png',
    'video/mpeg',
    'video/webm',
    'video/ogg',
    'video/quicktime',
    'video/avi',
    'application/pdf'
];
