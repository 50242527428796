import template from './row.html';
import controller from './row.controller';

let rowComponent = {
    controller,
    template,
    transclude: {
        icon: '?rymRowIcon',
        data: '?rymRowData'
    },
    bindings: {
        row: '<',
        onMainAction: '&',
        onSecondaryAction: '&',
        onChange: '&',
        secondaryActionIcon: '<',
        isDisabled: '<'
    }
};

export default rowComponent;
