import template from './imageDialog.html';
import controller from './imageDialog.controller';

let imageDialogComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onOk: '&',
        onCancel: '&'
    }
};

export default imageDialogComponent;
