const FAVORITE_ROW = `<rym-row row="::$ctrl.favoriteRow" test-hook="FavoriteCategoryDetails" ng-click="::$ctrl.toggleFavorite($event)">
<rym-row-icon class="padding-left(0-2)">
    <div
        class="right(0-2) width(0-5) height(0-5) center(on) rounded(max) lock-size(on) interactive(click) position(relative)"
    >
        <i
            class="material-icons"
            ng-class="{'force-color(yellow-delight)': $ctrl.category.isFavorite}"
            ng-bind="::$ctrl.category.isFavorite ? 'star' : 'star_border'"
        ></i>
        <rym-progress-circle class="edge" ng-if="$ctrl.isFavoriteLoading"></rym-progress-circle>
    </div>
</rym-row-icon>
</rym-row>`;
const COLOR_ROW = `<rym-row row="::$ctrl.colorRow" ng-click="::$ctrl.selectColor($event)">
<rym-row-data>
    <div class="text(headline-sm) truncate(on)" title="{{::$ctrl.colorTitle}}" ng-bind-html="::$ctrl.colorTitle"></div>
</rym-row-data>
<rym-row-icon class="padding-left(0-2)">
    <div class="color-box bg-color-{{$ctrl.category.colorId}}"></div>
</rym-row-icon>
</rym-row>`;
const INJECT = [
    '$scope',
    '$timeout',
    '$element',
    '$state',
    '$filter',
    '$rootScope',
    'categoryApiService',
    'menuService',
    'recompileService',
    'rowService',
    'eventEmitterService',
    'translationService',
    'categoryDetailService',
    'signalRService',
    'categoryService'
];

class CategoryDetailController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
        this.selected = false;
        this.colorTitle = this.translationService.translate('client_Color');
    }

    $doCheck() {
        if (this.categoryDetailService.getCategory() && this.category !== this.categoryDetailService.getCategory()) {
            this.initialized = false;
            this.category = this.categoryDetailService.getCategory();
            this.buildRowData();
            this.initialized = true;
        }
    }

    $onInit() {
        this.$rootScope.$on('$stateChangeStart', (e, toState, fromState) => {
            if (toState.name !== 'categories') {
                this.category = null;
                this.categoryDetailService.nullCategory();
            }
        });
        this.eventEmitterService.subscribe('categoryDetailClose', () => (this.category = null));
    }

    _recompileDetails() {
        this.buildRowData();
        this._recompileFavoriteRow();
        this._recompileColorRow();
    }

    _recompileFavoriteRow() {
        this.buildRowData();
        this.recompileService.recompile(this.$scope, this._getFavoriteRow(), FAVORITE_ROW);
    }

    _recompileColorRow() {
        this.buildRowData();
        this.recompileService.recompile(this.$scope, this._getColorRow(), COLOR_ROW);
    }

    _getCreatedByRow() {
        return this.$element.find('rym-row')[0];
    }

    _getFavoriteRow() {
        return this.$element.find('rym-row')[1];
    }

    _getColorRow() {
        return this.$element.find('rym-row')[2];
    }

    openMenu(event) {
        let menuButton = angular.element(event.currentTarget);
        this.overflowMenuOpen = true;
        let category = this.category;
        let options = {
            items: [
                {
                    id: 'menu option - delete action',
                    icon: 'delete_outline',
                    text: this.translationService.translate('client_Delete'),
                    onClick: () => this.remove(category)
                }
            ]
        };

        this.menuService.actionMenu(options, menuButton, false, false).finally(() => {
            this.overflowMenuOpen = false;
        });
    }

    buildRowData() {
        this.createdByRow = {
            title: this.translationService.translate('client_CreatedBy'),
            description: this.category.createdBy,
            user: { name: this.category.createdBy, email: this.category.createdByEmail },
            isDisabled: true
        };

        this.favoriteRow = {
            title: this.translationService.translate('client_Favorite'),
            description: this.category.isFavorite
                ? this.translationService.translate('client_CategoryFavoriteOn')
                : this.translationService.translate('client_CategoryFavoriteOff')
        };

        this.colorRow = {
            title: this.colorTitle,
            description: this.colorTitle
        };

        this.initialized = true;
    }

    updateCategoryName() {
        if (this.category.name) {
            this.categoryService.update(this.category);
        }
    }

    _recompile(custom, skipFocusEvent) {
        let newElement = this.recompileService.recompile(this.$scope, this.activeElement, custom);
        this.rowService.playDoneAnimation(newElement, skipFocusEvent);
        this.emitChange();
    }

    remove(category) {
        this.close();
        this.categoryService.delete(category);
    }

    toggleFavorite($event) {
        if (this.isFavoriteLoading) {
            return;
        }
        this.isFavoriteLoading = true;
        this._setActiveElement($event);

        $event.preventDefault();
        $event.stopImmediatePropagation();
        let oldValue = this.category.isFavorite;

        this.categoryService.toggleFavorite(this.category).then(() => {
            this.category.isFavorite = !oldValue;
            this.isFavoriteLoading = false;
            this._recompileFavoriteRow();
        });
    }

    selectColor($event) {
        this.colorMenuOpen = true;
        this._setActiveElement($event);

        $event.preventDefault();
        $event.stopImmediatePropagation();

        let options = {
            colorId: this.category.colorId
        };

        this.menuService
            .colorMenu(options, angular.element(this.activeElement), false)
            .then((data) => {
                this.setColorId(data.colorId).then(() => {
                    this.colorMenuOpen = false;
                });
            })
            .catch(() => {
                this.colorMenuOpen = false;
            });
    }

    setColorId(id) {
        this.category.colorId = id;
        this._recompileColorRow();
        return this.categoryService.update(this.category);
    }

    _setActiveElement($event) {
        this.activeElement = $event.currentTarget;
    }

    close() {
        if (!this.colorMenuOpen) {
            this.category = null;
            this.categoryDetailService.close();
        }
    }
}

CategoryDetailController.$inject = INJECT;

export default CategoryDetailController;
