var meetingsFilter = ($filter) => {
    return (input, filter, comparator, anyPropertyKey) => {
        var result = $filter('filter')(input, filter, comparator, anyPropertyKey);

        if (
            result &&
            !_.find(result, (item) => {
                return item.containsToday === true;
            })
        ) {
            let todayObject = _.find(input, (item) => {
                return item.containsToday === true;
            });
            result.push(todayObject);
        }

        return result;
    };
};

meetingsFilter.$inject = ['$filter'];
export default meetingsFilter;
