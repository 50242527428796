import meetingsComponent from './meetings.component';
import createMeetingDialogComponent from './components/createMeetingDialog/createMeetingDialog.component';
import exporteMeetingDialogComponent from './components/exportMeetingDialog/exportMeetingDialog.component';
import meetingsRoute from './meetings.routes';
import meetingsFilter from './meetings.filter';

angular
    .module('rym.meetings', [])
    .component('rymMeetings', meetingsComponent)
    .component('rymCreateMeetingDialog', createMeetingDialogComponent)
    .component('rymExportMeetingDialog', exporteMeetingDialogComponent)
    .filter('meetingsFilter', meetingsFilter)
    .run(meetingsRoute);
