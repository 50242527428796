class DataLayerService {
    constructor($window) {
        Object.assign(this, { $window });
    }

    pushData(data) {
        if (this.$window.dataLayer) {
            this.$window.dataLayer.push(data);
        }
    }
}

DataLayerService.$inject = ['$window'];

export default DataLayerService;
