import template from './userMenu.html';
import controller from './userMenu.controller';
import './userMenu.less';

let userMenuComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onCallback: '&',
        onSelect: '&',
        onCancel: '&',
        multiple: '<'
    }
};

export default userMenuComponent;
