const INITIAL_MAXIMUM_OF_ITEMS = 30;
const NUMBER_OF_ITEMS_BEFORE_SHOWING_SEARCH = 10;
const INFINIT_SCROLL_INCREMENT = 5;

class SelectDialogController {
    constructor(translationService) {
        Object.assign(this, { translationService });
    }

    $onInit() {
        this.filteredResults = this.options.items;
        this.limitTo = INITIAL_MAXIMUM_OF_ITEMS;

        this.showSearch = this.options.items.length > NUMBER_OF_ITEMS_BEFORE_SHOWING_SEARCH;

        this.options.items.forEach((item) => {
            if (item.isDefault) {
                this.select(item);
            }
        });
    }

    select(item) {
        this.selectedItem = item;
    }

    updateLimitTo() {
        this.limitTo += INFINIT_SCROLL_INCREMENT;
    }

    filter() {
        this.selectedItem = null;
        this.filteredResults = _.filter(this.options.items, (item) => {
            return item.name.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
    }
}

SelectDialogController.$inject = ['translationService'];

export default SelectDialogController;
