class ColorMenuController {
    constructor($filter) {
        Object.assign(this, {
            $filter
        });
    }

    $onInit() {
        this.colorIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    }
}

ColorMenuController.$inject = ['$filter'];

export default ColorMenuController;
