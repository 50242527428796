let userFilter = () => {
    return (items, field) => {
        if (angular.isUndefined(field) || field === '') {
            return items;
        }

        let searchTerm = field.toLocaleLowerCase();

        return _.filter(items, (obj) => {
            return (
                (obj.name && obj.name.toLocaleLowerCase().includes(searchTerm)) ||
                (obj.email && obj.email.toLocaleLowerCase().includes(searchTerm)) ||
                (obj.userName && obj.userName.toLocaleLowerCase().includes(searchTerm)) ||
                (obj.firstName && obj.firstName.toLocaleLowerCase().includes(searchTerm)) ||
                (obj.label && obj.label.toLocaleLowerCase().includes(searchTerm))
            );
        });
    };
};

export default userFilter;
