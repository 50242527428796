import controller from './rymScrollbar.controller';
import './scrollbar.less';

let RymScrollBarComponent = {
    controller,
    restrict: 'E',
    bindings: {
        calc: '<',
        delay: '<',
        setFocus: '<',
        isMain: '<',
        autoHide: '<',
        onScrollToBottom: '&',
        onScrollOverBottom: '&',
        onScrollToBottomOffset: '<'
    }
};

export default RymScrollBarComponent;
