class CardController {
    constructor() {}

    $onInit() {
        this.style = {};
        if (this.isPlaceholder) {
            this.style.borderRight = '1px dashed';
        } else {
            this.isPlaceholder = false;
        }
    }

    handleClickOnMore($event) {
        $event.stopImmediatePropagation();
        this.onClickOnMore({ $event });
    }
}

CardController.$inject = [];

export default CardController;
