import controller from './header.controller';
import template from './header.html';

let headerComponent = {
    controller,
    template,
    transclude: {
        icons: '?rymHeaderIcons',
        buttons: '?rymHeaderButtons',
        content: '?rymHeaderContent',
        extra: '?rymHeaderExtra'
    },
    bindings: {
        isLoading: '<',
        searchPlaceholder: '<',
        searchTerm: '<',
        enableSearch: '<',
        isFilterActive: '<',
        searchIconTitle: '<',
        showDivider: '<',
        onSearch: '&',
        onClickAtSearchIcon: '&'
    }
};

export default headerComponent;
