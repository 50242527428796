class SelectAgendaTemplateDialogController {
    constructor(translationService, meetingApiService, meetingBuildingService) {
        Object.assign(this, { translationService, meetingApiService, meetingBuildingService });
        this.searchTerm = '';
        this.isLoading = true;
        this.agenda = null;
    }

    $onInit() {
        this.meetingApiService.getTemplates().then((templates) => {
            this.agendaTemplates = templates;
            this.selectedAgendaTemplate = this.agendaTemplates[0];
            this._fetchPreview().then(() => {
                this.isLoading = false;
            });
        });
    }

    isAgendaTemplateSelected(agendaTemplate) {
        return agendaTemplate === this.selectedAgendaTemplate;
    }

    selectAgendaTemplate(agendaTemplate) {
        this.selectedAgendaTemplate = agendaTemplate;
        this._fetchPreview();
    }

    _fetchPreview() {
        this.isLoadingPreview = true;
        return this.meetingBuildingService.buildMeeting(this.selectedAgendaTemplate.id).then((meeting) => {
            this.isLoadingPreview = false;
            this.agenda = meeting.agenda;
        });
    }

    applyTemplate() {
        let data = { agendaTemplateId: this.selectedAgendaTemplate.id };
        this.onOk({ data });
    }
}

SelectAgendaTemplateDialogController.$inject = ['translationService', 'meetingApiService', 'meetingBuildingService'];

export default SelectAgendaTemplateDialogController;
