import getPlainText from 'html-to-plaintext';

class stringService {
    constructor() {}

    isUndefinedOrEmpty(value) {
        return typeof value === 'undefined' || value === null || value.length === 0;
    }

    isGuid(guid) {
        let guidRegex = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return !!guid && guidRegex.test(guid);
    }

    isEmail(email) {
        let emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return !!email && emailRegex.test(email);
    }

    linkify(text) {
        let replacePattern = /(?!<a[^>]*?>)((?![^"]*"^)(?:http(s?)\:\/\/|www\.)([\w'-]+\.[\w\]\/\-\%.~:?#[@!$&'()*+,;={}]+))(?![^<]*?<\/a>)/gim;
        return text.replace(replacePattern, '<a href="http$2://$3" target="_blank" rel="noopener">$1</a>');
    }

    unlinkify(text) {
        let replacePattern = /<\/?a[^>]*>/gim;
        return text.replace(replacePattern, '');
    }

    removeFontTagsFromHTML(text) {
        return text.replace(/<font[^><]*>|<.font[^><]*>/g, '');
    }

    replaceIELineBreak(text) {
        return text.replace(/\r\n/g, '<br/>');
    }

    replaceLineBreaksWithBreakTags(text) {
        return text.replace(/\n/g, '<br/>');
    }

    replaceEmptyParagraphTags(text) {
        return text.replace(/<p>(\s|&nbsp;|<\s?br\s?>)*<\/p>/g, '<br/>');
    }

    removeLineBreaks(text) {
        return text.replace(/\n/g, '').replace(/\r/g, '');
    }

    removeSpaces(text) {
        return text.replace(/&nbsp;(?!\S)/g, '').trim();
    }

    addSpaces(text) {
        return text.replace(/&nbsp;/g, ' ');
    }

    removeLeadingAndTrailingBreakTags(text) {
        return text.replace(/(^\s*((?:<br\s*\/?\s*>|<div><br\s*\/?\s*><\/div>))+|(((?:<br\s*\/?\s*>)|(?:<div><br\s*\/?\s*><\/div>))+)\s*)$/g, '');
    }

    removeBreakTagsInListElements(text) {
        return text.replace(/(?=[\s\S])(<br>)(?=<\/li>)/g, '');
    }

    innerText(el) {
        let isIE11 = /Trident.*rv[ :]*11\./.test(window.navigator.userAgent);

        return isIE11 ? this._innerTextIE11(el) : getPlainText(el);
    }

    _innerTextIE11(el) {
        let text = el.innerHTML
            .replace(/&nbsp;/gi, ' ')
            .replace(/<\w+><(\/|br)*?><\/\w+>/gi, '\n')
            .replace(/<\w+>/gi, '\n')
            .replace(/<(\/|br)*?>/gi, '\n')
            .replace(/<(\/|.)*?>/gi, '');

        return text;
    }

    tryParseTimeString(timeString) {
        if (/([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(timeString)) {
            return timeString;
        } else if (timeString.length > 2 && parseInt(timeString.substr(0, 2)) >= 24) {
            return '00:00';
        } else if (/^[0-2][0-9][0-5][0-9]$/.test(timeString)) {
            return timeString.substr(0, 2) + ':' + timeString.substr(2, 2);
        } else if (/^[0-2][0-9][0-5]$/.test(timeString)) {
            return timeString.substr(0, 2) + ':' + timeString.substr(2, 1) + '0';
        } else if (/^[0-2][0-9]$/.test(timeString)) {
            return `${timeString}:00`;
        } else if (/^[0-9]$/.test(timeString)) {
            return `0${timeString}:00`;
        } else return '00:00';
    }

    base64EncodeUnicode(string) {
        return btoa(
            encodeURIComponent(string).replace(/%([0-9A-F]{2})/g, function (match, p1) {
                return String.fromCharCode('0x' + p1);
            })
        );
    }

    encodeHtml(string) {
        return string.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
    }
}

stringService.$inject = [];
export default stringService;
