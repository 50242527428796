import controller from './subscriptionContactForm.controller';
import template from './subscriptionContactForm.html';

let subscriptionContactFormComponent = {
    template,
    controller,
    bindings: {
        isEnabled: '<',
        contactFormData: '=',
        update: '&',
        hasChanged: '='
    }
};

export default subscriptionContactFormComponent;
