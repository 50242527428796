import { HTTP_STATUS_CODES } from '../../../app.constants';
import * as tus from 'tus-js-client';
const MAX_FILE_SIZE = 1000000000;

class DocumentService {
    constructor(
        $state,
        $q,
        $window,
        $http,
        fileService,
        eventEmitterService,
        authService,
        toastService,
        translationService,
        meetingApiService,
        signalRService,
        userService,
        dialogService,
        applicationInsightsService,
        envService
    ) {
        Object.assign(this, {
            $state,
            $q,
            $window,
            $http,
            fileService,
            eventEmitterService,
            authService,
            toastService,
            translationService,
            meetingApiService,
            signalRService,
            userService,
            dialogService,
            applicationInsightsService,
            envService
        });

        this.documentInfo = null;
        this.documentCache = {};
        this.isIE11 = this.userService.isIE11();
        this.uploads = {};
    }

    getDocuments(meetingId, agendaId) {
        let deffered = this.$q.defer();

        if (this.documentCache[`${agendaId}`]) {
            deffered.resolve(this.documentCache[`${agendaId}`]);
        } else {
            this.meetingApiService.getAgenda(meetingId, agendaId).then((agenda) => {
                this.documentCache[`${agendaId}`] = agenda.documents;
                deffered.resolve(agenda.documents);
            });
        }
        return deffered.promise;
    }

    updateCache(agendaId, document) {
        if (this.documentCache[`${agendaId}`]) {
            let cachedDocument = this._getDocumentFromCache(agendaId, document.name);
            if (!cachedDocument) {
                this.documentCache[`${agendaId}`].push(document);
            }
        }
    }

    setCache(agendaId, documents) {
        if (!this.documentCache[`${agendaId}`]) {
            this.documentCache[`${agendaId}`] = documents.slice();
        }
    }

    uploadFiles(files, meetingId, agendaId, shouldRetry = true) {
        if (files && files.length) {
            this._checkFiles(files, agendaId).then((filesToUpload) => {
                this.authService.getAccessToken().then((accessToken) => {
                    if (tus.isSupported) {
                        console.log('Using new file upload endpoint..');

                        for (let i = 0; i < filesToUpload.length; i++) {
                            var that = this;
                            let file = filesToUpload[i];
                            let htmlEncodedFileName = encodeURIComponent(file.name);
                            console.log('Processing file ' + htmlEncodedFileName);

                            let meetingMetaDataHeader = JSON.stringify({
                                meetingId: meetingId,
                                agendaId: agendaId,
                                fileName: htmlEncodedFileName,
                                contentType: file.type
                            });

                            let upload = new tus.Upload(file, {
                                endpoint: `${this.envService.API}files`,
                                headers: {
                                    Authorization: 'Bearer ' + accessToken,
                                    MeetingMetaData: meetingMetaDataHeader
                                },
                                chunkSize: 1024 * 1024,
                                retryDelays: [],
                                removeFingerprintOnSuccess: true,
                                metadata: {
                                    filename: htmlEncodedFileName,
                                    filetype: file.type
                                },
                                onError: function (error) {
                                    that.handleFileUploadError(error, file, files, meetingId, agendaId, shouldRetry);
                                },
                                onProgress: function (bytesUploaded, bytesTotal) {
                                    let uploadedSize = `${that._convertToMegaBytes(bytesUploaded)} MB / ${that._convertToMegaBytes(bytesTotal)} MB`;
                                    let progress = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
                                    that._updateUploadStatus(agendaId, file.name, uploadedSize, progress);
                                    that.eventEmitterService.publishEvent('documentStatusUpdated', {
                                        name: file.name,
                                        uploadedSize: uploadedSize,
                                        progress: progress
                                    });
                                    console.log(bytesUploaded, bytesTotal, progress + '%');
                                },
                                onSuccess: function () {
                                    console.log('File upload success ' + file.name);
                                    that._handleSingleFileUploadCompleted(meetingId, agendaId, file.name, true, false);
                                }
                            });

                            upload.start();
                            this.uploads[`${file.name}`] = { upload: upload, meetingId: meetingId, agendaId: agendaId };
                        }
                    } else {
                        this.applicationInsightsService.trackEvent('Tus file not supported');
                        this.toastService.error();
                    }
                });
            });
        }
    }

    handleFileUploadError(error, file, files, meetingId, agendaId, shouldRetry) {
        let errorStr = error.toString();
        if (errorStr.includes('Failed to authenticate file upload request') && shouldRetry) {
            console.log('Token expired..');
            delete this.uploads[`${file.name}`];
            let pausedDocument = this._getDocumentFromCache(agendaId, file.name);
            this.deleteDocumentFromCache(agendaId, pausedDocument);

            this.authService.renew(true).then((newAccessToken) => {
                console.log('New Token..Try again');
                this.uploadFiles(files, meetingId, agendaId, false);
            });
        } else {
            console.log('File upload failed: ', error);
            this._handleSingleFileUploadCompleted(meetingId, agendaId, file.name, false, true);
            this.applicationInsightsService.trackException(new Error('Tus file upload failed: ' + errorStr));
        }
    }

    cancelActiveUpload(fileName) {
        let uploadObj = this.uploads[`${fileName}`];
        var that = this;
        if (uploadObj) {
            uploadObj.upload
                .abort(true)
                .then(() => {
                    that._handleSingleFileUploadCompleted(uploadObj.meetingId, uploadObj.agendaId, fileName, false, false);
                })
                .catch((err) => {
                    that._handleSingleFileUploadCompleted(uploadObj.meetingId, uploadObj.agendaId, fileName, false, true);
                });
        }
    }

    _handleSingleFileUploadCompleted(meetingId, agendaId, fileName, isSuccess, showError) {
        delete this.uploads[`${fileName}`];
        console.log('File upload ended ' + fileName);

        this.meetingApiService.clearCache(`${meetingId}/agendas`);
        this.meetingApiService.clearCache(`${meetingId}/agendas/${agendaId}`);
        let that = this;
        this.meetingApiService.getAgenda(meetingId, agendaId).then((agenda) => {
            if (isSuccess) {
                let updatedDocument = _.find(agenda.documents, (doc) => {
                    if (that.isIE11) {
                        return btoa(doc.name) === btoa(fileName);
                    }
                    return doc.name.normalize() === fileName || doc.name === fileName;
                });

                let document = that._getDocumentFromCache(agendaId, fileName);

                if (document) {
                    document.canDelete = true;
                    document.isLoading = false;
                    document.uploaded = updatedDocument.uploaded;
                    document.id = updatedDocument.id;
                    document.contentType = updatedDocument.contentType;
                    document.size = updatedDocument.size;
                }

                that.eventEmitterService.publishEvent('addedDocument' + agendaId, document);
                that.signalRService.addedDocument(meetingId, agendaId, document);

                if (document) {
                    that.signalRService.addedDocument(meetingId, agendaId, document);
                    that.toastService.success({ title: that.translationService.translate('client_UploadComplete'), description: `${document.name}` });
                }
            } else {
                let pausedDocument = that._getDocumentFromCache(agendaId, fileName);
                that.deleteDocumentFromCache(agendaId, pausedDocument);
                if (showError) {
                    that.toastService.error();
                }
            }
        });
    }

    _updateUploadStatus(agendaId, name, uploadedSize, percentage) {
        let document = this._getDocumentFromCache(agendaId, name);
        if (document) {
            document.uploadedSize = uploadedSize;
            document.percentage = percentage;
        }
    }

    _convertToMegaBytes(bytes) {
        return (bytes / Math.pow(1024, 2)).toFixed(2);
    }

    _getDocumentFromCache(agendaId, name) {
        let documents = this.documentCache[`${agendaId}`];
        if (!documents) {
            return null;
        }

        let document = _.find(documents, (doc) => {
            if (this.isIE11) {
                return btoa(doc.name) === btoa(name);
            }
            return doc.name.normalize() === name || doc.name === name;
        });

        return document;
    }

    _checkFiles(files, agendaId) {
        let filesToUpload = [];
        let promises = [];
        let deferredFiles = this.$q.defer();
        let totalFileSize = 0;

        _.forEach(files, (file) => {
            let deferred = this.$q.defer();
            promises.push(deferred.promise);
            if (!file.name) {
                file.name = this.$filter('date')(new Date(), 'yyyy-MM-dd_HHmmss');
                if (file.type.includes('image/png')) {
                    file.name = `${file.name}.png`;
                }
            }

            if (file.size > MAX_FILE_SIZE) {
                this.dialogService
                    .alert({
                        id: 'file exceeded size dialog',
                        title: this.translationService.translate('client_AttachmentToExceedsSizeTitle'),
                        ok: this.translationService.translate('close'),
                        description: this.translationService.translate('client_FileExceedsSize', { filename: file.name })
                    })
                    .then(() => {})
                    .finally(() => deferred.resolve());
            } else {
                totalFileSize += file.size;
                let document = _.find(this.documentCache[`${agendaId}`], ['name', file.name]);
                if (document) {
                    this.dialogService
                        .confirm({
                            id: 'file already exists dialog',
                            title: this.translationService.translate('client_ReplaceFile'),
                            ok: this.translationService.translate('replace'),
                            description: this.translationService.translate('client_FileAlreadyExists', { filename: file.name })
                        })
                        .then(() => {
                            filesToUpload.push(file);
                            document.isLoading = true;
                            document.canDelete = false;
                            document.id = 0;
                        })
                        .finally(() => deferred.resolve());
                } else {
                    filesToUpload.push(file);
                    this.setCache(agendaId, []);
                    this.documentCache[`${agendaId}`].push({ id: 0, name: file.name, contentType: file.type, isLoading: true });
                    deferred.resolve();
                }
            }
        });

        this.$q.all(promises).then(
            () => {
                if (totalFileSize > MAX_FILE_SIZE) {
                    _.pullAllBy(this.documents, [{ id: 0 }], 'id');
                    this.dialogService.alert({
                        id: 'attachment to exceeds size dialog',
                        title: this.translationService.translate('client_AttachmentToExceedsSizeTitle'),
                        ok: this.translationService.translate('close'),
                        description: this.translationService.translate('client_AttachmentToExceedsSize')
                    });
                    deferredFiles.reject();
                } else if (filesToUpload.length > 0) {
                    deferredFiles.resolve(filesToUpload);
                } else {
                    deferredFiles.reject();
                }
            },
            () => {
                deferredFiles.reject();
            }
        );

        return deferredFiles.promise;
    }

    downloadDocument(meetingId, document) {
        return this._downloadDocumentFromAzure(meetingId, document);
    }

    _downloadDocumentFromAzure(meetingId, document) {
        return this.meetingApiService.downloadDocument(meetingId, document.id).then(
            (result) => {
                let success = this.fileService.useLink(result, document.name);
                if (!success || this.isIE11) {
                    this.$window.open(result, '_blank');
                }
            },
            (data, status) => {
                this.toastService.error({ title: this.translationService.translate('client_FailedToDownload'), description: status });
            }
        );
    }

    deleteDocument(meetingId, agendaId, document) {
        return this.meetingApiService.removeDocument(meetingId, document.id).then(() => {
            this.deleteDocumentFromCache(agendaId, document);
            this.eventEmitterService.publishEvent('documentsUpdated');
            this.signalRService.deletedDocument(meetingId, agendaId, document);
            this.eventEmitterService.publishEvent('deletedDocument' + agendaId, document);
            this.toastService.success({ title: this.translationService.translate('client_AttachmentDeleted'), description: document.name });
        });
    }

    deleteDocumentFromCache(agendaId, document) {
        if (this.documentCache[`${agendaId}`]) {
            let index = _.findIndex(this.documentCache[`${agendaId}`], (doc) => {
                if (this.isIE11) {
                    return btoa(doc.name) === btoa(document.name);
                }
                return doc.name.normalize() === document.name || doc.name === document.name;
            });
            if (index !== -1) {
                this.documentCache[`${agendaId}`].splice(index, 1);
            }
        }
    }

    deleteFromCache(agendaId) {
        if (this.documentCache[`${agendaId}`]) {
            delete this.documentCache[`${agendaId}`];
        }
    }
}

DocumentService.$inject = [
    '$state',
    '$q',
    '$window',
    '$http',
    'fileService',
    'eventEmitterService',
    'authService',
    'toastService',
    'translationService',
    'meetingApiService',
    'signalRService',
    'userService',
    'dialogService',
    'applicationInsightsService',
    'envService'
];

export default DocumentService;
