class ContactService {
    constructor(contactApiService, dialogService, toastService, translationService) {
        Object.assign(this, { contactApiService, dialogService, toastService, translationService });
    }

    support() {
        this.translationService
            .translateBatch([
                'administration_ContactUs',
                'send',
                'cancel',
                'client_GatheringData',
                'client_ContactUsTitle',
                'client_ContactUsDescription',
                'administration_PaymentFailed'
            ])
            .then((translations) => {
                let options = {
                    id: 'contact support dialog',
                    title: translations.administration_ContactUs,
                    ok: translations.send,
                    cancel: translations.cancel,
                    includeUserInfo: true
                };

                this.dialogService.custom('rym-text-dialog', options).then((message) => {
                    this.toastService.loading({ title: translations.client_GatheringData });
                    this.contactApiService.contactSupport(message).then(
                        () => {
                            this.toastService.success({
                                title: translations.client_ContactUsTitle,
                                description: translations.client_ContactUsDescription
                            });
                        },
                        () => {
                            this.toastService.error({
                                title: translations.administration_PaymentFailed,
                                action: false,
                                duration: 8000,
                                textAction: null,
                                textActionText: null
                            });
                        }
                    );
                });
            });
    }
}

ContactService.$inject = ['contactApiService', 'dialogService', 'toastService', 'translationService'];

export default ContactService;
