import { SUPPORTED_OFFICE_FILE_MIME_TYPES } from '../document/mime';
class documentDialogController {
    constructor($sce, $element, $timeout, documentService) {
        Object.assign(this, { $sce, $element, $timeout, documentService });
    }

    $onInit() {
        this.type = this.options.document.contentType;
        this.name = this.options.document.name;

        if (SUPPORTED_OFFICE_FILE_MIME_TYPES.includes(this.type)) {
            this.filesrc = this.$sce.trustAsResourceUrl(
                'https://view.officeapps.live.com/op/embed.aspx?src=' + encodeURIComponent(this.options.fileURL)
            );
        } else {
            this.filesrc = this.$sce.trustAsResourceUrl(this.options.fileURL);
        }

        this.isImage = this.type.includes('image');
    }

    download() {
        this.documentService.downloadDocument(this.options.meetingId, this.options.document);
    }
}

documentDialogController.$inject = ['$sce', '$element', '$timeout', 'documentService'];

export default documentDialogController;
