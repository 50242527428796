import * as microsoftTeams from '@microsoft/teams-js';
const teamsLoggedOut = '1eb131fa-d61a-493d-a092-99b378296d37';
import BaseAuthService from '../../../auth/baseAuth.service';
import Oidc from 'oidc-client';

class TeamsService extends BaseAuthService {
    constructor($window, $location, $q, eventEmitterService, platformService, envService) {
        super($window, location, platformService, envService);
        this.$window = $window;
        this.$location = $location;
        this.$q = $q;
        this.eventEmitterService = eventEmitterService;
        this._userObjectIdKey = 'ce32d283-737f-4944-a984-b4e45d3fe098';
        this._loginHint = 'meetings_loginHint';
        this._initializeTeamsProps();
        this._baseDeepLink = `https://teams.microsoft.com/l/entity/${envService.TEAMS_APP_ID}/`;
    }

    //determinds if app is running in teams mode (this can be triggered by passing source=teams in the querystring)
    isTeams() {
        return this._isTeams;
    }

    //determinds if site is running inside of teams
    _isTeamsApp() {
        return this.isTeams() && this._isInIframe();
    }

    initialize() {
        let deferred = this.$q.defer();

        if (this._isTeamsApp()) {
            let that = this;
            microsoftTeams.initialize();
            microsoftTeams.getContext((context) => {
                if (context && context.subEntityId) {
                    that.$location.path(context.subEntityId).search('source=teams');
                }

                if (context && context.loginHint) {
                    let currentUser = window.localStorage.getItem(that._loginHint);
                    if (currentUser && currentUser !== context.loginHint) {
                        window.localStorage.removeItem(that._loginHint);
                        that.logoutHard();
                    } else {
                        window.localStorage.setItem(that._loginHint, context.loginHint);
                    }
                }

                deferred.resolve();
            });
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    login() {
        this.$window.location.replace(this.loginUrl());
    }

    loginUrl() {
        return this.$window.location.origin + '/teams_index.html';
    }

    _isSource(type) {
        return this.$location.search()['source'] === type;
    }

    _isInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return false;
        }
    }

    _initializeTeamsProps() {
        this._isTeams = this._isSource('teams');
        if (this._isTeams) {
            let isLoggedOut = !!this.$window.localStorage.getItem(teamsLoggedOut);
            if (isLoggedOut) {
                this.setBackUri();
                this.login();
            }
        }
    }

    logout() {
        this.$window.localStorage.setItem(teamsLoggedOut, 'b001988ec759');
        this.login();
    }

    logoutHard() {
        let that = this;
        let oidcConfig = this.getClientConfig();
        let mgr = new Oidc.UserManager(oidcConfig);
        this.setBackUri();
        mgr.removeUser().then(() => {
            mgr.signoutRedirect().then((resp) => {
                console.log('Signed out', resp);
                that.login();
            });
        });
    }

    getTeamsQuerystring() {
        return '?source=teams';
    }

    goToMeeting(meetingId) {
        if (this._isTeamsApp()) {
            let context = this._getLinkContext(`/meeting/${meetingId}`);
            var deeplink = `${this._baseDeepLink}meetings?label=meetings&context=${context}`;
            microsoftTeams.executeDeepLink(deeplink);
        } else {
            this._openLink(`/meeting/${meetingId}`);
        }
    }

    goToAction(actionId) {
        if (this._isTeamsApp()) {
            let context = this._getLinkContext(`/actions/${actionId}`);
            var deeplink = `${this._baseDeepLink}actions?label=actions&context=${context}`;
            microsoftTeams.executeDeepLink(deeplink);
        } else {
            this._openLink(`/actions/${actionId}`);
        }
    }

    goToMeetingAction(meetingId, actionId) {
        if (this._isTeamsApp()) {
            let context = this._getLinkContext(`/meeting/${meetingId}/action/${actionId}`);
            var deeplink = `${this._baseDeepLink}meetings?label=meetings&context=${context}`;
            microsoftTeams.executeDeepLink(deeplink);
        } else {
            this._openLink(`/meeting/${meetingId}/action/${actionId}`);
        }
    }

    goToMeetingDocument(meetingId, agendaId) {
        if (this._isTeamsApp()) {
            let context = this._getLinkContext(`/meeting/${meetingId}/agenda/${agendaId}/documents`);
            var deeplink = `${this._baseDeepLink}meetings?label=meetings&context=${context}`;
            microsoftTeams.executeDeepLink(deeplink);
        } else {
            this._openLink(`/meeting/${meetingId}/agenda/${agendaId}/documents`);
        }
    }

    _getLinkContext(contextUrl) {
        let obj = { subEntityId: contextUrl };
        var json = JSON.stringify(obj);
        return encodeURI(json);
    }

    _openLink(route) {
        let reloadUrl = this.$window.location.origin + route + this.getTeamsQuerystring();
        this.$window.open(reloadUrl);
    }
}

TeamsService.$inject = ['$window', '$location', '$q', 'eventEmitterService', 'platformService', 'envService'];

export default TeamsService;
