import controller from './inlineEdit.controller';
import template from './inlineEdit.html';
import './inlineEdit.less';

let inlineEditComponent = {
    controller,
    template,
    require: {
        ngModel: '?ngModel'
    },
    bindings: {
        placeholder: '<',
        save: '&',
        onKeyup: '&',
        onPaste: '&',
        activateAutofocus: '<',
        hideLoader: '<',
        onFocus: '&',
        onBlur: '&',
        truncate: '<',
        allowLineBreaks: '<',
        isDisabled: '<',
        invalidInput: '<',
        notifyChange: '<',
        useAutoSave: '<',
        textType: '@',
        addClass: '@'
    }
};

export default inlineEditComponent;
