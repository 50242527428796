class CardGroupModelTransformer {
    constructor($filter, cardModelTransformer, translationService) {
        this.$filter = $filter;
        this.cardModelTransformer = cardModelTransformer;
        this.translationService = translationService;
        this.count = 0;
    }

    transform(meetingDtos) {
        this._initIsToday(meetingDtos);

        return {
            id: this._getId(meetingDtos),
            trackingId: this._getTrackingId(meetingDtos),
            cardsData: meetingDtos,
            headline: this._getHeadline(meetingDtos),
            isHighlighted: this._isToday,
            emptyText: this._getEmptyText()
        };
    }

    _getHeadline(meetingDtos) {
        return this._isToday ? this.translationService.translate('today') : this.$filter('date')(meetingDtos[0].startDate, 'fullDate');
    }

    _getTrackingId(meetingDtos) {
        let trackingId = '';
        for (let meeting of meetingDtos) {
            trackingId += `${meeting.meetingName}-${meeting.id}-${meeting.office365MeetingId}-${meeting.meetingSeriesId}`;
        }

        return trackingId;
    }

    _getId(meetingDtos) {
        return this._isToday ? 'today' : 'day' + '-' + this.$filter('date')(meetingDtos[0].startDate, 'yyyy-MM-dd');
    }

    _initIsToday(meetingDtos) {
        this._isToday =
            !meetingDtos.length || this.$filter('date')(meetingDtos[0].startDate, 'yyyy-MM-dd') === this.$filter('date')(new Date(), 'yyyy-MM-dd');
        if (meetingDtos.length && meetingDtos[0].isToday) {
            meetingDtos.length = 0;
        }
    }

    _getEmptyText() {
        return this._isToday ? this.translationService.translate('client_NoMeetingsToday') : '';
    }
}
CardGroupModelTransformer.$inject = ['$filter', 'cardModelTransformer', 'translationService'];

export default CardGroupModelTransformer;
