import { BASE_URLS, HTTP_STATUS_CODES, LANGUAGES, OFFICE365, STRATSYS_IDSRV } from '../app.constants';
import BaseAuthService from './baseAuth.service';

const Idp = { STRATSYS: 'Stratsys', OFFICE365: 'Office365' };

class AuthService extends BaseAuthService {
    constructor(
        $window,
        $q,
        $location,
        $timeout,
        Oidc,
        dialogService,
        translationService,
        eventEmitterService,
        teamsService,
        platformService,
        envService,
        locationService
    ) {
        super($window, $q, platformService, envService);
        this.locationService = locationService;
        this.Oidc = Oidc;
        this.Oidc.Log.logger = console;
        this.Oidc.Log.level = Oidc.Log.NONE;
        Object.assign(this, { $window, $q, $location, $timeout, Oidc, dialogService, translationService, eventEmitterService, teamsService });

        this.envService.init().then(
            () => {
                let config = this.getClientConfig();
                this.mgr = new this.Oidc.UserManager(config);
            },
            (err) => {
                console.log('Failed to read settings from json');
            }
        );

        this.accessToken = null;
        this.tryingRenew = false;
        this.loginInProcess = false;
    }

    login() {
        if (!this.loginInProcess) {
            this.loginInProcess = true;

            this.setBackUri();

            if (this.teamsService.isTeams()) {
                this.mgr.signinSilent();
            } else {
                let loginHint = this.platformService.getLoginHint();
                let signinPromise = loginHint ? this.mgr.signinRedirect({ login_hint: loginHint }) : this.mgr.signinRedirect();

                signinPromise
                    .then(() => {})
                    .catch((err) => {
                        console.log(err);
                        this.loginInProcess = false;
                        this.removeBackUri();
                    });
            }
        }
    }

    logout() {
        if (this.teamsService.isTeams()) {
            this.mgr.removeUser().then((resp) => {
                console.log('User removed', resp);
                this.setBackUri();
                this.teamsService.logout();
            });
        } else {
            this.mgr.signoutRedirect().then((resp) => {
                console.log('Signed out', resp);
            });
        }
    }

    signinRedirect() {
        let config = this.getClientConfig();
        let signinMgr = new this.Oidc.UserManager(config);

        return signinMgr.signinRedirectCallback().then(
            (user) => {
                console.log('Successfully Obtained Token', user.access_token);
                let backUri = this.getBackUri();
                this.mgr.clearStaleState();
                if (backUri) {
                    this.removeBackUri();
                    this.$window.location.replace(backUri);
                } else {
                    this.$window.location.replace('/index.html');
                }
            },
            (error) => {
                console.log('Problem Getting Token : ' + (error.message || error));
                this.removeBackUri();
            }
        );
    }

    evictUser() {
        this.mgr.getUser().then((user) => {
            let oldUser = user;
            this.mgr.removeUser().then(() => {
                this.mgr
                    .signinSilent()
                    .then((user) => {
                        this.accessToken = user.access_token;
                    })
                    .catch((err) => {
                        this._showLoginDialog(oldUser);
                    });
            });
        });
    }

    _showLoginDialog(oldUser) {
        this.$timeout(() => {
            this.mgr.getUser().then((user) => {
                let u = user || oldUser;
                if (u && u.profile.idp === Idp.STRATSYS) {
                    this.login();
                } else {
                    if (!this.alertIsOpen) {
                        this.alertIsOpen = true;
                        this.dialogService
                            .alert({
                                id: 'logged out dialog',
                                title: this.translationService.translate('client_LoggedOut'),
                                ok: this.translationService.translate('client_LogIn'),
                                description: this.translationService.translate('client_YouHaveBeenLoggedOut')
                            })
                            .then(() => {
                                this.alertIsOpen = false;
                                this.login();
                            });
                    }
                }
            });
        }, 100);
    }

    renew(showLoginDialog) {
        let deferred = this.$q.defer();
        if (this.tryingRenew) {
            this.promises.push(deferred);
        } else {
            this.promises = [];
            this.tryingRenew = true;
            this.mgr
                .signinSilent()
                .then((user) => {
                    this.accessToken = user.access_token;
                    this.tryingRenew = false;
                    _.forEach(this.promises, (promise) => {
                        promise.resolve(this.accessToken);
                    });
                    deferred.resolve(this.accessToken);
                    this.eventEmitterService.publishEvent('tokenRenewed');
                })
                .catch((error) => {
                    this.tryingRenew = false;
                    deferred.reject(error);

                    if (this.teamsService.isTeams()) {
                        this.setBackUri();
                        this.teamsService.login();
                    } else if (showLoginDialog) {
                        this._showLoginDialog();
                    }
                });
        }

        return deferred.promise;
    }

    isLoggedIn() {
        let deferred = this.$q.defer();

        if (this.accessToken != null) {
            deferred.resolve(true);
        } else {
            this.mgr.getUser().then((user) => {
                if (user !== null) {
                    this.accessToken = user.access_token;
                    deferred.resolve(true);
                } else {
                    deferred.reject(false);
                }
            });
        }

        return deferred.promise;
    }

    initializeUser() {
        let deferred = this.$q.defer();

        let tenantId = this.platformService.getTenantId();
        if (!tenantId && !this.teamsService.isTeams()) {
            this.locationService.redirectToCommonLogin();
            return deferred.promise;
        }

        if (!tenantId && this.teamsService.isTeams()) {
            this.setBackUri();
            this.teamsService.login();
        } else {
            let config = this.getClientConfig();
            this.mgr = new this.Oidc.UserManager(config);

            this.mgr.events.addSilentRenewError((e) => {
                console.log('silent renew error', e.message);
                this.renew(true);
            });

            this.eventEmitterService.subscribe(
                'isStratsysIdp',
                () => {
                    let deferred = this.$q.defer();
                    deferred.resolve(this.mgr.settings.client_id === this.envService.STRATSYS_IDSRV_CLIENT_ID);
                    return deferred.promise;
                },
                true
            );

            this.mgr.events.addUserSignedOut(() => {
                if (this.teamsService.isTeams()) {
                    //signout it triggered automatic in teams ios app due to restricted security zone
                    //access token is still valid until time is elasped 3600..
                } else {
                    console.log('user signed out');
                    if (!this.isLocalhost()) {
                        this.evictUser();
                    }
                }
            });

            this.teamsService.initialize().then(() => {
                this.isLoggedIn()
                    .then((loggedIn) => {
                        deferred.resolve(true);
                    })
                    .catch((error) => {
                        this.renew(false)
                            .then((token) => {
                                deferred.resolve(true);
                            })
                            .catch((renewError) => {
                                deferred.reject(renewError);
                            });
                    });
            });
        }

        return deferred.promise;
    }

    getAccessToken() {
        let deferred = this.$q.defer();
        if (this.accessToken != null) {
            deferred.resolve(this.accessToken);
        } else {
            this.mgr.getUser().then((user) => {
                if (user != null) {
                    this.accessToken = user.access_token;
                    deferred.resolve(this.accessToken);
                } else {
                    console.log('failed to get access token');
                    deferred.reject();
                    this.login();
                }
            });
        }

        return deferred.promise;
    }

    isLocalhost() {
        return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    }
}

AuthService.$inject = [
    '$window',
    '$q',
    '$location',
    '$timeout',
    'Oidc',
    'dialogService',
    'translationService',
    'eventEmitterService',
    'teamsService',
    'platformService',
    'envService',
    'locationService'
];

export default AuthService;
