import { BASE_URLS, HTTP_STATUS_CODES } from '../app.constants';
const INJECT = [
    '$q',
    '$timeout',
    '$state',
    '$scope',
    '$rootScope',
    '$element',
    'categoryApiService',
    'anchorScrollService',
    'userService',
    'eventEmitterService',
    'translationService',
    'breadcrumbsService',
    'categoryDetailService',
    'menuService',
    '$filter',
    'categoryService'
];
class CategoriesController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
        this.loading = true;
        this.categories = [];
        this.filteredCategories = [];
        this.orderBy = 'name';
        this.hasChangedSortOrder = false;
        this.sortOrder = -1;
        this.eventEmitterService.subscribe('categoriesChanged', this._setCategories.bind(this));

        this.favoriteOn = this.translationService.translate('client_CategoryFavoriteOn');
        this.favoriteOff = this.translationService.translate('client_CategoryFavoriteOff');
    }

    $onInit() {
        this.breadcrumbsService.setLabel('categories', this.translationService.translate('client_Categories'));
        this.loading = false;
        this._updateNoHitsState();
        this._getCategories();
    }

    _setCategories(categories) {
        this.categories = categories;
    }

    getFavoriteTitle(category) {
        return category.isFavorite ? this.favoriteOn : this.favoriteOff;
    }

    _getCategories() {
        return this.categoryService
            .getAll()
            .then((categories) => {
                this.categories = categories;
                if (categories.length === 0) {
                    this._updateNoHitsState();
                } else {
                    this.checkForCategoryDetailState();
                }
            })
            .finally(() => {
                this.categoriesFinishedLoading = true;
            });
    }

    _updateNoHitsState() {
        if (this.categories.length) {
            this.noDataTitle = this.translationService.translate('client_NoCategoriesFound');
            this.noDataDescription = this.translationService.translate('client_NoItemMatchingSearch');
            this.noDataIcon = 'search';
        } else {
            this.noDataTitle = this.translationService.translate('client_NothingHere');
            this.noDataDescription = this.translationService.translate('client_NoCategoriesAvailable');
            this.noDataIcon = 'face';
        }
    }

    _getCurrentUser() {
        return this.userService.getCurrentUser().then((currentUser) => {
            this.currentUser = currentUser;
        });
    }

    handleOnSearch($event) {
        this.searchTerm = $event.searchTerm;
        this._updateNoHitsState();
    }

    createCategory() {
        this.categoryService.showCreateDialog();
    }

    changeOrderBy(column) {
        this.hasChangedSortOrder = true;
        if (this.orderBy.replace('-', '') === column) {
            this.reverseOrder = !this.reverseOrder;
        }
        this.orderBy = this.reverseOrder ? `-${column}` : column;
        this.anchorScrollService.scrollTop('categories-scroller');
        this.$timeout(() => this.$rootScope.$broadcast('list-order-changed'), 0);
    }

    isMatchingFilter() {
        return (category) => {
            return this.isMatchingSearchTerm(category);
        };
    }

    isMatchingSearchTerm(category) {
        return (
            !this.searchTerm ||
            (this.searchTerm && category.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (this.searchTerm && category.createdByEmail && category.createdByEmail.toLowerCase().includes(this.searchTerm.toLowerCase()))
        );
    }

    detail(category, event) {
        if (event) {
            event.stopPropagation && event.stopPropagation();
        }

        this.categoryDetailService.open(category, 'categories');
    }

    checkForCategoryDetailState() {
        if (this.$state.includes('categories.category')) {
            let categoryId = this.$state.params.categoryId;
            let index = _.findIndex(this.categories, (a) => {
                return a.id == categoryId;
            });

            if (index > -1) {
                this.detail(this.categories[index]);
            }
        }
    }

    isSelected(category) {
        return this.$state.includes('categories.category', { categoryId: category.id });
    }

    showMenu(event, category) {
        event.stopPropagation && event.stopPropagation();
        let menuButton = angular.element(event.currentTarget);

        let options = {
            direction: 'west-south',
            items: [
                {
                    id: 'menu option - delete action',
                    icon: 'delete_outline',
                    text: this.translationService.translate('client_Delete'),
                    onClick: () => this.remove(category)
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element(menuButton), false, false);
    }

    remove(category) {
        this.categoryService.delete(category).then(() => {
            if (this.isSelected(category)) {
                this.categoryDetailService.close();
            }
        });
    }
}

CategoriesController.$inject = INJECT;

export default CategoriesController;
