import controller from './dropdownMenu.controller';
import template from './dropdownMenu.html';
import './dropdownMenu.less';

let dropdownComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onCallback: '&',
        onCancel: '&',
        onSecondaryAction: '&'
    }
};

export default dropdownComponent;
