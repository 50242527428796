var categoryRoutes = function (routerHelper) {
    routerHelper.configureStates([
        {
            state: 'categories',
            config: {
                url: '/categories',
                template: '<rym-categories/>',
                ncyBreadcrumb: {
                    label: 'Categories'
                }
            }
        },
        {
            state: 'categories.category',
            config: {
                url: '/:categoryId',
                params: {
                    category: null,
                    parentUrl: 'categories',
                    searchRoutedFromId: null
                },
                ncyBreadcrumb: {
                    skip: true
                }
            }
        }
    ]);
};
categoryRoutes.$inject = ['routerHelper'];

export default categoryRoutes;
