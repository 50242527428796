import template from './makeUserDialog.html';
import './makeUserDialog.less';

let makeUserDialogComponent = {
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '='
    }
};

export default makeUserDialogComponent;
