import groupByFilter from './groupBy.filter';
import nameAndEmailFilter from './nameAndEmail.filter';
import userFilter from './user.filter';
import orderByDateFilter from './orderByDate.filter';
import transformFilter from './transform.filter';

angular
    .module('rym.filters', [])
    .filter('groupBy', groupByFilter)
    .filter('user', userFilter)
    .filter('orderByDate', orderByDateFilter)
    .filter('transform', transformFilter);
