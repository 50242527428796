function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'search',
            config: {
                url: '/search?term',
                template: '<rym-search></rym-search>',
                ncyBreadcrumb: {
                    label: 'search'
                }
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
