import Calendar from './calendar';
import { LANGUAGES } from '../../app.constants';

class DatePickerController {
    constructor($document, $scope, keyCode, userService, translationService) {
        Object.assign(this, { $document, $scope, keyCode, userService, translationService });
    }

    $onInit() {
        this.weekDays = [];
        this.userService.getCurrentUser().then((user) => {
            let mondayFirstDayOfWeek = user.languageId !== LANGUAGES.ENGLISH;

            this.buildWeekdaysArray(mondayFirstDayOfWeek);

            this.calendar = new Calendar(mondayFirstDayOfWeek, this.translationService);
            this.calendar.setSelectedDate(this.date);
            this.calendar.selectedMonth();

            this._setStartActiveIndexes();
            this._changeActiveDay(this.activeWeekIndex, this.activeDayIndex);
            this._bindEvents();
        });
    }

    buildWeekdaysArray(mondayFirstDayOfWeek) {
        this.translationService
            .translateBatch([
                'client_Sunday',
                'client_Monday',
                'client_Tuesday',
                'client_Wednesday',
                'client_Thursday',
                'client_Friday',
                'client_Saturday'
            ])
            .then((result) => {
                for (let weekday in result) {
                    this.weekDays.push({
                        day: result[weekday].substring(0, 2)
                    });
                }

                if (mondayFirstDayOfWeek) {
                    this.weekDays.splice(6, 0, this.weekDays.splice(0, 1)[0]);
                }
            });
    }

    select(day) {
        if (this.fromDate && this.fromDate > day.date) {
            return;
        }

        this.calendar.setSelectedDate(day.date);
        this.onChange({ date: this.calendar.getSelectedDate() });
    }

    _setStartActiveIndexes() {
        this.activeWeekIndex = 0;
        this.activeDayIndex = 0;

        for (let i = 0; i < this.calendar.weeks.length; i++) {
            let week = this.calendar.weeks[i];

            for (var j = 0; j < week.days.length; j++) {
                let day = week.days[j];

                if (this.calendar._isCurrentDate(day.date)) {
                    this.activeWeekIndex = i;
                    this.activeDayIndex = j;

                    return;
                }
            }
        }
    }

    _bindEvents() {
        this.keydownEvent = this.$document.bind('keydown', (event) => {
            if (!this.keydownEvent) {
                return;
            }

            if (event.keyCode === this.keyCode.ENTER) {
                this._onEnter();
            }

            if (event.keyCode === this.keyCode.LEFT) {
                event.preventDefault();
                this._onLeftArrow();
            }

            if (event.keyCode === this.keyCode.RIGHT) {
                event.preventDefault();
                this._onRightArrow();
            }

            if (event.keyCode === this.keyCode.TAB) {
                this._onEnter();
            }

            if (event.keyCode === this.keyCode.UP) {
                event.preventDefault();
                this._onUpArrow();
            }

            if (event.keyCode === this.keyCode.DOWN) {
                event.preventDefault();
                this._onDownArrow();
            }
        });
    }

    _changeActiveDay(weekIndex, dayIndex) {
        this.calendar.weeks[this.activeWeekIndex].days[this.activeDayIndex].isActive = false;
        this.calendar.weeks[weekIndex].days[dayIndex].isActive = true;
        this.activeWeekIndex = weekIndex;
        this.activeDayIndex = dayIndex;
    }

    _getActiveWeek() {
        return this.calendar.weeks[this.activeWeekIndex];
    }

    _getActiveDay() {
        return this.calendar.weeks[this.activeWeekIndex].days[this.activeDayIndex];
    }

    _onEnter() {
        this.$scope.$evalAsync(() => {
            let day = this._getActiveDay();
            this.select(day);
        });
    }

    _onLeftArrow() {
        this.$scope.$evalAsync(() => {
            let week = this._getActiveWeek();
            let newDayIndex = this.activeDayIndex - 1;

            if (newDayIndex < 0) {
                this.calendar.previousMonth();
                newDayIndex = week.days.length - 1;
            }

            this._changeActiveDay(this.activeWeekIndex, newDayIndex);
        });
    }

    _onRightArrow() {
        this.$scope.$evalAsync(() => {
            let week = this._getActiveWeek();
            let newDayIndex = this.activeDayIndex + 1;

            if (newDayIndex > week.days.length - 1) {
                this.calendar.nextMonth();
                newDayIndex = 0;
            }

            this._changeActiveDay(this.activeWeekIndex, newDayIndex);
        });
    }

    _onUpArrow() {
        this.$scope.$evalAsync(() => {
            this._changeActiveDay(Math.max(this.activeWeekIndex - 1, 0), this.activeDayIndex);
        });
    }

    _onDownArrow() {
        this.$scope.$evalAsync(() => {
            this._changeActiveDay(Math.min(this.activeWeekIndex + 1, this.calendar.weeks.length - 1), this.activeDayIndex);
        });
    }

    $onDestroy() {
        if (this.keydownEvent) {
            this.keydownEvent.unbind();
        }

        if (this.keyupEvent) {
            this.keyupEvent.unbind();
        }
    }
}

DatePickerController.$inject = ['$document', '$scope', 'keyCode', 'userService', 'translationService'];

export default DatePickerController;
