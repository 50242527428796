let transformFilter = () => {
    return _.memoize(
        (items, transformer) => {
            return items && items.map((item) => transformer.transform(item));
        },
        (items, transformer) => {
            return items && transformer._getTrackingId(items);
        }
    );
};
export default transformFilter;
