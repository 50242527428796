import template from './guide.html';
import controller from './guide.controller';
import './guide.less';

let guideComponent = {
    template,
    controller,
    transclude: {
        step: '?rymGuideStep'
    },
    bindings: {
        onDone: '&'
    }
};

export default guideComponent;
