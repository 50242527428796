let ngEnterDirective = (keyCode) => {
    return {
        restrict: 'A',
        link: (scope, element, attrs) => {
            element.bind('keydown keypress', (event) => {
                if (event.which === keyCode.ENTER) {
                    scope.$applyAsync(() => {
                        scope.$evalAsync(attrs.ngEnter, { $event: event });
                    });

                    event.preventDefault();
                }
            });
        }
    };
};

ngEnterDirective.$inject = ['keyCode'];

export default ngEnterDirective;
