function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'settings',
            config: {
                url: '/settings',
                template: '<rym-settings></rym-settings>',
                ncyBreadcrumb: {
                    label: 'Settings'
                }
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
