let emailValidationDirective = (stringService) => {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
            ctrl.$parsers.unshift((viewValue) => {
                if (stringService.isEmail(viewValue)) {
                    ctrl.$setValidity('mail', true);
                    return viewValue;
                } else {
                    ctrl.$setValidity('mail', false);
                    return undefined;
                }
            });
        }
    };
};

emailValidationDirective.$inject = ['stringService'];

export default emailValidationDirective;
