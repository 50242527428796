import template from './promptDialog.html';

let promptDialogComponent = {
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '=',
        validationRegex: '@'
    }
};

export default promptDialogComponent;
