class DialogController {
    $onInit() {
        if (this.options) {
            this.okButtonEventId = this.options.id ? `ok button in ${this.options.id}` : '';
            this.secondaryOkButtonEventId = this.options.id ? `secondary ok button in ${this.options.id}` : '';
            this.cancelButtonEventId = this.options.id ? `cancel button in ${this.options.id}` : '';
        }
    }

    clickedHideDialog() {
        this.options.hide = !this.options.hide;
        this.onHide();
    }
}

export default DialogController;
