import controller from './agendaActionItem.controller';
import template from './agendaActionItem.html';

let agendaActionItemComponent = {
    template,
    controller,
    bindings: {
        action: '=',
        onCreate: '&',
        onCreateMultiple: '&',
        onDelete: '&',
        onDuplicate: '&',
        onNew: '&',
        meetingId: '<',
        hideMore: '<',
        isDisabled: '<'
    },
    transclude: true
};

export default agendaActionItemComponent;
export const agendaActionTemplate = template;
