import { LANGUAGES } from './app.constants';
import { RUNTIME, RUNTIMES } from './app.constants';

class AppController {
    constructor($rootScope, userApiService, userService, applicationInsightsService, responsive, appcuesService) {
        Object.assign(this, {
            $rootScope,
            userApiService,
            userService,
            applicationInsightsService,
            responsive,
            appcuesService
        });

        this.allowNavigation = this.$rootScope.allowNavigation;
    }

    introGuideDone() {
        this.userService.getCurrentUser().then((user) => {
            if (!user.hasFinishedIntroGuide) {
                this.userApiService.setHasFinishedIntroGuideForCurrentUser(true);

                if (user.isAdmin && !this.responsive.is('xs') && RUNTIME === RUNTIMES.PROD) {
                    if (user.languageId !== LANGUAGES.SWEDISH) {
                        this.appcuesService.show('-Lsv4O8nCLdGeEgdZMP7');
                    } else if (user.languageId === LANGUAGES.SWEDISH) {
                        this.appcuesService.show('-LsvPWe2Oz2YuGAWVERj');
                    }
                }
            }

            if (!user.hasAcceptedTerms) {
                this.userService.showTermsOfService();
            }

            this.applicationInsightsService.trackEvent('intro_guide_done');
        });
    }
}

AppController.$inject = ['$rootScope', 'userApiService', 'userService', 'applicationInsightsService', 'responsive', 'appcuesService'];

export default AppController;
