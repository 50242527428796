import BaseApiService from './baseApi.service';

class ActionApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        dateService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'actions'
        );
        this.actions = [];
        this.dateService = dateService;
    }

    getAll() {
        return super.GET('actions').then((actions) => {
            _.forEach(actions, (action) => {
                this.dateService.convertEndDateToString(action);
            });

            this.actions = actions;
            return this.actions;
        });
    }

    create(action) {
        this.appcuesService.track('Action Created');
        return super.POST('actions', action).then((result) => {
            this.dateService.convertEndDateToString(result);

            return result;
        });
    }

    duplicate(id, office365Id) {
        this.appcuesService.track('Action Duplicated');
        if (office365Id) {
            return super.POST(`actions/office365/${office365Id}/duplicate`);
        }

        return super.POST(`actions/${id}/duplicate`).then((result) => {
            this.dateService.convertEndDateToString(result);

            return result;
        });
    }

    remove(id, office365Id) {
        if (office365Id) {
            return super.DELETE(`actions/office365`, office365Id);
        }

        return super.DELETE('actions', id);
    }

    get(id) {
        return super.GET(`actions/${id}`).then((action) => {
            this.dateService.convertEndDateToString(action);

            return action;
        });
    }

    putComment(id, comment) {
        this.appcuesService.track('Action Comment Changed');
        return super.PUT(`actions/${id}/comment`, { comment: comment });
    }

    putName(id, name) {
        return super.PUT(`actions/${id}/name`, name);
    }

    toggleStatus(action) {
        if (action.isFinished) {
            return this.setAsOngoing(action.id);
        } else {
            return this.setAsFinished(action.id);
        }
    }

    setAsFinished(id) {
        this.appcuesService.track('Action Checked');
        return super.PUT(`actions/${id}/finished`);
    }

    setAsOngoing(id) {
        this.appcuesService.track('Action Unchecked');
        return super.PUT(`actions/${id}/ongoing`);
    }

    updateEndDate(id, endDate) {
        return super.PUT(`actions/${id}/enddate`, endDate.toDateString());
    }

    putResponsible(actionId, user) {
        return super.PUT(`actions/${actionId}/responsible`, user);
    }

    numberOfUnfinished(currentUserId) {
        return super.GET(`actions/unfinished/${currentUserId}`);
    }

    getActionsForAgenda(agendaId) {
        return super.GET(`actions/agenda/${agendaId}`).then((actions) => {
            _.forEach(actions, (action) => {
                this.dateService.convertEndDateToString(action);
            });

            return actions;
        });
    }

    getActionsForMeeting(meetingId) {
        return super.GET(`actions/meeting/${meetingId}`).then((actions) => {
            _.forEach(actions, (action) => {
                this.dateService.convertEndDateToString(action);
            });

            return actions;
        });
    }

    clearActionCache() {
        return super.removeResourceCache();
    }

    getActionsForMeetingSeries(meetingSeriesId) {
        return super.GET(`actions/series/${meetingSeriesId}/all`);
    }

    clearCache(key) {
        return super.removeCache(key);
    }

    export(context) {
        return super.GET_BUFFER('actions/export', context);
    }
}

ActionApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'dateService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default ActionApiService;
