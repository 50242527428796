import { PARTICIPANT_TYPES } from '../../../../app.constants';

class meetingSeriesDetailController {
    constructor(
        $q,
        $element,
        $timeout,
        $scope,
        $state,
        $filter,
        anchorScrollService,
        navigationService,
        responsive,
        meetingMetadataService,
        meetingBuildingService,
        eventEmitterService,
        meetingApiService,
        categoryApiService,
        meetingSeriesService,
        translationService,
        timeService,
        userService
    ) {
        Object.assign(this, {
            $q,
            $element,
            $timeout,
            $scope,
            $state,
            $filter,
            anchorScrollService,
            navigationService,
            responsive,
            meetingMetadataService,
            meetingBuildingService,
            eventEmitterService,
            meetingApiService,
            categoryApiService,
            meetingSeriesService,
            translationService,
            timeService,
            userService
        });
        this.readOnly = true;

        this.scrollOffset = 48;
        this.duScrollOffset = this.scrollOffset * 2;
        this.firstDuScrollOffset = this.scrollOffset * 3;
    }

    $onInit() {
        this.show = false;
        this.parent.loading = true;

        this.responsive.on(['lg', 'xl'], this.$scope, () => {
            this.detailOpen = true;
            this.shouldShowAgenda = true;
        });

        this.bindChangeMeetingFunc();
        this.setSelected();
        this.meetingSeriesService.getMeetingsInMeetingSeries().then(() => {
            this.parent.setSelectedMeeting(this.parent.selectedMeeting);
        });

        this.eventEmitterService.subscribe('showAgendaAttachments', this.showAttachments.bind(this));
    }

    getMeetingDetails() {
        let queue = [
            this.meetingApiService.getOrganizer(this.meeting.id),
            this.categoryApiService.getAll(),
            this.meetingApiService.getParticipantsForMeeting(this.meeting.id)
        ];

        return this.$q.all(queue).then((result) => {
            this.organizer = result[0];
            this.category = _.find(result[1], (cat) => {
                return cat.id === this.meeting.categoryId;
            });
            this.participants = result[2].filter((p) => p.type !== PARTICIPANT_TYPES.READER);
        });
    }

    showAttachments(agenda) {
        this.agendaId = agenda.id;
        this.meetingId = this.meeting.id;

        this.attachmentsOpen = true;
    }

    _shouldLockMetaData() {
        return this.meeting && (this.meeting.lockMetaData || (this.meeting.office365UId && !this.meeting.isOrganizer));
    }

    bindChangeMeetingFunc() {
        this.parent.setChangeMeetingFunc(this.setSelected.bind(this));
    }

    closeSideNav() {
        if (this.attachmentsOpen) {
            this.attachmentsOpen = !this.attachmentsOpen;
        }

        if (this.parent.sideNavOpen) {
            this.parent.sideNavOpen = !this.parent.sideNavOpen;
        }
    }

    buildRowData() {
        this.translationService
            .translateBatch([
                'meetings_Location',
                'client_Organizer',
                'client_Date',
                'client_StartTime',
                'client_Duration',
                'client_Category',
                'client_Uncategorized',
                'client_Unspecified'
            ])
            .then((translations) => {
                this.locationRow = {
                    icon: 'place',
                    title: translations.meetings_Location,
                    description: this.meeting.location ? this.meeting.location : translations.client_Unspecified
                };

                this.organizerRow = {
                    title: translations.client_Organizer,
                    description: this.organizer.name,
                    user: {
                        name: this.organizer.name,
                        email: this.organizer.email
                    }
                };
                this.dateRow = {
                    icon: 'event',
                    title: translations.client_Date,
                    description: this.$filter('date')(this.meeting.startDate, 'rymDate')
                };
                this.startTimeRow = {
                    icon: 'schedule',
                    title: translations.client_StartTime,
                    description: this.$filter('date')(this.meeting.startDate, 'HH:mm')
                };
                this.endTimeRow = {
                    icon: 'schedule',
                    title: translations.client_Duration,
                    description: this.meeting.endDate ? this.timeService.getDuration(this.meeting.startDate, this.meeting.endDate) : '---'
                };
                this.categoryRow = {
                    icon: this.category ? this.category.colorId : 0,
                    title: translations.client_Category,
                    description: this.category ? this.category.name : translations.client_Uncategorized
                };

                this.responsive.on(['lg', 'xl'], this.$scope, () => {
                    this.parent.sideNavOpen = true;
                });
            });
    }

    $doCheck() {
        if (this.parent.scrollTo && !this.parent.loading && document.querySelector('#' + this.parent.scrollTo)) {
            this.scrollTo();
        }
    }

    setSelected() {
        this.show = false;
        this.parent.loading = true;
        this.isProtocolLoading = true;
        this.$timeout(() => {
            this.meetingBuildingService.buildMeeting(this.parent.selectedMeeting.id).then((meeting) => {
                this.meeting = meeting;
                this.lockMetaData = this._shouldLockMetaData();
                this.parent.setSelectedMeeting(this.meeting);
                this.meeting.oldMeetingName = this.meeting.meetingName;
                this.container = 'protocol-container';
                this.getMeetingDetails()
                    .then(() => {
                        this.buildRowData();
                    })
                    .catch((error) => {
                        this.toastService.error({ description: error.data.message });
                        this.parent.close();
                    })
                    .finally(() => {
                        this.initialized = true;
                        this.parent.loading = false;
                        this.show = true;
                    });
            });
        }, 20);
    }

    isSelected(meeting) {
        return meeting.id === this.meeting.id;
    }

    goTo(id) {
        this.anchorScrollService.scrollTo(id, this.container, this.scrollOffset);
    }

    updateDescription() {
        if (this._shouldLockMetaData()) {
            return;
        }

        return this.meetingMetadataService.updateDescription(this.meeting.description, this.meeting.id).then(() => {
            this._updateMeetingsOverviewIfMeetingChanged();
        });
    }

    updateMeetingName() {
        if (this._shouldLockMetaData()) {
            return;
        }
        return this.meetingMetadataService
            .updateMeetingName(this.meeting.meetingName, this.meeting.id, this.meeting.oldMeetingName)
            .then((result) => {
                this.parent.meetingSeriesName = result;
                this.meeting.oldMeetingName = result;
                this._updateMeetingsOverviewIfMeetingChanged();
            });
    }

    scrollTo() {
        this.$timeout(() => {
            if (this.parent.scrollTo) {
                this.anchorScrollService.scrollTo(this.parent.scrollTo, this.container, this.scrollOffset);
                this.parent.scrollTo = null;
            }
        }, 200);
    }

    _updateMeetingsOverviewIfMeetingChanged() {
        this.eventEmitterService.publishEvent('meetingChangedExternally', this.meeting);
    }

    closeAttachments() {
        let deferred = this.$q.defer();
        this.attachmentsOpen = false;
        this.$scope.$applyAsync(() => {
            this.documents = null;
            deferred.resolve();
        });

        return deferred.promise;
    }
}

meetingSeriesDetailController.$inject = [
    '$q',
    '$element',
    '$timeout',
    '$scope',
    '$state',
    '$filter',
    'anchorScrollService',
    'navigationService',
    'responsive',
    'meetingMetadataService',
    'meetingBuildingService',
    'eventEmitterService',
    'meetingApiService',
    'categoryApiService',
    'meetingSeriesService',
    'translationService',
    'timeService',
    'userService'
];
export default meetingSeriesDetailController;
