import { BASE_URLS, HTTP_STATUS_CODES } from '../app.constants';

class ActionsController {
    constructor(
        $q,
        $timeout,
        $state,
        $scope,
        $rootScope,
        $element,
        actionApiService,
        dialogService,
        toastService,
        responsive,
        navigationService,
        anchorScrollService,
        userService,
        animationService,
        actionService,
        eventEmitterService,
        translationService,
        breadcrumbsService,
        actionDetailService,
        menuService,
        exportService,
        $filter
    ) {
        Object.assign(this, {
            $q,
            $timeout,
            $state,
            $scope,
            $rootScope,
            $element,
            actionApiService,
            dialogService,
            toastService,
            responsive,
            navigationService,
            anchorScrollService,
            userService,
            animationService,
            actionService,
            eventEmitterService,
            translationService,
            breadcrumbsService,
            actionDetailService,
            menuService,
            exportService,
            $filter
        });
        this.orderBy = 'endDate';
        this.reverseOrder = false;
        this.loading = true;
        this.isShowingFilter = false;
        this.hasChangedSortOrder = false;
        this.sortOrder = -1;
        this.filteredActions = [];
    }

    $onInit() {
        this.breadcrumbsService.setLabel('actions', this.translationService.translate('client_Actions'));

        this.responsive.on(['xs', 'sm', 'md', 'lg'], this.$scope, () => {
            this.isShowingFilter = false;
        });

        this.responsive.on(['xl'], this.$scope, () => {
            this.isShowingFilter = false;
        });

        this.filter = {
            responsible: 'mine',
            status: 'unfinished'
        };

        this.eventEmitterService.subscribe('userChangedName', (user) => (this.curentUser = user));
        this.eventEmitterService.subscribe('organizationChanged', this._getActions.bind(this));
        this.eventEmitterService.subscribe('actionUpdated', this.updateAction.bind(this));

        this.$q.all([this._getActions(), this._getCurrentUser()]).finally(() => {
            this._updateNoHitsState();
            this.loading = false;
            if (this.$state.includes('actions.action')) {
                this.$rootScope.isFetchingAction = true;
                this.actionApiService
                    .get(this.$state.params.actionId)
                    .then(
                        (action) => {
                            if (action) {
                                this.fetchedAction = true;
                                this.detail(action);
                            }
                        },
                        (error) => {
                            if (error.status === HTTP_STATUS_CODES.CHANGE_ACTION_ORG) {
                            } else {
                                this.dialogService
                                    .alert({
                                        id: 'action not found dialog',
                                        title: this.translationService.translate('client_ActionNotFound'),
                                        description: this.translationService.translate('client_ActionNotFoundDescription')
                                    })
                                    .then(() => {
                                        this.actionDetailService.close();
                                    });
                            }
                        }
                    )
                    .finally(() => {
                        this.$timeout(() => {
                            this.$rootScope.isFetchingAction = false;
                        }, 10000);
                    });
            }
            this.$scope.$watchCollection('$ctrl.filteredActions', (newActions, oldActions) => {
                if (this.filteredActions && newActions !== oldActions) {
                    let isSelectedActionVisible = _.find(newActions, (action) => {
                        return this.$state.includes('actions.action', { actionId: action.id });
                    });

                    if (!isSelectedActionVisible && !this.fetchedAction) {
                        this.actionDetailService.close();
                    }
                }
            });
        });

        this.eventEmitterService.subscribe('deleteAction', this.remove.bind(this));
        this.eventEmitterService.subscribe('duplicateAction', this.duplicate.bind(this));

        this.$scope.$on('spliceActions', (event, action) => {
            this.removeActionFromArray(action);
        });

        this.$scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if (toParams.actionId) {
                this.selected = toParams.actionId;
            } else {
                this.selected = null;
                this.fetchedAction = false;
            }
        });

        this.$scope.$watchGroup(['$ctrl.searchTerm', '$ctrl.orderBy'], () => {
            this.clearSortOrders();
        });

        this.$scope.$watch(
            '$ctrl.filter',
            () => {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.clearSortOrders();
            },
            true
        );
    }

    _updateNoHitsState() {
        if (this.actions.length) {
            this.noDataTitle = this.translationService.translate('client_NoActionsFound');
            this.noDataDescription = this.translationService.translate('client_NoItemMatchingSearch');
            this.noDataIcon = 'search';
        } else {
            this.noDataTitle = this.translationService.translate('client_NothingHere');
            this.noDataDescription = this.translationService.translate('client_NoActionsAvailable');
            this.noDataIcon = 'face';
        }
    }

    toggleFilter() {
        this.isShowingFilter = !this.isShowingFilter;
    }

    closeFilter() {
        this.isShowingFilter = false;
    }

    changeOrderBy(column) {
        this.hasChangedSortOrder = true;
        if (this.orderBy.replace('-', '') === column) {
            this.reverseOrder = !this.reverseOrder;
        }
        this.orderBy = this.reverseOrder ? `-${column}` : column;
        this.anchorScrollService.scrollTop('actions-scroller');
        this.$timeout(() => this.$rootScope.$broadcast('list-order-changed'), 0);
    }

    handleOnSearch($event) {
        this.searchTerm = $event.searchTerm;
        this._updateNoHitsState();
    }

    isMatchingFilter() {
        return (action) => {
            return (
                (action.sortOrder && !action.isFinished) ||
                (this.isMatchingStatusFilter(action) &&
                    this.isMatchingResponsibleFilter(action) &&
                    this.isMatchingCategoryFilter(action) &&
                    this.isMatchingSearchTerm(action))
            );
        };
    }

    isMatchingCategoryFilter(action) {
        return angular.isUndefined(this.filter.categoryId) || action.categoryId === this.filter.categoryId;
    }

    isMatchingSearchTerm(action) {
        return (
            !this.searchTerm ||
            (this.searchTerm && action.name.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (this.searchTerm && action.responsible && action.responsible.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (this.searchTerm && action.responsibleEmail && action.responsibleEmail.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
            (this.searchTerm && action.from && action.from.toLowerCase().includes(this.searchTerm.toLowerCase()))
        );
    }

    updateIsFilterActive() {
        this.isFilterActive = this._isFilterActive();
    }

    _isFilterActive() {
        return this.filter.responsible !== 'mine' || angular.isDefined(this.filter.categoryId) || this.filter.status !== 'unfinished';
    }

    isMatchingResponsibleFilter(action) {
        if (action.isNew) {
            return true;
        }

        let currentUserId = this.currentUser ? this.currentUser.id : null;
        let isMine = action.responsibleId === currentUserId;

        switch (this.filter.responsible) {
            case 'mine':
                return isMine;
            case 'delegated':
                return !isMine;
            default:
                return true;
        }
    }

    isMatchingStatusFilter(action) {
        let isFinished = action.isFinished;

        if (action && !action.hasToggled) {
            switch (this.filter.status) {
                case 'finished':
                    if (!this.hasChangedSortOrder) {
                        this.orderBy = '-endDate';
                    }
                    return isFinished;
                case 'unfinished':
                    if (!this.hasChangedSortOrder) {
                        this.orderBy = 'endDate';
                    }
                    return !isFinished;
                case 'delayed':
                    return action.endDate > _.now();
                default:
                    return true;
            }
        }

        return true;
    }

    clearSortOrders() {
        if (!this.actions) {
            return;
        }
        this.actions.forEach((action) => {
            action.sortOrder = 0;
            action.isNew = false;
            delete action.hasToggled;
        });

        this.$timeout(() => {
            this.loading = false;
        }, 200);
    }

    remove(action) {
        this.dialogService
            .confirm({
                id: 'delete action dialog',
                title: this.translationService.translate('client_DeleteConfirm'),
                description: this.translationService.translate('client_DeleteItemConfirm', { itemName: action.name }),
                ok: this.translationService.translate('client_Delete')
            })
            .then(() => {
                this.actionApiService
                    .remove(action.id, action.office365Id)
                    .then(() => {
                        let index = _.findIndex(this.actions, (a) => {
                            return a.id === action.id;
                        });
                        this.animationService.animate(() => {
                            this.actions.splice(index, 1);
                        }, '.virtual-scroll-item');

                        if (this.isSelected(action)) {
                            this.actionDetailService.close();
                        }

                        this.toastService.success({ title: this.translationService.translate('client_ActionDeleted') });
                        this._notifyActionUpdate();
                        this._updateNoHitsState();
                    })
                    .catch((exception) => {
                        this.toastService.error({ description: exception.data.message });
                    });
            });
    }

    duplicate(action) {
        this.actionApiService.duplicate(action.id, action.office365Id).then((result) => {
            this.toastService.success({
                title: this.translationService.translate('client_ActionDuplicated')
            });

            result.isNew = true;
            result.sortOrder = 0;

            this.animationService.animate(() => {
                this.actions.push(result);
            }, '.virtual-scroll-item');
            if (this.$state.includes('actions.action', { actionId: action.id })) {
                this.actionDetailService.close();
            }
            this._notifyActionUpdate();
        });
    }

    add(action) {
        this.actions.push(action);
    }

    export() {
        let options = {
            id: 'export actions dialog',
            title: this.translationService.translate('client_Actions'),
            ok: this.translationService.translate('client_Export'),
            cancel: this.translationService.translate('cancel'),
            message: {
                subject: this.translationService.translate('client_Actions'),
                message: this.translationService.translate('client_ExportActionDescription', { date: this.$filter('date')(new Date(), 'rymDate') })
            }
        };

        this.dialogService.custom('rym-text-dialog', options).then((message) => {
            let exportContext = {
                title: message.subject,
                description: message.message,
                itemIds: this.$filter('filter')(this.actions, this.isMatchingFilter()).map((a) => a.id)
            };

            return this.exportService.exportActions(exportContext);
        });
    }

    removeActionFromArray(action) {
        let index = this.actions.indexOf(action);
        this.actions.splice(index, 1);
    }

    detail(action, event) {
        if (event) {
            event.stopPropagation && event.stopPropagation();
        }

        this.actionDetailService.open(action, 'actions');
    }

    updateAction(updatedAction) {
        let action = _.find(this.actions, (action) => {
            return action.id === updatedAction.id;
        });

        if (action) {
            this.$scope.$applyAsync(() => {
                Object.assign(action, updatedAction);
            });
        }
    }

    createAction() {
        let options = {
            id: 'create action dialog',
            title: this.translationService.translate('client_CreateAction'),
            ok: this.translationService.translate('client_Create'),
            cancel: this.translationService.translate('cancel'),
            defaultResponsible: true
        };

        this.dialogService.custom('rym-create-action-dialog', options).then((data) => {
            this.toastService.loading({
                title: this.translationService.translate('client_ActionCreating')
            });

            this.actionApiService
                .create({
                    name: data.name,
                    endDate: data.date,
                    responsibleId: data.responsible.id
                })
                .then((result) => {
                    this.$timeout(() => {
                        this.anchorScrollService.scrollTopAnimated('actions-scroller', 1000).then(() => {
                            result.isNew = true;
                            result.sortOrder = this.sortOrder--;
                            this.animationService.animate(() => {
                                this.add(result);
                            }, '.virtual-scroll-item');

                            this.toastService.success({
                                title: this.translationService.translate('client_ActionCreated')
                            });
                            this.$timeout(
                                () => {
                                    result.isNew = false;
                                },
                                3000,
                                false
                            );
                        });
                    });
                    this._notifyActionUpdate();
                });
        });
    }

    _getCurrentUser() {
        return this.userService.getCurrentUser().then((currentUser) => {
            this.currentUser = currentUser;
        });
    }

    _getActions() {
        return this.actionApiService
            .getAll()
            .then((actions) => {
                this.actions = actions;
                if (actions.length === 0) {
                    this._updateNoHitsState();
                }
            })
            .finally(() => {
                this.actionsFinishedLoading = true;
            });
    }

    _resetFilter() {
        this.filter = {
            status: 'unfinished',
            responsible: 'mine',
            categoryId: undefined
        };
    }

    _notifyActionUpdate() {
        this.actionService.notifyActionUpdate();
    }

    showMenu(event, action) {
        event.stopPropagation && event.stopPropagation();
        let menuButton = angular.element(event.currentTarget);

        let options = {
            items: [
                {
                    id: 'menu option - duplicate action',
                    icon: 'content_copy',
                    text: this.translationService.translate('client_Duplicate'),
                    onClick: () => this.duplicate(action)
                },
                {
                    id: 'menu option - delete action',
                    icon: 'delete_outline',
                    text: this.translationService.translate('client_Delete'),
                    onClick: () => this.remove(action)
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element(menuButton), false, false);
    }

    $onDestroy() {
        this.eventEmitterService.unSubscribe('deleteAction');
    }

    isDelayed(action) {
        return action.endDate && !action.isFinished && new Date(action.endDate) < new Date();
    }

    isSelected(action) {
        return this.$state.includes('actions.action', { actionId: action.id });
    }
}

ActionsController.$inject = [
    '$q',
    '$timeout',
    '$state',
    '$scope',
    '$rootScope',
    '$element',
    'actionApiService',
    'dialogService',
    'toastService',
    'responsive',
    'navigationService',
    'anchorScrollService',
    'userService',
    'animationService',
    'actionService',
    'eventEmitterService',
    'translationService',
    'breadcrumbsService',
    'actionDetailService',
    'menuService',
    'exportService',
    '$filter'
];

export default ActionsController;
