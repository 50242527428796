class ActionService {
    constructor(eventEmitterService) {
        Object.assign(this, { eventEmitterService });
    }

    notifyActionUpdate(action) {
        if (action) {
            this.eventEmitterService.publishEvent('actionUpdated', action);
        }
        this.eventEmitterService.publishEvent('invalidateActionCache');
        this.updateUnfinishedActions();
    }

    updateUnfinishedActions() {
        this.eventEmitterService.publishEvent('updateUnfinishedActions');
    }
}

ActionService.$inject = ['eventEmitterService'];

export default ActionService;
