class MeetingMenuController {
    constructor($state, translationService) {
        this.$state = $state;
        this.translationService = translationService;
    }

    $onInit() {
        if (this.$state.is('meeting')) {
            this.meetingInBackground = parseInt(this.$state.params.id);
        }
    }
}

MeetingMenuController.$inject = ['$state', 'translationService'];

export default MeetingMenuController;
