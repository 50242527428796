let AutoFocusDirective = ($timeout, responsive, domService) => {
    return {
        restrict: 'AC',
        scope: {
            active: '=',
            delay: '<'
        },
        link: function ($scope, $element) {
            if ($scope.active && !responsive.is('xs')) {
                $timeout(function () {
                    domService.focus($element[0]);
                }, $scope.delay || 0);
            }
        }
    };
};

AutoFocusDirective.$inject = ['$timeout', 'responsive', 'domService'];

export default AutoFocusDirective;
