import template from './timeMenu.html';
import './timeMenu.less';

let timeMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default timeMenuComponent;
