import BaseApiService from './baseApi.service';

class SubscriptionApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'subscription'
        );
    }

    getCustomer() {
        return super.GET('organizations/current/subscriptions/current/billinginformation');
    }

    invoicePreview(preview) {
        return super.POST('organizations/current/subscriptions/current/invoices/preview', preview);
    }

    createCustomer(customer) {
        return super.POST('organizations/current/subscriptions/current/billinginformation', customer);
    }

    subscribe(subscription) {
        return super.POST('organizations/current/subscriptions', subscription);
    }

    update(subscription) {
        return super.PUT('organizations/current/subscriptions/current', subscription);
    }

    updateCard(paymentMethodId) {
        return super.PUT(`organizations/current/subscriptions/current/paymentmethods/${paymentMethodId}`);
    }

    retryPaymentWithNewPaymentMethod({ paymentMethodId, latestInvoiceId }) {
        return super.PUT(`organizations/current/subscriptions/current/paymentmethods/${paymentMethodId}/retry/${latestInvoiceId}`);
    }

    updateContactAndBillingDetails(billinginformation) {
        return super.PUT('organizations/current/subscriptions/current/billinginformation', billinginformation);
    }

    cancelSubscription() {
        return super.PUT('organizations/current/subscriptions/current/cancel');
    }
}

SubscriptionApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default SubscriptionApiService;
