import navigationComponent from './navigation.component';
import tabComponent from './components/tab/tab.component';
import profileTabComponent from './components/profileTab/profileTab.component';
import navigationService from './navigation.service';

angular
    .module('rym.navigation', [])
    .component('rymNavigation', navigationComponent)
    .component('rymNavigationTab', tabComponent)
    .component('rymProfileTab', profileTabComponent)
    .service('navigationService', navigationService);
