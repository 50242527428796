import template from './filterTab.html';
import controller from './filterTab.controller';
import './filterTab.less';

let filterTabComponent = {
    template: template,
    controller: controller,
    link: (scope, element, attrs, tabsCtrl) => {
        tabsCtrl.add(scope);

        scope.select = () => {
            tabsCtrl.select();
        };
    },
    require: {
        filterTabsCtrl: '?^rymFilterTabs'
    },
    transclude: {
        icon: '?rymFilterTabIcon',
        action: '?rymFilterTabSecondaryAction'
    },
    bindings: {
        text: '@',
        fontWeight: '<',
        value: '=',
        isDefault: '<'
    }
};

export default filterTabComponent;
