const SKIP_TRIAL_EXCEEDED = true;

class MeetingController {
    constructor(
        $q,
        $stateParams,
        $interval,
        $scope,
        $state,
        $rootScope,
        $timeout,
        $filter,
        meetingApiService,
        breadcrumbsService,
        navigationService,
        responsive,
        anchorScrollService,
        dialogService,
        meetingMetadataService,
        toastService,
        menuService,
        userService,
        eventEmitterService,
        meetingBuildingService,
        organizationService,
        paymentService,
        exportService,
        translationService,
        signalRService,
        stringService,
        meetingCreationService,
        participantsService,
        agendaBuildingService
    ) {
        Object.assign(this, {
            $q,
            $stateParams,
            $interval,
            $scope,
            $state,
            $rootScope,
            $timeout,
            $filter,
            meetingApiService,
            breadcrumbsService,
            navigationService,
            responsive,
            anchorScrollService,
            dialogService,
            meetingMetadataService,
            toastService,
            menuService,
            userService,
            eventEmitterService,
            meetingBuildingService,
            organizationService,
            paymentService,
            exportService,
            translationService,
            signalRService,
            stringService,
            meetingCreationService,
            participantsService,
            agendaBuildingService
        });
        this.isDetailsShowing = true;
        this.scrollOffset = 48;
        this.duScrollOffset = this.scrollOffset * 4;
    }

    $onInit() {
        this.isLoading = true;
        this.destroyCalled = false;
        this.agendaMailMessage = '';
        this.protocolMailMessage = '';
        this.agendaMailSubject = '';
        this.protocolMailSubject = '';
        this.reOrderIcon = 'reorder';
        this.now = new Date();
        this.responsive.on(['xs', 'sm', 'md'], this.$scope, () => {
            this.isDetailsShowing = false;
        });

        this.responsive.on(['lg', 'xl'], this.$scope, () => {
            this.isDetailsShowing = true;
        });

        if (this.$stateParams.templateMode) {
            this.duScrollOffset = this.scrollOffset * 2;
            this.templateBuildingMode = true;
            this.isDetailsShowing = false;
        } else {
            this.templateBuildingMode = false;
        }

        this.setBreadcrumb();
        this._getTrialExceeded();
        this.eventEmitterService.subscribe('meetingStartDateUpdated', () => {
            this.setBreadcrumb();
            this.eventEmitterService.publishEvent('invalidateActionCache');
        });
        this.eventEmitterService.subscribe('userChangedName', (user) => (this.curentUser = user));

        this.meetingId = this.meetingId || parseInt(this.$stateParams.id, 10);

        if (this.meetingId) {
            this.eventEmitterService.subscribe('updateMeetingName' + this.meetingId, this.meetingNameChanged.bind(this));
            this.eventEmitterService.subscribe('updateMeetingDescription' + this.meetingId, this.meetingDescriptionChanged.bind(this));
            this.eventEmitterService.subscribe('agendaItemAdded' + this.meetingId, this.agendaItemAdded.bind(this));
            this.eventEmitterService.subscribe('agendaItemRemoved' + this.meetingId, this.agendaItemRemoved.bind(this));
            this.eventEmitterService.subscribe('meetingAgendaItemPostponed' + this.meetingId, this.agendaItemRemoved.bind(this));
            this.eventEmitterService.subscribe('addPostponedAgendaToMeeting' + this.meetingId, this.reloadAgendaContent.bind(this));
            this.eventEmitterService.subscribe('updateAgendaPosition' + this.meetingId, this.updateAgendaPosition.bind(this));
            this.eventEmitterService.subscribe('reloadAgendaContent' + this.meetingId, this.reloadAgendaContent.bind(this));
            this.eventEmitterService.subscribe('joinedMeeting' + this.meetingId, this.userJoinedMeeting.bind(this));
            this.eventEmitterService.subscribe('signalRReconnected', this.rejoinMeeting.bind(this));

            this._fetchMeeting();
        }

        this.eventEmitterService.subscribe('meetingChangedExternally', this.handleMeetingChangedExternally.bind(this));
        this.enableInlineEdit = !this.isDisabled && this.onChange;
    }

    _startPoll() {
        this.checkForDomElement = this.$interval(
            () => {
                this.$scope.$evalAsync(() => {
                    if (document.getElementById(this.$stateParams.scrollToId)) {
                        this.goTo(this.$stateParams.scrollToId);
                        this._cancelPoll();
                    }
                    if (document.getElementById(`a${this.$stateParams.scrollToId}`)) {
                        this.goTo(`a${this.$stateParams.scrollToId}`);
                        this._cancelPoll();
                    }
                });
            },
            200,
            0,
            false
        );
    }

    _cancelPoll() {
        this.$interval.cancel(this.checkForDomElement);
    }

    _updateAuthorizations() {
        this._updateIsDisabled();
        this._updateShowCallToAction();
        this._updateShowSendAgenda();
        this._updateShowSendProtocol();
        this._updateShowRoleSettings();
        this._updateShowCreateNextMeeting();
        this._updateShowLock();
        this._updateShowUnlock();
        this._updateShowDelete();
        this._updateShowExportToOutlook();
        this._updateDisableMeetingDetails();
    }

    _updateShowSendAgenda() {
        this.showSendAgenda = (this.isEditor || this.meeting.isOrganizer) && this.meetingHasStarted;
    }

    _updateShowSendProtocol() {
        this.showSendProtocol = (this.isEditor || this.meeting.isOrganizer) && (!this.meetingHasStarted || this.meeting.protocolSent);
    }

    _updateShowRoleSettings() {
        this.showRoleSettings = this.isEditor || this.meeting.isOrganizer;
    }

    _updateShowCreateNextMeeting() {
        this.showCreateNextMeeting =
            (this.isEditor || this.meeting.isOrganizer) && ((!this.meeting.protocolSent && this.meetingHasStarted) || !this.meetingHasStarted);
    }

    _updateIsDisabled() {
        this.isDisabled = this.meeting.protocolIsLocked || !(this.templateBuildingMode || this.meeting.isOrganizer || this.isEditor);
    }

    _updateShowCallToAction() {
        this.showCallToAction = this.isEditor || this.meeting.isOrganizer || this.templateBuildingMode;
    }

    _updateShowLock() {
        this.showLock = !this.meeting.protocolIsLocked && (this.isEditor || this.meeting.isOrganizer || this.meeting.isCreator);
    }

    _updateShowUnlock() {
        this.showUnlock = this.meeting.protocolIsLocked && this.meeting.protocolLockedByUser.id === this.currentUser.id;
    }

    _updateShowDelete() {
        this.showDelete = this.meeting.isOrganizer || (this.meeting.isCreator && this.isEditor);
    }

    _updateShowExportToOutlook() {
        this.showExportToOutlook = (this.isEditor || this.meeting.isOrganizer) && !this.meeting.office365UId && this._hasValidOffice365Token();
    }

    _updateDisableMeetingDetails() {
        this.disableMeetingDetails =
            this.meeting.protocolIsLocked ||
            this.currentUser.isExternal ||
            ((!this.isEditor || !!this.meeting.office365UId) && !this.meeting.isOrganizer);
    }

    $onDestroy() {
        this.destroyCalled = true;

        if (this.joinMeetingTimeout) {
            this.$timeout.cancel(this.joinMeetingTimeout);
        }

        let id = this.meeting && this.meeting.id ? this.meeting.id : this.meetingId;
        this.signalRService.leaveMeeting(id);
    }

    handleMeetingChangedExternally(meeting) {
        if (this.meeting.id === meeting.id) {
            this.meeting.meetingName = meeting.meetingName;
            this.meeting.description = meeting.description;
        }
    }

    meetingNameChanged(name) {
        this.$scope.$applyAsync(() => {
            this.meeting.meetingName = name;
            this.meetingName = name;
            this.setBreadcrumb();
        });
        this.meetingApiService.removeCacheObject();
    }

    meetingDescriptionChanged(description) {
        this.$scope.$applyAsync(() => {
            this.meeting.description = description;
        });
    }

    userJoinedMeeting(user) {
        this.toastService.information({ title: `${user.name} ${this.translationService.translate('client_EnteredMeeting')}` });
    }

    updateMeetingName() {
        return this.meetingMetadataService.updateMeetingName(this.meeting.meetingName, this.meeting.id, this.meetingName).then((result) => {
            this.meeting.meetingName = result;
            this.meetingName = result;
            this.setBreadcrumb();
            this.signalRService.updateMeetingName(this.meeting.id, this.meetingName);
        });
    }

    updateDescription() {
        return this.meetingMetadataService.updateDescription(this.meeting.description, this.meeting.id).then((result) => {
            this.signalRService.updateMeetingDescription(this.meeting.id, this.meeting.description);
        });
    }

    agendaItemRemoved(agenda) {
        this._removeAgendaList(this.meeting.agenda, agenda);
        this.$scope.$apply();
    }

    agendaItemAdded(agendaUpdate) {
        let parentId = parseInt(agendaUpdate.parentId);
        if (parentId !== this.meeting.rootAgenda.id) {
            _.forEach(this.meeting.agenda, (childAgenda) => {
                if (parentId === childAgenda.id) {
                    if (!childAgenda.children) {
                        childAgenda.children = [];
                    }
                    childAgenda.children.push(agendaUpdate.agenda);
                }
            });
        } else {
            this.meeting.agenda.push(agendaUpdate.agenda);
        }

        this.$scope.$apply();
    }

    shouldShowAgenda() {
        return this.show && (this.responsive.is('lg') || this.responsive.is('xl')) && this.meeting.agenda.length;
    }

    setBreadcrumb() {
        if (this.templateBuildingMode) {
            this.breadcrumbsService.setLabel('template', this.meetingName);
        } else {
            this.breadcrumbsService.setLabel(
                'meeting',
                this.meeting ? `${this.meeting.meetingName} (${this.$filter('date')(this.meeting.startDate, 'mediumDate')})` : ''
            );
        }
    }

    createAgenda(parent, $event) {
        if ($event) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        let sortOrder;

        if (!parent) {
            parent = this.meeting.rootAgenda;
            sortOrder = this.meeting.agenda && this.meeting.agenda.length > 0 ? _.last(this.meeting.agenda).sortOrder + 1 : 0;
        } else {
            sortOrder = parent.children && parent.children.length > 0 ? _.last(parent.children).sortOrder + 1 : 0;
        }

        if (parent.isLoading === true) {
            return;
        }

        parent.isLoading = true;
        let newAgenda = {
            id: 0,
            title: '',
            description: '',
            protocol: null,
            sortOrder: sortOrder,
            parentId: parent.id,
            meetingId: this.meeting.id,
            decisions: [],
            actions: [],
            documents: []
        };

        if (parent === this.meeting.rootAgenda) {
            newAgenda.children = [];
        }

        if (parent && parent.parentId !== 0) {
            if (!parent.children) {
                parent.children = [];
            }
            parent.children.push(newAgenda);
        } else {
            this.meeting.agenda.push(newAgenda);
        }

        newAgenda.setFocus = true;

        return this.meetingApiService
            .createAgenda(this.meeting.id, parent.id)
            .then((newAgendaTmp) => {
                if (newAgendaTmp != null) {
                    newAgenda.id = newAgendaTmp.id;
                    this.signalRService.addedAgenda(this.meeting.id, parent.id, newAgenda);
                } else {
                    if (parent) {
                        parent.children.pop();
                    } else {
                        this.meeting.agenda.pop();
                    }
                }
            })
            .catch(() => {
                if (parent === this.meeting.rootAgenda) {
                    this.meeting.agenda.pop();
                } else if (parent.children) {
                    parent.children.pop();
                }

                this.toastService.error();
            })
            .finally(() => (parent.isLoading = false));
    }

    removeAgenda(data) {
        let agenda = data.agenda,
            deleteChildren = data.deleteChildren;

        let remove = () => {
            return this.meetingApiService.removeAgenda(agenda.meetingId, agenda.id).then(() => {
                this._removeSeriesCache();
            });
        };

        if (deleteChildren) {
            return remove().then(() => {
                this._removeAgendaList(this.meeting.agenda, agenda);
                this.signalRService.removedAgenda(this.meeting.id, agenda);
            });
        }

        this.isDeletingItems = true;
        return this.meetingApiService.moveAgendaChildren(agenda.meetingId, agenda.id).then(() => {
            _.forEach(this.meeting.agenda, (topAgenda, index) => {
                if (topAgenda.sortOrder > agenda.sortOrder) {
                    topAgenda.sortOrder = index + agenda.children.length;
                }
            });

            _.forEach(agenda.children, (agendaChild, index) => {
                agendaChild.parentId = agenda.parentId;
                agendaChild.sortOrder = agenda.sortOrder + index;
            });

            this.meeting.agenda.splice(agenda.sortOrder, 0, ...agenda.children.splice(0, agenda.children.length));
            this.meeting.agenda.splice(
                _.findIndex(this.meeting.agenda, (agendaToRemove) => agenda.id === agendaToRemove.id),
                1
            );

            return remove().finally(() => {
                this.isDeletingItems = false;
            });
        });
    }

    postponeAgenda(data) {
        let agenda = data.agenda,
            deleteChildren = data.deleteChildren;

        let postpone = () => {
            return this.meetingApiService.postponeAgenda(agenda.meetingId, agenda.id).then(() => {
                this._removeSeriesCache();
            });
        };

        if (deleteChildren) {
            return postpone().then(() => {
                this._removeAgendaList(this.meeting.agenda, agenda);
                this.signalRService.postponedAgenda(this.meeting.id, this.meeting.meetingSeriesId, agenda);
            });
        }

        this.isDeletingItems = true;
        return this.meetingApiService.moveAgendaChildren(agenda.meetingId, agenda.id).then(() => {
            _.forEach(this.meeting.agenda, (topAgenda, index) => {
                if (topAgenda.sortOrder > agenda.sortOrder) {
                    topAgenda.sortOrder = index + agenda.children.length;
                }
            });

            _.forEach(agenda.children, (agendaChild, index) => {
                agendaChild.parentId = agenda.parentId;
                agendaChild.sortOrder = agenda.sortOrder + index;
            });

            this.meeting.agenda.splice(agenda.sortOrder, 0, ...agenda.children.splice(0, agenda.children.length));
            this.meeting.agenda.splice(
                _.findIndex(this.meeting.agenda, (agendaToRemove) => agenda.id === agendaToRemove.id),
                1
            );

            return postpone().finally(() => {
                this.isDeletingItems = false;
            });
        });
    }

    _removeSeriesCache() {
        this.eventEmitterService.publishEvent('invalidateDecisionCache');
        this.eventEmitterService.publishEvent('invalidateActionCache');
        this.eventEmitterService.publishEvent('invalidatePostponedAgendaCache');
        this.eventEmitterService.publishEvent('updateUnfinishedActions');
        this.eventEmitterService.publishEvent('updatePostponedAgendas');
    }

    goTo(id) {
        this.anchorScrollService.scrollTo(id, 'agendas-container', this.scrollOffset);
    }

    _removeAgendaList(items, agenda) {
        var index = _.findIndex(this.meeting.agenda, ['id', agenda.id]);

        if (index !== -1) {
            this.meeting.agenda.splice(index, 1);
            this._updateSortOrderForOldSiblings(this.meeting.agenda, agenda.sortOrder);
        } else {
            _.forEach(items, (x) => {
                if (!x.children) {
                    return;
                }

                index = _.findIndex(x.children, ['id', agenda.id]);
                if (index !== -1) {
                    x.children.splice(index, 1);
                    this._updateSortOrderForOldSiblings(x.children, agenda.sortOrder);
                } else {
                    this._removeAgendaList(x.children, agenda);
                }
            });
        }
    }

    openMenu($event) {
        let menuButton = $event.currentTarget,
            options = { items: [] };

        if (!this.templateBuildingMode) {
            if (this.showSendAgenda) {
                options.items.push({
                    id: 'menu option - send meeting agenda',
                    icon: 'mail',
                    text: this.meeting.agendaSent
                        ? this.translationService.translate('client_SendUpdatedAgenda')
                        : this.translationService.translate('client_SendAgenda'),
                    onClick: this.sendAgenda.bind(this)
                });
            }

            if (this.showSendProtocol) {
                options.items.push({
                    id: 'menu option - send meeting protocol',
                    icon: 'mail',
                    text: this.translationService.translate('meetings_SendProtocol'),
                    onClick: this.sendProtocol.bind(this)
                });
            }

            if (this.showCreateNextMeeting) {
                options.items.push({
                    id: 'menu option - create next meeting',
                    icon: 'add',
                    text: this.translationService.translate('client_CreateNextMeeting'),
                    onClick: this.newMeeting.bind(this)
                });
            }
        }

        if (this.showUnlock) {
            options.items.push({
                id: 'menu option - unlock meeting',
                icon: 'lock_open',
                text: this.translationService.translate('client_Unlock'),
                tooltip: this.translationService.translate('client_UnlockInfo', { lockedby: this.meeting.protocolLockedBy }),
                onClick: this.toggleLockProtocol.bind(this)
            });
        }

        if (this.showLock) {
            options.items.push({
                id: 'menu option - lock meeting',
                icon: 'lock',
                text: this.translationService.translate('client_Lock'),
                tooltip: this.translationService.translate('meetings_LockProtocolTitle'),
                onClick: this.toggleLockProtocol.bind(this)
            });
        }

        if (this.templateBuildingMode) {
            this.menuService.actionMenu(options, angular.element(menuButton), false);
            return;
        }

        options.items.push({
            id: 'menu option - export meeting to pdf',
            icon: 'file_download',
            text: this.translationService.translate('client_ExportMinutes'),
            onClick: () => {
                this.dialogService
                    .custom('rym-export-meeting-dialog', {
                        id: 'export meeting dialog',
                        meetingId: this.meeting.id
                    })
                    .then((options) => {
                        return this.exportService.exportWithOptions(options, this.meeting);
                    });
            }
        });

        if (this.showExportToOutlook) {
            options.items.push({
                id: 'menu option - export meeting to outlook',
                mdiIcon: 'office',
                text: this.translationService.translate('client_ExportToCalendar'),
                onClick: () => {
                    if (this.currentUser.isExternal) {
                        return this._showIsParticipantDialog();
                    }

                    let options = {
                        id: 'export to calendar dialog',
                        title: this.translationService.translate('client_ExportToCalendar'),
                        description: this.translationService.translate('client_ExportToCalendarDescription'),
                        placeholder: this.translationService.translate('client_Message'),
                        ok: this.translationService.translate('create'),
                        cancel: this.translationService.translate('client_Cancel')
                    };
                    this.dialogService.custom('rym-export-to-calendar-dialog', options).then((message) => {
                        let msg = angular.isUndefined(message) ? '' : message;
                        this.exportService
                            .exportMeetingToOffice365(this.meeting, this.stringService.replaceLineBreaksWithBreakTags(msg))
                            .then((uId) => {
                                this.meeting.office365UId = uId;
                                this._updateShowExportToOutlook();
                            });
                    });
                }
            });
        }

        if (!this.currentUser.isExternal) {
            options.items.push({
                id: 'menu option - save meeting as template',
                icon: 'content_copy',
                text: this.translationService.translate('meetings_SaveTemplate'),
                onClick: () => this.createTemplate()
            });
        }

        if (this.hasTemplates && this.isEditor) {
            options.items.push({
                id: 'menu option - apply agenda template',
                icon: 'content_paste',
                text: this.translationService.translate('client_ApplyTemplate'),
                onClick: this.openApplyAgendaTemplateDialog.bind(this)
            });
        }

        if (this.showRoleSettings) {
            options.items.push({
                id: 'menu option - role settings for meeting',
                icon: 'settings',
                text: this.translationService.translate('client_RoleSettings'),
                onClick: () => {
                    if (this.currentUser.isExternal) {
                        return this._showIsParticipantDialog();
                    }
                    this.participantsService.openRoleSettingsDialog();
                }
            });
        }

        if (this.showDelete) {
            options.items.push({
                id: 'menu option - delete meeting',
                icon: 'delete_outline',
                text: this.translationService.translate('client_Delete'),
                onClick: () => this.remove(this.meeting)
            });
        }

        this.menuService.actionMenu(options, angular.element(menuButton), false);
    }

    showCreateNextMeetingButtonInHeader() {
        return this.meeting.protocolSent && this.meetingHasStarted;
    }

    remove(meeting) {
        this.dialogService
            .confirm({
                id: 'delete meeting dialog',
                title: this.translationService.translate('client_DeleteConfirm'),
                description: this.translationService.translate('client_DeleteItemConfirm', { itemName: meeting.meetingName })
            })
            .then(() => {
                this.meetingApiService
                    .remove(meeting.id)
                    .then(() => {
                        this.meetingApiService.clearMeetingsCache();
                        this.$state.go('meetings');
                    })
                    .catch((exception) => {
                        this.toastService.error({ description: exception.data.message });
                    });
            });
    }

    createTemplate() {
        let dialog = {
            id: 'create template dialog',
            title: this.translationService.translate('administration_TemplateName'),
            input: this.meeting.meetingName,
            placeholder: this.translationService.translate('client_Name') + ' *'
        };

        this.dialogService.prompt(dialog).then((input) => {
            let template = {
                name: input,
                id: this.meeting.id,
                description: this.meeting.description,
                ok: this.translationService.translate('create')
            };

            this.meetingApiService
                .postTemplate(template)
                .then(() => {
                    this.toastService.success({ title: this.translationService.translate('meetings_TemplateIsCreated') });
                })
                .catch((error) => {
                    this.toastService.error({
                        title: this.translationService.translate('client_CouldNotCreateTemplate'),
                        description: error.data.message
                    });
                });
        });
    }

    updateAgendaPosition() {
        this.eventEmitterService.publishEvent('blurNotes');
        this.meetingApiService.clearCache(`${this.meeting.id}/agendas`);
        this._buildMeeting();
    }

    reloadAgendaContent() {
        this.eventEmitterService.publishEvent('blurNotes');
        this._buildMeeting(true);
    }

    rejoinMeeting() {
        this.signalRService.joinMeeting(this.meeting.id, this.currentUser, true);
        this.signalRService.joinMeetingSeries(this.meeting.meetingSeriesId);
    }

    showOrganizerInfo() {
        this.meetingMetadataService.showOrganizerInfo(this.meeting);
    }

    _hasValidOffice365Token() {
        return this.currentUser && this.currentUser.hasValidOffice365Token;
    }

    _fetchMeeting(hardReload) {
        let deferred = this.$q.defer();

        this.userService.getCurrentUser().then((user) => {
            this.currentUser = user;

            if (this.destroyCalled) {
                return;
            }

            // fetch base meeting info
            this.meetingBuildingService.GetMeeting(this.meetingId, hardReload).then((meeting) => {
                if (this.destroyCalled) {
                    return;
                }

                this.meeting = meeting;
                if (this.$stateParams.meeting) {
                    this.meeting.office365Id = this.$stateParams.meeting.office365Id;
                }

                this.meetingName = meeting.meetingName;
                this.startDate = new Date(meeting.startDate);
                this.meetingHasStarted = this.now.getTime() >= this.startDate.getTime();
                this.setBreadcrumb();

                // register onload event of participants
                this.isEditor = false;
                this._updateAuthorizations();
                this.participantsService.addOnLoaded(this.meeting.id, (changes) => {
                    this.isEditor = changes.isEditor;
                    this._updateAuthorizations();
                });

                //signalR
                this.joinMeetingTimeout = this.$timeout(() => {
                    this.signalRService.joinMeeting(this.meetingId, this.currentUser, false);
                    this.signalRService.joinMeetingSeries(this.meeting.meetingSeriesId);
                }, 1500);

                // fetch agenda and rebuild meeting with data
                this.meetingBuildingService
                    .buildAgendaOnMeeting(this.meeting, hardReload)
                    .then((meeting) => {
                        if (this.destroyCalled) {
                            return;
                        }

                        this.meeting = meeting;
                        this.eventEmitterService.publishEvent('updateAgendaSortOrder', this.meeting.agenda);

                        this.meetingApiService.hasTemplates().then((hasTemplates) => {
                            this.hasTemplates = hasTemplates;

                            if (!this.hasTemplates && this.meeting.agenda.length === 0) {
                                this.createAgenda();
                            }

                            this.isLoading = false;
                            this._startPoll();
                            if (!this.$stateParams.scrollToId) {
                                this._cancelPoll();
                            }

                            deferred.resolve();
                        });

                        //get prev meeting
                        if (this.meeting.previousMeetingId) {
                            this.meetingApiService
                                .get(meeting.previousMeetingId, true)
                                .then((previousMeeting) => {
                                    this.previousMeeting = previousMeeting;
                                })
                                .catch({});
                        }
                    })
                    .finally(() => {
                        this.show = true;
                    });
            });
        });

        return deferred.promise;
    }

    _buildMeeting(hardReload) {
        return this.meetingBuildingService
            .buildMeeting(this.meetingId, hardReload)
            .then((meeting) => {
                this.meeting = meeting;
                if (this.$stateParams.meeting) {
                    this.meeting.office365Id = this.$stateParams.meeting.office365Id;
                }

                this.meetingName = meeting.meetingName;
                this.setBreadcrumb();

                if (meeting.previousMeetingId) {
                    this.previousMeeting = { meetingSeriesId: meeting.meetingSeriesId, id: meeting.previousMeetingId };
                }

                this.startDate = new Date(meeting.startDate);
                this.meetingHasStarted = this.now.getTime() >= this.startDate.getTime();
                this.eventEmitterService.publishEvent('updateAgendaSortOrder', this.meeting.agenda);
            })
            .finally(() => {
                this.show = true;
            });
    }

    updateTree(moveEvent) {
        return this.agendaBuildingService.updateTree(moveEvent, this.meeting.agenda, this.meeting.rootAgenda).then((agendaObj) => {
            return agendaObj;
        });
    }

    _updateSortOrderForNewSiblings(agenda, childCount) {
        childCount = childCount ? childCount : 0;
        let isFirstLevel = _.find(this.meeting.agenda, (a) => a.id === agenda.id);

        if (isFirstLevel) {
            let agendasCopy = angular.copy(this.meeting.agenda);
            _.forEach(agendasCopy, (a, index) => {
                if (a.id !== agenda.id && a.sortOrder == agenda.sortOrder) {
                    if (agenda.previousSortOrder > agenda.sortOrder) {
                        a.sortOrder = a.sortOrder + 0.1;
                    } else {
                        a.sortOrder = a.sortOrder - 0.1;
                    }
                }
            });
            agendasCopy = _.orderBy(agendasCopy, ['sortOrder'], ['asc']);
            _.forEach(agendasCopy, (a, index) => (a.sortOrder = index));
            angular.merge(this.meeting.agenda, agendasCopy);
        } else {
            let agendaParent = this.meeting.rootAgenda.id === agenda.parentId ? this.meeting.rootAgenda : this._findAgenda(agenda.parentId);
            let copy = angular.copy(agendaParent.children);
            _.forEach(copy, (a, index) => (a.sortOrder = index));
            angular.merge(agendaParent.children, copy);
            delete agenda.previousSortOrder;
        }
    }

    _updateSortOrderForOldSiblings(list, sortOrder) {
        if (list) {
            _.forEach(list, (item) => {
                if (item && item.sortOrder > sortOrder) {
                    item.sortOrder--;
                }
            });
        }
    }

    toggleReOrder() {
        this.activateReorder = !this.activateReorder;
    }

    shouldShowReOrderOption() {
        let hasMoreThenOneAgendaItem =
            this.meeting.agenda &&
            this.meeting.agenda.length > 0 &&
            (this.meeting.agenda.length > 1 || (this.meeting.agenda[0].children && this.meeting.agenda[0].children.length > 0));
        return hasMoreThenOneAgendaItem && !this.meeting.protocolIsLocked;
    }

    _findAgenda(agendaId) {
        let agenda = _.find(this.meeting.agenda, (ag) => ag.id === agendaId);

        if (agenda) {
            return agenda;
        } else {
            _.forEach(this.meeting.agenda, (ag) => {
                agenda = _.find(ag.children, (a) => a.id === agendaId);
                if (agenda) {
                    return false;
                }
            });

            return agenda;
        }
    }

    openApplyAgendaTemplateDialog() {
        let options = {};
        this.dialogService.custom('rym-select-agenda-template-dialog', options).then((result) => {
            if (this.meeting.agenda.length) {
                this.dialogService
                    .confirm({
                        id: 'confirm apply template dialog',
                        title: this.translationService.translate('client_ConfirmApplyTemplateTitle'),
                        description: this.translationService.translate('client_ConfirmApplyTemplateDescription'),
                        ok: this.translationService.translate('client_ApplyTemplate')
                    })
                    .then(() => {
                        this._applyAgendaTemplate(result.agendaTemplateId);
                    });
            } else {
                this._applyAgendaTemplate(result.agendaTemplateId);
            }
        });
    }

    _applyAgendaTemplate(agendaTemplateId) {
        this.toastService.loading({ title: this.translationService.translate('client_ApplyingTemplate') });
        this.meetingApiService.applyTemplate(this.meetingId, agendaTemplateId).then(() => {
            this.meetingBuildingService.buildMeeting(this.meetingId).then((meeting) => {
                this.toastService.success({ title: this.translationService.translate('client_TemplateApplied') });
                this.meeting = meeting;
            });
        });
    }

    sendAgenda() {
        if (this.currentUser.isExternal) {
            return this._showIsParticipantDialog();
        }

        let options = {
            id: 'send agenda dialog',
            title: this.translationService.translate('client_SendAgenda'),
            description: this.translationService.translate('client_SendAgendaDescription'),
            ok: this.translationService.translate('send'),
            cancel: this.translationService.translate('cancel'),
            meeting: this.meeting,
            isProtocol: false,
            extendedInfo: true,
            mailMessage: this.agendaMailMessage,
            mailSubject: this.agendaMailSubject
        };

        let showDoneToaster = true;

        this.dialogService
            .custom('rym-send-meeting-dialog', options)
            .then((mailToDto) => {
                mailToDto.recipients = mailToDto.recipients.map((x) => x.email);
                this.toastService.loading({ title: this.translationService.translate('meetings_SendingAgenda'), duration: 3000 });
                this.$timeout(
                    () => {
                        if (showDoneToaster) {
                            this.toastService.success({ title: this.translationService.translate('meetings_InvitationSent') });
                            this.$timeout(
                                () => {
                                    this.userService.shouldRateRYM();
                                },
                                2000,
                                false
                            );
                        }
                    },
                    4000,
                    false
                );
                this.meetingApiService
                    .sendAgenda(this.meeting.id, mailToDto)
                    .then((meeting) => {
                        this.meeting.agendaSent = meeting.agendaSent;
                    })
                    .catch((exception) => {
                        showDoneToaster = false;
                        this.toastService.error({
                            title: this.translationService.translate('meetings_InvitationFailed'),
                            description: exception.data.message
                        });
                    });
            })
            .catch(($event) => {
                this.agendaMailMessage = $event.mailMessage;
                this.agendaMailSubject = $event.mailSubject;
            });
    }

    toggleLockProtocol() {
        if (this.currentUser.isExternal) {
            return this._showIsParticipantDialog();
        }

        this.dialogService
            .confirm({
                id: this.meeting.protocolIsLocked ? 'unlock meeting dialog' : 'lock meeting dialog',
                title: this.meeting.protocolIsLocked
                    ? this.translationService.translate('client_UnlockMeeting')
                    : this.translationService.translate('client_LockMeeting'),
                description: this.meeting.protocolIsLocked
                    ? this.translationService.translate('client_UnlockInfo')
                    : this.translationService.translate('client_LockInfo'),
                ok: this.meeting.protocolIsLocked
                    ? this.translationService.translate('client_Unlock')
                    : this.translationService.translate('client_Lock')
            })
            .then(() => {
                this.meetingApiService.lockProtocol(this.meeting.id, !this.meeting.protocolIsLocked).then((meeting) => {
                    this.meeting.protocolLockedBy = meeting.protocolLockedBy;
                    this.meeting.protocolLockedSince = meeting.protocolLockedSince;
                    this.meeting.protocolIsLocked = !this.meeting.protocolIsLocked;
                    this.meeting.protocolLockedByUser = meeting.protocolLockedByUser;
                    this._updateAuthorizations();
                    this.eventEmitterService.publishEvent('lockMeeting', this.meeting.protocolIsLocked);
                    this.eventEmitterService.publishEvent('notifyDocumentProtocolLock', this.meeting.protocolIsLocked);
                });
            });
    }

    sendProtocol() {
        if (this.currentUser.isExternal) {
            return this._showIsParticipantDialog();
        }

        let options = {
            id: 'send protocol dialog',
            title: this.translationService.translate('meetings_SendProtocol'),
            description: this.translationService.translate('client_SendProtocolDescription'),
            ok: this.translationService.translate('send'),
            cancel: this.translationService.translate('cancel'),
            meeting: this.meeting,
            isProtocol: true,
            mailMessage: this.protocolMailMessage,
            mailSubject: this.protocolMailSubject
        };

        let shouldShowDoneToaster = true;

        this.dialogService
            .custom('rym-send-meeting-dialog', options)
            .then((mailToDto) => {
                mailToDto.recipients = mailToDto.recipients.map((x) => x.email);
                this.toastService.loading({ title: this.translationService.translate('meetings_SendingProtocol'), duration: 3000 });
                this.$timeout(
                    () => {
                        if (shouldShowDoneToaster) {
                            this.toastService.success({ title: this.translationService.translate('meetings_ProtocolSent') });
                            this.$timeout(
                                () => {
                                    this.userService.shouldRateRYM();
                                },
                                2000,
                                false
                            );
                        }
                    },
                    4000,
                    false
                );
                this.meetingApiService
                    .sendProtocol(this.meeting.id, mailToDto)
                    .then((meeting) => {
                        this.meeting.protocolSent = meeting.protocolSent;
                    })
                    .catch((exception) => {
                        shouldShowDoneToaster = false;
                        this.toastService.error({
                            title: this.translationService.translate('meetings_ProtocolFailed'),
                            description: exception.data.message
                        });
                    });
            })
            .catch((changes) => {
                this.protocolMailMessage = changes.mailMessage;
                this.protocolMailSubject = changes.mailSubject;
            });
    }

    showPreviousMeeting() {
        let options = {
            id: 'prevous meeting dialog',
            meeting: this.previousMeeting
        };

        this.dialogService.custom('rym-meeting-preview-dialog', options);
    }

    newMeeting() {
        if (this.templateBuildingMode) {
            this.meetingCreationService.showCreateMeetingDialog(null, this.meeting);
        } else {
            this.meetingCreationService.showCreateMeetingDialog(this.meeting, null);
        }
    }

    _meetingCreated(meeting) {
        this.$state.go('meeting', { id: meeting.id });
        this.paymentService.showTrialInformation(SKIP_TRIAL_EXCEEDED).then((hasShown) => {
            if (!hasShown) {
                this.toastService.hide();
            }
        });
    }

    _getTrialExceeded() {
        let deferred = this.$q.defer();
        this.organizationService.trialExceeded().then((isExceeded) => {
            deferred.resolve(isExceeded);
        });

        return deferred.promise;
    }

    _showIsParticipantDialog() {
        return this.paymentService.showIsParticipant();
    }
}

MeetingController.$inject = [
    '$q',
    '$stateParams',
    '$interval',
    '$scope',
    '$state',
    '$rootScope',
    '$timeout',
    '$filter',
    'meetingApiService',
    'breadcrumbsService',
    'navigationService',
    'responsive',
    'anchorScrollService',
    'dialogService',
    'meetingMetadataService',
    'toastService',
    'menuService',
    'userService',
    'eventEmitterService',
    'meetingBuildingService',
    'organizationService',
    'paymentService',
    'exportService',
    'translationService',
    'signalRService',
    'stringService',
    'meetingCreationService',
    'participantsService',
    'agendaBuildingService'
];
export default MeetingController;
