import './common/common.module';
import './api/api.module';
import 'angular-ui-router';

angular
    .module('rym.routes', ['ui.router', 'rym.common', 'rym.api'])
    .provider('routerHelper', ['$locationProvider', '$stateProvider', '$urlRouterProvider', routerHelperProvider]);

function routerHelperProvider($locationProvider, $stateProvider, $urlRouterProvider) {
    /* jshint validthis:true */
    this.$get = RouterHelper;
    $locationProvider.html5Mode(true);

    function RouterHelper($state) {
        var hasOtherwise = false;
        var service = {
            configureStates: configureStates,
            getStates: getStates
        };

        return service;

        function configureStates(states, otherwisePath) {
            states.forEach((state) => {
                $stateProvider.state(state.state, state.config);
            });

            if (otherwisePath && !hasOtherwise) {
                hasOtherwise = true;
                $urlRouterProvider.otherwise(otherwisePath);
            }
        }

        function getStates() {
            return $state.get();
        }
    }

    RouterHelper.$inject = ['$q', '$state', 'featureToggleService', 'organizationService', 'authService'];
}
