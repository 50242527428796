class AnimationService {
    constructor() {
        this.duration = 200;
    }

    animate(func, selector) {
        this.selector = selector;
        this.elements = this._getElements();
        this._first();
        func();

        requestAnimationFrame(() => {
            this.elements = this._getElements();
            this._last();
            this._diff();
            this._revert();
            this._play();
            setTimeout(() => {
                this._reset();
            }, this.duration);
        });
    }

    _getElements() {
        return [].slice.call(document.querySelectorAll(this.selector || '*'));
    }

    _first() {
        this.elements.forEach((element) => {
            element.first = {
                top: element.offsetTop,
                left: element.offsetLeft,
                height: element.offsetHeight,
                width: element.offsetWidth
            };

            element.oldCssText = element.style.cssText;
        });
    }

    _last() {
        this.elements.forEach((element) => {
            element.last = {
                top: element.offsetTop,
                left: element.offsetLeft
            };
        });
    }

    _diff() {
        this.elements.forEach((element) => {
            if (!element.first) {
                element.diff = {
                    top: 0,
                    left: 0,
                    opacity: 1
                };
            } else if (!element.last) {
                element.diff = {
                    top: 0,
                    left: 0,
                    opacity: -1
                };
            } else {
                element.diff = {
                    top: element.last.top - element.first.top,
                    left: element.last.left - element.first.left,
                    opacity: 0
                };
            }
        });
    }

    _revert() {
        this.elements.forEach((element) => {
            element.style.transform = `translate(${-element.diff.left}px, ${-element.diff.top}px)`;
            if (element.diff.opacity !== 0) {
                element.style.opacity = element.diff.opacity === 1 ? 0 : 1;
            }
        });
    }

    _play() {
        requestAnimationFrame(() => {
            this.elements.forEach((element) => {
                element.style.transition = `${this.duration}ms`;
                element.style.transform = `translate(0px, 0px)`;
                if (element.diff.opacity !== 0) {
                    element.style.opacity = element.diff.opacity === 1 ? 1 : 0;
                }
            });
        });
    }

    _reset() {
        this.elements.forEach((element) => {
            element.style.cssText = element.oldCssText;
        });
    }
}

AnimationService.$inject = [];
export default AnimationService;
