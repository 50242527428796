import controller from './tutorialVideoDialog.controller';
import template from './tutorialVideoDialog.html';

let tutorialVideoDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default tutorialVideoDialogComponent;
