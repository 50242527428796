import template from './userImage.html';
import controller from './userImage.controller';

let component = {
    template,
    controller,
    bindings: {
        username: '<',
        name: '<',
        isExternal: '<',
        initials: '<'
    }
};

export default component;
