import template from './actionDetail.html';
import controller from './actionDetail.controller';
import './actionDetail.less';

let actionDetailComponent = {
    template,
    controller
};

export default actionDetailComponent;
