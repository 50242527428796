class decisionController {
    constructor($timeout, meetingApiService, stringService, menuService, keyCode, domService, translationService) {
        this.$timeout = $timeout;
        this.meetingApiService = meetingApiService;
        this.stringService = stringService;
        this.menuService = menuService;
        this.keyCode = keyCode;
        this.domService = domService;
        this.translationService = translationService;
        this.hasAddedNewRow = false;
    }

    $onInit() {
        this.oldText = this.decision.text;
    }

    remove() {
        return this.onDelete({ decision: this.decision, oldText: this.oldText });
    }

    updateText() {
        if (this.decision.text) {
            this.oldText = this.decision.text;
        }

        if (!this.decision.id) {
            return this.create();
        }

        return this.meetingApiService.putDecisionText(this.meetingId, this.decision.id, this.decision.text).then(this.onUpdate());
    }

    onKeyup(value, event) {
        if (
            value &&
            this.stringService.removeSpaces(value).length > 0 &&
            this.stringService.isUndefinedOrEmpty(this.decision.text) &&
            !this.hasAddedNewRow
        ) {
            if (!this.decision.id) {
                this.decision.text = value;
                this.hasAddedNewRow = true;
                return this.onCreate();
            }
        }
    }

    onPaste(value, event) {
        if (value && this.stringService.removeSpaces(value).length > 0 && !this.hasAddedNewRow) {
            if (!this.decision.id) {
                this.decision.text = value;
                this.hasAddedNewRow = true;
                return this.onCreate();
            }
        }
    }

    create() {
        return this.onCreate({ text: this.decision.text, isInline: true }).then((object) => {
            this.decision.meetingId = object.meetingId;
            this.decision.id = object.decision.id;
            this.onUpdate();
        });
    }

    showDecisionMenu($event) {
        if ($event) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }
        let menuButton = $event.currentTarget;
        let options = {
            items: [
                {
                    id: 'menu option - delete decision',
                    icon: 'delete_outline',
                    text: this.translationService.translate('client_Delete'),
                    onClick: () => this.remove()
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element(menuButton), false);
    }

    isNew() {
        if (angular.isUndefined(this.decision.id) && this.stringService.isUndefinedOrEmpty(this.decision.text)) {
            return true;
        }

        return false;
    }

    handleFocusEvent($event) {
        this.onFocus($event);
    }

    handleBlurEvent($event) {
        if (this.stringService.isUndefinedOrEmpty(this.decision.text)) {
            this.hasAddedNewRow = false;
            return this.remove();
        }

        this.onBlur($event);
    }

    handleKeyDown(event) {
        if (this.decision.text && event.keyCode === this.keyCode.ENTER) {
            let nextDecision = this.domService.findNextTabStop(event.target);
            this.$timeout(() => {
                nextDecision.focus();
            });
        }
    }
}

decisionController.$inject = ['$timeout', 'meetingApiService', 'stringService', 'menuService', 'keyCode', 'domService', 'translationService'];
export default decisionController;
