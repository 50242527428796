const VOIDNODETAGS = [
    'AREA',
    'BASE',
    'BR',
    'COL',
    'EMBED',
    'HR',
    'IMG',
    'INPUT',
    'KEYGEN',
    'LINK',
    'MENUITEM',
    'META',
    'PARAM',
    'SOURCE',
    'TRACK',
    'WBR',
    'BASEFONT',
    'BGSOUND',
    'FRAME',
    'ISINDEX'
];

class domService {
    constructor(stringService) {
        this.stringService = stringService;
    }

    scrollParent(element) {
        if (!element && !element.parent) {
            return;
        }

        try {
            let regex = /(auto|scroll)/;
            let parent = element.parent();
            let style;
            try {
                style = getComputedStyle(parent[0], null);
            } catch (exception) {
                return null;
            }
            if (!style) {
                return null;
            }
            let overflowValue = style.overflow;

            if (regex.test(overflowValue) || parent[0].className.includes('rym-scroll-content')) {
                return parent;
            }

            return this.scrollParent(parent);
        } catch (error) {
            return null;
        }
    }

    closestWithAttribute(element, attribute) {
        if (!(element && typeof element.getAttribute === 'function')) {
            return;
        }

        try {
            let attributeValue = element.getAttribute(attribute);
            if (attributeValue) {
                return element;
            } else {
                let parent = element.parentNode;
                if (parent !== document.body) {
                    return this.closestWithAttribute(parent, attribute);
                }
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    findNextTabStop(el) {
        try {
            let universe = document.querySelectorAll('div, input, button');
            let list = Array.prototype.filter.call(universe, (item) => {
                return item.tabIndex >= '0' && !item.disabled;
            });
            let index = list.indexOf(el);
            return list[index + 1] || list[0];
        } catch (error) {
            return null;
        }
    }

    pasteAtCaret(e, removeLineBreaks) {
        if (!e) {
            return;
        }
        try {
            e.preventDefault();
            var text;
            var clp = (e.originalEvent || e).clipboardData;
            if (clp === undefined || clp === null) {
                text = window.clipboardData.getData('text') || '';
                if (text !== '') {
                    text = text.replace(/<[^>]*>/g, '');
                    if (removeLineBreaks) {
                        text = this.stringService.removeLineBreaks(text);
                    }
                    if (window.getSelection) {
                        var newNode = document.createElement('span');
                        newNode.innerHTML = text;
                        window.getSelection().getRangeAt(0).insertNode(newNode);
                    } else {
                        document.selection.createRange().pasteHTML(text);
                    }
                }
            } else {
                text = clp.getData('text/plain') || '';
                if (text !== '') {
                    text = text.replace(/<[^>]*>/g, '');
                    if (removeLineBreaks) {
                        text = this.stringService.removeLineBreaks(text);
                    }
                    document.execCommand('insertText', false, text);
                }
            }
        } catch (error) {}
    }

    getElementFromEvent(event) {
        if (!event) {
            return;
        }

        if (!!event.currentTarget) {
            return event.currentTarget;
        } else if (!!event.target) {
            return event.target;
        } else if (!!event.srcElement) {
            return event.srcElement;
        }
    }

    focus(el) {
        if (!el && !el.tagName && el.selectionStart) {
            return;
        }

        try {
            if (el.tagName === 'INPUT' && _.isNumber(el.selectionStart)) {
                el.focus();
                el.selectionStart = el.selectionEnd = el.value.length;
            } else if (el.isContentEditable) {
                this._placeCaretAtEndForContentEditable(el);
            } else {
                el.focus();
            }
        } catch (error) {}
    }

    _placeCaretAtEndForContentEditable(element) {
        try {
            let range = document.createRange(),
                selection = window.getSelection();

            let canContainText = (node) => {
                if (node.nodeType === 1) {
                    return !VOIDNODETAGS.includes(node.nodeName);
                } else {
                    return false;
                }
            };

            let getLastChild = (el) => {
                let lc = el.lastChild;
                while (lc && lc.nodeType != 1) {
                    if (lc.previousSibling) lc = lc.previousSibling;
                    else break;
                }
                return lc;
            };

            while (getLastChild(element) && canContainText(getLastChild(element))) {
                element = getLastChild(element);
            }

            range.selectNodeContents(element);
            range.collapse(false);

            selection.removeAllRanges();
            selection.addRange(range);
        } catch (error) {}
    }

    getTargetText(element) {
        if (!element && !element.innerText) {
            return;
        }

        try {
            let targetText = element.innerText;
            if (!targetText) {
                let input = angular.element(element).find('input')[0];
                targetText = input ? input.value : '';
            }

            return targetText;
        } catch (error) {
            return null;
        }
    }

    getFromAncestors(element, callback) {
        let values = [];

        try {
            while (element !== document.body) {
                let value = callback(element);
                if (value) {
                    values.unshift(value);
                }
                element = element.parentNode;
            }
        } catch (error) {}

        return values;
    }

    getSelector(element) {
        if (!element) {
            return;
        }

        try {
            return this.getFromAncestors(element, this._getIdentifier).join('>').toLowerCase();
        } catch (error) {
            return null;
        }
    }

    _getIdentifier(element) {
        if (!(element && typeof element.getAttribute === 'function')) {
            return;
        }

        try {
            let id = element.getAttribute('id');
            let selector = id ? `#${id}` : '';

            if (!selector) {
                selector = element.tagName;
            }

            let i = 1;
            while ((element = element.previousElementSibling)) i++;
            return i ? `${selector}:nth-child(${i})` : selector;
        } catch (error) {
            return;
        }
    }
}

domService.$inject = ['stringService'];
export default domService;
