import BaseApiService from './baseApi.service';
const resource = 'categories';

class CategoryApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            `${resource}/:id`
        );
    }

    getAll() {
        return super.GET(resource);
    }

    create(category) {
        this.appcuesService.track('Category Created');
        return super.POST(resource, category);
    }

    update(category) {
        return super.PUT(resource, category);
    }

    remove(id) {
        return super.DELETE(resource, id);
    }

    toggleFavorite(id) {
        return super.PUT(`categories/${id}/favorite`, id);
    }

    clearRelatedCaches() {
        super.removeSpecificResourceCache('meetings');
        super.removeSpecificResourceCache('actions');
    }
}

CategoryApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default CategoryApiService;
