import template from './changeUserDialog.html';
import controller from './changeUserDialog.controller';

let changeUserDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default changeUserDialogComponent;
