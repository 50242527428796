class selectMultipleUsersDialogController {
    constructor($scope, translationService, responsive) {
        Object.assign(this, { $scope, translationService, responsive });
        this.selected = [];
    }

    $onInit() {
        this.state = 'all';

        this.suggestedUsers = angular.copy(this.options.suggestedUsers);

        this.responsive.on(['xs'], this.$scope, () => {
            this.showSelectedUsers = false;
            this.showUserList = true;
        });

        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$scope, () => {
            this.showSelectedUsers = true;
            this.showUserList = true;
        });

        if (this.options.selectedUsers) {
            this.selected = angular.copy(this.options.selectedUsers);
        }

        this._updateValid();
    }

    select(user) {
        this.selected.push(user);
        this._updateValid();
    }

    unselect(user) {
        _.remove(this.selected, (selectedUser) => {
            return selectedUser.email === user.email;
        });
        this.selected = angular.copy(this.selected);
        this._updateValid();
    }

    handleChangeTab($event) {
        this.showSelectedUsers = $event.value === 'selected';
        this.showUserList = $event.value === 'all';
    }

    createTemporaryUser(term) {
        this.select({
            name: term,
            email: term,
            username: term,
            isExternal: true
        });
    }

    _updateValid() {
        this.valid = !!this.selected.length;
    }
}

selectMultipleUsersDialogController.$inject = ['$scope', 'translationService', 'responsive'];

export default selectMultipleUsersDialogController;
