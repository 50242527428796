import { TIME } from '../../app.constants';

class InlineEditController {
    constructor($window, $document, $element, $timeout, $sce, $rootScope, $scope, $sanitize, stringService, selectionService, keyCode, domService) {
        Object.assign(this, {
            $window,
            $document,
            $element,
            $timeout,
            $sce,
            $rootScope,
            $scope,
            $sanitize,
            stringService,
            selectionService,
            keyCode,
            domService
        });
        this.isLoading = false;
        this.oldValue = null;
        this.unsavedChanges = false;
    }

    $doCheck() {
        const rightMouseButton = 3;
        if (this.contenteditableElement && this.contenteditableElement[0] && !this.initialized) {
            this.contenteditableElement.on('mousedown', (f) => {
                if (f.which === rightMouseButton) {
                    this.contextMenuOpen = true;

                    this.$timeout(
                        () => {
                            this._bindCancel();
                        },
                        200,
                        false
                    );
                }
            });

            this.initialized = true;
        }
    }

    $onInit() {
        this.initTextType();

        this.contenteditableElement = angular.element(this.$element.children()[0]);
        this.ngModel.$render = this._render.bind(this);
        if (angular.isUndefined(this.useAutoSave)) {
            this.useAutoSave = true;
        }

        if (this.activateAutofocus) {
            this.$timeout(
                () => {
                    if (!this.ngModel.$viewValue) {
                        this.$element[0].focus();
                    } else {
                        this.domService.focus(this.$element[0]);
                    }
                },
                0,
                false
            );
        }

        this._updatePreventNextFocus = () => {
            if (this.$document[0].activeElement === this._getEditElement()[0]) {
                this.preventNextFocus = true;
            }
        };

        window.addEventListener('blur', this._updatePreventNextFocus);
    }

    $onDestroy() {
        this._cancelTimeout();
        window.removeEventListener('blur', this._updatePreventNextFocus);
    }

    _bindCancel() {
        this.$timeout(
            () => {
                let list = (event) => {
                    if (this.contextMenuOpen) {
                        this.$scope.$applyAsync(() => {
                            this.handleKeyupEvent(event);
                            this.contextMenuOpen = false;
                        });
                        this.$document.off('mousemove', list);
                        this.$document.off('mousedown', clickOutside);
                    }
                };

                let clickOutside = (event) => {
                    if (event.target) {
                        this.contextMenuOpen = false;
                        this.handleKeyupEvent(event);
                    }
                    this.$document.off('mousedown', clickOutside);
                    this.$document.off('mousemove', list);
                };

                this.$document.on('mousedown', clickOutside);
                this.$document.on('mousemove', list);
            },
            200,
            false
        );
    }

    _render() {
        let encodedValue = this.stringService.encodeHtml(this.ngModel.$viewValue || '');
        let linkifiedValue = this.stringService.linkify(encodedValue);
        let IEFix = this.stringService.replaceIELineBreak(linkifiedValue);
        let html = this.stringService.replaceLineBreaksWithBreakTags(IEFix);

        this.contenteditableElement.html(this.$sanitize(html));
    }

    _read() {
        let innerText = this.stringService.innerText(this.contenteditableElement[0]).trim();
        this.ngModel.$setViewValue(innerText);
    }

    _cancelTimeout() {
        if (this.removeFocus) {
            this.$timeout.cancel(this.removeFocus);
        }

        if (this.autoSave) {
            this.$timeout.cancel(this.autoSave);
        }
    }

    handleKeyupEvent(event) {
        this.onKeyup({ value: this._getEditElement().html(), event: event });
        this._read();

        this._cancelTimeout();

        if (event && event.keyCode !== this.keyCode.TAB) {
            if (this.oldValue === this.ngModel.$viewValue && this.unsavedChanges) {
                this.unsavedChanges = false;
                this.broadcastChanges('RevertedChanges');
            } else if (this.oldValue !== this.ngModel.$viewValue && !this.unsavedChanges) {
                this.broadcastChanges('UnsavedChanges');
                this.unsavedChanges = true;
            }

            this.removeFocus = this.$timeout(
                () => {
                    this._getEditElement()[0].blur();
                    this.$timeout.cancel(this.autoSave);
                },
                TIME.REMOVE_FOCUS,
                false
            );

            if (this.useAutoSave) {
                this.autoSave = this.$timeout(
                    () => {
                        this._handleSave(true);
                    },
                    TIME.AUTO_SAVE,
                    false
                );
            }
        }
    }

    handlePasteEvent($event) {
        $event.preventDefault();
        this.domService.pasteAtCaret($event, !this.allowLineBreaks);
        this._read();

        if (this.onPaste) {
            this.onPaste({ value: this._getEditElement().html(), event: $event });
        }
    }

    handleClickEvent($event) {
        const anchor = 'A';

        if ($event.target && $event.target.tagName === anchor) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            let url = $event.target.attributes.href.value;
            window.open(url, '_blank');
        }
    }

    handleFocusEvent($event) {
        if (this.preventNextFocus) {
            this._getEditElement()[0].blur();
            this.preventNextFocus = false;
        }

        if (this.contextMenuOpen) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        } else {
            if (this.removeFocus) {
                this.$timeout.cancel(this.removeFocus);
            }

            if (!this.failedToSave) {
                this.oldValue = this.ngModel.$viewValue ? this.ngModel.$viewValue : '';
            }
            this.onFocus();

            this.removeFocus = this.$timeout(
                () => {
                    this._getEditElement()[0].blur();
                },
                TIME.REMOVE_FOCUS,
                false
            );
        }
    }

    handleBlurEvent($event) {
        this._cancelTimeout();
        this.$scope.$evalAsync(() => {
            if (this.contextMenuOpen) {
                $event.preventDefault();
                $event.stopImmediatePropagation();
            } else {
                this._handleSave();
                this._removeFocus();
                this._render();

                if (this.truncate) {
                    this._getEditElement()[0].scrollLeft = 0;
                }

                this.onBlur();
            }
        });
    }

    handleKeydownEvent(event) {
        if (this._isInvalidKey(event)) {
            event.preventDefault();
            return;
        }
    }

    _getEditElement() {
        if (this.editElement) {
            return this.editElement;
        }
        return (this.editElement = angular.element(this.$element.children()[0]));
    }

    _handleSave(preventProgressLine) {
        if (this.ngModel.$viewValue !== null && this.oldValue !== this.ngModel.$viewValue && !this.isLoading) {
            let promise = this.save();
            if (promise && promise.then) {
                if (!preventProgressLine) {
                    this.isLoading = true;
                }
                promise
                    .then(
                        () => {
                            this.unsavedChanges = false;
                            this.broadcastChanges('SavedChanges');
                        },
                        () => {
                            this.failedToSave = true;
                        }
                    )
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        }
    }

    // Workaround for webkit's bug
    _removeFocus() {
        if (navigator.userAgent.match(/webkit/i)) {
            window.getSelection().removeAllRanges();
        }
    }

    _isInvalidKey(event) {
        return (
            (!this.allowLineBreaks && event.keyCode === this.keyCode.ENTER) ||
            (!this.allowLineBreaks && event.keyCode === this.keyCode.RETURN) ||
            (event.ctrlKey && event.keyCode === this.keyCode.B) ||
            (event.ctrlKey && event.keyCode === this.keyCode.I) ||
            (event.ctrlKey && event.keyCode === this.keyCode.U)
        );
    }

    broadcastChanges(string) {
        if (this.notifyChange) {
            this.$rootScope.$broadcast(string);
        }
    }

    initTextType() {
        if (this.textType && this.textType.length) {
            this.textClass = `text(${this.textType})`;
        }
    }
}

InlineEditController.$inject = [
    '$window',
    '$document',
    '$element',
    '$timeout',
    '$sce',
    '$rootScope',
    '$scope',
    '$sanitize',
    'stringService',
    'selectionService',
    'keyCode',
    'domService'
];

export default InlineEditController;
