import categoriesComponent from './categories.component';
import categoriesRoutes from './categories.routes';
import categoryDetailComponent from './categoryDetail/categoryDetail.component';
import categoryDetailService from './categoryDetail/categoryDetail.service';

angular
    .module('rym.categories', [])
    .component('rymCategories', categoriesComponent)
    .component('rymCategoryDetail', categoryDetailComponent)
    .service('categoryDetailService', categoryDetailService)
    .run(categoriesRoutes);
