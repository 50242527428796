import template from './profilePicture.html';
import controller from './profilePicture.controller';

let iconButtonComponent = {
    template,
    controller,
    bindings: {
        username: '<',
        name: '<',
        isExternal: '<',
        initials: '<',
        isSmall: '<'
    }
};

export default iconButtonComponent;
