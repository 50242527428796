import template from './wizard.html';
import controller from './wizard.controller';
import './wizard.less';

let wizardComponent = {
    template,
    controller,
    transclude: {
        header: '?rymWizardHeader',
        content: '?rymWizardContent',
        footer: '?rymWizardFooter'
    },
    bindings: {
        onCancel: '&',
        onOk: '&',
        options: '='
    }
};

export default wizardComponent;
