import { LANGUAGES, NUMBER_OF_FREE_LICENSES, PAYMENT_SCHEDULE, FEES, FEES_WITH_DISCOUNT, COUNTRIES } from '../app.constants';

const INJECT = [
    '$q',
    '$scope',
    '$interval',
    '$timeout',
    '$filter',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'breadcrumbsService',
    'organizationService',
    'anchorScrollService',
    'userService',
    'toastService',
    'subscriptionApiService',
    'subscriptionService'
];

class SubscriptionController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this.breadcrumbsService.setLabel('subscription', this.translationService.translate('client_Subscription'));
        this._init();
    }

    _init() {
        this.loading = true;
        this.proceedToNextStep = false;
        this.isLoading = false;
        this.showCardForm = false;
        this.showContactForm = false;
        this.requiresCard = false;
        this.subscriptionService.initializeStripe().then(() => {
            let promises = [
                this.userService.getCurrentUser(),
                this.organizationService.getCurrentOrganization(),
                this.organizationService.getCurrentPlan(),
                this.organizationService.getMeetingsRemaining()
            ];

            this.$q
                .all(promises)
                .then((result) => {
                    this._user = result[0];
                    this._organization = result[1];
                    this._plan = result[2];
                    this.daysLeftInTrial = result[3];
                    this._setValues(this._organization, this._plan, this._user);
                    this._update();
                })
                .finally(() => {
                    if (this.isPayingOrganization) {
                        if (!this.isUsingInvoice) {
                            this.subscriptionApiService
                                .getCustomer()
                                .then((res) => {
                                    this._setCustomerData(res);
                                })
                                .finally(() => {
                                    if (this.requiresCard) {
                                        this.toastService.error({
                                            title: this.translationService.translate('client_PleaseEnterValidCard'),
                                            duration: 10000
                                        });
                                    }
                                    this.loading = false;
                                });
                        } else {
                            this.loading = false;
                        }
                        if (this.requiresAction) {
                            this.toastService.error({
                                title: this.translationService.translate('client_ChargeFailedTryAgain'),
                                duration: 10000
                            });
                        }
                    } else if (!this.isPayingOrganization) {
                        this.loading = false;
                    }
                });
        });
    }

    showContactUs() {
        this.contactService.support();
    }

    setNumberOfLicenses($event) {
        this.numberOfLicenses = $event.value;
        this._update();
    }

    setPaymentScheduleOptions($event) {
        this.isAnually = $event.options.filter((x) => x.isSelected)[0].value === PAYMENT_SCHEDULE.ANNUALLY;
        this._update();
    }

    _updatePaymentSchedule() {
        this.paymentScheduleOptions = [
            {
                value: PAYMENT_SCHEDULE.ANNUALLY,
                name: this.translationService.translate('client_Annually'),
                description: this.translationService.translate('client_AnnuallyDescription'),
                attentionText: this.translationService.translate('client_SaveXPercent', { percent: 17 }),
                isSelected: this.isAnually,
                isDisabled: this.isFormDisabled()
            },
            {
                value: PAYMENT_SCHEDULE.MONTHLY,
                name: this.translationService.translate('client_Monthly'),
                description: this.translationService.translate('client_MonthlyDescription'),
                attentionText: '',
                isSelected: !this.isAnually,
                isDisabled: (this.isPayingOrganization && this.customerPaymentInterval === 'year') || this.isFormDisabled()
            }
        ];
    }

    _updateActiveSubscriptionSummary() {
        this.activeSubscriptionSummary = [
            {
                title: this.translationService.translate('client_Licenses'),
                text: this.customerLicenses,
                testHook: 'NumberOfLicenses'
            },
            {
                title: this.translationService.translate('client_PaymentSchedule'),
                text: this.isAnually ? this.translationService.translate('client_Annually') : this.translationService.translate('client_Monthly'),
                testHook: 'PaymentSchedule'
            },
            {
                title: this.customerIsCanceled
                    ? this.translationService.translate('client_SubscriptionCanceled')
                    : this.translationService.translate('client_Total'),
                text: this.customerIsCanceled ? '' : this.nextPaymentAmountText,
                attention: this.customerIsCanceled,
                testHook: 'Total'
            }
        ];
    }

    _updateContactAndBillingSummary() {
        this.contactAndBillingSummary = [
            {
                title: this.translationService.translate('emailAddress'),
                text: this.customerEmail,
                testHook: 'Email'
            },
            {
                title: this.translationService.translate('client_OrganizationName'),
                text: this.customerName,
                testHook: 'OrganizationName'
            },
            {
                title: this.translationService.translate('administration_Address'),
                text: this.customerAddress,
                hide: this.customerAddress === '',
                testHook: 'Address'
            },
            {
                title: this.translationService.translate('country'),
                text: this.customerCountry.country,
                hide: !this.customerCountry.hasOwnProperty('country'),
                testHook: 'Country'
            }
        ];
    }

    _updateCardDetailsSummary() {
        const creditCardImages = [
            'https://stratsysmeetingscdn.azureedge.net/images/visa.png',
            'https://stratsysmeetingscdn.azureedge.net/images/mastercard.png',
            'https://stratsysmeetingscdn.azureedge.net/images/amex.png'
        ];
        this.cardDetailsSummary = [
            {
                text: `●●●● ●●●● ●●●● ${this.customerCreditCard}`,
                imageSrc: creditCardImages.find((x) => x.includes(this.customerCreditCardBrand)),
                icon: 'credit-card',
                testHook: 'CardNumber'
            },
            {
                title: this.requiresCard
                    ? this.translationService.translate('client_PleaseUpdateCard')
                    : this.translationService.translate('client_ExpiresWithoutDate'),
                text: this.customerCreditCardExpDate,
                attention: this.requiresCard || this.cardAboutToExpire,
                testHook: 'ExpirationDate'
            },
            {
                title: this.requiresAction
                    ? this.translationService.translate('client_ChargeFailed')
                    : this.translationService.translate('client_YourNextInvoiceIsOn'),
                text: this.requiresAction ? '' : this.customerNextInvoice,
                attention: this.requiresAction || this.customerIsCanceled
            }
        ];
        if (this.customerIsCanceled) {
            this.cardDetailsSummary[2].title = this.translationService.translate('client_SubscriptionWillEndOn');
        }
    }

    _updatePricingPlanSummary() {
        this.pricingPlanSummary = [
            {
                title: this.translationService.translate('client_Licenses'),
                text: this.numberOfLicenses,
                testHook: 'NumberOfLicenses'
            },
            {
                title: this.translationService.translate('client_PaymentSchedule'),
                text: this.isAnually ? this.translationService.translate('client_Annually') : this.translationService.translate('client_Monthly'),
                testHook: 'PaymentSchedule'
            },
            {
                title: this.translationService.translate('client_Discount'),
                text: this.subscriptionService.getDiscountText(
                    this.currency,
                    this.isAnnually,
                    this.numberOfLicenses,
                    this.isAnually ? 'year' : 'month'
                ),
                lineThrough: !this.isAnnually,
                testHook: 'Discount'
            },
            {
                title: this.translationService.translate('client_Total'),
                text: this.subscriptionService.getPaymentText(this.currency, this.isAnnually ? 'year' : 'month', this.newPaymentAmount),
                testHook: 'Total'
            }
        ];
    }

    _updateDisablePrimaryButton() {
        this.disablePrimaryButton = !this.hasChanged && !this.isInTrial;
    }

    updateSubscription() {
        if (this.isPayingOrganization) {
            this.isLoading = true;
            let licenseChange = this.numberOfLicenses - this.customerLicenses;
            this.updatedSubscription = {
                licenses: this.numberOfLicenses,
                paymentInterval: this.isAnnually ? 'year' : 'month',
                currency: this.customerCurrency
            };
            let options = {
                id: 'change subscription dialog',
                cancel: this.translationService.translate('client_Cancel'),
                currentPrice: this.customerNextInvoicePrice,
                currentLicenses: this.customerLicenses,
                currentInterval: this.customerPaymentInterval,
                upgradedPrice: this.newPaymentAmount,
                upgradedLicenses: this.numberOfLicenses
            };
            if (licenseChange > 0) {
                this.subscriptionService
                    .getInvoicePreview(this.updatedSubscription, options)
                    .then((res) => {
                        res.isCompanyFromSweden = this.customerCountry.countryShort === 'SE';

                        this.dialogService.custom('rym-subscription-upgrade-dialog', res).then(() => {
                            this.toastService.loading({ title: this.translationService.translate('administration_UpdatingPaymentPlan') });
                            this.isLoading = true;
                            this.subscriptionService
                                .updateSubscription(this.updatedSubscription)
                                .then(() => {
                                    this.$timeout(() => {
                                        this.refresh();
                                        this.toastService.success({
                                            title: this.translationService.translate('client_PaymentPlanUpdated')
                                        });
                                    }, 5000);
                                })
                                .catch((response) => {
                                    this._setRetryData(response);
                                });
                        });
                    })
                    .finally(() => (this.isLoading = false));
            } else {
                this.isLoading = false;
                options.title = this.translationService.translate('client_UpgradeSubscription');
                options.ok = this.translationService.translate('client_Confirm');
                options.currency = this.currency.toLowerCase();
                options.interval = this.isAnnually ? 'year' : 'month';

                this.dialogService.custom('rym-subscription-upgrade-dialog', options).then(() => {
                    this.toastService.loading({ title: this.translationService.translate('administration_UpdatingPaymentPlan') });
                    this.isLoading = true;
                    this.subscriptionService.updateSubscription(this.updatedSubscription).then(() => {
                        this.$timeout(() => {
                            this.refresh();
                            this.toastService.success({
                                title: this.translationService.translate('client_PaymentPlanUpdated')
                            });
                        }, 5000);
                    });
                });
            }
        } else {
            this.proceedToNextStep = !this.proceedToNextStep;
        }
    }

    updateCardDetails() {
        this.toastService.loading({ title: this.translationService.translate('client_UpdatingCreditCard') });
        this.isLoading = true;
        this.clearCardForm = false;
        if (this.requiresAction) {
            this.subscriptionService
                .retryInvoiceWithNewPaymentMethod(this.latestInvoiceId, this.formData)
                .then(() => {
                    this.$timeout(() => {
                        this.refresh();
                        this.toastService.success({
                            title: this.translationService.translate('client_CreditCardUpdated')
                        });
                    }, 5000);
                })
                .catch((res) => {
                    this._setRetryData(res);
                });
        } else {
            this.subscriptionService.updateCardDetails(this.formData).then(() => {
                this.refresh();
                this.toastService.success({
                    title: this.translationService.translate('client_CreditCardUpdated')
                });
            });
        }
    }

    toggleSubscription() {
        if (!this.customerIsCanceled) {
            let options = {
                id: 'cancel subscription',
                title: this.translationService.translate('client_CancelSubscription'),
                description: this.translationService.translate('client_AreYouSureYouWantToCancel', { date: this.customerNextInvoice }),
                ok: this.translationService.translate('client_Confirm')
            };

            this.dialogService.confirm(options).then(() => {
                this.isLoading = true;
                this.toastService.loading({
                    title: this.translationService.translate('client_CancelingSubscription')
                });
                this.subscriptionApiService.cancelSubscription().then(() => {
                    this.refresh();
                    this.toastService.success({
                        title: this.translationService.translate('client_SubscriptionCanceled')
                    });
                });
            });
        } else {
            this.isLoading = true;
            this.toastService.loading({
                title: this.translationService.translate('client_ResumingSubscription')
            });
            this.subscriptionService
                .updateSubscription({
                    licenses: this.numberOfLicenses,
                    paymentInterval: this.isAnnually ? 'year' : 'month',
                    currency: this.customerCurrency
                })
                .then(() => {
                    this.refresh();
                    this.toastService.success({
                        title: this.translationService.translate('client_PaymentPlanUpdated')
                    });
                });
        }
    }

    updateContactAndBillingDetails() {
        this.isLoading = true;
        this.subscriptionService.updateContactAndBillingDetails(this.contactFormData).then(() => {
            this.refresh();
            this.toastService.success({
                title: this.translationService.translate('client_ContactAndBillingDetailsUpdated')
            });
        });
    }

    _setRetryData(res) {
        this.requiresAction = true;
        this.clearCardForm = true;
        this.isLoading = false;
        this.toastService.error({
            title: this.translationService.translate('client_CardUpdateFailed'),
            duration: 10000
        });
        this.latestInvoiceId = res.latestInvoiceId;
        this._updateCardDetailsSummary();
    }

    refresh() {
        this.organizationService.clearAllCache();
        this.subscriptionService.clearCache();
        this._init();
    }

    _update() {
        this._updatePaymentSchedule();
        this._updateIsAnnually();
        this._updateHasChanged();
        this._updatePrimaryButtonText();
        this._updateDisablePrimaryButton();
        this._calculateNextPaymentAmount();
        this._updateSubscriptionInfo();
        this._updatePricingPlanSummary();
        this._updateRequiresAction();
    }

    reset() {
        this._setValues(this._organization, this._plan, this._user);
        this._update();
    }

    _setCustomerData(customerData) {
        let values = {
            customerEmail: customerData.email,
            customerName: customerData.name,
            customerAddress: customerData.address ? customerData.address : '',
            customerPostalCode: customerData.postalCode ? customerData.postalCode : '',
            customerCity: customerData.city ? customerData.city : '',
            customerCountry: customerData.country ? COUNTRIES.filter((x) => x.countryShort === customerData.country)[0] : {},
            customerVatNumber: customerData.taxId,
            customerLicenses: customerData.nextInvoice ? customerData.nextInvoice.numberOfLicenses : customerData.latestInvoice.numberOfLicenses,
            customerCurrency: customerData.nextInvoice ? customerData.nextInvoice.currency : customerData.latestInvoice.currency,
            customerPaymentInterval: customerData.nextInvoice ? customerData.nextInvoice.interval : customerData.latestInvoice.interval,
            customerNextInvoice: customerData.nextInvoice
                ? this.$filter('date')(customerData.nextInvoice.paymentAttempt, 'rymDate')
                : this.$filter('date')(customerData.cancelAt, 'rymDate'),
            customerNextInvoicePrice: customerData.nextInvoice ? customerData.nextInvoice.totalPrice : customerData.latestInvoice.totalPrice,
            customerCreditCard: customerData.defaultCreditCard ? customerData.defaultCreditCard.lastFour : '',
            customerCreditCardBrand: customerData.defaultCreditCard ? customerData.defaultCreditCard.brand : null,
            customerCreditCardExpDate: customerData.defaultCreditCard
                ? `${customerData.defaultCreditCard.expYear % 100}/${
                      customerData.defaultCreditCard.expMonth < 10
                          ? '0' + customerData.defaultCreditCard.expMonth
                          : customerData.defaultCreditCard.expMonth
                  }`
                : '',
            customerCreditCardExpDateAsDateString: customerData.defaultCreditCard
                ? new Date(`${customerData.defaultCreditCard.expYear}-${customerData.defaultCreditCard.expMonth}`)
                : new Date(),
            customerIsCanceled: customerData.subscriptionIsCanceled
        };
        this.latestInvoiceId = customerData.latestInvoice.id;
        Object.assign(this, { ...values });
        this._updateCardExpDateAttention();
        this._updateRequiresCard();
        this._updateNextPaymentAmountText();
        this._updateActiveSubscriptionSummary();
        this._updateContactAndBillingSummary();
        this._updateCardDetailsSummary();
        this._update();
        this._setSubscriptionRequiresAction();
    }

    _updateCardExpDateAttention() {
        this.cardAboutToExpire =
            this.customerCreditCardExpDateAsDateString.getMonth() === new Date().getMonth() &&
            this.customerCreditCardExpDateAsDateString.getFullYear() === new Date().getFullYear();
    }

    _setSubscriptionRequiresAction() {
        this.subscriptionService.setSubscriptionRequiresAction();
    }

    _setValues(organization, plan, user) {
        let values = this._getValues(organization, plan, user);
        Object.assign(this, { ...values });
    }

    _getValues(organization, plan, user) {
        let numberOfLicenses;
        if (!organization.paymentPlan.includes('free')) {
            numberOfLicenses = organization.paymentPlanValue;
        } else {
            numberOfLicenses = Math.max(plan.numberOfUsersInOrganization, NUMBER_OF_FREE_LICENSES);
        }

        let currency = 'USD';
        if (organization.paymentPlan.includes('sek') || (organization.paymentPlan.includes('free') && user.languageId === LANGUAGES.SWEDISH)) {
            currency = 'SEK';
        }

        return {
            currency: currency,
            isAnually: !organization.paymentPlan.includes('month'),
            numberOfLicenses: numberOfLicenses,
            daysLeftInTrial: this.daysLeftInTrial,
            isInTrial: organization.paymentPlan.includes('free30Days'),
            paymentStatus: organization.paymentStatus,
            isPayingOrganization: !organization.paymentPlan.includes('free'),
            isUsingInvoice: plan.usingInvoice
        };
    }

    _updateHasChanged() {
        let values = this._getValues(this._organization, this._plan, this._user);
        this.hasChanged =
            values.currency !== this.currency || values.isAnually !== this.isAnually || values.numberOfLicenses !== this.numberOfLicenses;
    }

    _updateIsAnnually() {
        this.isAnnually = this.paymentScheduleOptions.filter((x) => x.isSelected)[0].name === this.translationService.translate('client_Annually');
    }

    _updatePrimaryButtonText() {
        if (this.isInTrial) {
            this.primaryButtonText = this.translationService.translate('client_Proceed');
        } else if (this.numberOfLicenses < this._organization.paymentPlanValue) {
            this.primaryButtonText = this.translationService.translate('administration_Downgrade');
        } else {
            this.primaryButtonText = this.translationService.translate('client_Summary');
        }
    }

    _updateRequiresAction() {
        this.requiresAction = this.paymentStatus === 'failed';
    }

    _updateRequiresCard() {
        if (!this.customerCreditCard) {
            this.requiresCard = true;
        }
    }

    _calculateNextPaymentAmount() {
        let fees = FEES;

        if (this.isAnnually) {
            fees = FEES_WITH_DISCOUNT;
        }

        this._fees = this.currency === 'USD' ? fees.USD : fees.SEK;

        if (this.isAnnually) {
            this.newPaymentAmount = 12 * ((this.numberOfLicenses - NUMBER_OF_FREE_LICENSES) * this._fees.MONTHLY_LICENSE_FEE + this._fees.START_FEE);
        } else {
            this.newPaymentAmount = (this.numberOfLicenses - NUMBER_OF_FREE_LICENSES) * this._fees.MONTHLY_LICENSE_FEE + this._fees.START_FEE;
        }
    }

    _updateNextPaymentAmountText() {
        const priceFormat = this.customerCurrency === 'usd' ? 'client_PriceInUsd' : 'client_PriceInSek';
        this.nextPaymentAmountText = this.translationService.translate(priceFormat, {
            price: this.customerNextInvoicePrice
        });
    }

    _updateSubscriptionInfo() {
        this.subscriptionInfo = {
            currency: this.currency,
            licenses: this.numberOfLicenses,
            interval: this.isAnnually ? 'year' : 'month',
            name: this._organization.name,
            email: this._user.email,
            totalCost: this.newPaymentAmount
        };
    }

    showCurrencyMenu($event) {
        let options = {
            items: [
                {
                    id: 'menu option - currancy usd',
                    icon: 'language',
                    isDefault: this.currency === 'USD',
                    text: 'USD',
                    onClick: () => {
                        this.currency = 'USD';
                        this._update();
                    }
                },
                {
                    id: 'menu option - currancy sek',
                    icon: 'language',
                    isDefault: this.currency === 'SEK',
                    text: 'SEK',
                    onClick: () => {
                        this.currency = 'SEK';
                        this._update();
                    }
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element($event.currentTarget), false);
    }

    showUpdateCardForm() {
        this.showCardForm = !this.showCardForm;
    }

    showUpdateContactForm() {
        this.contactFormData = {
            email: this.customerEmail,
            name: this.customerName,
            address: this.customerAddress,
            postalCode: this.customerPostalCode,
            city: this.customerCity,
            country: this.customerCountry,
            vat: this.customerVatNumber
        };
        this.showContactForm = !this.showContactForm;
    }

    isFormDisabled() {
        return this.requiresAction || this.requiresCard || this.customerIsCanceled || this.isUsingInvoice;
    }
}

SubscriptionController.$inject = INJECT;

export default SubscriptionController;
