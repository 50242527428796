import template from './slider.html';
import controller from './slider.controller';

let component = {
    template,
    controller,
    bindings: {
        value: '<',
        maxValue: '<',
        minValue: '<',
        onChange: '&'
    }
};

export default component;
