import meetingComponent from './meeting.component';
import meetingDetailsComponent from './components/meetingDetails/meetingDetails.component';
import agendaComponent from './components/agenda/agenda.component';
import decisionComponent from './components/agenda/decision/decision.component';
import documentComponent from './components/agenda/document/document.component';
import documentViewService from './components/agenda/document/documentView.service';
import documentDialogComponent from './components/agenda/documentDialog/documentDialog.component';
import agendaActionItemComponent from './components/agenda/actionItem/agendaActionItem.component';
import agendaNavComponent from './components/agenda/agendaNav/agendaNav.component';
import changeUserDialogComponent from './components/changeUserDialog/changeUserDialog.component';
import sendMeetingDialogComponent from './components/sendMeetingDialog/sendMeetingDialog.component';
import roleSettingsDialogComponent from './components/roleSettingsDialog/roleSettingsDialog.component';
import exportToCalendarDialogComponent from './components/exportToCalendarDialog/exportToCalendarDialog.component';
import selectAgendaTemplateDialogComponent from './components/selectAgendaTemplateDialog/selectAgendaTemplateDialog.component';
import meetingRoute from './meeting.routes';

angular
    .module('rym.meeting', [])
    .component('rymMeeting', meetingComponent)
    .component('rymMeetingDetails', meetingDetailsComponent)
    .component('rymAgenda', agendaComponent)
    .component('rymDecision', decisionComponent)
    .component('rymDocument', documentComponent)
    .component('rymDocumentDialog', documentDialogComponent)
    .component('rymAgendaActionItem', agendaActionItemComponent)
    .component('rymAgendaNav', agendaNavComponent)
    .component('rymChangeUserDialog', changeUserDialogComponent)
    .component('rymSendMeetingDialog', sendMeetingDialogComponent)
    .component('rymRoleSettingsDialog', roleSettingsDialogComponent)
    .component('rymExportToCalendarDialog', exportToCalendarDialogComponent)
    .component('rymSelectAgendaTemplateDialog', selectAgendaTemplateDialogComponent)
    .service('documentViewService', documentViewService)
    .run(meetingRoute);
