import service from './dialog.service';
import dialogComponent from './dialog.component';
import fullScreenDialogComponent from './fullScreenDialog/fullScreenDialog.component';
import promptDialogComponent from './promptDialog/promptDialog.component';
import makeUserDialogComponent from './makeUserDialog/makeUserDialog.component';
import selectDialogComponent from './selectDialog/selectDialog.component';
import selectMultipleUsersDialogComponent from './selectMultipleUsersDialog/selectMultipleUsersDialog.component';
import textDialogComponent from './textDialog/textDialog.component';
import imageDialog from './imageDialog/imageDialog.component';
import './dialog.less';

angular
    .module('rym.dialog', [])
    .service('dialogService', service)
    .component('rymDialog', dialogComponent)
    .component('rymFullScreenDialog', fullScreenDialogComponent)
    .component('rymPromptDialog', promptDialogComponent)
    .component('rymTextDialog', textDialogComponent)
    .component('rymMakeUserDialog', makeUserDialogComponent)
    .component('rymSelectMultipleUsersDialog', selectMultipleUsersDialogComponent)
    .component('rymImageDialog', imageDialog)
    .component('rymSelectDialog', selectDialogComponent);
