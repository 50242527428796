const INJECT = [
    '$rootScope',
    '$state',
    '$timeout',
    'categoryApiService',
    'animationService',
    'eventEmitterService',
    'actionService',
    'signalRService',
    'userService',
    'categoryService'
];

class categoryFavoritIconController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
        this.isStatusLoading = false;
    }

    $onInit() {
        this.$rootScope.$on('toggle', (event) => {
            this.toggleStatus();
        });
    }

    toggleStatus(event) {
        if (event) {
            this._stopOtherEvents(event);
        }

        if (this.isStatusLoading) {
            return;
        }
        this.isStatusLoading = true;

        this.categoryService
            .toggleFavorite(this.category)
            .then(() => {
                this.isStatusLoading = false;
            })
            .catch((error) => {
                this.isStatusLoading = false;
            });
    }

    _stopOtherEvents(event) {
        // To prevent details view to open.
        event.preventDefault();
        event.stopPropagation();
    }
}

categoryFavoritIconController.$inject = INJECT;
export default categoryFavoritIconController;
