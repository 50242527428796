class OfflineDialogController {
    constructor($scope, $timeout, translationService) {
        Object.assign(this, { $scope, $timeout, translationService });
        this.options = { title: this.translationService.translate('client_NoInternetAccess') };
        this.remainingTime = 3;
    }

    $onInit() {
        Offline.on('up', () => {
            this.onOk();
        });

        Offline.on('reconnect:tick', () => {
            var ref, time, unit;
            (ref = this._roundTime(Offline.reconnect.remaining)), (time = ref[0]), (unit = ref[1]);
            this.remainingTime = time;
            if (time > 1) {
                this.timeUnit = this.translationService.translate(`client_${unit}s`).toLowerCase();
            } else {
                this.timeUnit = this.translationService.translate(`client_${unit}`).toLowerCase();
            }

            this.$scope.$applyAsync();
        });

        Offline.on('reconnect:connecting', () => {
            this.connecting = true;
            this.$scope.$applyAsync();
        });

        Offline.on('reconnect:failure', () => {
            this.$timeout(() => {
                this.connecting = false;
            }, 2000);
        });

        Offline.on('reconnect:success', () => {
            this.connecting = false;
            this.onOk();
        });
    }

    tryConnect() {
        Offline.reconnect.tryNow();
    }

    _roundTime(sec) {
        let mult, unit, units, val;
        units = {
            Day: 86400,
            Hour: 3600,
            Minute: 60,
            Second: 1
        };
        for (unit in units) {
            mult = units[unit];
            if (sec >= mult) {
                val = Math.floor(sec / mult);
                return [val, unit];
            }
        }
        return ['now', ''];
    }
}

OfflineDialogController.$inject = ['$scope', '$timeout', 'translationService'];

export default OfflineDialogController;
