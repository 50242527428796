import BaseApiService from './baseApi.service';

class OrganizationApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'organizations'
        );
        this.$cacheFactory = $cacheFactory;
    }

    currentPlan() {
        return super.GET(`organizations/currentplan`);
    }

    current() {
        return super.GET(`organizations/current`);
    }

    getMeetingsRemainingInTrialOrganization() {
        return super.GET('organizations/trial/remaining');
    }

    getAdministrators() {
        return super.GET('organizations/admin');
    }

    get() {
        return super.GET('organizations');
    }

    getLogo() {
        return super.GET('organizations/logo');
    }

    deleteLogo(id) {
        return super.DELETE(`organizations/logo`, id);
    }

    numberOfUsers(id) {
        return super.GET(`organizations/${id}/numberofusers`);
    }

    clearCache(key) {
        return super.removeCache(key);
    }

    dropCacheForAllApiServices() {
        return super.removeCacheObject();
    }

    getDomain() {
        return super.GET('organizations/domain');
    }
}

OrganizationApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default OrganizationApiService;
