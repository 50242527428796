import template from './meetingSeriesMeetings.html';
import controller from './meetingSeriesMeetings.controller';
import './meetingSeriesMeetings.less';

let meetingSeriesMeetingsComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingSeriesDialog'
    }
};

export default meetingSeriesMeetingsComponent;
