import template from './cardList.html';
import controller from './cardList.controller';

let component = {
    template,
    controller,
    bindings: {
        cardListData: '<',
        cardGroupModelTransformer: '<',
        cardListModelTransformer: '<',
        cardModelTransformer: '<',
        hidePlaceholders: '<',
        enableInfiniteScroll: '<',
        enableRefresh: '<',
        returnButtonText: '<',
        noDataTitle: '<',
        noDataDescription: '<',
        onClickOnCard: '&',
        onClickOnMore: '&',
        onScrollToTop: '&',
        onScrollToBottom: '&',
        onClickOnRefresh: '&'
    }
};

export default component;
