class DialogService {
    constructor($document, $compile, $rootScope, $q, $timeout, $rootElement, translationService) {
        this.$rootScope = $rootScope;
        this.$compile = $compile;
        this.$document = $document;
        this.$q = $q;
        this.$timeout = $timeout;
        this.$rootElement = $rootElement;
        this.translationService = translationService;
        this.dialogScopes = [];
    }

    alert(options) {
        return this._show(
            'rym-dialog',
            angular.extend(
                {
                    id: options.id || 'alert dialog',
                    title: '',
                    ok: this.translationService.translate('client_Close')
                },
                options || {}
            )
        );
    }

    prompt(options) {
        return this._show(
            'rym-prompt-dialog',
            angular.extend(
                {
                    id: options.id || 'prompt dialog',
                    title: this.translationService.translate('client_Add'),
                    ok: this.translationService.translate('client_Done'),
                    cancel: this.translationService.translate('client_Cancel')
                },
                options || {}
            )
        );
    }

    confirm(options) {
        return this._show(
            'rym-dialog',
            angular.extend(
                {
                    id: options.id || 'confirm dialog',
                    title: this.translationService.translate('client_DeleteConfirm'),
                    ok: this.translationService.translate('client_Delete'),
                    cancel: this.translationService.translate('client_Cancel')
                },
                options || {}
            )
        );
    }

    select(options) {
        return this._show(
            'rym-select-dialog',
            angular.extend(
                {
                    id: options.id || 'select dialog',
                    title: this.translationService.translate('client_Add'),
                    ok: this.translationService.translate('client_Add'),
                    cancel: this.translationService.translate('client_Cancel')
                },
                options || {}
            )
        );
    }

    makeUser(options) {
        return this._show(
            'rym-make-user-dialog',
            angular.extend(
                {
                    id: options.id || 'upgrade to organizer dialog',
                    title: this.translationService.translate('client_UpgradeToOrganizer'),
                    ok: this.translationService.translate('client_Ok'),
                    cancel: this.translationService.translate('client_Cancel')
                },
                options || {}
            )
        );
    }

    custom(component, options, element) {
        return this._show(component, options, element);
    }

    _show(component, options, element) {
        let deferred = this.$q.defer();
        const zIndex = _.get(options, ['zIndex']);
        let dialogClass = options.ok ? 'rym-dialog' : '';
        let template =
            '<' +
            component +
            ' ng-if="show" options="options" class="animation(fade-in) animation(fade-out) z(22) position(fixed) width(max) height(max)" on-ok="ok(data)" on-cancel="cancel($event)" hide="hide()" open="open()" />';
        let scope = this.$rootScope.$new();

        scope.options = angular.extend(
            {
                title: '',
                description: '',
                ok: '',
                cancel: ''
            },
            options
        );

        this.open = true;

        scope.ok = (data) => {
            this._findIndexAndSpliceScope(scope);
            scope.show = false;
            this.open = false;
            deferred.resolve(data);
        };

        scope.cancel = ($event) => {
            this._findIndexAndSpliceScope(scope);
            scope.show = false;
            this.open = false;
            deferred.reject($event);
        };

        scope.hide = () => {
            scope.isHidden = true;
        };

        scope.open = () => {
            scope.isHidden = false;
        };

        let dialog = this.$compile(template)(scope);
        if (element) {
            this.$document.find(element).append(dialog);
        } else {
            this.$rootElement[0].appendChild(dialog[0]);
        }

        this.dialogScopes.push(scope);

        this.$timeout(() => {
            scope.show = true;
        }, 50);

        return deferred.promise;
    }

    isDialogOpen() {
        return this.dialogScopes.length > 0;
    }

    _findIndexAndSpliceScope(scope) {
        let index = _.findIndex(this.dialogScopes, (sc) => sc.$id === scope.$id);

        if (index !== -1) {
            this.dialogScopes.splice(index, 1);
        }
    }

    closeAllDialogs() {
        _.forEach(this.dialogScopes, (scope) => {
            this.$timeout(() => {
                scope.cancel();
            }, 100);
        });
    }
}

DialogService.$inject = ['$document', '$compile', '$rootScope', '$q', '$timeout', '$rootElement', 'translationService'];

export default DialogService;
