class TabController {
    constructor() {}

    $onInit() {
        this.tabsController.register(this);
    }

    select() {
        this.isSelected = true;
    }

    unselect() {
        this.isSelected = false;
    }

    onSelect() {
        this.tabsController.select(this);
    }
}

TabController.$inject = [];

export default TabController;
