import { agendaActionTemplate } from './agendaActionItem.component';

class ActionItemController {
    constructor(
        $interval,
        $state,
        $document,
        $timeout,
        $rootScope,
        $q,
        $element,
        $scope,
        $filter,
        dateService,
        stringService,
        menuService,
        meetingApiService,
        keyCode,
        rowService,
        recompileService,
        actionApiService,
        eventEmitterService,
        actionService,
        translationService,
        actionDetailService,
        signalRService,
        userApiService,
        dialogService,
        userService
    ) {
        Object.assign(this, {
            $interval,
            $state,
            $document,
            $timeout,
            $rootScope,
            $q,
            $element,
            $scope,
            $filter,
            dateService,
            stringService,
            menuService,
            meetingApiService,
            keyCode,
            rowService,
            recompileService,
            actionApiService,
            eventEmitterService,
            actionService,
            translationService,
            actionDetailService,
            signalRService,
            userApiService,
            dialogService,
            userService
        });
        this.template = agendaActionTemplate;
    }

    $doCheck() {
        if (!this.action.id && this.action.name && !this.creating) {
            if (!this.hasCreatedNewRow) {
                this.hasCreatedNewRow = true;
                this.onNew({ add: true });
            }

            if (this.$element[0].contains(this.$document[0].activeElement) && !this.polling && !this.dialogOpen) {
                this._startPoll();
            }
        }

        if (!this.action.id && !this.action.name && this.hasCreatedNewRow) {
            this.hasCreatedNewRow = false;
            this.onNew({ add: false });
            this._cancelPoll();
        }

        if (this.action.id && !this.hasBoundEventEmitter) {
            this.hasBoundEventEmitter = true;
            this.eventEmitterService.subscribe(this.action.id, this.updateAction.bind(this));
        }
    }

    _startPoll() {
        this.polling = true;
        this.saveCheck = this.$interval(
            () => {
                this.$scope.$evalAsync(() => {
                    if (!this.$element[0].contains(this.$document[0].activeElement) && !this.menuOpen && !this.creating) {
                        this.create();
                        this.$interval.cancel(this.saveCheck);
                    }
                });
            },
            200,
            0,
            false
        );
    }

    _cancelPoll() {
        this.polling = false;
        this.$interval.cancel(this.saveCheck);
    }

    $onInit() {
        this.buildRows();
        this.oldActionName = this.action.name;
        this.eventEmitterService.subscribe('agendaActionChanged' + this.action.id, this._externalChangeOfAction.bind(this));
    }

    _externalChangeOfAction(action) {
        if (!angular.equals(action, this.action)) {
            angular.extend(this.action, action);
            if (action.isDeleted) {
                this.remove();
            } else {
                this.updateAction({ action: this.action, source: 'external' });
            }
        }
    }

    focus() {
        this.$element.find('rym-inline-edit').children()[0].focus();
    }

    updateAction(event) {
        this.action = angular.extend(this.action, event.action);
        this.buildRows();
        this.actionService.notifyActionUpdate();
        if (event.source !== 'actionDetails') {
            this.eventEmitterService.publishEvent('updateActionDetail', this.action);
        }
        this.recompileService.recompile(this.$scope, this.$element.children(), this.template);
    }

    updateComment() {
        return this.actionApiService.putComment(this.action.id, this.action.comment).then(() => {
            this.eventEmitterService.publishEvent('updateActionDetail', this.action);
            if (!this.action.comment) {
                this.recompileService.recompile(this.$scope, this.$element.children(), this.template);
            }
            this._notifyActionUpdate();
            this._notifyPossibleCopies();
        });
    }

    buildRows() {
        this.dateRow = {
            icon: 'update',
            title: null,
            description: this.action.endDate
                ? this.$filter('date')(this.action.endDate, 'rymDate')
                : this.translationService.translate('client_Deadline'),
            cssClass: this._getCssClassForDateRow()
        };

        this.responsibleRow = {
            user: {
                email: this.action.responsibleEmail,
                isExternal: this.action.responsibleIsExternal,
                name: this.action.responsible
            },
            title: null,
            description: this.action.responsible ? this.action.responsible : this.translationService.translate('responsibleUser')
        };
    }

    _getCssClassForDateRow() {
        return this.action.endDate && !this.action.isFinished && new Date(this.action.endDate) < new Date() ? 'attention(on)' : '';
    }

    detail() {
        this.actionDetailService.open(this.action, 'meeting');
    }

    showMenu($event) {
        this.translationService.translateBatch(['client_More', 'client_WriteComment', 'client_Duplicate', 'client_Delete']).then((translations) => {
            if (this.action.id) {
                let menuButton = $event.currentTarget;
                let options = {
                    items: [
                        {
                            id: 'menu option - comment action',
                            icon: 'comment',
                            text: translations.client_WriteComment,
                            onClick: () => this.comment()
                        },
                        {
                            id: 'menu option - duplicate action',
                            icon: 'content_copy',
                            text: translations.client_Duplicate,
                            onClick: () => this.onDuplicate()
                        }
                    ]
                };

                if (!this.hideMore) {
                    options.items.push({
                        id: 'menu option - show action details',
                        icon: 'info',
                        text: translations.client_More,
                        onClick: () => this.detail()
                    });
                }

                options.items.push({
                    id: 'menu option - delete action',
                    icon: 'delete_outline',
                    text: translations.client_Delete,
                    onClick: () => this.remove()
                });

                this.menuService.actionMenu(options, angular.element(menuButton), false);
            }
        });
    }

    commentBlur() {
        if (!this.action.comment) {
            this.recompileService.recompile(this.$scope, this.$element.children(), this.template);
        }
    }

    comment() {
        this.canWriteComment = true;
        this.recompileService.recompile(this.$scope, this.$element.children(), this.template);
        this.$timeout(
            () => {
                this.canWriteComment = false;
            },
            50,
            false
        );
    }

    _setActiveElement($event) {
        this.activeElement = $event.currentTarget ? $event.currentTarget : $event.target.parentNode;
    }

    showDateMenu($event) {
        if (this.dateMenuOpen || this.isDisabled) {
            return;
        }
        this._setActiveElement($event);
        this.menuOpen = true;
        this.dateMenuOpen = true;
        $event.preventDefault();
        $event.stopImmediatePropagation();
        let options = {
            date: this.action.endDate ? new Date(this.action.endDate) : new Date(),
            allowTabs: true,
            onClose: () => {
                this.notifyMenuClosed();
                this.$timeout(
                    () => {
                        this.dateMenuOpen = false;
                    },
                    100,
                    false
                );
            }
        };
        this.menuService.dateMenu(options, angular.element(this.activeElement), false, false).then((data) => {
            if (this.action.id) {
                this.rowService.playLoadingAnimation(this.activeElement);
                this.actionApiService.updateEndDate(this.action.id, data).then((action) => {
                    this.action.endDate = action.endDate;
                    this.dateRow.description = this.$filter('date')(this.action.endDate, 'rymDate');
                    this.dateRow.cssClass = this._getCssClassForDateRow();
                    this.recompile(null, true);
                    this.eventEmitterService.publishEvent('updateActionDetail', this.action);
                    this._notifyActionUpdate();
                    this._notifyPossibleCopies();
                });
            } else {
                this.updateEndDate(data);
                this.recompile(null);
            }
        });
    }

    notifyMenuClosed() {
        this.menuOpen = false;
    }

    showResponsibleMenu($event) {
        if (this.responsibleMenuOpen || this.isDisabled) {
            return;
        }
        this.menuOpen = true;
        this.responsibleMenuOpen = true;
        this._setActiveElement($event);
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.userService.getCurrentUser().then((user) => {
            this.meetingApiService.getParticipantsForMeeting(this.meetingId).then((result) => {
                let suggestedUsers = this.$filter('filter')(result, { isDeleted: false });
                let options = {
                    disableSearch: user.isExternal,
                    selectedUsers: [],
                    suggestedUsers: suggestedUsers,
                    onClick: (result) => {
                        this.updateResponsible(result.user);
                    },
                    onMultiple: () => {
                        this._cancelPoll();
                        this.dialogOpen = true;

                        let responsible = {
                            id: this.action.responsibleId,
                            email: this.action.responsibleEmail,
                            name: this.action.responsible,
                            isExternal: this.action.responsibleIsExternal
                        };

                        this.meetingApiService.getParticipantsForMeeting(this.meetingId).then((result) => {
                            let options = {
                                id: 'select responsible dialog',
                                title: this.translationService.translate('client_SelectResponsible'),
                                selectedUsers: this.action.responsibleEmail && this.action.responsibleEmail.length ? [responsible] : null,
                                suggestedUsers: result,
                                ok: this.translationService.translate('client_Select'),
                                cancel: this.translationService.translate('client_Cancel'),
                                disableSearch: user.isExternal
                            };

                            this.dialogService
                                .custom('rym-select-multiple-users-dialog', options)
                                .then((data) => {
                                    this.createMultiple(data);
                                    this.dialogOpen = false;
                                })
                                .catch(() => {
                                    this.rowService.focusElement(this.activeElement);
                                    if (!this.action.id) {
                                        this._startPoll();
                                    }
                                });
                        });
                    },
                    onInvite: (email) => {
                        this.updateResponsible({ email: email, userName: email, isExternal: true });
                    },
                    onClose: () => {
                        this.notifyMenuClosed();
                        this.$timeout(() => {
                            this.responsibleMenuOpen = false;
                        }, 500);
                    }
                };

                this.menuService.userMenu(options, angular.element(this.activeElement), false);
            });
        });
    }

    createMultiple(users) {
        let first = users.pop();
        this.updateResponsible(first);
        if (!this.action.id) {
            this.creating = true;
            this.isLoading = true;
            this.onCreate({ action: this.action, isInline: true });
        }

        let action = angular.copy(this.action);

        this.onCreateMultiple({ action: action, users: users }).finally(() => {
            this.isLoading = false;
            this.polling = false;
        });
    }

    recompile(customElement, shouldPlayDone) {
        this.$scope.$evalAsync(() => {
            let newEl = this.recompileService.recompile(this.$scope, this.activeElement, customElement);
            if (newEl) {
                if (shouldPlayDone) {
                    this.rowService.playDoneAnimation(newEl);
                } else {
                    this.rowService.focusElement(newEl);
                }
            }
        });
    }

    updateEndDate(date) {
        this.action.endDate = date;
        this.dateService.convertEndDateToString(this.action);
        this.dateRow.title = null;
        this.dateRow.cssClass = this._getCssClassForDateRow();
        this.dateRow.description = this.$filter('date')(this.action.endDate, 'rymDate');
        this._notifyActionUpdate();
    }

    updateResponsible(user) {
        const customEl =
            '<rym-row class="width(max)" row="::$ctrl.responsibleRow" ng-click="::$ctrl.showResponsibleMenu($event)"><rym-row-icon><rym-user-image class="lock-size(on) left(0-2) right(0-4) small" ng-if="::$ctrl.action.responsible" username="::$ctrl.responsibleRow.user.email" is-external="::$ctrl.responsibleRow.user.isExternal" name="::$ctrl.responsibleRow.user.name"></rym-user-image><i ng-if="::!$ctrl.action.responsible" class="material-icons">person_add</i></rym-row-icon></rym-row>';

        if (this.action.responsibleId && this.action.responsibleId === user.id) {
            return;
        }

        this.action.responsibleEmail = user.email;
        this.action.responsible = user.name ? user.name : user.email;
        this.action.responsibleId = user.id;
        this.action.responsibleIsExternal = user.isExternal;

        //NOTE: user can either be a participant or a userSuggestion.
        //if its a participant or a suggestion where user is from rym then we dont need to add the user to the db
        if ((!user.provider && !this.action.id) || (user.provider == 'rym' && !this.action.id)) {
            this.buildRows();
            this.recompile(customEl, false);
        } else if (this.action.id) {
            this.rowService.playLoadingAnimation(this.activeElement);
            this.actionApiService.putResponsible(this.action.id, user).then((action) => {
                angular.extend(this.action, action);
                this.buildRows();
                this.recompile(customEl, true);
                this.eventEmitterService.publishEvent('updateActionDetail', this.action);
                this._notifyActionUpdate();
                this._notifyPossibleCopies();
            });
        } else {
            this.rowService.playLoadingAnimation(this.activeElement);
            this.userApiService
                .addUsers([user])
                .then((newUser) => {
                    this.action.responsibleEmail = newUser[0].email;
                    this.action.responsible = newUser[0].name;
                    this.action.responsibleId = newUser[0].id;
                    this.action.responsibleIsExternal = newUser[0].isExternal;
                })
                .finally(() => {
                    this.buildRows();
                    this.recompile(customEl, true);
                });
        }
    }

    updateActionName() {
        if (!this.action.name) {
            return (this.action.name = this.oldActionName);
        } else if (this.action.id) {
            return this.actionApiService.putName(this.action.id, this.action.name).then(() => {
                this.eventEmitterService.publishEvent('updateActionDetail', this.action);
                this.oldActionName = this.action.name;
                this._notifyActionUpdate();
                this._notifyPossibleCopies();
            });
        }
    }

    create() {
        if (this.stringService.isUndefinedOrEmpty(this.action.name) || this.isLoading || this.creating) {
            return;
        }
        this.creating = true;
        this.isLoading = true;
        return this.onCreate({ action: this.action, isInline: true })
            .then((object) => {
                this.meetingId = object.meetingId;
                angular.extend(this.action, object.action);
                this.oldActionName = this.action.name;
                this._notifyActionUpdate();
                this.eventEmitterService.subscribe('agendaActionChanged' + this.action.id, this._externalChangeOfAction.bind(this));
            })
            .finally(() => {
                this.isLoading = false;
                this.polling = false;
            });
    }

    remove() {
        return this.onDelete({ action: this.action });
    }

    _notifyActionUpdate() {
        this.actionService.notifyActionUpdate();
        this.signalRService.updateAction(this.action);
    }

    _notifyPossibleCopies() {
        this.eventEmitterService.publishEvent('agendaActionChanged' + this.action.id, this.action);
    }
}

ActionItemController.$inject = [
    '$interval',
    '$state',
    '$document',
    '$timeout',
    '$rootScope',
    '$q',
    '$element',
    '$scope',
    '$filter',
    'dateService',
    'stringService',
    'menuService',
    'meetingApiService',
    'keyCode',
    'rowService',
    'recompileService',
    'actionApiService',
    'eventEmitterService',
    'actionService',
    'translationService',
    'actionDetailService',
    'signalRService',
    'userApiService',
    'dialogService',
    'userService'
];

export default ActionItemController;
