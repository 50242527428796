import template from './fullScreenDialog.html';
import controller from './fullScreenDialog.controller';

let fullScreenDialogComponent = {
    template,
    controller,
    transclude: {
        header: '?rymFullScreenDialogHeader',
        subHeader: '?rymFullScreenDialogSubHeader',
        content: '?rymFullScreenDialogContent'
    },
    bindings: {
        headline: '@',
        options: '=',
        onOk: '&',
        onSubmit: '&',
        onCancel: '&',
        dialogId: '@'
    }
};

export default fullScreenDialogComponent;
