class ExtensionService {
    constructor() {}
}

ExtensionService.$inject = [];
export default ExtensionService;

//loadash extension
_.mixin({
    containsEmail: function (array, email) {
        return _.some(array, (u) => u.email.toLocaleLowerCase() === email.toLocaleLowerCase());
    }
});
