class OrganizationService {
    constructor(
        $q,
        $rootScope,
        $window,
        $timeout,
        organizationApiService,
        eventEmitterService,
        paymentPlanService,
        authService,
        toastService,
        translationService
    ) {
        Object.assign(this, {
            $q,
            $rootScope,
            $window,
            $timeout,
            organizationApiService,
            eventEmitterService,
            paymentPlanService,
            authService,
            toastService,
            translationService
        });
        this.current = {};
        this.eventEmitterService.subscribe('updateUsersInPlan', this.updateUsersInCurrentPlan.bind(this));
        this.eventEmitterService.subscribe('trialExceeded', this.trialHasBeenExceeded.bind(this));
        this.eventEmitterService.subscribe('updatePaymentPlan', this.updatePaymentPlan.bind(this));
    }

    getCurrentOrganization() {
        if (this.current.organization) {
            return this.$q.resolve(this.current.organization);
        } else {
            return this.setCurrentOrganization();
        }
    }

    setCurrentOrganization() {
        return this.organizationApiService.current().then(
            (organization) => {
                this.current.organization = organization;
                return organization;
            },
            (err) => {
                return this.$q.reject(err);
            }
        );
    }

    getDomain() {
        return this.organizationApiService.getDomain();
    }

    trialHasBeenExceeded() {
        this.current.trialStatus = 0;
        this.organizationApiService.clearCache('currentplan');
        this.organizationApiService.clearCache('trial/remaining');
    }

    updatePaymentPlan() {
        this.current = {};
        this.$q.all([this.getCurrentOrganization(), this.getCurrentPlan()]).then((results) => {});
    }

    getCurrentPlan() {
        if (this.current.currentPlan) {
            return this.$q.resolve(this.current.currentPlan);
        } else {
            return this.setCurrentPlan();
        }
    }

    getIsTrial() {
        return this.getCurrentPlan().then((plan) => {
            return this.paymentPlanService.isFreePlan(plan.id);
        });
    }

    getMeetingsRemaining() {
        this.organizationApiService.clearCache('trial/remaining');
        return this.organizationApiService.getMeetingsRemainingInTrialOrganization();
    }

    setCurrentPlan() {
        this.organizationApiService.clearCache('currentplan');
        return this.organizationApiService.currentPlan().then(
            (result) => {
                this.current.currentPlan = result;
                return result;
            },
            (err) => {
                return this.$q.reject(err);
            }
        );
    }

    canAddUser() {
        return this.getCurrentPlan().then((result) => {
            return result.numberOfUsersInOrganization < result.userLimit;
        });
    }

    trialExceeded() {
        return this.getIsTrial().then((isTrial) => {
            if (isTrial) {
                return this.getMeetingsRemaining().then((status) => status <= 0);
            } else {
                return isTrial;
            }
        });
    }

    administrators() {
        return this.organizationApiService.getAdministrators();
    }

    updateUsersInCurrentPlan(isDelete) {
        this.getCurrentPlan().then((plan) => {
            let users = this.current.currentPlan.numberOfUsersInOrganization;
            this.current.currentPlan.numberOfUsersInOrganization = isDelete ? users - 1 : users + 1;
        });
    }

    isLoggedInThroughStratsys() {
        return this.eventEmitterService.publishEvent('isStratsysIdp').then((isStratsys) => isStratsys);
    }

    clearAllCache() {
        this.current = {};
        return this.organizationApiService.dropCacheForAllApiServices();
    }
}

OrganizationService.$inject = [
    '$q',
    '$rootScope',
    '$window',
    '$timeout',
    'organizationApiService',
    'eventEmitterService',
    'paymentPlanService',
    'authService',
    'toastService',
    'translationService'
];

export default OrganizationService;
