class windowService {
    constructor($window, $document, locationService) {
        Object.assign(this, { $window, $document, locationService });
    }

    openPopup(url, title, w, h, reloadOnClose) {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = this.$window.screenLeft !== undefined ? this.$window.screenLeft : this.$window.screenX;
        const dualScreenTop = this.$window.screenTop !== undefined ? this.$window.screenTop : this.$window.screenY;

        const width = this.$window.innerWidth
            ? this.$window.innerWidth
            : this.$document.documentElement.clientWidth
            ? this.$document.documentElement.clientWidth
            : screen.width;
        const height = this.$window.innerHeight
            ? this.$window.innerHeight
            : this.$document.documentElement.clientHeight
            ? this.$document.documentElement.clientHeight
            : screen.height;

        const systemZoom = width / this.$window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const newWindow = this.$window.open(
            url,
            title,
            `
        scrollbars=yes,
        width=${w / systemZoom}, 
        height=${h / systemZoom}, 
        top=${top}, 
        left=${left}
        `
        );

        if (this.$window.focus) {
            newWindow.focus();
        }

        var that = this;
        if (reloadOnClose) {
            var winClosed = setInterval(function () {
                if (newWindow.closed) {
                    clearInterval(winClosed);
                    that.locationService.reload();
                }
            }, 250);
        }
    }
}

windowService.$inject = ['$window', '$document', 'locationService'];
export default windowService;
