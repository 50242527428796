class integrationDialogController {
    constructor($scope, $q, $location, $window, translationService, userService) {
        Object.assign(this, { $scope, $q, $location, $window, translationService, userService });
    }

    $onInit() {
        this.hideDialog = false;
    }

    setHideDialogForUser() {
        this.hideDialog = !this.hideDialog;
        this.userService.setHideOffice365IntegrationDialogForCurrentUser(this.hideDialog);
    }
}

integrationDialogController.$inject = ['$scope', '$q', '$location', '$window', 'translationService', 'userService'];

export default integrationDialogController;
