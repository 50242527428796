class DropdownController {
    constructor(translationService) {
        Object.assign(this, { translationService });
        this.filteredItems = [];
    }

    performSecondaryAction() {
        this.options.onSecondaryAction && this.options.onSecondaryAction();
        this.onCancel();
    }

    select(value) {
        this.onCallback({ data: value });
    }
}

DropdownController.$inject = ['translationService'];
export default DropdownController;
