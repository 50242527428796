import { BASE_URLS, LANGUAGES, GetApiUrl } from './app.constants';
import 'angular-vs-repeat';

let config = async function ($windowProvider, $provide, $qProvider, $translateProvider, responsiveProvider, treeConfig) {
    responsiveProvider
        .registerDevice('xs', 0, 599)
        .registerDevice('sm', 600, 959)
        .registerDevice('md', 960, 1279)
        .registerDevice('lg', 1280, 1919)
        .registerDevice('xl', 1919);

    let $window = $windowProvider.$get(),
        url = $window.location.href;

    let apiUrl = GetApiUrl(window.location.origin);

    $provide.constant(treeConfig, angular.extend(treeConfig, { appendChildOnHover: false }));
    $qProvider.errorOnUnhandledRejections(false);

    $translateProvider.useUrlLoader(apiUrl + 'translations');
    $translateProvider.useSanitizeValueStrategy('sceParameters');

    let storedLang = $window.localStorage.getItem(LANGUAGES.STORAGE_KEY);
    let preferredLanguage = storedLang !== null ? storedLang : LANGUAGES.ENGLISH_CODE;
    $translateProvider.preferredLanguage(preferredLanguage);

    $provide.decorator('$controller', [
        '$delegate',
        function ($delegate) {
            return function (constructor, locals, later, indent) {
                if (typeof constructor.name === 'string' && !locals.$scope.controllerName) {
                    locals.$scope.controllerName = constructor.name;
                }
                return $delegate(constructor, locals, later, indent);
            };
        }
    ]);
};

config.$inject = ['$windowProvider', '$provide', '$qProvider', '$translateProvider', 'responsiveProvider', 'treeConfig', 'tmhDynamicLocaleProvider'];

export default config;
