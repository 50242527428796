class FormUserPickerController {
    constructor($element, $scope, translationService, menuService, keyCode, userApiService, meetingApiService) {
        this.$element = $element;
        this.$scope = $scope;
        this.menuService = menuService;
        this.translationService = translationService;
        this.keyCode = keyCode;
        this.userApiService = userApiService;
        this.meetingApiService = meetingApiService;
        this.options = {};
    }

    $onInit() {
        if (!this.placeHolderText) {
            this.placeHolderText = this.translationService.translate('client_SelectUser');
        }
    }

    select(user) {
        this.ngModel.$setViewValue(user);
        this.options.responsible = user.id;
    }

    invite(email) {
        this.ngModel.$setViewValue({ email: email, name: email, isExternal: true });
        this.userApiService.inviteParticipant(email).then((user) => {
            this.select(user[0]);
        });
    }

    $postLink() {
        if (this.meetingId) {
            this.meetingApiService
                .getParticipantsForMeeting(this.meetingId)
                .then((result) => {
                    this.suggestedUsers = result;
                    this._initMenu();
                })
                .catch(() => this._initMenu());
        } else {
            this.userApiService.getSuggestedParticipants().then((result) => {
                this.suggestedUsers = result;
                this._initMenu();
            });
        }
    }

    _initMenu() {
        this.$scope.$evalAsync(() => {
            let menuButton = this.$element[0].querySelector('.menu-entry');

            this.options = {
                responsible: this.ngModel.$viewValue ? this.ngModel.$viewValue.id : null,
                tabToClose: true,
                onClick: (data) => this.select(data.user),
                onInvite: (email) => this.invite(email),
                suggestedUsers: this.suggestedUsers
            };

            this.menuService.userMenu(this.options, angular.element(menuButton));
        });
    }
}

FormUserPickerController.$inject = ['$element', '$scope', 'translationService', 'menuService', 'keyCode', 'userApiService', 'meetingApiService'];

export default FormUserPickerController;
