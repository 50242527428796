import BaseApiService from './baseApi.service';

class UserApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        stringService,
        eventEmitterService,
        applicationInsightsService,
        envService,
        appcuesService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'users'
        );
        this.stringService = stringService;
        this.eventEmitterService = eventEmitterService;
        this.$q = $q;
        this.notFoundPhotos = [];
    }

    search(searchTerm) {
        let searchValue = encodeURIComponent(searchTerm);
        return super.GET(`users/autocomplete?query=${searchValue}`);
    }

    setHidePlaceholderMeetingsForCurrentUser(hidePlaceholderMeetings) {
        return super.PUT('users/current/hidePlaceholderMeetings', { hidePlaceholderMeetings });
    }

    setHideOffice365IntegrationDialogForCurrentUser(hideOffice365IntegrationDialog) {
        return super.PUT('users/current/hideOffice365IntegrationDialog', { hideOffice365IntegrationDialog });
    }

    setHasLoggedIn(hubspotCookieValue) {
        return super.PUT('users/current/hasLoggedIn', hubspotCookieValue);
    }

    setHasFinishedIntroGuideForCurrentUser(hasFinishedIntroGuide) {
        this.appcuesService.track('Intro Guide Finished');
        return super.PUT('users/current/hasFinishedIntroGuide', { hasFinishedIntroGuide });
    }

    setHasAcceptedTermsForCurrentUser(hubspotCookieValue) {
        return super.PUT('users/current/hasAcceptedTerms', hubspotCookieValue);
    }

    getCurrentUser() {
        return super.GET('users/current');
    }

    getFeatureTogglesForCurrentUser() {
        return super.GET('users/current/featuretoggles');
    }

    getAll() {
        return super.GET('users');
    }

    inviteParticipant(email) {
        return super.POST('users', [{ userName: email, isExternal: true, isAdministrator: false }]);
    }

    getProfilePhoto() {
        if (!this.username) {
            return $q.Deferred.reject();
        }

        return super.GET('users/current/photo');
    }

    addUsers(users) {
        return super.POST('users', users);
    }

    sendInvitation(id) {
        return super.PUT(`users/${id}/sendinvitation`);
    }

    getProfilePhotoForUser(username, cancellationToken) {
        if (!username || !this.stringService.isEmail(username) || this.notFoundPhotos.includes(username)) {
            return this.$q.reject();
        }
        return super.queue('GET', `users/photo/${username}`, null, cancellationToken).then(
            (data) => {
                return data;
            },
            (error) => {
                if (cancellationToken && !cancellationToken.isCancelled) {
                    this.notFoundPhotos.push(username);
                }
            }
        );
    }

    getUserExists(username) {
        if (!username || !this.stringService.isEmail(username)) {
            return this.$q.reject();
        }
        return super.GET(`users/exists/${username}`);
    }

    getSuggestedParticipants() {
        return super.GET('users/suggestedparticipants');
    }

    getTimezones() {
        return super.GET('users/timezones');
    }

    getCultures() {
        return super.GET('users/cultures');
    }

    putTimezoneForCurrentUser(timezone) {
        return super.PUT('users/current/timezone', timezone);
    }

    putPhoneForCurrentUser(phone) {
        return super.PUT('users/current/phone', phone);
    }

    putLanguageForCurrentUser(language) {
        return super.PUT('users/current/language', language);
    }

    putSendActionRemindersForCurrentUser(sendReminders) {
        return super.PUT('users/reminders', sendReminders);
    }

    postRating(rating) {
        return super.POST(`users/rate`, rating);
    }

    clearCache(key) {
        return super.removeCache(key);
    }

    putPreferredCulture(format) {
        return super.PUT('users/current/culture', format);
    }
}

UserApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'stringService',
    'eventEmitterService',
    'applicationInsightsService',
    'envService',
    'appcuesService'
];

export default UserApiService;
