class buttonController {
    constructor($element) {
        this.$element = $element;
    }

    $onInit() {
        this.tooltip = this.tooltip || this.text;
        this.type = this.isSubmit ? 'submit' : 'button';

        if (this.isWide) {
            this.$element.addClass('width(max)');
        }
    }

    handleClick($event) {
        if (this.isDisabled) {
            return;
        }

        this.onClick({
            $event
        });
    }
}

buttonController.$inject = ['$element'];

export default buttonController;
