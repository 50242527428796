import template from './menu.html';
import './menu.less';

let menuComponent = {
    template,
    transclude: {
        content: '?rymMenuContent'
    },
    bindings: {
        options: '=',
        onCallback: '&',
        onCancel: '&'
    }
};

export default menuComponent;
