let infiniteScrollDirective = ($document, $timeout, $interval) => {
    return {
        restrict: 'A',
        scope: {
            callback: '&rymInfiniteScroll',
            scrollerId: '@',
            top: '<rymInfiniteScrollTop',
            start: '<rymInfiniteScrollStart'
        },
        priority: 1001,
        link: function (scope, element, attr) {
            var containerElement;
            var rawElement;
            var isBusy = false;

            if (angular.isDefined(scope.start)) {
                let startInterval = $interval(() => {
                    if (scope.start === true) {
                        $interval.cancel(startInterval);
                        getContainerElement();
                    }
                }, 1000);
            } else {
                getContainerElement();
            }

            function getContainerElement() {
                if (scope.scrollerId) {
                    containerElement = angular.element(document.getElementById(scope.scrollerId));
                } else {
                    containerElement = element;
                }

                if (!containerElement[0]) {
                    $timeout(() => {
                        getContainerElement();
                    }, 0);

                    return;
                }

                rawElement = containerElement[0];

                if (scope.top === true) {
                    initInverseScrollEvent();
                } else {
                    initScrollEvent();
                }
            }

            function initScrollEvent() {
                containerElement.bind('scroll', () => {
                    if (rawElement.scrollTop >= rawElement.scrollHeight - rawElement.offsetHeight - 10) {
                        if (angular.isFunction(scope.callback)) {
                            scope.$evalAsync(scope.callback());
                        }
                    }
                });
            }

            function initInverseScrollEvent() {
                const scrollOffset = 40;
                containerElement.bind('scroll', () => {
                    if (rawElement.scrollTop <= 0 && !isBusy) {
                        let firstElement = containerElement[0].getElementsByClassName('rym-infinit-scroll-element')[0];
                        if (!firstElement) {
                            return;
                        }

                        isBusy = true;

                        $timeout(() => {
                            if (angular.isFunction(scope.callback)) {
                                scope.$evalAsync(scope.callback());
                            }

                            scope.$$postDigest(() => {
                                if (firstElement) {
                                    rawElement.scrollTop = firstElement.offsetTop - scrollOffset;
                                }

                                isBusy = false;
                            });
                        }, 100);
                    }
                });
            }
        }
    };
};

infiniteScrollDirective.$inject = ['$document', '$timeout', '$interval'];

export default infiniteScrollDirective;
