import template from './userList.html';
import controller from './userList.controller';
import './userList.less';

let userListComponent = {
    template,
    controller,
    bindings: {
        searchTerm: '<',
        suggestedUsers: '<',
        selectedUsers: '<',
        onSelect: '&',
        onUnselect: '&',
        secondarySelect: '<',
        onSecondarySelect: '&',
        onInvite: '&',
        placeholder: '<',
        createText: '<',
        suggestedUsersText: '<',
        disableSearch: '<',
        enableMultipleSelection: '<',
        heightAuto: '<',
        usersToIgnore: '<'
    }
};

export default userListComponent;
