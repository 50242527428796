import { FEATURE_TOGGLES } from '../app.constants';

const SKIP_TRIAL_EXCEEDED = true;
const BATCH_SIZE = 10;

class MeetingsController {
    constructor(
        $sce,
        $element,
        $timeout,
        $filter,
        $scope,
        $rootScope,
        $state,
        $q,
        meetingApiService,
        menuService,
        dialogService,
        toastService,
        navigationService,
        responsive,
        anchorScrollService,
        organizationService,
        paymentService,
        userService,
        eventEmitterService,
        translationService,
        breadcrumbsService,
        exportService,
        timeService,
        stringService,
        meetingSeriesService,
        meetingCreationService,
        cardListModelTransformer,
        cardGroupModelTransformer,
        cardModelTransformer,
        meetingFilterService,
        featureToggleService
    ) {
        Object.assign(this, {
            $sce,
            $element,
            $timeout,
            $filter,
            $scope,
            $rootScope,
            $state,
            $q,
            meetingApiService,
            menuService,
            dialogService,
            toastService,
            navigationService,
            responsive,
            anchorScrollService,
            organizationService,
            paymentService,
            userService,
            eventEmitterService,
            translationService,
            breadcrumbsService,
            exportService,
            timeService,
            stringService,
            meetingSeriesService,
            meetingCreationService,
            cardListModelTransformer,
            cardGroupModelTransformer,
            cardModelTransformer,
            meetingFilterService,
            featureToggleService
        });
    }

    $onInit() {
        this.categoryId = parseInt(this.$state.params.category, 10) || undefined;
        this.meetingFilterService.setCategory(this.categoryId);
        this.meetings = [];
        this.skip = 0;
        this.maxDate = new Date();
        this.hiddenMeetings = [];
        this.isShowingFilter = false;
        this.currentUser = null;
        this.filter = {};
        this.searchTerm = this.meetingFilterService.getSearchTerm();
        this._initBreadcrumb();
        this._fetchData();
        this.isFilterActive = this.isShowingFilter = this._isFilterActive();
        this._updateEnableInfiniteScroll();
        this.eventEmitterService.subscribe('meeting-deleted', (deletedMeetingId) => {
            this._remove(deletedMeetingId);
        });

        this.eventEmitterService.subscribe('meetingChangedExternally', (updatedMeeting) => {
            let oldMeeting = this.meetings.find((m) => {
                return m.id === updatedMeeting.id;
            });

            if (!oldMeeting) {
                return;
            }

            angular.merge(oldMeeting, updatedMeeting);
            this.performFilter();
        });

        this.responsive.on(['xs'], this.$scope, () => {
            this.isShowingDivider = true;
        });

        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$scope, () => {
            this.isShowingDivider = false;
        });
    }

    toggleFilter() {
        this.isShowingFilter = !this.isShowingFilter;
    }

    handleOnScrollToBottom() {
        if (this.isFetchingMeetings || !this._hasValidOffice365Token()) {
            return;
        }

        this.isFetchingMeetings = true;
        this.fetchMoreMeetings().then(
            (meetings) => {
                if (meetings.length) {
                    this.enableRefresh = false;
                    this.noMoreOffice365MeetingsToFetch = false;
                    this._addMeetings(meetings);
                    this.isFetchingMeetings = false;
                } else {
                    this.noMoreOffice365MeetingsToFetch = true;
                    this.performFilter();
                    this.enableRefresh = true;
                    this.isFetchingMeetings = false;
                }
            },
            (err) => {
                this.isFetchingMeetings = false;
            }
        );
    }

    _hasValidOffice365Token() {
        return this.currentUser && this.currentUser.hasValidOffice365Token;
    }

    fetchMoreMeetings() {
        this.skip += BATCH_SIZE;
        return this.meetingApiService.getProviderMeetingsForUser(this.skip).then((office365Meetings) => {
            return office365Meetings;
        });
    }

    _fetchData() {
        return this._fetchCurrentUser().then(this._fetchMeetings.bind(this));
    }

    _fetchCurrentUser() {
        return this.userService.getCurrentUser().then(this.setCurrentUser.bind(this));
    }

    setCurrentUser(currentUser) {
        this.currentUser = currentUser;
    }

    _updateEnableInfiniteScroll() {
        this.enableInfiniteScroll =
            this._hasValidOffice365Token() &&
            !this.searchTerm &&
            (!this.isFilterActive || this.categoryId === null) &&
            !this.noMoreOffice365MeetingsToFetch;
    }

    _initBreadcrumb() {
        this.breadcrumbsService.setLabel('meetings', this.translationService.translate('client_Meetings'));
    }

    _fetchMeetings() {
        return this.meetingApiService.getMeetingsForUser().then(this._addMeetings.bind(this));
    }

    _addMeetings(meetings) {
        let office365Meetings = meetings.filter((meeting) => meeting.office365MeetingId !== null);
        if (office365Meetings.length) {
            this.maxDate = new Date(
                meetings.length &&
                    meetings.reduce((latestOffice365MeetingYet, meeting) =>
                        meeting.id === 0 && meeting.startDate > latestOffice365MeetingYet.startDate ? meeting : latestOffice365MeetingYet
                    ).startDate
            );
        }
        this.meetingFilterService.setMaxDate(this.maxDate);
        this.hiddenMeetings.push(...meetings);
        this.performFilter();
    }

    performSearch($event) {
        this._goToToday();
        this.searchTerm = $event && $event.searchTerm && $event.searchTerm.toLowerCase();
        this.meetingFilterService.setSearchTerm(this.searchTerm);
        this.performFilter();
    }

    showCreateDialog() {
        if (this.isCreateMeetingDialogOpen) {
            return;
        }

        this.isCreateMeetingDialogOpen = true;

        if (this.categoryId) {
            let newMeeting = { categoryId: this.categoryId };
            this.meetingCreationService.showCreateMeetingDialog(newMeeting).then((r) => {
                this.isCreateMeetingDialogOpen = false;
            });
        } else {
            this.meetingCreationService.showCreateMeetingDialog().then((r) => {
                this.isCreateMeetingDialogOpen = false;
            });
        }
    }

    goToMeeting(meeting) {
        if (meeting.isPlaceholder) {
            if (this.currentUser.isExternal) {
                this._showIsParticipantDialog();
                return;
            }
            this.meetingCreationService.showCreateMeetingDialog(meeting);
        } else {
            this._goToMeeting(meeting);
        }
    }

    _goToMeeting(meeting) {
        this.$state.go('meeting', { id: meeting.id, meeting: meeting });
        this.toastService.hide();
    }

    updateIsFilterActive() {
        this._goToToday();
        this.meetingFilterService.setCategory(this.categoryId);
        this.isFilterActive = this._isFilterActive();
        this.performFilter();
    }

    _goToToday() {
        this.eventEmitterService.publishEvent('rym-card-list:start-loading');
        this.$timeout(() => {
            this.eventEmitterService.publishEvent('rym-card-list:go-to-today', 0);
            this.eventEmitterService.publishEvent('rym-card-list:stop-loading');
        }, 1);
    }

    performFilter() {
        this.meetings = this.meetingFilterService.filterMeetings(
            this.hiddenMeetings,
            this._hasValidOffice365Token(),
            this.noMoreOffice365MeetingsToFetch
        );
        this._updateEnableInfiniteScroll();
    }

    _isFilterActive() {
        return angular.isDefined(this.categoryId);
    }

    handleOnMeetingCreated($event) {
        this._goToMeeting($event.meeting);
    }

    handleOnClickOnCard($event) {
        this.goToMeeting($event.card.meeting);
    }

    handleOnClickOnMore($event) {
        this.showMeetingMenu($event.currentTarget, $event.card.meeting);
    }

    handleOnClickOnRefresh($event) {
        this.noMoreOffice365MeetingsToFetch = false;
        this._updateEnableInfiniteScroll();
        this.handleOnScrollToBottom();
    }

    showMeetingMenu(menuButton, meeting) {
        let options = {
            items: []
        };

        const TAB_TO_OPEN = {
            MEETINGS: 'meetings',
            DECISIONS: 'decisions',
            ACTIONS: 'actions'
        };

        if (meeting.id) {
            let text = this.translationService.translate('client_CreateNextMeeting');

            if (meeting.isOrganizer || meeting.isCurrentUserEditor) {
                options.items.push({
                    id: 'menu option - create next meeting',
                    icon: 'add',
                    text: text,
                    onClick: () => this.showCreateFollowUpMeetingDialog(meeting)
                });
            }

            options.items.push(
                {
                    id: 'menu option - show meetings for meeting series',
                    icon: 'event_note',
                    text: this.translationService.translate('client_ShowMeetingsInSeries'),
                    onClick: () => this.showMeetingSeries(meeting, TAB_TO_OPEN.MEETINGS)
                },
                {
                    id: 'menu option - show actions for meeting series',
                    icon: 'check',
                    text: this.translationService.translate('client_ViewActions'),
                    onClick: () => this.showMeetingSeries(meeting, TAB_TO_OPEN.ACTIONS)
                },
                {
                    id: 'menu option - show decisions for meeting series',
                    icon: 'gavel',
                    text: this.translationService.translate('client_ShowDecisionsInSeries'),
                    onClick: () => this.showMeetingSeries(meeting, TAB_TO_OPEN.DECISIONS)
                },
                {
                    id: 'menu option - export meeting to pdf',
                    icon: 'file_download',
                    text: this.translationService.translate('client_ExportMinutes'),
                    onClick: () => {
                        this.dialogService
                            .custom('rym-export-meeting-dialog', {
                                id: 'export meeting dialog',
                                meetingId: meeting.id
                            })
                            .then((options) => {
                                return this.exportService.exportWithOptions(options, meeting);
                            });
                    }
                }
            );

            if (meeting.isOrganizer || meeting.isCurrentUserEditor) {
                options.items.push({
                    id: 'menu option - merge meeting series',
                    icon: 'call_merge',
                    text: this.translationService.translate('client_MergeSeries'),
                    onClick: () => this.mergeSeries(meeting.meetingSeriesId)
                });
            }

            if (!meeting.office365UId && this._hasValidOffice365Token() && meeting.isCurrentUserEditor) {
                options.items.push({
                    id: 'menu option - export meeting to outlook',
                    mdiIcon: 'office',
                    text: this.translationService.translate('client_ExportToCalendar'),
                    onClick: () => {
                        let options = {
                            id: 'export to calendar dialog',
                            title: this.translationService.translate('client_ExportToCalendar'),
                            description: this.translationService.translate('client_ExportToCalendarDescription'),
                            placeholder: this.translationService.translate('client_Message'),
                            ok: this.translationService.translate('create'),
                            cancel: this.translationService.translate('client_Cancel')
                        };
                        this.dialogService.custom('rym-export-to-calendar-dialog', options).then((message) => {
                            let msg = angular.isUndefined(message) ? '' : message;
                            this.exportService
                                .exportMeetingToOffice365(meeting, this.stringService.replaceLineBreaksWithBreakTags(msg))
                                .then((uId) => {
                                    meeting.office365UId = uId;
                                });
                        });
                    }
                });
            }
        }

        if (meeting.isOrganizer || (meeting.isCreator && meeting.isCurrentUserEditor)) {
            options.items.push({
                id: 'menu option - delete meeting',
                icon: 'delete_outline',
                text: this.translationService.translate('client_Delete'),
                onClick: () => this.remove(meeting)
            });
        }

        this.menuService.actionMenu(options, angular.element(menuButton), false);
    }

    _showIsParticipantDialog() {
        return this.paymentService.showIsParticipant();
    }

    remove(meeting) {
        this.dialogService
            .confirm({
                id: 'delete meeting dialog',
                title: this.translationService.translate('client_DeleteConfirm'),
                description: this.translationService.translate('client_DeleteItemConfirm', { itemName: meeting.meetingName })
            })
            .then(() => {
                this.meetingApiService
                    .remove(meeting.id)
                    .then(() => {
                        this._remove(meeting.id);
                        this.meetingSeriesService._invalidateMeetingCache();
                    })
                    .catch((exception) => {
                        this.toastService.error({ description: exception.data.message });
                    });
            });
    }

    _remove(meetingId) {
        let meetingToBeRemoved = _.find(this.hiddenMeetings, (m) => {
            return m.id === meetingId;
        });

        _.pull(this.hiddenMeetings, meetingToBeRemoved);
        this.performFilter();
    }

    mergeSeries(seriesId) {
        this.meetingApiService.getMeetingSeries('').then((meetingSeries) => {
            let selectableMeetingSeries = meetingSeries.filter((meetingSerie) => {
                return meetingSerie.id !== seriesId;
            });

            if (selectableMeetingSeries.length > 0) {
                this.dialogService
                    .select({
                        id: 'merge series dialog',
                        title: this.translationService.translate('client_MergeSeries'),
                        description: this.translationService.translate('client_MergeSeriesDescription'),
                        ok: this.translationService.translate('client_Merge'),
                        items: selectableMeetingSeries
                    })
                    .then((selectedSeries) => {
                        this.toastService.loading({ title: this.translationService.translate('client_MergingSeries') });
                        this.meetingApiService.mergeSeries(seriesId, selectedSeries.id).then((updatedMeetings) => {
                            updatedMeetings.forEach((updatedMeeting) => {
                                let oldMeeting = this.meetings.find((meeting) => {
                                    return meeting.id === updatedMeeting.id;
                                });

                                angular.merge(oldMeeting, updatedMeeting);
                            });
                            this.performFilter();

                            this.toastService.success({ title: this.translationService.translate('client_SeriesMerged') });
                        });
                    });
            } else {
                this.dialogService.alert({
                    id: 'no series when merging dialog',
                    title: this.translationService.translate('client_NoSeries'),
                    description: this.translationService.translate('client_NoSeriesDescription')
                });
            }
        });
    }

    showCreateFollowUpMeetingDialog(meeting) {
        this.meetingCreationService.showCreateMeetingDialog(meeting);
    }

    showMeetingSeries(meeting, tab) {
        let options = {
            id: 'meeting series dialog',
            meeting: meeting,
            scrollTo: meeting.id,
            protocolIsLocked: meeting.protocolIsLocked
        };

        if (tab) {
            options.tab = tab;
        }

        this.dialogService.custom('rym-meeting-series-dialog', options).then(() => {});
    }
}

MeetingsController.$inject = [
    '$sce',
    '$element',
    '$timeout',
    '$filter',
    '$scope',
    '$rootScope',
    '$state',
    '$q',
    'meetingApiService',
    'menuService',
    'dialogService',
    'toastService',
    'navigationService',
    'responsive',
    'anchorScrollService',
    'organizationService',
    'paymentService',
    'userService',
    'eventEmitterService',
    'translationService',
    'breadcrumbsService',
    'exportService',
    'timeService',
    'stringService',
    'meetingSeriesService',
    'meetingCreationService',
    'cardListModelTransformer',
    'cardGroupModelTransformer',
    'cardModelTransformer',
    'meetingFilterService',
    'featureToggleService'
];

export default MeetingsController;
