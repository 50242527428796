import template from './button.html';
import controller from './button.controller';
import './button.less';

let buttonComponent = {
    template,
    controller,
    bindings: {
        onClick: '&',
        isDisabled: '<',
        isPrimary: '<',
        isSecondary: '<',
        isQuadrary: '<',
        isAttention: '<',
        isSubmit: '<',
        isWide: '<',
        isAutofocus: '<',
        isSmall: '<',
        tooltip: '@',
        text: '@'
    }
};

export default buttonComponent;
