class CardListController {
    constructor($element, $timeout, $filter, $scope, $rootScope, anchorScrollService, navigationService, eventEmitterService) {
        Object.assign(this, { $element, $timeout, $filter, $scope, $rootScope, anchorScrollService, navigationService, eventEmitterService });
        this.start = null;
        this.increased = 0;
        this.startDecreaseValue = 5;
        this.initialStartDecreaseValue = 5;
        this.hasScrolled = false;
        this.isShowingFilter = false;
        this.startInfiniteScroll = false;
        this.isLoading = true;
        this.uniqueSuffix = Math.random().toString(36).substr(2, 9);
        this.showRefresh = false;
    }

    $onInit() {
        this._initDuScroll();
        this.eventEmitterService.subscribe('rym-card-list:start-loading', () => {
            this.isLoading = true;
        });
        this.eventEmitterService.subscribe('rym-card-list:stop-loading', () => {
            this.isLoading = false;
        });
        this.eventEmitterService.subscribe('rym-card-list:go-to-today', (duration) => this.goToToday(duration));
        this.eventEmitterService.subscribe('rym-card-list:go-to-added', (id) => this.goToAdded(id));
    }

    $onChanges(changes) {
        if (changes.cardListData && changes.cardListData.isFirstChange()) {
            return;
        }

        if (changes.cardListData) {
            angular.extend(this, this.cardListModelTransformer.transform(this.cardListData));
            this._calculateStart();
            this.cardGroups = _.drop(this.cardGroupsData, Math.min(this.start, this.cardGroupsData.length)).map((cardGroupData) =>
                this.cardGroupModelTransformer.transform(cardGroupData)
            );

            if (!this.isInitialized) {
                this.$timeout(this._initStart.bind(this)).then(() => {
                    this.$timeout(() => {
                        this.isLoading = false;
                    }, 500);
                });
                this.isInitialized = true;
            }

            this.isEmpty = !this.cardListData || this.cardListData.length === 0 || this.cardListData[0].length === 0;
        }
    }

    _bindScrollEvent() {
        this.todayActive = this.$rootScope.$on('duScrollspy:becameActive', ($event, $element, $target) => {
            let hasScrolled = this.hasScrolled;
            this.$scope.$applyAsync(() => {
                if (hasScrolled === true) {
                    this.hasScrolled = false;
                }
            });
        });

        this.todayInactive = this.$rootScope.$on('duScrollspy:becameInactive', () => {
            let hasScrolled = this.hasScrolled;
            let loading = this.isLoading;
            this.$scope.$applyAsync(() => {
                if (hasScrolled === false && !loading) {
                    this.hasScrolled = true;
                    this.isTodayAbove = this._isTodayAbove();
                }
            });
        });

        let scrollContainer = angular.element(this.$element[0].querySelector(`#meetings-scroller-${this.uniqueSuffix}`));
        scrollContainer.bind('scroll', ($event) => {
            this.scrollPosition = $event.target.scrollTop;
        });
    }

    _isTodayAbove() {
        let today = document.getElementById('today');
        return today && today.getBoundingClientRect().top < 0;
    }

    _initStart() {
        this.goToToday();

        this.$scope.$applyAsync(() => {
            this._bindScrollEvent();
        });

        if (angular.isFunction(this.todayActive)) {
            this.todayActive();
            this.todayInactive();
        }
        this.startInfiniteScroll = true;
    }

    _calculateStart() {
        let indexOfToday = _.findIndex(this.cardGroupsData, (cardGroupData) => {
            return (
                !cardGroupData.length ||
                this.$filter('date')(cardGroupData[0].startDate, 'yyyy-MM-dd') === this.$filter('date')(new Date(), 'yyyy-MM-dd')
            );
        });

        this.start = Math.max(indexOfToday - this.initialStartDecreaseValue, 0);
    }

    _initDuScroll() {
        this.duScrollListenerOffset = window.innerHeight;
    }

    goToToday(duration = 500) {
        return this.anchorScrollService.scrollTo('today', `meetings-scroller-${this.uniqueSuffix}`, 96, duration);
    }

    goToAdded(cardId) {
        return this.anchorScrollService.scrollTo(cardId, `meetings-scroller-${this.uniqueSuffix}`, 96, 100);
    }

    updateStartValue() {
        this.start = Math.max(0, this.start - this.startDecreaseValue);

        this.cardGroups = _.drop(this.cardGroupsData, this.start).map((cardGroupData) => this.cardGroupModelTransformer.transform(cardGroupData));
    }

    $onDestroy() {
        if (angular.isFunction(this.todayActive)) {
            this.todayActive();
            this.todayInactive();
        }
    }

    handleClickOnCard($event) {
        this.onClickOnCard({ $event });
    }

    handleClickOnMore($event) {
        this.onClickOnMore({ $event });
    }

    handleClickOnRefresh($event) {
        this.onClickOnRefresh({ $event });
    }

    getNumberOfPlaceholders(group) {
        return group.filter((meeting) => {
            return meeting.isPlaceholder && !meeting.isToday;
        }).length;
    }

    handleOnScrollToBottom($event) {
        this.showRefresh = true;
        if (this.enableInfiniteScroll || this.enableRefresh) {
            this.onScrollToBottom({ $event });
        }
    }

    handleOnScrollOverBottom($event) {
        this.showRefresh = false;
    }
}

CardListController.$inject = [
    '$element',
    '$timeout',
    '$filter',
    '$scope',
    '$rootScope',
    'anchorScrollService',
    'navigationService',
    'eventEmitterService'
];

export default CardListController;
