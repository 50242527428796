let ScrollToMeDirective = (domService) => {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (attr.rymScrollToMe === 'true') {
                let scrollParent = domService.scrollParent(element);
                scrollParent.scrollTo(element);
            }
        }
    };
};

ScrollToMeDirective.$inject = ['domService'];

export default ScrollToMeDirective;
