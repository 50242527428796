class meetingPreviewDialogController {
    constructor(
        $scope,
        $q,
        $element,
        $timeout,
        meetingApiService,
        userService,
        responsive,
        menuService,
        eventEmitterService,
        meetingSeriesService,
        translationService
    ) {
        Object.assign(this, {
            $scope,
            $q,
            $element,
            $timeout,
            meetingApiService,
            userService,
            responsive,
            menuService,
            eventEmitterService,
            meetingSeriesService,
            translationService
        });
    }

    $onInit() {
        this.loading = true;
        this.meeting = this.options.meeting;
        this.selectedMeeting = this.options.meeting;
        this.meetingSeriesName = this.options.meeting.meetingName;
        this.meetingSeriesService.setMeetingSeriesId(this.meeting.meetingSeriesId);
        this.responsive.on(['xs', 'sm', 'md'], this.$scope, () => {
            this.isMobile = true;
            this.sideNavOpen = false;
        });
        this.responsive.on(['lg', 'xl'], this.$scope, () => {
            this.isMobile = false;
            this.sideNavOpen = true;
        });
        this.userService.getCurrentUser().then((user) => {
            this.user = user;
        });
    }

    $postLink() {}

    export() {
        this.eventEmitterService.publishEvent('exportList');
    }

    setSelectedMeeting(meeting) {
        let selectMeeting = _.find(this.meetingSeriesService.getMeetingSeries().meetings, (meet) => {
            return meeting.id === meet.id;
        });
        if (selectMeeting) {
            this.selectedMeeting = selectMeeting;
        }
    }

    setChangeMeetingFunc(func) {
        this.changeMeeting = func;
    }

    showMeetingMenu() {
        let el = this.$element[0].querySelector('.meeting-picker');
        let options = {
            selected: this.selectedMeeting,
            meetings: _.orderBy(this.meetingSeriesService.getMeetingSeries().meetings, ['startDate'], ['desc'])
        };

        let menu = this.menuService.meetingMenu(options, angular.element(el), false, true);

        if (menu && menu.then) {
            menu.then((data) => {
                this.selectedMeeting = data.meeting;
                this.$timeout(() => {
                    this.changeMeeting();
                });
            });
        }
    }

    toggleStaticSideNav() {
        this.sideNavOpen = !this.sideNavOpen;
        this.eventEmitterService.publishEvent('parentToggleSideNav', this.sideNavOpen);
    }
}

meetingPreviewDialogController.$inject = [
    '$scope',
    '$q',
    '$element',
    '$timeout',
    'meetingApiService',
    'userService',
    'responsive',
    'menuService',
    'eventEmitterService',
    'meetingSeriesService',
    'translationService'
];

export default meetingPreviewDialogController;
