class SideNavController {
    constructor($document, $element, $scope, $timeout, $rootScope, keyCode, responsive, menuService) {
        Object.assign(this, { $document, $element, $scope, $timeout, $rootScope, keyCode, responsive, menuService });
    }

    $onInit() {
        this.isFixedDefault = this.isFixed;

        this.responsive.on(['xs', 'sm', 'md'], this.$scope, () => {
            this.isFixed = true;
        });

        this.responsive.on(['lg', 'xl'], this.$scope, () => {
            this.isFixed = this.isFixedDefault;
        });
    }

    $onDestroy() {
        if (this.clickEvent) {
            this.clickEvent.unbind();
        }
    }

    closeSideNav(event) {
        if ((event.target !== event.currentTarget && event.currentTarget.contains(event.target)) || this.menuService.isOpen()) {
            return;
        }

        this.close();
    }

    $onChanges(changesObj) {
        if (changesObj.isOpen) {
            if (changesObj.isOpen.currentValue === true) {
                this.bindKeydownEvent();
            } else {
                if (this.clickEvent) {
                    this.clickEvent.unbind();
                }

                if (this.keydownEvent) {
                    this.keydownEvent.unbind();
                }
            }
        }
    }

    bindKeydownEvent() {
        if (this.closeOnBlur !== true) {
            return;
        }

        this.$timeout(
            () => {
                this.clickEvent = this.$document.bind('click', (event) => {
                    if (!this.clickEvent) {
                        return;
                    }

                    if (this.$element[0].contains(event.target)) {
                        return;
                    }

                    this.onCloseEvent();
                    this.clickEvent.unbind();
                });

                this.keydownEvent = this.$document.bind('keydown', (event) => {
                    if (!this.keydownEvent) {
                        return;
                    }

                    // Esc key
                    if (event.keyCode === this.keyCode.ESCAPE) {
                        this.onCloseEvent();
                        this.keydownEvent.unbind();
                    }
                });
            },
            0,
            false
        );
    }

    onCloseEvent() {
        this.$scope.$evalAsync(() => {
            if (angular.isFunction(this.close)) {
                this.close();
            }
        });
    }

    open() {
        this.isOpen = true;
    }
}

SideNavController.$inject = ['$document', '$element', '$scope', '$timeout', '$rootScope', 'keyCode', 'responsive', 'menuService'];

export default SideNavController;
