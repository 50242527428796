class LocationService {
    constructor($window, $state, platformService, teamsService, envService) {
        Object.assign(this, { $window, $state, platformService, teamsService, envService });
    }

    reload(tenantId) {
        let currentUrl = this.$window.location.href;
        let baseLocation = '/index.html';
        if (currentUrl.indexOf('/meetings') > -1) {
            baseLocation = '/meetings';
        } else if (currentUrl.indexOf('/actions') > -1) {
            baseLocation = '/actions';
        } else if (currentUrl.indexOf('/settings') > -1) {
            baseLocation = '/settings';
        }

        let queryString = '';
        if (this.teamsService.isTeams()) {
            queryString = this.teamsService.getTeamsQuerystring();
        } else if (this.platformService.isStratsys()) {
            queryString = this.platformService.getStratsysQuerystring(tenantId);
        }

        let reloadUrl = baseLocation + queryString;
        this.$window.location.replace(reloadUrl);
    }

    navigateTo(item) {
        if (this.teamsService.isTeams()) {
            if (item.meetingId > 0 && item.typeName === 'Action') {
                this.teamsService.goToMeetingAction(item.meetingId, item.id);
            } else if (item.meetingId > 0 && item.typeName === 'Document') {
                this.teamsService.goToMeetingDocument(item.meetingId, item.id);
            } else if (item.meetingId > 0 && item.typeName !== 'Action' && item.typeName !== 'Document') {
                this.teamsService.goToMeeting(item.meetingId);
            } else {
                this.teamsService.goToAction(item.id);
            }
        } else {
            if (item.meetingId > 0 && item.typeName === 'Action') {
                this.$state.go('meeting.action', { id: item.meetingId, actionId: item.id, scrollToId: item.id, searchRoutedFromId: item.scrollToId });
            } else if (item.meetingId > 0 && item.typeName === 'Document') {
                this.$state.go('meeting.documents', {
                    id: item.meetingId,
                    agendaId: item.id,
                    scrollToId: item.id,
                    searchRoutedFromId: item.scrollToId
                });
            } else if (item.meetingId > 0 && item.typeName !== 'Action' && item.typeName !== 'Document') {
                this.$state.go('meeting', { id: item.meetingId, scrollToId: item.id, searchRoutedFromId: item.scrollToId });
            } else {
                this.$state.go('actions.action', { actionId: item.id, searchRoutedFromId: item.scrollToId });
            }
        }
    }

    goToMeeting(meetingId) {
        if (this.teamsService.isTeams() && !this.$state.includes('meeting') && !this.$state.includes('meetings')) {
            this.teamsService.goToMeeting(meetingId);
        } else {
            this.$state.go('meeting', { id: meetingId });
        }
    }

    goToMeetingAction(meetingId, actionId) {
        if (this.teamsService.isTeams()) {
            this.teamsService.goToMeetingAction(meetingId, actionId);
        } else {
            this.$state.go('meeting.action', { id: meetingId, actionId: actionId, scrollToId: actionId });
        }
    }

    redirectToCommonLogin() {
        let loginUrl = `${this.envService.PLATFORM_COMMON_LOGIN_URL}?startpage=meetings`;
        this.$window.location.replace(loginUrl);
    }
}

LocationService.$inject = ['$window', '$state', 'platformService', 'teamsService', 'envService'];

export default LocationService;
