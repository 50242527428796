import template from './introGuide.html';
import controller from './introGuide.controller';

let introGuideComponent = {
    template,
    controller,
    bindings: {
        onDone: '&'
    }
};

export default introGuideComponent;
