const INJECT = ['$window', '$q', 'envService'];
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

class ApplicationInsightsService {
    constructor() {
        var obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });

        this.appInsights = this.$window.appInsights;
        this.initialized = this.$q.defer();
    }

    setAuthenticatedUserContext(id) {
        if (!this.appInsights) {
            return;
        }

        this.appInsights.setAuthenticatedUserContext(id);
    }

    initialize(user) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.envService.APPLICATION_INSIGHTS_KEY
            }
        });
        appInsights.loadAppInsights();
        this.$window.appInsights = appInsights;
        this.appInsights = appInsights;

        var meetingTelemetryInitializer = (envelope) => {
            var telemetryItem = envelope.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties['userId'] = user.id;
            telemetryItem.properties['organizationId'] = user.organizationId;
            telemetryItem.properties['isStratsysOrganization'] = user.isStratsysOrganization;
            telemetryItem.properties['stratsysUserId'] = user.stratsysUserId;
            telemetryItem.properties['path'] = location.pathname;
            telemetryItem.properties['genericPath'] = this._toGenericUrl(location.pathname);
            telemetryItem.properties['referrer'] = document.referrer;
        };
        this._clearTelemetryInitializers();
        this.appInsights.addTelemetryInitializer(meetingTelemetryInitializer);
        this.initialized.resolve();
    }

    trackPage(name) {
        if (!this.appInsights) {
            return;
        }

        this._addEvent(() => {
            this.appInsights.trackPageView({ name });
        });
    }

    trackEvent(name, properties) {
        if (!this.appInsights) {
            return;
        }

        this._addEvent(() => {
            this.appInsights.trackEvent({
                name,
                properties
            });
        });
    }

    trackException(error) {
        this._addEvent(() => {
            this.appInsights.trackException({ exception: error });
        });
    }

    _toGenericUrl(url) {
        return url.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g, '*').replace(/\d+/g, '*');
    }

    _addEvent(event) {
        this.initialized.promise.then(() => {
            try {
                event();
            } catch (error) {}
        });
    }

    _clearTelemetryInitializers() {
        let telemetryInitializers = this.appInsights.appInsights._telemetryInitializers;

        if (telemetryInitializers && telemetryInitializers.length > 0) {
            _.remove(this.appInsights.appInsights._telemetryInitializers, (telemetryInitializer) => {
                return telemetryInitializer.name === 'meetingTelemetryInitializer';
            });
        }
    }
}

ApplicationInsightsService.$inject = INJECT;

export default ApplicationInsightsService;
