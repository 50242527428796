import template from './organizationMenu.html';
import './organizationMenu.less';

let organizationMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default organizationMenuComponent;
