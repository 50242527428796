class ToastController {
    constructor($timeout, $scope, responsive, dialogService) {
        Object.assign(this, { $timeout, $scope, responsive, dialogService });

        this.toast = {};
        this.timer = null;
        this.isVisible = false;
    }

    $onInit() {
        if (this.responsive.is('xs')) {
            this.isMobile = true;
        }

        this.$scope.$on('rym-toast-add', (event, options) => {
            if (!this.isShowing(options)) {
                this.show(options);
            }
        });

        this.$scope.$on('rym-toast-remove', (event) => {
            this.hide();
        });
    }

    isShowing(options) {
        return this.isVisible && this.toast.title === options.title;
    }

    hide() {
        this.$timeout(() => {
            this.toast = {};
            if (this.timer) {
                this.$timeout.cancel(this.timer);
                this.isVisible = false;
                this.$scope.$applyAsync();
            } else {
                this.isVisible = false;
                this.$scope.$applyAsync();
            }
        }, this.toast.delay || 0);
    }

    show(options) {
        let wait = this.toast.delay;
        this.$timeout(() => {
            this.$timeout.cancel(this.timer);
            let delay = this.isVisible ? 200 : 0;
            this.isVisible = false;
            this.toast = options;

            this.$timeout(() => {
                if (this.toast.title && this.toast.title.length) {
                    this.isVisible = true;
                }
            }, delay);

            if (options.duration) {
                this.timer = this.$timeout(() => {
                    this.isVisible = false;
                }, options.duration);
            }
        }, wait || 0);
    }

    performAction(data) {
        if (angular.isFunction(this.toast.action)) {
            this.toast.action(data);
        } else {
            this.hide();
        }
    }

    performTextAction() {
        if (this.dialogService.isDialogOpen()) {
            this.dialogService.closeAllDialogs();
        }

        this.toast.textAction();
    }
}

ToastController.$inject = ['$timeout', '$scope', 'responsive', 'dialogService'];

export default ToastController;
