class iconButtonController {
    constructor() {}

    $onInit() {
        this.useMDI = this.icon.includes('mdi');
    }

    handleClick($event) {
        if (this.isDisabled) {
            return;
        }
        this.onClick({ $event });
    }
}

iconButtonController.$inject = [];

export default iconButtonController;
