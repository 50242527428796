import template from './progressIndicator.html';

let progressIndicatorComponent = {
    template,
    bindings: {
        percentage: '<'
    }
};

export default progressIndicatorComponent;
