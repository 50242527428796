import { ACTIONS_EVENT_TYPES } from '../../../../app.constants';

class MeetingSerieEventsController {
    constructor(meetingSeriesService, translationService) {
        Object.assign(this, { meetingSeriesService, translationService });
    }

    $onInit() {
        this.meetingSeriesService.getEventsInMeetingSeries().then((eventDtos) => {
            let events = eventDtos.map((event) => {
                let timestamp = new Date(event.timestamp);
                return {
                    user: event.user,
                    timestamp: timestamp,
                    message: this.buildMessage(event)
                };
            });

            this.eventsGroupedByDate = _(events)
                .groupBy((event) => {
                    return new Date(event.timestamp.getFullYear(), event.timestamp.getMonth(), event.timestamp.getDate());
                })
                .map((value, key) => ({ date: new Date(key), events: value }))
                .value();
        });
    }

    buildMessage(event) {
        switch (event.type) {
            case ACTIONS_EVENT_TYPES.STATUS_CHANGED:
                return this.translationService.translate('client_StatusChangedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name),
                    status: this._bold(event.action.isFinished ? 'finished' : 'unfinished')
                });
            case ACTIONS_EVENT_TYPES.COMMENT_CHANGED:
                return this.translationService.translate('client_CommentChangedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name),
                    comment: this._bold(event.action.comment)
                });
            case ACTIONS_EVENT_TYPES.CREATED:
                return this.translationService.translate('client_ActionCreatedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name)
                });
            case ACTIONS_EVENT_TYPES.DELETED:
                return this.translationService.translate('client_ActionDeletedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name)
                });
            case ACTIONS_EVENT_TYPES.END_DATE_CHANGED:
                return this.translationService.translate('client_ActionEndDateChangedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name),
                    endDate: this._bold(event.action.actionEndDate)
                });
            case ACTIONS_EVENT_TYPES.NAME_CHANGED:
                return this.translationService.translate('client_ActionNameChangedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name)
                });

            case ACTIONS_EVENT_TYPES.RESPONSIBLE_CHANGED:
                return this.translationService.translate('client_ActionResponsibleChangedEvent', {
                    user: this._bold(event.user.name),
                    name: this._bold(event.action.name),
                    responsible: this._bold(event.action.responsibleName)
                });

            default:
                return this.translationService.translate('client_UnknownEvent', { event: JSON.stringify(event) });
        }
    }

    _bold(string) {
        return `<span class="text(body-md)">${string}</span>`;
    }
}

MeetingSerieEventsController.$inject = ['meetingSeriesService', 'translationService'];

export default MeetingSerieEventsController;
