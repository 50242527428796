const validAttributes = ['ng-click', 'on-click', 'ui-sref'];

const regExs = [/\$ctrl\.[a-z A-Z\.]+\(/g, /\$ctrl\.[a-z A-Z\.]+/g, /\w+/g];

let idGeneratorDirective = function ($state, $timeout) {
    return {
        restrict: 'A',
        compile: function () {
            return {
                pre: function () {},
                post: function ($scope, $element) {
                    $timeout(() => {
                        if (!$element[0] || $element[0].getAttribute('id')) {
                            return;
                        }

                        let attributeValue;
                        for (var validAttribute of validAttributes) {
                            attributeValue = $element[0].getAttribute(validAttribute);
                            if (attributeValue) {
                                break;
                            }
                        }

                        let match;
                        for (let regEx of regExs) {
                            match = attributeValue.match(regEx);
                            if (match) {
                                break;
                            }
                        }

                        let tagName = $element[0].tagName;
                        let rymComponent = '';
                        if (tagName.includes('RYM')) {
                            rymComponent = '--' + tagName.substring(4).toLowerCase();
                        }

                        let icons = $element[0].querySelectorAll('.material-icons');

                        let icon = '';
                        if (icons.length === 1) {
                            let trim = icons[0].innerText.replace('_', '-').trim();
                            icon = '--' + trim;
                        }

                        let camelCaseRegexp = /([a-z])([A-Z])/g;
                        let dashFormat = '$1-$2';
                        let action = match[0]
                            .replace('$ctrl.', '')
                            .replace('(', '')
                            .replace('.', '-')
                            .replace(camelCaseRegexp, dashFormat)
                            .toLowerCase()
                            .trim();

                        let controllerName = '';
                        if ($scope.controllerName) {
                            controllerName = '--' + $scope.controllerName.replace(camelCaseRegexp, dashFormat).toLowerCase();
                        }

                        $element[0].setAttribute('id', `${$state.current.name.replace('.', '-')}${controllerName}--${action}${rymComponent}${icon}`);
                    }, 1000);
                }
            };
        }
    };
};

idGeneratorDirective.$inject = ['$state', '$timeout'];

export default idGeneratorDirective;
