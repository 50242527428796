import template from './countryMenu.html';
import './countryMenu.less';

let countryMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default countryMenuComponent;
