class WizardStepController {
    constructor() {}

    $onInit() {
        this.wizardCtrl.add(this);
    }

    $onDestroy() {
        this.wizardCtrl.remove(this);
    }
}

WizardStepController.$inject = [];

export default WizardStepController;
