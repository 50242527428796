const INJECT = ['tmhDynamicLocaleCache', 'tmhDynamicLocale', '$locale', 'userApiService', '$q'];
import { LANGUAGES } from '../../../app.constants';

class LocalizationService {
    constructor() {
        Object.assign(this, ...Object.keys(arguments).map((i) => ({ [INJECT[i]]: arguments[i] })));
    }

    _loadDefaultLocales() {
        let loadInjectedLocale = () => {
            let injector = angular.injector(['ngLocale']);
            return injector.get('$locale');
        };

        _.forEach(this.cultures, (culture) => {
            require(`../../../locales/angular-locale_${culture.toLowerCase()}`);
            this.tmhDynamicLocaleCache.put(culture, loadInjectedLocale());
        });
    }

    setLocale(lang, preferredLocale) {
        return this._getCultures().then(() => {
            this._loadDefaultLocales();
            let culture = this._getCorrectCulture(lang);

            return this.tmhDynamicLocale.set(culture).then(() => {
                if (preferredLocale) return this.setPreferredLocale(preferredLocale);

                return this.$q.resolve();
            });
        });
    }

    getLocales() {
        return this.cultures.map((culture) => this.tmhDynamicLocaleCache.get(culture));
    }

    getCurrentLocale() {
        return this.$locale;
    }

    setPreferredLocale(preferredLocale) {
        let preferredCachedLocale;
        if ((preferredCachedLocale = this.tmhDynamicLocaleCache.get(preferredLocale))) {
            let localeToOverride = { ...this.$locale };

            localeToOverride.DATETIME_FORMATS = {
                ...localeToOverride.DATETIME_FORMATS,
                ...preferredCachedLocale.getDateFormats()
            };

            this.tmhDynamicLocaleCache.put(`pref-${preferredLocale}`, localeToOverride);
            return this.tmhDynamicLocale.set(`pref-${preferredLocale}`).then(() => {
                this.tmhDynamicLocaleCache.remove(`pref-${preferredLocale}`);
            });
        }

        return this.$q.resolve();
    }

    _getCultures() {
        return this.userApiService.getCultures().then((cultures) => (this.cultures = cultures));
    }

    _getCorrectCulture(langCode) {
        switch (langCode) {
            case LANGUAGES.NORWEGIAN_CODE:
                return this.cultures.find((c) => c === 'nb-NO');
            case LANGUAGES.SWEDISH_CODE:
                return this.cultures.find((c) => c === 'sv-SE');
            case LANGUAGES.GERMAN_CODE:
                return this.cultures.find((c) => c === 'de-DE');
            case LANGUAGES.ENGLISH_CODE:
                return navigator.language.toLocaleLowerCase() === 'en-us'
                    ? this.cultures.find((c) => c === 'en-US')
                    : this.cultures.find((c) => c === 'en-GB');
            default:
                return this.cultures.find((c) => c === 'en-GB');
        }
    }
}

LocalizationService.$inject = INJECT;
export default LocalizationService;
