import template from './meetingSeriesActions.html';
import controller from './meetingSeriesActions.controller';

let meetingSeriesActionsComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingSeriesDialog'
    }
};

export default meetingSeriesActionsComponent;
