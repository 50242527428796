const SKIP_TRIAL_EXCEEDED = true;

class meetingMetadataService {
    constructor(
        $state,
        $timeout,
        meetingApiService,
        eventEmitterService,
        dialogService,
        translationService,
        paymentService,
        userService,
        organizationService,
        toastService
    ) {
        Object.assign(this, {
            $state,
            $timeout,
            meetingApiService,
            eventEmitterService,
            dialogService,
            translationService,
            paymentService,
            userService,
            organizationService,
            toastService
        });
    }

    updateMeetingName(meetingName, meetingId, oldName) {
        if (meetingName.length === 0) {
            return this.$timeout(() => {
                return oldName;
            }, 10);
        } else {
            return this.meetingApiService.putMeetingName(meetingId, meetingName).then((result) => {
                return meetingName;
            });
        }
    }

    updateDescription(description, meetingId) {
        return this.meetingApiService.putDescription(meetingId, description);
    }

    showOrganizerInfo(meeting) {
        if ((meeting.office365UId || meeting.isCreatedExternally) && !meeting.isOrganizer) {
            var options = {
                id: 'meeting created in Outlook dialog',
                title: this.translationService.translate('client_NotOrganizerForMeeting'),
                description: this.translationService.translate('client_MeetingCreatedInOutlook')
            };
            return this.dialogService.alert(options);
        }

        if (meeting.provider && (meeting.provider === 'Outlook' || meeting.provider.providerName === 'Outlook') && !meeting.office365UId) {
            var options = {
                id: 'meeting created in plugin dialog',
                title: this.translationService.translate('meetings_CreatedInOutlook'),
                description: this.translationService.translate('client_MeetingCreatedInPlugin')
            };

            return this.dialogService.alert(options);
        }
    }
}

meetingMetadataService.$inject = [
    '$state',
    '$timeout',
    'meetingApiService',
    'eventEmitterService',
    'dialogService',
    'translationService',
    'paymentService',
    'userService',
    'organizationService',
    'toastService'
];
export default meetingMetadataService;
