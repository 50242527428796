import template from './colorMenu.html';
import controller from './colorMenu.controller';
import './colorMenu.less';

let colorMenuComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default colorMenuComponent;
