import template from './document.html';
import controller from './document.controller';
import './document.less';

let documentComponent = {
    template,
    controller,
    bindings: {
        meetingId: '<',
        agendaId: '<',
        closeFunc: '&?',
        allowUpload: '<'
    }
};

export default documentComponent;
