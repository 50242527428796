import template from './meetingMenu.html';
import controller from './meetingMenu.controller';
import './meetingMenu.less';

let meetingMenuComponent = {
    template,
    controller,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default meetingMenuComponent;
