try {
    let testCookie = window.localStorage.getItem('testCookie');
} catch (err) {
    if (err.message.indexOf('Access is denied for this document') >= 0) {
        alert('Please accept cookies from stratsys.com');
        throw 'Access is denied for stratsys.com cookies';
    }
}

import { RUNTIME, RUNTIMES } from './app.constants';

//require('./externalSnippets/applicationInsights');

import angular from 'angular';
import _ from 'lodash';
import '@babel/polyfill';
import 'angular-animate';
import 'angular-resource';
import 'angular-breadcrumb';
import 'angular-sanitize';
import 'angular-scroll';
import 'angular-touch';
import 'ng-wig';
import 'ng-file-upload';
import 'angular-ui-tree';
import 'angular-translate';
import 'angular-translate-loader-url';
import 'angular-dynamic-locale';

if (RUNTIME === RUNTIMES.DEV) {
    require('angular-mocks');
}

import '@stratsys/pdr/lib/forced-document-register-element';
import '@stratsys/pdr/lib/pdr-icon';
import '@stratsys/pdr/lib/animation-height';
//import '@stratsys/pdr/lib/accessibility-debug';
import config from './app.config';
import run from './app.run';
import './app.exceptionHandler';
import './routerHelperProvider';
import './responsive';
import './common/common.module';
import './navigation/navigation.module';
import './meetings/meetings.module';
import './meeting/meeting.module';
import './actions/actions.module';
import './settings/settings.module';
import './templates/templates.module';
import './search/search.module';
import './developers/developers.module';
import './404/404.module';
import './api/api.module';
import './auth/auth.module';
import './filters/filters.module';
import './subscription/subscription.module';
import './idGenerator/idGenerator.module';
import './modelTransformers/modelTransformer.module';
import './categories/categories.module';
import app from './app.controller';
import './app.less';
import './style-guide/style-guide.less';

angular
    .module('rym', [
        'ngTouch',
        'ngAnimate',
        'ngResource',
        'ncy-angular-breadcrumb',
        'ngSanitize',
        'duScroll',
        'ngWig',
        'ui.tree',
        'ngFileUpload',
        'pascalprecht.translate',
        'vs-repeat',
        'rym.modelTransformer',
        'rym.routes',
        'rym.responsive',
        'rym.common',
        'rym.navigation',
        'rym.meetings',
        'rym.meeting',
        'rym.actions',
        'rym.settings',
        'rym.templates',
        'rym.search',
        'rym.developers',
        'rym.404',
        'rym.api',
        'rym.auth',
        'rym.filters',
        'rym.exceptionHandler',
        'rym.subscription',
        'tmh.dynamicLocale',
        'rym.idGenerator',
        'rym.categories'
    ])
    .controller('app', app)
    .config(config)
    .value('duScrollActiveClass', 'duScrollCurrent')
    .value('duScrollWait', 0)
    .run(run);
