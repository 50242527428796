const INJECT = [
    '$q',
    '$stateParams',
    '$state',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'organizationService',
    'userService',
    'toastService',
    'subscriptionApiService'
];

class SubscriptionUpgradeDialogController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this._init();
    }

    _init() {
        this.priceFormat = this.options.currency === 'usd' ? 'client_PriceInUsd' : 'client_PriceInSek';
        this.priceFormatInterval = this.options.interval === 'year' ? 'client_PerYear' : 'client_PerMonth';
        this.currentPriceFormatInterval = this.options.currentInterval === 'year' ? 'client_PerYear' : 'client_PerMonth';
        this._update();
    }

    _update() {
        this._updateCurrentPaymentAmountText();
        this._updateUpgradedPaymentAmountText();
        this._updateImmediatePaymentAmountText();
        this._updateImmediatePaymentAmountWithTaxText();
        this._updateTaxAmountText();
    }

    _updateUpgradedPaymentAmountText() {
        let price = this.translationService.translate(this.priceFormat, {
            price: this.options.upgradedPrice
        });
        this.upgradedPaymentAmountText = this.translationService.translate(this.priceFormatInterval, { price });
    }

    _updateCurrentPaymentAmountText() {
        let price = this.translationService.translate(this.priceFormat, {
            price: this.options.currentPrice
        });
        this.currentPaymentAmountText = this.translationService.translate(this.currentPriceFormatInterval, { price });
    }

    _updateImmediatePaymentAmountText() {
        this.immediatePaymentAmountText = this.translationService.translate(this.priceFormat, {
            price: this.options.immediatePrice
        });
    }

    _updateImmediatePaymentAmountWithTaxText() {
        this.immediatePaymentAmountWithTaxText = this.translationService.translate(this.priceFormat, {
            price: this.options.immediatePriceWithTax
        });
    }

    _updateTaxAmountText() {
        this.taxAmountText = this.translationService.translate(this.priceFormat, {
            price: this.options.immediatePriceWithTax - this.options.immediatePrice
        });
    }
}

SubscriptionUpgradeDialogController.$inject = INJECT;

export default SubscriptionUpgradeDialogController;
