import controller from './selectMultipleUsersDialog.controller';
import template from './selectMultipleUsersDialog.html';

let selectMultipleUsersDialogComponent = {
    controller,
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default selectMultipleUsersDialogComponent;
