import controller from './wizardStep.controller';

let wizardStepComponent = {
    controller: controller,
    link: (scope, element, attrs, wizardCtrl) => {
        wizardCtrl.add(scope);
    },
    require: {
        wizardCtrl: '^rymWizard'
    },
    bindings: {
        stepInfo: '<',
        index: '<'
    }
};

export default wizardStepComponent;
