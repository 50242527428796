class dateService {
    constructor($filter) {
        this.$filter = $filter;
    }

    convertEndDateToString(action) {
        if (!action.endDate) {
            return;
        }

        action.endDateString = this.$filter('date')(action.endDate, 'rymDate');
    }
}

dateService.$inject = ['$filter'];
export default dateService;
