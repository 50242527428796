export const BASE_URLS = {
    RYM_OLD: 'https://localhost:44334',
    RYM: 'https://meetings-api.dev.office.stratsys.net',
    API: 'https://meetings-api.dev.office.stratsys.net/api/v2/',
    IDSRV: 'https://login.svc.dev.stratsys.net/idsrv'
};

// export const BASE_URLS = {
//     RYM_OLD: 'https://localhost:44334',
//     RYM: 'https://localhost:44334/',
//     API: 'https://localhost:44334/api/v2/',
//     IDSRV: 'https://localhost:44311/idsrv'
// };

export function GetApiUrl(currentUrl) {
    let apiUrl = '';

    switch (currentUrl) {
        case 'https://localhost:44336':
            apiUrl = 'https://localhost:44334/api/v2/';
            break;
        case 'https://appdev.meetings.stratsys.com':
            apiUrl = 'https://apidev.meetings.stratsys.com/api/v2/';
            break;
        case 'https://apptest.meetings.stratsys.com':
            apiUrl = 'https://apitest.meetings.stratsys.com/api/v2/';
            break;
        case 'https://app.meetings.stratsys.com':
            apiUrl = 'https://api.meetings.stratsys.com/api/v2/';
            break;
        case 'https://app.meetings.dev.stratsys.net':
            apiUrl = 'https://api.meetings.dev.stratsys.net/api/v2/';
            break;
        case 'https://app.meetings.test.stratsys.net':
            apiUrl = 'https://api.meetings.test.stratsys.net/api/v2/';
            break;
    }

    return apiUrl;
}

export function GetSwarmDomain(currentUrl) {
    let swarm = '';

    switch (currentUrl) {
        case 'https://localhost:44336':
            swarm = 'svc.dev.stratsys.net';
            break;
        case 'https://app.meetings.stratsys.com':
            swarm = 'svc.stratsys.com';
            break;
        case 'https://app.meetings.dev.stratsys.net':
            swarm = 'svc.dev.stratsys.net';
            break;
        case 'https://app.meetings.test.stratsys.net':
            swarm = 'svc.test.stratsys.net';
            break;
    }

    return swarm;
}

export const VERSION = '1.0.3';
export const WEBSITE = 'https://www.stratsys.com/stratsys-meetings';
export const WEBSITE_PRODUCTS = { ENGLISH: 'https://www.stratsys.com/products', SWEDISH: 'https://www.stratsys.com/sv/produkter' };
export const WEBSITE_BLOG = {
    ENGLISH: 'https://www.stratsys.com/sv/stratsys-manual/meetings-english',
    SWEDISH: 'https://www.stratsys.com/sv/stratsys-manual/meetings-do-board'
};
export const WEBSITE_TERMS_OF_SERVICE = {
    ENGLISH: 'https://www.stratsys.com/terms-of-service-meetings',
    SWEDISH: 'https://www.stratsys.com/sv/moten/licensvillkor'
};
export const RUNTIMES = { PROD: 'production', TEST: 'test', DEV: 'development' };
export const RUNTIME = RUNTIMES.DEV;
export const HTTP_STATUS_CODES = {
    UNAUTHORIZED: 401,
    OK: 200,
    NOTFOUND: 404,
    SERVERERROR: 500,
    BAD_GATEWAY: 502,
    UNSUPPORTEDMEDIATYPE: 415,
    ERR_CONNECTION: -1,
    ERR_NETWORK_CHANGED: 0,
    BADREQUEST: 400,
    FORBIDDEN: 403,
    CONFLICT: 409,
    SEEOTHER: 303,
    OFFICE365_TOKEN_ERROR: 438,
    NOT_ALLOWED_ACTION: 439,
    NOT_ALLOWED_MEETING: 440,
    CHANGE_MEETING_ORG: 441,
    CHANGE_ACTION_ORG: 442,
    RATE_LIMIT_REACHED: 443
};
export const TIME = { REMOVE_FOCUS: 180000, AUTO_SAVE: 4000 };
export const PROVIDER = { STRATSYS: 'Stratsts', OFFICE365: 'Office365' };
export const CURRENCIES = { SEK: 'SEK', USD: 'USD' };
export const LANGUAGES = {
    SWEDISH: 1,
    ENGLISH: 2,
    NORWEGIAN: 3,
    GERMAN: 4,
    ENGLISH_GB: 5,
    SWEDISH_CODE: 'sv',
    ENGLISH_CODE: 'en',
    NORWEGIAN_CODE: 'no',
    GERMAN_CODE: 'de',
    ENGLISH_GB_CODE: 'en-gb',
    STORAGE_KEY: 'lang'
};
export const CONNECTION_STATES = { CONNECTING: 0, CONNECTED: 1, DISCONNECTED: 4 };
export const ACTIONS_EVENT_TYPES = {
    RESPONSIBLE_CHANGED: 'ActionResponsibleUpdatedEvent',
    STATUS_CHANGED: 'ActionStatusUpdatedEvent',
    COMMENT_CHANGED: 'ActionCommentUpdatedEvent',
    DELETED: 'ActionDeletedEvent',
    CREATED: 'ActionCreatedEvent',
    NAME_CHANGED: 'ActionNameUpdatedEvent',
    END_DATE_CHANGED: 'ActionEndDateUpdatedEvent'
};
export const FEATURE_TOGGLES = {
    EVENTS: 'SM-1168',
    ADDITIONAL_PROFILE_INFO: 'SM-1564',
    OFFICE365_INTEGRATION_SETTING: 'SM-1829',
    OFFICE365_GROUPS: 'SM-2013',
    SEARCH: 'SM-1888'
};
export const APPLICATION_INSIGHTS = { INSTRUMENTATIONKEY: '1a6a2a1a-43c0-41c4-8629-7eb0d658e46f' };
export const OFFICE365 = { LOGIN_REDIRECTED_FROM_STRATSYS: '2b91dc74-2796-4068-8e13-788de3349f20' };
export const PARTICIPANT_TYPES = { READER: 4, NON_EDITOR: 2, EDITOR: 0 };
export const FEES = { USD: { START_FEE: 36, MONTHLY_LICENSE_FEE: 12 }, SEK: { START_FEE: 300, MONTHLY_LICENSE_FEE: 90 } };
export const FEES_WITH_DISCOUNT = { USD: { START_FEE: 30, MONTHLY_LICENSE_FEE: 10 }, SEK: { START_FEE: 250, MONTHLY_LICENSE_FEE: 75 } };
export const NUMBER_OF_FREE_LICENSES = 5;
export const SWEDISH_TAX = 0.25;
export const PAYMENT_SCHEDULE = { ANNUALLY: 1, MONTHLY: 2 };
export const STRATSYS_IDSRV = {
    NEW: 'https://login.svc.dev.stratsys.net/',
    OLD: 'https://logindev.stratsys.se/',
    CLIENT_ID: '3658-3203-7852-36',
    CLIENT_SECRET: 'eKUZ1ps5MGcIA6vyTD8Urg=='
};
export const PLATFORM_COMMON_LOGIN = {
    URL: ' https://app.dev.stratsys.net'
};
export const STRIPE = { PUBLIC_KEY: 'pk_test_kUblUBzbTl1C201zosRlJP6T' };
export const TEAMS = { APP_ID: '6b5dd756-1b56-4753-a400-5c6ab7ef1d60' };
export const COUNTRIES = [
    {
        country: 'Afghanistan',
        countryCodeNumerical: 4,
        isEU: false,
        countryShort: 'AF'
    },
    {
        country: 'Åland',
        countryCodeNumerical: 248,
        isEU: false,
        countryShort: 'AX'
    },
    {
        country: 'Albania',
        countryCodeNumerical: 8,
        isEU: false,
        countryShort: 'AL'
    },
    {
        country: 'Algeria',
        countryCodeNumerical: 12,
        isEU: false,
        countryShort: 'DZ'
    },
    {
        country: 'American Samoa',
        countryCodeNumerical: 16,
        isEU: false,
        countryShort: 'AS'
    },
    {
        country: 'Andorra',
        countryCodeNumerical: 20,
        isEU: false,
        countryShort: 'AD'
    },
    {
        country: 'Angola',
        countryCodeNumerical: 24,
        isEU: false,
        countryShort: 'AO'
    },
    {
        country: 'Anguilla',
        countryCodeNumerical: 660,
        isEU: false,
        countryShort: 'AI'
    },
    {
        country: 'Antigua and Barbuda',
        countryCodeNumerical: 28,
        isEU: false,
        countryShort: 'AG'
    },
    {
        country: 'Argentina',
        countryCodeNumerical: 32,
        isEU: false,
        countryShort: 'AR'
    },
    {
        country: 'Armenia',
        countryCodeNumerical: 51,
        isEU: false,
        countryShort: 'AM'
    },
    {
        country: 'Aruba',
        countryCodeNumerical: 533,
        isEU: false,
        countryShort: 'AW'
    },
    {
        country: 'Australia',
        countryCodeNumerical: 36,
        isEU: false,
        countryShort: 'AU'
    },
    {
        country: 'Austria',
        countryCodeNumerical: 40,
        isEU: true,
        countryShort: 'AT'
    },
    {
        country: 'Azerbaijan',
        countryCodeNumerical: 31,
        isEU: false,
        countryShort: 'AZ'
    },
    {
        country: 'Bahamas',
        countryCodeNumerical: 44,
        isEU: false,
        countryShort: 'BS'
    },
    {
        country: 'Bahrain',
        countryCodeNumerical: 48,
        isEU: false,
        countryShort: 'BH'
    },
    {
        country: 'Bangladesh',
        countryCodeNumerical: 50,
        isEU: false,
        countryShort: 'BD'
    },
    {
        country: 'Barbados',
        countryCodeNumerical: 52,
        isEU: false,
        countryShort: 'BB'
    },
    {
        country: 'Belarus',
        countryCodeNumerical: 112,
        isEU: false,
        countryShort: 'BY'
    },
    {
        country: 'Belgium',
        countryCodeNumerical: 56,
        isEU: true,
        countryShort: 'BE'
    },
    {
        country: 'Belize',
        countryCodeNumerical: 84,
        isEU: false,
        countryShort: 'BZ'
    },
    {
        country: 'Benin',
        countryCodeNumerical: 204,
        isEU: false,
        countryShort: 'BJ'
    },
    {
        country: 'Bermuda',
        countryCodeNumerical: 60,
        isEU: false,
        countryShort: 'BM'
    },
    {
        country: 'Bhutan',
        countryCodeNumerical: 64,
        isEU: false,
        countryShort: 'BT'
    },
    {
        country: 'Bolivia',
        countryCodeNumerical: 68,
        isEU: false,
        countryShort: 'BO'
    },
    {
        country: 'Bonaire',
        countryCodeNumerical: 535,
        isEU: false,
        countryShort: 'BQ'
    },
    {
        country: 'Bosnia and Herzegovina',
        countryCodeNumerical: 70,
        isEU: false,
        countryShort: 'BA'
    },
    {
        country: 'Botswana',
        countryCodeNumerical: 72,
        isEU: false,
        countryShort: 'BW'
    },
    {
        country: 'Bouvet Island',
        countryCodeNumerical: 74,
        isEU: false,
        countryShort: 'BV'
    },
    {
        country: 'Brazil',
        countryCodeNumerical: 76,
        isEU: false,
        countryShort: 'BR'
    },
    {
        country: 'British Indian Ocean Territory',
        countryCodeNumerical: 86,
        isEU: false,
        countryShort: 'IO'
    },
    {
        country: 'Brunei Darussalam',
        countryCodeNumerical: 96,
        isEU: false,
        countryShort: 'BN'
    },
    {
        country: 'Bulgaria',
        countryCodeNumerical: 100,
        isEU: true,
        countryShort: 'BG'
    },
    {
        country: 'Burkina Faso',
        countryCodeNumerical: 854,
        isEU: false,
        countryShort: 'BF'
    },
    {
        country: 'Burundi',
        countryCodeNumerical: 108,
        isEU: false,
        countryShort: 'BI'
    },
    {
        country: 'Cambodia',
        countryCodeNumerical: 116,
        isEU: false,
        countryShort: 'KH'
    },
    {
        country: 'Cameroon',
        countryCodeNumerical: 120,
        isEU: false,
        countryShort: 'CM'
    },
    {
        country: 'Canada',
        countryCodeNumerical: 124,
        isEU: false,
        countryShort: 'CA'
    },
    {
        country: 'Cape Verde',
        countryCodeNumerical: 132,
        isEU: false,
        countryShort: 'CV'
    },
    {
        country: 'Cayman Islands',
        countryCodeNumerical: 136,
        isEU: false,
        countryShort: 'KY'
    },
    {
        country: 'Central African Republic',
        countryCodeNumerical: 140,
        isEU: false,
        countryShort: 'CF'
    },
    {
        country: 'Chad',
        countryCodeNumerical: 148,
        isEU: false,
        countryShort: 'TD'
    },
    {
        country: 'Chile',
        countryCodeNumerical: 152,
        isEU: false,
        countryShort: 'CL'
    },
    {
        country: 'China',
        countryCodeNumerical: 156,
        isEU: false,
        countryShort: 'CN'
    },
    {
        country: 'Christmas Island',
        countryCodeNumerical: 162,
        isEU: false,
        countryShort: 'CX'
    },
    {
        country: 'Cocos (Keeling) Islands',
        countryCodeNumerical: 166,
        isEU: false,
        countryShort: 'CC'
    },
    {
        country: 'Colombia',
        countryCodeNumerical: 170,
        isEU: false,
        countryShort: 'CO'
    },
    {
        country: 'Comoros',
        countryCodeNumerical: 174,
        isEU: false,
        countryShort: 'KM'
    },
    {
        country: 'Congo (Brazzaville)',
        countryCodeNumerical: 178,
        isEU: false,
        countryShort: 'CG'
    },
    {
        country: 'Congo (Kinshasa)',
        countryCodeNumerical: 180,
        isEU: false,
        countryShort: 'CD'
    },
    {
        country: 'Cook Islands',
        countryCodeNumerical: 184,
        isEU: false,
        countryShort: 'CK'
    },
    {
        country: 'Costa Rica',
        countryCodeNumerical: 188,
        isEU: false,
        countryShort: 'CR'
    },
    {
        country: 'Côte dIvoire',
        countryCodeNumerical: 384,
        isEU: false,
        countryShort: 'CI'
    },
    {
        country: 'Croatia',
        countryCodeNumerical: 191,
        isEU: true,
        countryShort: 'HR'
    },
    {
        country: 'Cuba',
        countryCodeNumerical: 192,
        isEU: false,
        countryShort: 'CU'
    },
    {
        country: 'Curaçao',
        countryCodeNumerical: 531,
        isEU: false,
        countryShort: 'CW'
    },
    {
        country: 'Cyprus',
        countryCodeNumerical: 196,
        isEU: true,
        countryShort: 'CY'
    },
    {
        country: 'Czech Republic',
        countryCodeNumerical: 203,
        isEU: true,
        countryShort: 'CZ'
    },
    {
        country: 'Denmark',
        countryCodeNumerical: 208,
        isEU: true,
        countryShort: 'DK'
    },
    {
        country: 'Djibouti',
        countryCodeNumerical: 262,
        isEU: false,
        countryShort: 'DJ'
    },
    {
        country: 'Dominica',
        countryCodeNumerical: 212,
        isEU: false,
        countryShort: 'DM'
    },
    {
        country: 'Dominican Republic',
        countryCodeNumerical: 214,
        isEU: false,
        countryShort: 'DO'
    },
    {
        country: 'Ecuador',
        countryCodeNumerical: 218,
        isEU: false,
        countryShort: 'EC'
    },
    {
        country: 'Egypt',
        countryCodeNumerical: 818,
        isEU: false,
        countryShort: 'EG'
    },
    {
        country: 'El Salvador',
        countryCodeNumerical: 222,
        isEU: false,
        countryShort: 'SV'
    },
    {
        country: 'Equatorial Guinea',
        countryCodeNumerical: 226,
        isEU: false,
        countryShort: 'GQ'
    },
    {
        country: 'Eritrea',
        countryCodeNumerical: 232,
        isEU: false,
        countryShort: 'ER'
    },
    {
        country: 'Estonia',
        countryCodeNumerical: 233,
        isEU: true,
        countryShort: 'EE'
    },
    {
        country: 'Ethiopia',
        countryCodeNumerical: 231,
        isEU: false,
        countryShort: 'ET'
    },
    {
        country: 'Falkland Islands',
        countryCodeNumerical: 238,
        isEU: false,
        countryShort: 'FK'
    },
    {
        country: 'Faroe Islands',
        countryCodeNumerical: 234,
        isEU: false,
        countryShort: 'FO'
    },
    {
        country: 'Fiji',
        countryCodeNumerical: 242,
        isEU: false,
        countryShort: 'FJ'
    },
    {
        country: 'Finland',
        countryCodeNumerical: 246,
        isEU: true,
        countryShort: 'FI'
    },
    {
        country: 'France',
        countryCodeNumerical: 250,
        isEU: true,
        countryShort: 'FR'
    },
    {
        country: 'French Guiana',
        countryCodeNumerical: 254,
        isEU: false,
        countryShort: 'GF'
    },
    {
        country: 'French Polynesia',
        countryCodeNumerical: 258,
        isEU: false,
        countryShort: 'PF'
    },
    {
        country: 'French Southern Lands',
        countryCodeNumerical: 260,
        isEU: false,
        countryShort: 'TF'
    },
    {
        country: 'Gabon',
        countryCodeNumerical: 266,
        isEU: false,
        countryShort: 'GA'
    },
    {
        country: 'Gambia',
        countryCodeNumerical: 270,
        isEU: false,
        countryShort: 'GM'
    },
    {
        country: 'Georgia',
        countryCodeNumerical: 268,
        isEU: false,
        countryShort: 'GE'
    },
    {
        country: 'Germany',
        countryCodeNumerical: 276,
        isEU: true,
        countryShort: 'DE'
    },
    {
        country: 'Ghana',
        countryCodeNumerical: 288,
        isEU: false,
        countryShort: 'GH'
    },
    {
        country: 'Gibraltar',
        countryCodeNumerical: 292,
        isEU: false,
        countryShort: 'GI'
    },
    {
        country: 'Greece',
        countryCodeNumerical: 300,
        isEU: true,
        countryShort: 'GR'
    },
    {
        country: 'Greenland',
        countryCodeNumerical: 304,
        isEU: false,
        countryShort: 'GL'
    },
    {
        country: 'Grenada',
        countryCodeNumerical: 308,
        isEU: false,
        countryShort: 'GD'
    },
    {
        country: 'Guadeloupe',
        countryCodeNumerical: 312,
        isEU: false,
        countryShort: 'GP'
    },
    {
        country: 'Guam',
        countryCodeNumerical: 316,
        isEU: false,
        countryShort: 'GU'
    },
    {
        country: 'Guatemala',
        countryCodeNumerical: 320,
        isEU: false,
        countryShort: 'GT'
    },
    {
        country: 'Guernsey',
        countryCodeNumerical: 831,
        isEU: false,
        countryShort: 'GG'
    },
    {
        country: 'Guinea',
        countryCodeNumerical: 324,
        isEU: false,
        countryShort: 'GN'
    },
    {
        country: 'Guinea-Bissau',
        countryCodeNumerical: 624,
        isEU: false,
        countryShort: 'GW'
    },
    {
        country: 'Guyana',
        countryCodeNumerical: 328,
        isEU: false,
        countryShort: 'GY'
    },
    {
        country: 'Haiti',
        countryCodeNumerical: 332,
        isEU: false,
        countryShort: 'HT'
    },
    {
        country: 'Heard and McDonald Islands',
        countryCodeNumerical: 334,
        isEU: false,
        countryShort: 'HM'
    },
    {
        country: 'Honduras',
        countryCodeNumerical: 340,
        isEU: false,
        countryShort: 'HN'
    },
    {
        country: 'Hong Kong',
        countryCodeNumerical: 344,
        isEU: false,
        countryShort: 'HK'
    },
    {
        country: 'Hungary',
        countryCodeNumerical: 348,
        isEU: true,
        countryShort: 'HU'
    },
    {
        country: 'Iceland',
        countryCodeNumerical: 352,
        isEU: false,
        countryShort: 'IS'
    },
    {
        country: 'India',
        countryCodeNumerical: 356,
        isEU: false,
        countryShort: 'IN'
    },
    {
        country: 'Indonesia',
        countryCodeNumerical: 360,
        isEU: false,
        countryShort: 'ID'
    },
    {
        country: 'Iran',
        countryCodeNumerical: 364,
        isEU: false,
        countryShort: 'IR'
    },
    {
        country: 'Iraq',
        countryCodeNumerical: 368,
        isEU: false,
        countryShort: 'IQ'
    },
    {
        country: 'Ireland',
        countryCodeNumerical: 372,
        isEU: true,
        countryShort: 'IE'
    },
    {
        country: 'Isle of Man',
        countryCodeNumerical: 833,
        isEU: false,
        countryShort: 'IM'
    },
    {
        country: 'Israel',
        countryCodeNumerical: 376,
        isEU: false,
        countryShort: 'IL'
    },
    {
        country: 'Italy',
        countryCodeNumerical: 380,
        isEU: true,
        countryShort: 'IT'
    },
    {
        country: 'Jamaica',
        countryCodeNumerical: 388,
        isEU: false,
        countryShort: 'JM'
    },
    {
        country: 'Japan',
        countryCodeNumerical: 392,
        isEU: false,
        countryShort: 'JP'
    },
    {
        country: 'Jersey',
        countryCodeNumerical: 832,
        isEU: false,
        countryShort: 'JE'
    },
    {
        country: 'Jordan',
        countryCodeNumerical: 400,
        isEU: false,
        countryShort: 'JO'
    },
    {
        country: 'Kazakhstan',
        countryCodeNumerical: 398,
        isEU: false,
        countryShort: 'KZ'
    },
    {
        country: 'Kenya',
        countryCodeNumerical: 404,
        isEU: false,
        countryShort: 'KE'
    },
    {
        country: 'Kiribati',
        countryCodeNumerical: 296,
        isEU: false,
        countryShort: 'KI'
    },
    {
        country: 'Kuwait',
        countryCodeNumerical: 414,
        isEU: false,
        countryShort: 'KW'
    },
    {
        country: 'Kyrgyzstan',
        countryCodeNumerical: 417,
        isEU: false,
        countryShort: 'KG'
    },
    {
        country: 'Laos',
        countryCodeNumerical: 418,
        isEU: false,
        countryShort: 'LA'
    },
    {
        country: 'Latvia',
        countryCodeNumerical: 428,
        isEU: true,
        countryShort: 'LV'
    },
    {
        country: 'Lebanon',
        countryCodeNumerical: 422,
        isEU: false,
        countryShort: 'LB'
    },
    {
        country: 'Lesotho',
        countryCodeNumerical: 426,
        isEU: false,
        countryShort: 'LS'
    },
    {
        country: 'Liberia',
        countryCodeNumerical: 430,
        isEU: false,
        countryShort: 'LR'
    },
    {
        country: 'Libya',
        countryCodeNumerical: 434,
        isEU: false,
        countryShort: 'LY'
    },
    {
        country: 'Liechtenstein',
        countryCodeNumerical: 438,
        isEU: false,
        countryShort: 'LI'
    },
    {
        country: 'Lithuania',
        countryCodeNumerical: 440,
        isEU: true,
        countryShort: 'LT'
    },
    {
        country: 'Luxembourg',
        countryCodeNumerical: 442,
        isEU: true,
        countryShort: 'LU'
    },
    {
        country: 'Macau',
        countryCodeNumerical: 446,
        isEU: false,
        countryShort: 'MO'
    },
    {
        country: 'Macedonia',
        countryCodeNumerical: 807,
        isEU: false,
        countryShort: 'MK'
    },
    {
        country: 'Madagascar',
        countryCodeNumerical: 450,
        isEU: false,
        countryShort: 'MG'
    },
    {
        country: 'Malawi',
        countryCodeNumerical: 454,
        isEU: false,
        countryShort: 'MW'
    },
    {
        country: 'Malaysia',
        countryCodeNumerical: 458,
        isEU: false,
        countryShort: 'MY'
    },
    {
        country: 'Maldives',
        countryCodeNumerical: 462,
        isEU: false,
        countryShort: 'MV'
    },
    {
        country: 'Mali',
        countryCodeNumerical: 466,
        isEU: false,
        countryShort: 'ML'
    },
    {
        country: 'Malta',
        countryCodeNumerical: 470,
        isEU: true,
        countryShort: 'MT'
    },
    {
        country: 'Marshall Islands',
        countryCodeNumerical: 584,
        isEU: false,
        countryShort: 'MH'
    },
    {
        country: 'Martinique',
        countryCodeNumerical: 474,
        isEU: false,
        countryShort: 'MQ'
    },
    {
        country: 'Mauritania',
        countryCodeNumerical: 478,
        isEU: false,
        countryShort: 'MR'
    },
    {
        country: 'Mauritius',
        countryCodeNumerical: 480,
        isEU: false,
        countryShort: 'MU'
    },
    {
        country: 'Mayotte',
        countryCodeNumerical: 175,
        isEU: false,
        countryShort: 'YT'
    },
    {
        country: 'Mexico',
        countryCodeNumerical: 484,
        isEU: false,
        countryShort: 'MX'
    },
    {
        country: 'Micronesia',
        countryCodeNumerical: 583,
        isEU: false,
        countryShort: 'FM'
    },
    {
        country: 'Moldova',
        countryCodeNumerical: 498,
        isEU: false,
        countryShort: 'MD'
    },
    {
        country: 'Monaco',
        countryCodeNumerical: 492,
        isEU: false,
        countryShort: 'MC'
    },
    {
        country: 'Mongolia',
        countryCodeNumerical: 496,
        isEU: false,
        countryShort: 'MN'
    },
    {
        country: 'Montenegro',
        countryCodeNumerical: 499,
        isEU: false,
        countryShort: 'ME'
    },
    {
        country: 'Montserrat',
        countryCodeNumerical: 500,
        isEU: false,
        countryShort: 'MS'
    },
    {
        country: 'Morocco',
        countryCodeNumerical: 504,
        isEU: false,
        countryShort: 'MA'
    },
    {
        country: 'Mozambique',
        countryCodeNumerical: 508,
        isEU: false,
        countryShort: 'MZ'
    },
    {
        country: 'Myanmar',
        countryCodeNumerical: 104,
        isEU: false,
        countryShort: 'MM'
    },
    {
        country: 'Namibia',
        countryCodeNumerical: 516,
        isEU: false,
        countryShort: 'NA'
    },
    {
        country: 'Nauru',
        countryCodeNumerical: 520,
        isEU: false,
        countryShort: 'NR'
    },
    {
        country: 'Nepal',
        countryCodeNumerical: 524,
        isEU: false,
        countryShort: 'NP'
    },
    {
        country: 'Netherlands',
        countryCodeNumerical: 528,
        isEU: true,
        countryShort: 'NL'
    },
    {
        country: 'Netherlands Antilles',
        countryCodeNumerical: 530,
        isEU: false,
        countryShort: 'AN'
    },
    {
        country: 'New Caledonia',
        countryCodeNumerical: 540,
        isEU: false,
        countryShort: 'NC'
    },
    {
        country: 'New Zealand',
        countryCodeNumerical: 554,
        isEU: false,
        countryShort: 'NZ'
    },
    {
        country: 'Nicaragua',
        countryCodeNumerical: 558,
        isEU: false,
        countryShort: 'NI'
    },
    {
        country: 'Niger',
        countryCodeNumerical: 562,
        isEU: false,
        countryShort: 'NE'
    },
    {
        country: 'Nigeria',
        countryCodeNumerical: 566,
        isEU: false,
        countryShort: 'NG'
    },
    {
        country: 'Niue',
        countryCodeNumerical: 570,
        isEU: false,
        countryShort: 'NU'
    },
    {
        country: 'Norfolk Island',
        countryCodeNumerical: 574,
        isEU: false,
        countryShort: 'NF'
    },
    {
        country: 'Norfolk Island',
        countryCodeNumerical: 574,
        isEU: false,
        countryShort: 'NF'
    },
    {
        country: 'Northern Mariana Islands',
        countryCodeNumerical: 580,
        isEU: false,
        countryShort: 'MP'
    },
    {
        country: 'North Korea',
        countryCodeNumerical: 408,
        isEU: false,
        countryShort: 'KP'
    },
    {
        country: 'Norway',
        countryCodeNumerical: 578,
        isEU: false,
        countryShort: 'NO'
    },
    {
        country: 'Oman',
        countryCodeNumerical: 512,
        isEU: false,
        countryShort: 'OM'
    },
    {
        country: 'Pakistan',
        countryCodeNumerical: 586,
        isEU: false,
        countryShort: 'PK'
    },
    {
        country: 'Palau',
        countryCodeNumerical: 585,
        isEU: false,
        countryShort: 'PW'
    },
    {
        country: 'Palestine',
        countryCodeNumerical: 275,
        isEU: false,
        countryShort: 'PS'
    },
    {
        country: 'Panama',
        countryCodeNumerical: 591,
        isEU: false,
        countryShort: 'PA'
    },
    {
        country: 'Papua New Guinea',
        countryCodeNumerical: 598,
        isEU: false,
        countryShort: 'PG'
    },
    {
        country: 'Paraguay',
        countryCodeNumerical: 600,
        isEU: false,
        countryShort: 'PY'
    },
    {
        country: 'Peru',
        countryCodeNumerical: 604,
        isEU: false,
        countryShort: 'PE'
    },
    {
        country: 'Philippines',
        countryCodeNumerical: 608,
        isEU: false,
        countryShort: 'PH'
    },
    {
        country: 'Pitcairn',
        countryCodeNumerical: 612,
        isEU: false,
        countryShort: 'PN'
    },
    {
        country: 'Poland',
        countryCodeNumerical: 616,
        isEU: true,
        countryShort: 'PL'
    },
    {
        country: 'Portugal',
        countryCodeNumerical: 620,
        isEU: true,
        countryShort: 'PT'
    },
    {
        country: 'Puerto Rico',
        countryCodeNumerical: 630,
        isEU: false,
        countryShort: 'PR'
    },
    {
        country: 'Qatar',
        countryCodeNumerical: 634,
        isEU: false,
        countryShort: 'QA'
    },
    {
        country: 'Reunion',
        countryCodeNumerical: 638,
        isEU: false,
        countryShort: 'RE'
    },
    {
        country: 'Romania',
        countryCodeNumerical: 642,
        isEU: true,
        countryShort: 'RO'
    },
    {
        country: 'Russia',
        countryCodeNumerical: 643,
        isEU: false,
        countryShort: 'RU'
    },
    {
        country: 'Rwanda',
        countryCodeNumerical: 646,
        isEU: false,
        countryShort: 'RW'
    },
    {
        country: 'Saint Barthélemy',
        countryCodeNumerical: 652,
        isEU: false,
        countryShort: 'BL'
    },
    {
        country: 'Saint Helena',
        countryCodeNumerical: 654,
        isEU: false,
        countryShort: 'SH'
    },
    {
        country: 'Saint Kitts and Nevis',
        countryCodeNumerical: 659,
        isEU: false,
        countryShort: 'KN'
    },
    {
        country: 'Saint Lucia',
        countryCodeNumerical: 662,
        isEU: false,
        countryShort: 'LC'
    },
    {
        country: 'Saint Martin (French part)',
        countryCodeNumerical: 663,
        isEU: false,
        countryShort: 'MF'
    },
    {
        country: 'Saint Pierre and Miquelon',
        countryCodeNumerical: 666,
        isEU: false,
        countryShort: 'PM'
    },
    {
        country: 'Saint Vincent and the Grenadines',
        countryCodeNumerical: 670,
        isEU: false,
        countryShort: 'VC'
    },
    {
        country: 'Samoa',
        countryCodeNumerical: 882,
        isEU: false,
        countryShort: 'WS'
    },
    {
        country: 'San Marino',
        countryCodeNumerical: 674,
        isEU: false,
        countryShort: 'SM'
    },
    {
        country: 'Sao Tome and Principe',
        countryCodeNumerical: 678,
        isEU: false,
        countryShort: 'ST'
    },
    {
        country: 'Saudi Arabia',
        countryCodeNumerical: 682,
        isEU: false,
        countryShort: 'SA'
    },
    {
        country: 'Senegal',
        countryCodeNumerical: 686,
        isEU: false,
        countryShort: 'SN'
    },
    {
        country: 'Serbia',
        countryCodeNumerical: 688,
        isEU: false,
        countryShort: 'RS'
    },
    {
        country: 'Seychelles',
        countryCodeNumerical: 690,
        isEU: false,
        countryShort: 'SC'
    },
    {
        country: 'Sierra Leone',
        countryCodeNumerical: 694,
        isEU: false,
        countryShort: 'SL'
    },
    {
        country: 'Singapore',
        countryCodeNumerical: 702,
        isEU: false,
        countryShort: 'SG'
    },
    {
        country: 'Sint Maarten (Dutch part)',
        countryCodeNumerical: 534,
        isEU: false,
        countryShort: 'SX'
    },
    {
        country: 'Slovakia',
        countryCodeNumerical: 703,
        isEU: true,
        countryShort: 'SK'
    },
    {
        country: 'Slovenia',
        countryCodeNumerical: 705,
        isEU: true,
        countryShort: 'SI'
    },
    {
        country: 'Solomon Islands',
        countryCodeNumerical: 90,
        isEU: false,
        countryShort: 'SB'
    },
    {
        country: 'Somalia',
        countryCodeNumerical: 706,
        isEU: false,
        countryShort: 'SO'
    },
    {
        country: 'South Africa',
        countryCodeNumerical: 710,
        isEU: false,
        countryShort: 'ZA'
    },
    {
        country: 'South Georgia and South Sandwich Islands',
        countryCodeNumerical: 239,
        isEU: false,
        countryShort: 'GS'
    },
    {
        country: 'South Korea',
        countryCodeNumerical: 410,
        isEU: false,
        countryShort: 'KR'
    },
    {
        country: 'South Sudan',
        countryCodeNumerical: 728,
        isEU: false,
        countryShort: 'SS'
    },
    {
        country: 'Spain',
        countryCodeNumerical: 724,
        isEU: true,
        countryShort: 'ES'
    },
    {
        country: 'Sri Lanka',
        countryCodeNumerical: 144,
        isEU: false,
        countryShort: 'LK'
    },
    {
        country: 'Sudan',
        countryCodeNumerical: 736,
        isEU: false,
        countryShort: 'SD'
    },
    {
        country: 'Suriname',
        countryCodeNumerical: 740,
        isEU: false,
        countryShort: 'SR'
    },
    {
        country: 'Svalbard and Jan Mayen Islands',
        countryCodeNumerical: 744,
        isEU: false,
        countryShort: 'SJ'
    },
    {
        country: 'Swaziland',
        countryCodeNumerical: 748,
        isEU: false,
        countryShort: 'SZ'
    },
    {
        country: 'Sweden',
        countryCodeNumerical: 752,
        isEU: true,
        countryShort: 'SE'
    },
    {
        country: 'Switzerland',
        countryCodeNumerical: 756,
        isEU: false,
        countryShort: 'CH'
    },
    {
        country: 'Syria',
        countryCodeNumerical: 760,
        isEU: false,
        countryShort: 'SY'
    },
    {
        country: 'Taiwan',
        countryCodeNumerical: 158,
        isEU: false,
        countryShort: 'TW'
    },
    {
        country: 'Tajikistan',
        countryCodeNumerical: 762,
        isEU: false,
        countryShort: 'TJ'
    },
    {
        country: 'Tanzania',
        countryCodeNumerical: 834,
        isEU: false,
        countryShort: 'TZ'
    },
    {
        country: 'Thailand',
        countryCodeNumerical: 764,
        isEU: false,
        countryShort: 'TH'
    },
    {
        country: 'Timor-Leste',
        countryCodeNumerical: 626,
        isEU: false,
        countryShort: 'TL'
    },
    {
        country: 'Togo',
        countryCodeNumerical: 768,
        isEU: false,
        countryShort: 'TG'
    },
    {
        country: 'Tokelau',
        countryCodeNumerical: 772,
        isEU: false,
        countryShort: 'TK'
    },
    {
        country: 'Tonga',
        countryCodeNumerical: 776,
        isEU: false,
        countryShort: 'TO'
    },
    {
        country: 'Trinidad and Tobago',
        countryCodeNumerical: 780,
        isEU: false,
        countryShort: 'TT'
    },
    {
        country: 'Tunisia',
        countryCodeNumerical: 788,
        isEU: false,
        countryShort: 'TN'
    },
    {
        country: 'Turkey',
        countryCodeNumerical: 792,
        isEU: false,
        countryShort: 'TR'
    },
    {
        country: 'Turkmenistan',
        countryCodeNumerical: 795,
        isEU: false,
        countryShort: 'TM'
    },
    {
        country: 'Turks and Caicos Islands',
        countryCodeNumerical: 796,
        isEU: false,
        countryShort: 'TC'
    },
    {
        country: 'Tuvalu',
        countryCodeNumerical: 798,
        isEU: false,
        countryShort: 'TV'
    },
    {
        country: 'Uganda',
        countryCodeNumerical: 800,
        isEU: false,
        countryShort: 'UG'
    },
    {
        country: 'Ukraine',
        countryCodeNumerical: 804,
        isEU: false,
        countryShort: 'UA'
    },
    {
        country: 'United Arab Emirates',
        countryCodeNumerical: 784,
        isEU: false,
        countryShort: 'AE'
    },
    {
        country: 'United Kingdom',
        countryCodeNumerical: 826,
        isEU: false,
        countryShort: 'GB'
    },
    {
        country: 'United States Minor Outlying Islands',
        countryCodeNumerical: 581,
        isEU: false,
        countryShort: 'UM'
    },
    {
        country: 'United States',
        countryCodeNumerical: 840,
        isEU: false,
        countryShort: 'US'
    },
    {
        country: 'Uruguay',
        countryCodeNumerical: 858,
        isEU: false,
        countryShort: 'UY'
    },
    {
        country: 'Uzbekistan',
        countryCodeNumerical: 860,
        isEU: false,
        countryShort: 'UZ'
    },
    {
        country: 'Vanuatu',
        countryCodeNumerical: 548,
        isEU: false,
        countryShort: 'VU'
    },
    {
        country: 'Vatican City',
        countryCodeNumerical: 336,
        isEU: false,
        countryShort: 'VA'
    },
    {
        country: 'Venezuela',
        countryCodeNumerical: 862,
        isEU: false,
        countryShort: 'VE'
    },
    {
        country: 'Vietnam',
        countryCodeNumerical: 704,
        isEU: false,
        countryShort: 'VN'
    },
    {
        country: 'British Virgin Islands',
        countryCodeNumerical: 92,
        isEU: false,
        countryShort: 'VG'
    },
    {
        country: 'U.S. Virgin Islands',
        countryCodeNumerical: 850,
        isEU: false,
        countryShort: 'VI'
    },
    {
        country: 'Wallis and Futuna Islands',
        countryCodeNumerical: 876,
        isEU: false,
        countryShort: 'WF'
    },
    {
        country: 'Western Sahara',
        countryCodeNumerical: 732,
        isEU: false,
        countryShort: 'EH'
    },
    {
        country: 'Yemen',
        countryCodeNumerical: 887,
        isEU: false,
        countryShort: 'YE'
    },
    {
        country: 'Zambia',
        countryCodeNumerical: 894,
        isEU: false,
        countryShort: 'ZM'
    },
    {
        country: 'Zimbabwe',
        countryCodeNumerical: 716,
        isEU: false,
        countryShort: 'ZW'
    }
];
