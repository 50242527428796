class searchController {
    constructor(
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $interval,
        anchorScrollService,
        translationService,
        meetingApiService,
        $filter,
        userService,
        locationService
    ) {
        Object.assign(this, {
            $rootScope,
            $scope,
            $state,
            $stateParams,
            $interval,
            anchorScrollService,
            translationService,
            meetingApiService,
            $filter,
            userService,
            locationService
        });
        this.isShowingFilter = false;
    }

    $onInit() {
        this.filter = {
            sort: 'all'
        };
        this.timeout;
        this.searchTerm = '';
        this.initialStateTitle = this.translationService.translate('search');
        this.initialStateDescription = this.translationService.translate('client_SearchInitialDescription');
        if (this.$stateParams.term) {
            this._getSearchResult(this.$stateParams.term);
        }
        this.$rootScope.$on('$stateChangeSuccess', (ev, to, toParams, from, fromParams) => {
            if (fromParams.searchRoutedFromId && to.name === 'search') {
                this._startPoll(fromParams.searchRoutedFromId);
            }
        });
    }

    handleOnSearch($event) {
        clearTimeout(this.timeout);
        this.searchTerm = $event.searchTerm;
        this.$state.go(
            'search',
            { term: this.searchTerm },
            {
                notify: false,
                reload: false,
                location: 'replace',
                inherit: true
            }
        );
        if (this.searchTerm.length === 0) {
            this.searchResult = undefined;
        }
        this.timeout = setTimeout(() => {
            if (this.searchTerm.length > 2) {
                this.loading = true;
                this._getSearchResult(this.searchTerm);
            }
        }, 1000);
    }

    navigate(item) {
        this.locationService.navigateTo(item);
    }

    translateType(type) {
        if (type === 'Action') {
            return this.translationService.translate('client_Action');
        }
        if (type === 'Agenda') {
            return this.translationService.translate('client_AgendaItem');
        }
        if (type === 'Decision') {
            return this.translationService.translate('meetings_Decision');
        }
        if (type === 'Document') {
            return this.translationService.translate('meetings_AgendaDocuments');
        }
        if (type === 'Meeting') {
            return this.translationService.translate('client_Meeting');
        }
    }

    _startPoll(id) {
        this.checkForDomElement = this.$interval(
            () => {
                this.$scope.$evalAsync(() => {
                    if (document.getElementById(id)) {
                        this._goTo(id);
                        this._cancelPoll();
                    }
                    if (document.getElementById(id)) {
                        this._goTo(id);
                        this._cancelPoll();
                    }
                });
            },
            200,
            0,
            false
        );
    }

    _cancelPoll() {
        this.$interval.cancel(this.checkForDomElement);
    }

    _goTo(id) {
        this.anchorScrollService.scrollTo(id, 'search-container', 0);
    }

    _getSearchResult(searchTerm) {
        this.searchTerm = searchTerm;
        this.meetingApiService
            .getSearchResults(searchTerm)
            .then((res) => {
                this.searchResult = this._formatDesponseData(res.data);
                if (!this.searchResult.length) {
                    this._updateNoHitsState();
                }
            })
            .finally(() => {
                this.loading = false;
            });
    }

    _buildQueryString() {}

    _updateNoHitsState() {
        this.noDataTitle = this.translationService.translate('client_NoResultFound');
        this.noDataDescription = this.translationService.translate('client_NoItemMatchingSearch');
        this.noDataIcon = 'search';
    }

    _formatDesponseData(array) {
        array = this._formatDates(array);
        array = this._defineIcons(array);
        array = this._assignScrollToId(array);
        return array;
    }

    _assignScrollToId(array) {
        return array.map((item, index) => {
            item.scrollToId = index + 1;
            return item;
        });
    }

    _formatDates(array) {
        return array.map((item) => {
            item.date = this.$filter('date')(new Date(item.date), 'rymDate');
            return item;
        });
    }

    _defineIcons(array) {
        return array.map((item) => {
            if (item.typeName === 'Action') {
                item.icon = 'check';
            }
            if (item.typeName === 'Agenda') {
                item.icon = 'tasks';
            }
            if (item.typeName === 'Decision') {
                item.icon = 'list-ul';
            }
            if (item.typeName === 'Document') {
                item.icon = 'paperclip';
            }
            if (item.typeName === 'Meeting') {
                item.icon = 'calendar';
            }
            return item;
        });
    }

    updateIsFilterActive() {}

    toggleFilter() {
        this.isShowingFilter = !this.isShowingFilter;
    }

    closeFilter() {
        this.isShowingFilter = false;
    }
}

searchController.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$interval',
    'anchorScrollService',
    'translationService',
    'meetingApiService',
    '$filter',
    'userService',
    'locationService'
];

export default searchController;
