import '../routerHelperProvider';
import 'angular-jsvat';
import subscriptionRoute from './subscription.routes';
import subscriptionComponent from './subscription.component';
import subscriptionSummaryComponent from './subscriptionSummary/subscriptionSummary.component';
import subscriptionUpgradeDialogComponent from './subscriptionUpgradeDialog/subscriptionUpgradeDialog.component';
import subscriptionCheckoutComponent from './subscriptionCheckout/subscriptionCheckout.component';
import subscriptionCardFormComponent from './subscriptionCardForm/subscriptionCardForm.component';
import subscriptionFormSummaryComponent from './subscriptionFormSummary/subscriptionFormSummary.component';
import subscriptionContactFormComponent from './subscriptionContactForm/subscriptionContactForm.component';

angular
    .module('rym.subscription', ['rym.routes', 'rym.common', 'angular-jsvat'])
    .component('rymSubscription', subscriptionComponent)
    .component('rymSubscriptionSummary', subscriptionSummaryComponent)
    .component('rymSubscriptionUpgradeDialog', subscriptionUpgradeDialogComponent)
    .component('rymSubscriptionCheckout', subscriptionCheckoutComponent)
    .component('rymSubscriptionCardForm', subscriptionCardFormComponent)
    .component('rymSubscriptionFormSummary', subscriptionFormSummaryComponent)
    .component('rymSubscriptionContactForm', subscriptionContactFormComponent)
    .run(subscriptionRoute);
