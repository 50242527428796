const MAX_SIGNATURES = 4;

class exportMeetingDialogController {
    constructor(meetingApiService, translationService) {
        Object.assign(this, { meetingApiService, translationService });
        this.exportTitle = this.translationService.translate('meetings_Protocol');
        this._addSignature(this.translationService.translate('client_Moderator'));
    }

    $onInit() {
        this.isLoading = true;
        this.meetingApiService.getParticipantsForMeeting(this.options.meetingId).then((participants) => {
            this.participants = participants;
            this.possibleItems = participants;
            this.isLoading = false;
        });
    }

    addSignature() {
        if (!this.canAddSignatures()) {
            return;
        }
        this._addSignature(this.translationService.translate('client_Secretary'));
    }

    _addSignature(title) {
        let that = this;
        if (this.signatures == null) {
            this.signatures = [];
        }
        this.signatures.push({
            title: title,
            user: [],
            addToSelected: function (item) {
                this.user = [item];
                that.possibleItems = _.difference(
                    that.participants,
                    that.signatures.map((x) => x.user[0])
                );
            }
        });
    }

    removeSignature() {
        this.signatures.pop();
        this.possibleItems = _.difference(
            this.participants,
            this.signatures.map((x) => x.user[0])
        );
    }

    canAddSignatures() {
        return this.signatures.length < this.participants.length;
    }

    getAddTooltip() {
        if (this.canAddSignatures()) {
            return this.translationService.translate('client_Add');
        } else {
            return this.translationService.translate('client_CanNotAddMoreSignaturesThanParticipants');
        }
    }

    export() {
        let signatures = [];

        if (this.exportWithSignatures) {
            _.forEach(
                _.filter(this.signatures, (sign) => sign.user.length > 0),
                (signature) => {
                    signatures.push({ title: signature.title, name: signature.user[0].name });
                }
            );
        }

        let exportOptions = {
            meetingId: this.options.meetingId,
            exportTitle: this.exportTitle,
            signatureModel: {
                signatures: signatures
            }
        };

        this.onOk({ data: exportOptions });
    }
}

exportMeetingDialogController.$inject = ['meetingApiService', 'translationService'];

export default exportMeetingDialogController;
