import template from './agendaNav.html';
import controller from './agendaNav.controller';
import './agendaNav.less';

let agendaNavComponent = {
    template,
    controller,
    bindings: {
        onDragDrop: '&',
        items: '=',
        handleHidden: '<',
        goTo: '&',
        scrollOffset: '<'
    }
};

export default agendaNavComponent;
