import { FEES, FEES_WITH_DISCOUNT, NUMBER_OF_FREE_LICENSES } from '../../../app.constants';

const INJECT = [
    '$q',
    '$state',
    '$timeout',
    'paymentPlanService',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'organizationService',
    'anchorScrollService',
    'userService',
    'toastService',
    'subscriptionApiService',
    'eventEmitterService',
    'envService'
];

class SubscriptionService {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });

        this.isStripeInitialized = false;
        this.current = {};
    }

    initializeStripe() {
        let deferred = this.$q.defer();

        if (this.isStripeInitialized) {
            deferred.resolve();
        } else {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://js.stripe.com/v3/';
            document.body.appendChild(script);

            this._defineStripe(deferred, 1);
        }
        return deferred.promise;
    }

    _defineStripe(deferred, counter) {
        this.$timeout(() => {
            if (window.hasOwnProperty('Stripe')) {
                this.stripe = Stripe(this.envService.STRIPE_PUBLIC_KEY);
                this.isStripeInitialized = true;
                deferred.resolve();
            } else if (counter < 5) {
                counter++;
                this._defineStripe(deferred, counter);
            } else {
                this.$state.go('meetings');
            }
        }, 100);
    }

    getStripe() {
        return this.stripe;
    }

    clearCache() {
        this.current = {};
    }

    goToSubscriptionView() {
        this.$state.go('subscription');
    }

    getPaymentText(currency, interval, amount) {
        let priceFormat = currency.toLowerCase() === 'usd' ? 'client_PriceInUsd' : 'client_PriceInSek';
        let priceFormatInterval = interval.toLowerCase() === 'year' ? 'client_PerYear' : 'client_PerMonth';
        let price = this.translationService.translate(priceFormat, {
            price: Math.abs(amount)
        });
        if (amount < 0) {
            price = `-${price}`;
        }
        return this.translationService.translate(priceFormatInterval, { price });
    }

    getDiscountText(currency, isAnnually, licenses, interval) {
        let feesWithDiscount = FEES;
        if (isAnnually) {
            feesWithDiscount = FEES_WITH_DISCOUNT;
        }
        let discountedFees = currency === 'USD' ? feesWithDiscount.USD : feesWithDiscount.SEK;
        let fees = currency === 'USD' ? FEES.USD : FEES.SEK;
        let monthlyFeeWithDiscount = (licenses - NUMBER_OF_FREE_LICENSES) * discountedFees.MONTHLY_LICENSE_FEE + discountedFees.START_FEE;
        let monthlyFee = (licenses - NUMBER_OF_FREE_LICENSES) * fees.MONTHLY_LICENSE_FEE + fees.START_FEE;
        if (interval === 'year') {
            return this.getPaymentText(currency, 'year', 12 * (monthlyFeeWithDiscount - monthlyFee));
        } else {
            return this.getPaymentText(currency, 'month', monthlyFeeWithDiscount - monthlyFee);
        }
    }

    subscribe(customer, subscriptionDto, formData) {
        this.current.formData = formData;
        let deferred = this.$q.defer();
        this.subscriptionApiService.createCustomer(customer).then((result) => {
            this.current.customerId = result.id;
            this._createPaymentMethod().then(() => {
                subscriptionDto.paymentMethodId = this.current.paymentMethodId;
                subscriptionDto.customerId = this.current.customerId;
                this._createSubscription(subscriptionDto)
                    .then((res) => {
                        this._handleResponse(res)
                            .then(() => {
                                deferred.resolve();
                            })
                            .catch((response) => {
                                deferred.reject(response);
                            });
                    })
                    .catch((err) => {
                        deferred.reject(err);
                    });
            });
        });
        return deferred.promise;
    }

    getInvoicePreview(updatedSubscription, options) {
        return this.subscriptionApiService.invoicePreview(updatedSubscription).then((res) => {
            options.title = this.translationService.translate('client_UpgradeSubscription');
            options.ok = this.translationService.translate('client_Confirm');
            if (this._isToday(new Date(res.paymentAttempt))) {
                options.immediatePrice = res.totalPrice;
                options.immediatePriceWithTax = res.totalPriceWithTax;
            } else {
                options.immediatePrice = 0;
                options.immediatePriceWithTax = 0;
            }

            options.currency = res.currency;
            options.interval = res.interval;
            return options;
        });
    }

    _isToday(someDate) {
        const today = new Date();
        return someDate.getDate() == today.getDate() && someDate.getMonth() == today.getMonth() && someDate.getFullYear() == today.getFullYear();
    }

    updateSubscription(updateSubscriptionDto) {
        let deferred = this.$q.defer();
        this.subscriptionApiService.update(updateSubscriptionDto).then((res) => {
            this._handleResponse(res)
                .then(() => {
                    deferred.resolve();
                })
                .catch((response) => {
                    deferred.reject(response);
                });
        });
        return deferred.promise;
    }

    updateCardDetails(formData) {
        let deferred = this.$q.defer();
        this.stripe
            .createPaymentMethod({
                type: 'card',
                card: formData.cardElement,
                billing_details: {
                    name: formData.cardName
                }
            })
            .then((result) => {
                this.subscriptionApiService.updateCard(result.paymentMethod.id).then((res) => {
                    deferred.resolve();
                });
            });
        return deferred.promise;
    }

    updateContactAndBillingDetails(contactFormData) {
        let billingInformation = {
            email: contactFormData.email,
            name: contactFormData.name,
            country: contactFormData.country.countryShort,
            city: contactFormData.city,
            address: contactFormData.address,
            postalCode: contactFormData.postalCode,
            taxId: contactFormData.vat
        };
        return this.subscriptionApiService.updateContactAndBillingDetails(billingInformation).then((res) => {
            return res;
        });
    }

    retryInvoiceWithNewPaymentMethod(latestInvoiceId, formData) {
        this.current.formData = formData;
        let deferred = this.$q.defer();
        this._createPaymentMethod().then(() => {
            let retryObject = {
                paymentMethodId: this.current.paymentMethodId,
                latestInvoiceId: latestInvoiceId
            };
            this.subscriptionApiService.retryPaymentWithNewPaymentMethod(retryObject).then((result) => {
                this._handleResponse(result)
                    .then(() => {
                        deferred.resolve();
                    })
                    .catch((res) => {
                        deferred.reject(res);
                    });
            });
        });
        return deferred.promise;
    }

    _handleResponse(res) {
        let deferred = this.$q.defer();
        if (res.status === 'requires_action') {
            this._confirmCardPayment(res).then((result) => {
                if (result.error) {
                    deferred.reject(res);
                } else {
                    if (result.paymentIntent.status == 'succeeded') {
                        deferred.resolve();
                    }
                }
            });
        } else if (!res.status || res.status === 'succeeded') {
            deferred.resolve();
        } else if (res.status === 'requires_payment_method') {
            deferred.reject(res);
        }
        return deferred.promise;
    }

    _createPaymentMethod() {
        return this.stripe
            .createPaymentMethod({
                type: 'card',
                card: this.current.formData.cardElement,
                billing_details: {
                    name: this.current.formData.cardName
                }
            })
            .then((result) => {
                this.current.paymentMethodId = result.paymentMethod.id;
            });
    }

    _createSubscription(subscriptionDto) {
        let deferred = this.$q.defer();
        this.subscriptionApiService
            .subscribe(subscriptionDto)
            .then((result) => {
                deferred.resolve(result);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }

    _confirmCardPayment(res) {
        return this.stripe.confirmCardPayment(res.clientSecret, { payment_method: this.current.paymentMethodId }).then((result) => {
            return result;
        });
    }

    setSubscriptionRequiresAction() {
        let promises = [this.organizationService.getCurrentOrganization(), this.organizationService.getCurrentPlan()];
        this.$q.all(promises).then((result) => {
            if (result[0].paymentPlan.includes('seatBased') && !result[1].usingInvoice) {
                this.subscriptionApiService.getCustomer().then((res) => {
                    this.requiresAction =
                        result[0].paymentStatus === 'failed' ||
                        res.defaultCreditCard === null ||
                        (new Date(`${res.defaultCreditCard.expYear}-${res.defaultCreditCard.expMonth}`).getMonth() === new Date().getMonth() &&
                            new Date(`${res.defaultCreditCard.expYear}-${res.defaultCreditCard.expMonth}`).getFullYear() ===
                                new Date().getFullYear());
                    this.eventEmitterService.publishEvent('updateSubscriptionRequiresAction');
                });
            }
        });
    }

    getRequiresAction() {
        return this.requiresAction;
    }
}

SubscriptionService.$inject = INJECT;

export default SubscriptionService;
