class FeatureToggleService {
    constructor($q, userApiService) {
        Object.assign(this, { $q, userApiService });
    }

    getAllFeatureToggles() {
        return this.userApiService.getFeatureTogglesForCurrentUser().then((features) => {
            this.featureToggles = features;
        });
    }

    isEnabled(name) {
        let deferred = this.$q.defer();

        if (this.featureToggles) {
            deferred.resolve(this._isEnabled(name));
        } else {
            this.getAllFeatureToggles().then(() => {
                deferred.resolve(this._isEnabled(name));
            });
        }

        return deferred.promise;
    }

    _isEnabled(name) {
        if (_.has(this.featureToggles, name.toLowerCase())) {
            return this.featureToggles[name.toLowerCase()];
        } else {
            return false;
        }
    }
}

FeatureToggleService.$inject = ['$q', 'userApiService'];

export default FeatureToggleService;
