function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: '404',
            config: {
                url: '/404',
                template: '<rym-404><rym-404>'
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
