import { HTTP_STATUS_CODES, FEATURE_TOGGLES } from '../../../app.constants';

class meetingDetailsController {
    constructor(
        $filter,
        $scope,
        $q,
        $element,
        $document,
        $window,
        meetingApiService,
        categoryService,
        dialogService,
        organizationService,
        userApiService,
        menuService,
        userService,
        toastService,
        keyCode,
        stringService,
        timeService,
        eventEmitterService,
        recompileService,
        rowService,
        paymentService,
        translationService,
        meetingSeriesService,
        meetingMetadataService,
        participantsService,
        featureToggleService
    ) {
        Object.assign(this, {
            $filter,
            $scope,
            $q,
            $element,
            $document,
            $window,
            meetingApiService,
            categoryService,
            dialogService,
            organizationService,
            userApiService,
            menuService,
            userService,
            toastService,
            keyCode,
            stringService,
            timeService,
            eventEmitterService,
            recompileService,
            rowService,
            paymentService,
            translationService,
            meetingSeriesService,
            meetingMetadataService,
            participantsService,
            featureToggleService
        });
    }

    $onInit() {
        this.setUpTime();
        this.getMeetingDetails();
        this.shouldLockMetaData();
        this.featureToggleService.isEnabled(FEATURE_TOGGLES.EVENTS).then((result) => {
            this.isEventsEnabled = result;
        });
        this.$scope.$on('SavedChanges', (event, data) => {
            this.lastSaved = `${this.translationService.translate('client_Saved')} ${new Date().toLocaleTimeString()}`;
            this.saveStatus = this.translationService.translate('client_SavedChanges');
        });

        this.$scope.$on('UnsavedChanges', (event, data) => {
            this.saveStatus = this.translationService.translate('client_UnsavedChanges');
        });

        this.$scope.$on('RevertedChanges', (event, data) => {
            this.saveStatus = '';
        });

        this.userService.getCurrentUser().then((user) => {
            this.currentUser = user;
        });

        if (this.meeting.isCurrentUserEditor) {
            this.userApiService.getSuggestedParticipants().then((suggestedParticipants) => (this.suggestedParticipants = suggestedParticipants));
        }
        this.eventEmitterService.subscribe('userChangedName', (user) => (this.curentUser = user));
        this.eventEmitterService.subscribe('lockMeeting', this._disableMetaData.bind(this));
        this.eventEmitterService.subscribe('updateUnfinishedActions', this.updateActionRow.bind(this));
        this.eventEmitterService.subscribe('updatePostponedAgendas', this.updatePostponedAgendaRow.bind(this));
        this.eventEmitterService.subscribe('meetingAgendaItemPostponed' + this.meeting.id, this.refetchPostponedAgendasInMeetingSeries.bind(this));
        this.eventEmitterService.subscribe('addPostponedAgendaToMeeting' + this.meeting.id, this.refetchPostponedAgendasInMeetingSeries.bind(this));
        this.eventEmitterService.subscribe(
            'meetingSeriesPostponedAgendasChanged' + this.meeting.meetingSeriesId,
            this.refetchPostponedAgendasInMeetingSeries.bind(this)
        );
    }

    showOrganizerInfo() {
        this.meetingMetadataService.showOrganizerInfo(this.meeting);
    }

    inviteParticipant($event) {
        this.participantsService.add({
            isPresent: true,
            name: $event.item.email,
            email: $event.item.email,
            isExternal: false
        });

        this.participantsService.save();
    }

    getMeetingDetails() {
        this.isLoadingDetails = true;
        this.meetingSeriesService.setMeetingSeriesId(this.meeting.meetingSeriesId);
        let queue = [
            this.getOrganizer(),
            this.getCategories(),
            this.participantsService.init(this.meeting.id),
            this.getActionsInMeetingSeries(),
            this.getPostponedAgendasInMeetingSeries()
        ];

        this.$q.all(queue).then(() => {
            this.buildRowData();
            this.participantsService.onChange((changes) => {
                this.participants = changes.participants.filter((participant) => this.participantsService.isParticipant(participant));
            });
            this.isEditor = this.participantsService.isCurrentEditor();
            this.isLoadingDetails = false;
        });
    }

    getActionsInMeetingSeries() {
        return this.meetingSeriesService.getActionsInMeetingSeries().then((actions) => (this.actionsForSeries = actions));
    }

    updateActionRow() {
        this.getActionsInMeetingSeries().then(() => {
            this.actionsRow = {
                icon: 'check',
                title: this.translationService.translate('client_Actions'),
                description: this.translationService.translate('client_NumberOfUnfinishedActions', {
                    number: _.filter(this.actionsForSeries, (a) => !a.isFinished).length
                })
            };

            this.activeElement = this.$element[0].querySelector('[row="::$ctrl.actionsRow"]');

            this._recompile(null, null, true);
        });
    }

    getPostponedAgendasInMeetingSeries() {
        return this.meetingSeriesService
            .getPostponedAgendasInMeetingSeries()
            .then((agendaResult) => (this.numberOfPostponedAgendasForSeries = agendaResult.count));
    }

    updatePostponedAgendaRow() {
        this.getPostponedAgendasInMeetingSeries().then(() => {
            this.postponedAgendaRow = {
                icon: 'format_list_numbered',
                title: this.translationService.translate('client_PostponedAgendas'),
                description: this.translationService.translate('client_NumberOfPostponedAgendas', { count: this.numberOfPostponedAgendasForSeries })
            };

            this.activeElement = this.$element[0].querySelector('[row="::$ctrl.postponedAgendaRow"]');

            this._recompile(null, null, true);
        });
    }

    refetchPostponedAgendasInMeetingSeries(agenda) {
        this.meetingSeriesService.invalidateCache();
        this.updatePostponedAgendaRow();
    }

    setUpTime() {
        this.durations = this.timeService.getMeetingDurationArray();
        this.meeting.startDate = new Date(this.meeting.startDate);
        this.meeting.startTime = this.timeService.getTimeAsString(this.meeting.startDate);

        if (this.meeting.endDate) {
            this.meeting.endDate = new Date(this.meeting.endDate);
        }
        this.startTimes = this.timeService.getMeetingTimesArray(this.meeting.startDate);
        if (this.meeting.endDate) {
            this._setDuration();
        }
    }

    _setDuration() {
        this.durationString = this.timeService.getDuration(this.meeting.startDate, this.meeting.endDate);
        this.duration = _.find(this.durations, (d) => {
            return d.time.includes(this.durationString);
        });
        if (!this.duration) {
            this.duration = this.timeService.findClosestIndexInDurationArray(this.durationString, this.durations);
        }
    }

    buildRowData() {
        this.previousMeetingRow = {
            icon: 'event_note',
            title: this.translationService.translate('client_Meetings'),
            description: this.translationService.translate('client_AllMeetingsInSeries')
        };

        this.decisionsRow = {
            icon: 'gavel',
            title: this.translationService.translate('client_Decisions'),
            description: this.translationService.translate('client_AllDecisionsInSeries')
        };

        this.eventsRow = {
            icon: 'history',
            title: this.translationService.translate('client_Events'),
            description: this.translationService.translate('client_AllEventsInSeries')
        };

        this.locationRow = {
            icon: 'place',
            title: this.translationService.translate('meetings_Location'),
            placeholder: this.translationService.translate('meetings_Location'),
            inlineEdit: !this.lockMetaData,
            description: this.meeting.location === 'null' ? '' : this.meeting.location
        };
        this.organizerRow = {
            title: this.translationService.translate('client_Organizer'),
            description: this.organizer.name,
            user: {
                name: this.organizer.name,
                email: this.organizer.email
            },
            isDisabled: true
        };

        this.actionsRow = {
            icon: 'check',
            title: this.translationService.translate('client_Actions'),
            description: this.translationService.translate('client_NumberOfUnfinishedActions', {
                number: _.filter(this.actionsForSeries, (a) => !a.isFinished).length
            })
        };

        this.postponedAgendaRow = {
            icon: 'format_list_numbered',
            title: this.translationService.translate('client_PostponedAgendas'),
            description: this.translationService.translate('client_NumberOfPostponedAgendas', { count: this.numberOfPostponedAgendasForSeries })
        };

        this.dateRow = {
            icon: 'event',
            title: this.translationService.translate('client_Date'),
            description: this.$filter('date')(this.meeting.startDate, 'rymDate')
        };

        this.startTimeRow = {
            icon: 'schedule',
            title: this.translationService.translate('client_StartTime'),
            description: this.$filter('date')(this.meeting.startDate, 'HH:mm')
        };

        this.endTimeRow = {
            icon: 'timer',
            title: this.translationService.translate('client_Duration'),
            description: this.meeting.endDate ? this.durationString : '---'
        };

        this.showJoinUrl = this.meeting.onlineMeetingJoinUrl !== null;
        this.onlineJoinUrlRow = {
            icon: 'open_in_new',
            title: this.translationService.translate('client_MicrosoftTeamsMeetingTitle'),
            description: this.translationService.translate('client_MicrosoftTeamsMeetingDescription')
        };

        this.categoryRow = {
            icon: this.category ? this.category.colorId : 0,
            title: this.translationService.translate('client_Category'),
            description: this.category ? this.category.name : this.translationService.translate('client_Uncategorized')
        };

        this.initialized = true;
    }

    _setActiveElement($event) {
        this.activeElement = $event.currentTarget ? $event.currentTarget : this.$document[0].activeElement.parentElement;
    }

    getOrganizer() {
        return this.meetingApiService.getOrganizer(this.meeting.id).then((result) => (this.organizer = result));
    }

    shouldLockMetaData() {
        if (this.meeting.lockMetaData) {
            this.lockMetaData = true;
        } else {
            this.lockMetaData = this.meeting.protocolIsLocked;
        }

        if (this.lockMetaData) {
            this._toggleTabIndex();
        }

        if (this.locationRow) {
            this.locationRow.inlineEdit = !this.lockMetaData;

            this.recompileService.recompile(this.$scope, angular.element(this.$document[0].getElementById('locationRow')));
        }
    }

    getCategories() {
        return this.categoryService.getAll().then((result) => {
            this.categories = angular.copy(result);

            this.categories.unshift({
                id: null,
                colorId: 0,
                name: this.translationService.translate('client_Uncategorized')
            });

            this.category = _.find(this.categories, (category) => category.id === this.meeting.categoryId);
        });
    }

    showStartTimes($event) {
        this._setActiveElement($event);
        this.isShowingStartTime = true;
    }

    showEndTimes($event) {
        if (this.lockMetaData) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            return;
        }

        this._setActiveElement($event);
        this.isShowingEndTime = true;
    }

    joinOnlineMeeting($event) {
        this.$window.open(this.meeting.onlineMeetingJoinUrl, '_blank');
    }

    showCategories($event) {
        if (this.lockMetaData || (this.currentUser.isExternal && this.categories.length < 2)) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            return;
        }

        this._setActiveElement($event);

        let options = {
            searchPlaceholder: this.translationService.translate('search'),
            items: this.categories.map((x) => {
                return {
                    isDefault: this.category.id === x.id,
                    id: x.id,
                    value: x.name,
                    icon: `<div class="height(0-3) width(0-3) rounded(small) left(0-2) bg-color-${x.colorId}"></div>`
                };
            }),
            onClick: (data) => {
                let loadElement = this.$document[0].getElementById('categoryRow');
                this.rowService.playLoadingAnimation(loadElement);
                this.updateCategory(_.find(this.categories, (category) => category.id === data.id)).then(() => {
                    this.rowService.playDoneAnimation(loadElement);
                });
            }
        };

        if (!this.currentUser.isExternal) {
            options = {
                ...options,
                onSecondaryActionText: this.translationService.translate('client_CreateCategory'),
                onSecondaryAction: () => {
                    let options = {
                        title: this.translationService.translate('client_CreateCategory'),
                        ok: this.translationService.translate('client_Create'),
                        cancel: this.translationService.translate('cancel')
                    };
                    return this.categoryService.showCategoryDialog(options).then((category) => {
                        this.rowService.playLoadingAnimation(this.activeElement);
                        this.categoryService
                            .create(category)
                            .then((createdCategory) => this.getCategories().then(() => this.updateCategory(createdCategory)));
                    });
                }
            };
        }

        this.menuService.dropdownMenu(options, angular.element(this.activeElement), false);
    }

    showDate($event) {
        if (this.lockMetaData) {
            return;
        }

        this._setActiveElement($event);
        let options = {
            date: this.meeting.startDate
        };

        this.menuService.dateMenu(options, angular.element(this.activeElement), false, false).then((date) => {
            this.updateStartDate(date);
        });
    }

    addParticipant($event) {
        let user = $event.item;
        this.participantsService.add(user);
        this.participantsService.save();
    }

    removeParticipant(user) {
        this.participantsService.remove(user);
        this.participantsService.save();
    }

    updateLocation($event) {
        return this.meetingApiService.putLocation(this.meeting.id, $event.value);
    }

    updateStartDate(date) {
        let loadElement = this.$document[0].getElementById('dateRow');
        this.rowService.playLoadingAnimation(loadElement);
        return this.meetingApiService
            .putStartDate(this.meeting.id, date)
            .then((result) => {
                this.meeting.startDate = new Date(result.startDate);
                this.dateRow.description = this.$filter('date')(this.meeting.startDate, 'rymDate');
                this.eventEmitterService.publishEvent('meetingStartDateUpdated', result.date);
            })
            .finally(() => {
                this.activeElement = loadElement;
                this._recompile();
            });
    }

    updateCategory(category) {
        return this.meetingApiService.putCategory(this.meeting.id, category.id).then(() => {
            this.category = category;
            this.meeting.categoryId = category.id;
            this.categoryRow.icon = category.colorId;
            this.categoryRow.description = category.name;
        });
    }

    setStartTime($event) {
        if ($event && $event.keyCode === this.keyCode.ENTER) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            this.updateStartTime({ time: this.stringService.tryParseTimeString(this.meeting.startTime) }, this.startTimeForm.start.$dirty);
        }
    }

    setEndTime($event) {
        if ($event && $event.keyCode === this.keyCode.ENTER) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            this.updateEndTime(this.durationString);
        }
    }

    updateStartTime(startTime, isDirty) {
        this.closeStartTime();
        if (isDirty) {
            let loadElement = this.$document[0].getElementById('startTime');
            this.rowService.playLoadingAnimation(loadElement);
            this.isShowingStartTime = false;
            return this.meetingApiService
                .putStartTime(this.meeting.id, this.timeService.setTimeFromString(this.meeting.startDate, startTime.time))
                .then((result) => {
                    this.meeting.startDate = new Date(result.startDate);
                    this.meeting.endDate = new Date(result.endDate);
                    this.meeting.startTime = this.timeService.getTimeAsString(this.meeting.startDate);

                    if (this.durationString && this.meeting.endDate) {
                        this._setDuration();
                    }
                    this.startTimeRow.description = this.$filter('date')(this.meeting.startDate, 'HH:mm');
                })
                .finally(() => {
                    this.activeElement = loadElement;
                    this._recompile();
                });
        }
    }

    upgradeParticipant(participant) {
        let orgName;
        this.organizationService.getCurrentOrganization().then((result) => {
            orgName = result.name;

            let options = {
                id: 'upgrade user to organizer dialog',
                isAdmin: this.currentUser.isAdmin,
                ok: this.translationService.translate('administration_Upgrade'),
                cancel: this.translationService.translate('cancel'),
                description: this.translationService.translate('client_MakeOrganizerDescription')
            };

            this.dialogService.makeUser(options).then((makeAdmin) => {
                this.rowService.playLoadingAnimation(this.activeElement);

                let promise = makeAdmin ? this.userApiService.putAsAdministrator(participant.id) : this.userApiService.putAsOrganizer(participant.id);

                promise
                    .then(() => {
                        participant.isExternal = false;
                        this.eventEmitterService.publishEvent('updateUsersInPlan');
                    })
                    .catch((exception) => {
                        if (exception.status === HTTP_STATUS_CODES.FORBIDDEN) {
                            this.userService.getCurrentUser().then((user) => {
                                this.paymentService.userlimitExceeded(user.isAdmin);
                            });
                        } else {
                            this.toastService.error();
                        }
                    })
                    .finally(() => this._recompile(this.activeElement.scope()));
            });
        });
    }

    togglePresence(participant) {
        participant.isPresent = !participant.isPresent;
        this.participantsService.togglePresence(participant.id).finally(() => {
            this._recompile(this.activeElement.scope());
        });
    }

    _recompile(scope, custom, skipAnimation) {
        let newElement = this.recompileService.recompile(scope ? scope : this.$scope, this.activeElement, custom);
        if (!skipAnimation) {
            this.rowService.playDoneAnimation(newElement);
        }
    }

    updateEndTime(duration, hidden) {
        this.isShowingEndTime = false;
        let loadElement = this.$document[0].getElementById('endTime');
        this.meeting.endDate = this.timeService.parseDurationFromString(this.meeting.startDate, duration);

        this.closeEndTime();
        if (!hidden) {
            this.rowService.playLoadingAnimation(loadElement);
        }

        return this.meetingApiService
            .putEndTime(this.meeting.id, this.meeting.endDate)
            .then(() => {
                this._setDuration();
                this.endTimeRow.description = this.durationString ? this.durationString : '---';
            })
            .finally(() => {
                this.activeElement = loadElement;
                this._recompile(null, null, hidden);
            });
    }

    showMeetingSeries() {
        let options = {
            id: 'meeting series meetings dialog',
            meeting: this.meeting,
            tab: 'meetings',
            isDisabled: this.isDisabled
        };

        this.dialogService.custom('rym-meeting-series-dialog', options);
    }

    showMeetingSeriesAction() {
        let options = {
            id: 'meeting series actions dialog',
            meeting: this.meeting,
            tab: 'actions',
            isDisabled: this.isDisabled
        };

        this.dialogService.custom('rym-meeting-series-dialog', options);
    }

    showMeetingSeriesDecision() {
        let options = {
            id: 'meeting series decisions dialog',
            meeting: this.meeting,
            tab: 'decisions'
        };

        this.dialogService.custom('rym-meeting-series-dialog', options);
    }

    showMeetingSeriesPostponedAgendas() {
        let options = {
            id: 'meeting series postponed agendas dialog',
            meeting: this.meeting,
            tab: 'postponedAgendas',
            protocolIsLocked: this.meeting.protocolIsLocked
        };

        this.dialogService.custom('rym-meeting-series-dialog', options);
    }

    showMeetingSeriesEvents() {
        let options = {
            id: 'meeting series events dialog',
            meeting: this.meeting,
            tab: 'events'
        };

        this.dialogService.custom('rym-meeting-series-dialog', options);
    }

    close() {
        this.isOpen = false;
    }

    closeStartTime() {
        this.isShowingStartTime = false;
        this.hasInitilizedStartTimeScrollbar = false;
    }

    closeEndTime() {
        this.isShowingEndTime = false;
    }

    handleCloseEndTimeClick() {
        if (this.endTimeForm && this.endTimeForm.$dirty && this.endTimeForm.$valid) {
            this.updateEndTime(this.durationString);
        } else {
            this.closeEndTime();
            this._setDuration();
        }
    }

    handleCloseStartTimeClick() {
        if (this.startTimeForm && this.startTimeForm.$dirty) {
            this.updateStartTime({ time: this.stringService.tryParseTimeString(this.meeting.startTime) }, this.startTimeForm.start.$dirty);
        } else {
            this.closeStartTime();
        }
    }

    _disableMetaData(lock) {
        this.protocolIsLocked = lock;
        this.shouldLockMetaData();
    }

    _toggleTabIndex() {
        let sections = this.$element[0].querySelectorAll('.metadata');
        let tabbableElements = [];
        _.forEach(sections, (section) => {
            tabbableElements = tabbableElements.concat(Array.from(section.querySelectorAll('[tabindex]')));
        });

        if (tabbableElements.length) {
            if (parseInt(tabbableElements[0].getAttribute('tabindex')) === 0) {
                _.forEach(tabbableElements, (element) => {
                    element.setAttribute('tabindex', -1);
                    element.classList.remove('rym-focus-button');
                });
            } else if (parseInt(tabbableElements[0].getAttribute('tabindex')) === -1) {
                _.forEach(tabbableElements, (element) => {
                    element.setAttribute('tabindex', 0);
                    element.classList.add('rym-focus-button');
                });
            }
        }
    }

    openRoleSettingsDialog() {
        this.participantsService.openRoleSettingsDialog({ autoSave: true }).then(() => {
            this.participantsService.save();
        });
    }

    showUserTagMenu($event) {
        if (this.lockMetaData) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        return this.translationService
            .translateBatch(['client_Remove', 'meetings_Absent', 'meetings_Present', 'meetings_ChangeName', 'client_UpgradeToOrganizer'])
            .then((translations) => {
                return this.$q.all([this.userService.getCurrentUser(), this.organizationService.getCurrentOrganization()]).then((results) => {
                    let user = results[0],
                        organization = results[1];

                    let participant = $event.user;
                    this.activeElement = $event.target;
                    let options = { items: [] };

                    options.items.push({
                        id: participant.isPresent ? 'menu option - make participant absent' : 'menu option - make participant present',
                        icon: participant.isPresent ? 'speaker_notes_off' : 'speaker_notes',
                        text: participant.isPresent ? translations.meetings_Absent : translations.meetings_Present,
                        onClick: () => {
                            this.togglePresence(participant);
                        }
                    });

                    if (
                        !this.lockMetaData &&
                        !user.isExternal &&
                        (!this.meeting.office365UId || this.meeting.isOrganizer) &&
                        participant.email !== this.currentUser.email &&
                        !this.participantsService.isOrganizer(participant)
                    ) {
                        options.items.push({
                            id: 'menu option - delete meeting',
                            icon: 'delete_outline',
                            text: translations.client_Remove,
                            onClick: () => this.removeParticipant(participant)
                        });
                    }

                    return this.menuService.actionMenu(options, angular.element(this.activeElement), false);
                });
            });
    }
}

meetingDetailsController.$inject = [
    '$filter',
    '$scope',
    '$q',
    '$element',
    '$document',
    '$window',
    'meetingApiService',
    'categoryService',
    'dialogService',
    'organizationService',
    'userApiService',
    'menuService',
    'userService',
    'toastService',
    'keyCode',
    'stringService',
    'timeService',
    'eventEmitterService',
    'recompileService',
    'rowService',
    'paymentService',
    'translationService',
    'meetingSeriesService',
    'meetingMetadataService',
    'participantsService',
    'featureToggleService'
];

export default meetingDetailsController;
