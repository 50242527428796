function routing(routerHelper) {
    routerHelper.configureStates(
        [
            {
                state: 'meetings',
                config: {
                    url: '/meetings?category',
                    template: '<rym-meetings></rym-meetings>',
                    ncyBreadcrumb: {
                        label: 'Meetings'
                    }
                }
            }
        ],
        '/meetings'
    );
}

routing.$inject = ['routerHelper'];
export default routing;
