class RowService {
    constructor($animateCss, $parse, $rootScope, keyCode) {
        Object.assign(this, { $animateCss, $parse, $rootScope, keyCode });
    }

    focusElement(row) {
        let r = row[0] ? row[0] : row;
        let focusable = r.querySelector('[tabindex]');
        focusable ? focusable.focus() : angular.noop;
    }

    playDoneAnimation(row, skipFocusEvent) {
        let r = row[0] ? row[0] : row;
        let el = this._getProgressLine(row);

        if (!skipFocusEvent) {
            this.focusElement(r);
        }

        if (angular.isUndefined(el)) {
            return;
        }
        return this.$animateCss(el, {
            addClass: 'done',
            removeClass: 'loading',
            duration: 2
        })
            .start()
            .then(() => {
                el.removeClass('done');
            });
    }

    playLoadingAnimation(row) {
        this._getProgressLine(row).addClass('loading');
    }

    bindClickFunction(row, func) {
        let ngClick = row.attr('ng-click');
        if (angular.isDefined(ngClick)) {
            this._bindClassAndTabIndex(row);
            let func = this.$parse(ngClick);

            row.on('keypress', (event) => {
                if (event.keyCode === this.keyCode.ENTER) {
                    this.$rootScope.$evalAsync(() => {
                        func(row.scope(), { $event: event });
                    });
                }
            });
        } else if (func) {
            this._bindClassAndTabIndex(row);
            row.on('keypress click', (event) => {
                if (event.keyCode === this.keyCode.ENTER || event.type === 'click') {
                    this.$rootScope.$evalAsync(() => {
                        func({ $event: event });
                    });
                }
            });
        }
    }

    _bindClassAndTabIndex(row) {
        row.children().attr('tabindex', 0).addClass('rym-focus-button');
    }

    _getProgressLine(row) {
        return angular.element(row).find('loading-line');
    }

    isAnimationPlaying(row) {
        return this._getProgressLine(row).hasClass('loading');
    }
}

RowService.$inject = ['$animateCss', '$parse', '$rootScope', 'keyCode'];

export default RowService;
