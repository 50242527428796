import template from './offlineDialog.html';
import controller from './offlineDialog.controller';
import './offlineDialog.less';

let offlineDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&'
    }
};

export default offlineDialogComponent;
