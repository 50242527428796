class meetingSeriesPostponedAgendasController {
    constructor(meetingSeriesService, meetingApiService, eventEmitterService, signalRService, participantsService) {
        Object.assign(this, { meetingSeriesService, meetingApiService, eventEmitterService, signalRService, participantsService });
    }

    $onInit() {
        this.parent.loading = true;
        this.postponedAgendas = [];
        let meetingSeriesId = this.parent.meeting.meetingSeriesId;

        if (this.parent.protocolIsLocked) {
            this.isDisabled = true;
        } else {
            this.participantsService.init(this.parent.meeting.id).then(() => {
                this.isDisabled = !this.participantsService.isCurrentEditor();
            });
        }

        this.meetingSeriesService
            .getPostponedAgendasInMeetingSeries()
            .then((agendaResult) => {
                this.postponedAgendas = agendaResult.items;
                this.parent.setPostponedAgendasIndicator(agendaResult.count);
            })
            .finally(() => {
                this.parent.loading = false;
                this.parent.tabLoaded('postponedAgendas');
            });

        this.eventEmitterService.subscribe('updatePostponedAgendas', this._reloadPostponedAgendas.bind(this));
        this.eventEmitterService.subscribe('meetingSeriesPostponedAgendasChanged' + meetingSeriesId, this._reloadPostponedAgendas.bind(this));
        this.eventEmitterService.subscribe('showPostponedAgendaAttachments', this.showAttachments.bind(this));
    }

    $onDestroy() {}

    _reloadPostponedAgendas() {
        this.meetingSeriesService.getPostponedAgendasInMeetingSeries().then((agendaResult) => {
            this.postponedAgendas = agendaResult.items;
            this.parent.setPostponedAgendasIndicator(agendaResult.count);
        });
    }

    addPostponedAgendaToMeeting(data) {
        let meetingSeriesId = this.parent.meeting.meetingSeriesId;
        let meetingId = this.parent.meeting.id;
        return this.meetingApiService.addPostponedAgendaToMeeting(meetingSeriesId, data.agenda.id, meetingId).then((res) => {
            this.parent.tabLoaded('postponedAgendas', true);
            this.eventEmitterService.publishEvent('updatePostponedAgendas');
            this.eventEmitterService.publishEvent('reloadAgendaContent' + meetingId);
            this.signalRService.addPostponedAgendaToMeeting(meetingId, meetingSeriesId);
        });
    }

    showAttachments(agenda) {
        this.agendaId = agenda.id;
        this.meetingId = agenda.meetingId;

        this.attachmentsOpen = true;
    }

    closeAttachments() {
        this.attachmentsOpen = false;
    }

    removeAgenda(data) {
        let agenda = data.agenda,
            deleteChildren = data.deleteChildren;

        let meetingSeriesId = this.parent.meeting.meetingSeriesId;
        return this.meetingApiService.removeAgenda(agenda.meetingId, agenda.id).then(() => {
            this.parent.tabLoaded('postponedAgendas', true);
            this.eventEmitterService.publishEvent('updatePostponedAgendas');
            this.signalRService.addPostponedAgendaToMeeting(0, meetingSeriesId);
        });
    }
}

meetingSeriesPostponedAgendasController.$inject = [
    'meetingSeriesService',
    'meetingApiService',
    'eventEmitterService',
    'signalRService',
    'participantsService'
];

export default meetingSeriesPostponedAgendasController;
