class UserMenuController {
    constructor(
        $filter,
        $element,
        $q,
        $timeout,
        $scope,
        userApiService,
        userService,
        stringService,
        keyCode,
        eventEmitterService,
        translationService
    ) {
        Object.assign(this, {
            $filter,
            $element,
            $q,
            $timeout,
            $scope,
            userApiService,
            userService,
            stringService,
            keyCode,
            eventEmitterService,
            translationService
        });
    }

    $onInit() {
        this.searchTerm = this.options.searchTerm || '';
        this.suggestedUsers = _.differenceBy(this.options.suggestedUsers, this.options.selectedUsers, (user) => {
            return user.email;
        });
        this.selectedUsers = this.options.selectedUsers;
    }

    select($event) {
        this.onCallback({
            data: {
                event: $event,
                user: $event.user
            }
        });
    }

    secondarySelect() {
        this.options.onMultiple && this.options.onMultiple();
        this.onCancel();
    }

    invite(term) {
        this.options.onInvite && this.options.onInvite(term);
        this.onCancel();
    }
}

UserMenuController.$inject = [
    '$filter',
    '$element',
    '$q',
    '$timeout',
    '$scope',
    'userApiService',
    'userService',
    'stringService',
    'keyCode',
    'eventEmitterService',
    'translationService'
];

export default UserMenuController;
