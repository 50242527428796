import template from './toast.html';
import controller from './toast.controller';
import './toast.less';

let toastComponent = {
    template,
    controller
};

export default toastComponent;
