import template from './iconButton.html';
import controller from './iconButton.controller.js';
import './iconButton.less';

let iconButtonComponent = {
    template,
    controller,
    bindings: {
        icon: '@',
        tooltip: '@',
        isPrimary: '<',
        isDisabled: '<',
        onClick: '&'
    }
};

export default iconButtonComponent;
