import template from './card.html';
import controller from './card.controller';

let component = {
    template,
    controller,
    bindings: {
        headline: '@',
        tileColor: '@',
        tileText: '@',
        icon1: '@',
        icon2: '@',
        iconText1: '@',
        iconText2: '@',
        moreTitle: '@',
        moreIcon: '@',
        onClickOnMore: '&',
        isPlaceholder: '<',
        placeholderButtonText: '@'
    }
};

export default component;
