class AppcuesService {
    constructor($window) {
        Object.assign(this, { $window });
    }

    start() {
        this.$window.Appcues && this.$window.Appcues.start();
    }

    page() {
        this.$window.Appcues && this.$window.Appcues.page();
    }

    identify(userId, properties) {
        this.$window.Appcues && this.$window.Appcues.identify(userId, properties);
    }

    show(id) {
        this.$window.Appcues && this.$window.Appcues.show(id);
    }

    track(eventName) {
        this.$window.Appcues && this.$window.Appcues.track(eventName);
    }
}

AppcuesService.$inject = ['$window'];
export default AppcuesService;
