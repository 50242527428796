class ProfilePictureController {
    constructor(userApiService) {
        this.userApiService = userApiService;
        this.photo = '';
        this.cancellationToken = {
            isCancelled: false
        };
    }

    $onChanges(changes) {
        if (!changes.hasOwnProperty('isSmall') || changes.isSmall.isFirstChange()) {
            this.photo = null;
            if (changes.username) {
                this.savedUser = changes.username.currentValue;
                this.setPhoto();
            } else if (changes.isExternal) {
                this.setPhoto();
            }

            this.setInitials();
        }
    }

    setPhoto() {
        if (this.savedUser) {
            this.userApiService.getProfilePhotoForUser(this.savedUser, this.cancellationToken).then((result) => {
                this.photo = result.data;
            });
        } else {
            this.photo = null;
        }
    }

    setInitials() {
        if (!this.name || this.name.trim() === '') {
            return;
        }

        let names = this.name.replace(/\s+/g, ' ').trim().split(' ');

        let initials = names[0][0];
        if (names.length > 1) {
            initials += names[names.length - 1][0];
        }

        this.initials = initials.toUpperCase();
    }

    $onDestroy() {
        this.cancellationToken.isCancelled = true;
    }
}

ProfilePictureController.$inject = ['userApiService'];

export default ProfilePictureController;
