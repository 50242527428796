class RecompileService {
    constructor($compile) {
        Object.assign(this, { $compile });
    }

    recompile(scope, element, customElement) {
        if (element == null) {
            return;
        }

        let el = angular.isUndefined(element[0]) ? element : element[0];
        let parent = el.parentNode;
        if (!parent) {
            return;
        }
        let clone = scope.$new(false, scope.$parent);
        let recompiledElement = this.$compile(customElement ? customElement : el.outerHTML)(clone);
        parent.insertBefore(recompiledElement[0], el);

        this._remove(el);
        return recompiledElement;
    }

    _remove(element) {
        return element && element.parentNode && element.parentNode.removeChild(element);
    }
}

RecompileService.$inject = ['$compile'];

export default RecompileService;
