/* */
"format cjs";
'use strict';
angular.module("ngLocale", [], ["$provide", function($provide) {
  var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};

  const formats = {
    rymDate: "EEE, d MMM, y",
    fullDate: "EEEE d MMMM y",
    longDate: "d. MMMM y",
    medium: "d. MMM y HH.mm.ss",
    mediumDate: "d. MMM y",
    mediumTime: "HH.mm.ss",
    short: "dd.MM.y HH.mm",
    shortDate: "dd.MM.y",
    shortTime: "HH.mm"
  };

  $provide.value("$locale", {
    "DATETIME_FORMATS": {
      "AMPMS": [
        "a.m.",
        "p.m."
      ],
      "DAY": [
        "S\u00f8ndag",
        "Mandag",
        "Tirsdag",
        "Onsdag",
        "Torsdag",
        "Fredag",
        "L\u00f8rdag"
      ],
      "ERANAMES": [
        "f\u00f8r Kristus",
        "etter Kristus"
      ],
      "ERAS": [
        "f.Kr.",
        "e.Kr."
      ],
      "FIRSTDAYOFWEEK": 0,
      "MONTH": [
        "Januar",
        "Februar",
        "Mars",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Desember"
      ],
      "SHORTDAY": [
        "S\u00f8n",
        "Man",
        "Tir",
        "Ons",
        "Tor",
        "Fre",
        "L\u00f8r"
      ],
      "SHORTMONTH": [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Des"
      ],
      "STANDALONEMONTH": [
        "Januar",
        "Februar",
        "Mars",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Desember"
      ],
      "WEEKENDRANGE": [
        5,
        6
      ],
      ...formats
    },
    "NUMBER_FORMATS": {
      "CURRENCY_SYM": "kr",
      "DECIMAL_SEP": ",",
      "GROUP_SEP": "\u00a0",
      "PATTERNS": [
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 3,
          "minFrac": 0,
          "minInt": 1,
          "negPre": "-",
          "negSuf": "",
          "posPre": "",
          "posSuf": ""
        },
        {
          "gSize": 3,
          "lgSize": 3,
          "maxFrac": 2,
          "minFrac": 2,
          "minInt": 1,
          "negPre": "-\u00a4\u00a0",
          "negSuf": "",
          "posPre": "\u00a4\u00a0",
          "posSuf": ""
        }
      ]
    },
    "id": "nb-NO",
    "localeID": "no",
    "getDateFormats": () => formats,
    "pluralCat": function(n, opt_precision) {  if (n == 1) {    return PLURAL_CATEGORY.ONE;  }  return PLURAL_CATEGORY.OTHER;}
  });
}]);
