class NotificationService {
    constructor($state, $window, eventEmitterService, dialogService, translationService) {
        Object.assign(this, { $state, $window, eventEmitterService, dialogService, translationService });
    }

    init() {
        this.eventEmitterService.subscribe('notAllowedToViewAction', this.notAllowedToViewActionDialog.bind(this));
        this.eventEmitterService.subscribe('notAllowedToViewMeeting', this.notAllowedToViewMeetingDialog.bind(this));
    }

    notAllowedToViewActionDialog(urlObj) {
        let description =
            this.translationService.translate('client_NotAllowedToViewActionDescription') +
            '<br>' +
            this.translationService.translate('client_SignoutAndGoToMeetingsUrl') +
            '<br>' +
            '<a href="' +
            urlObj.url +
            '">' +
            urlObj.url +
            '</a>';

        this.dialogService
            .alert({
                id: 'not allowed to view action',
                title: this.translationService.translate('client_NotAllowedToViewActionTitle'),
                ok: this.translationService.translate('logout'),
                cancel: this.translationService.translate('client_Close'),
                description: description
            })
            .then(
                () => {
                    this.$window.parent.location.href = urlObj.logoutUrl;
                },
                () => {
                    this.$state.go('actions');
                }
            );
    }

    notAllowedToViewMeetingDialog(urlObj) {
        let description =
            this.translationService.translate('client_NotAllowedToViewMeetingDescription') +
            '<br>' +
            this.translationService.translate('client_SignoutAndGoToMeetingsUrl') +
            '<br>' +
            '<a href="' +
            urlObj.url +
            '">' +
            urlObj.url +
            '</a>';

        this.dialogService
            .alert({
                id: 'not allowed to view meeting',
                title: this.translationService.translate('client_NotAllowedToViewMeetingTitle'),
                ok: this.translationService.translate('logout'),
                cancel: this.translationService.translate('client_Close'),
                description: description
            })
            .then(
                () => {
                    this.$window.parent.location.href = urlObj.logoutUrl;
                },
                () => {
                    this.$state.go('meetings');
                }
            );
    }
}

NotificationService.$inject = ['$state', '$window', 'eventEmitterService', 'dialogService', 'translationService'];

export default NotificationService;
