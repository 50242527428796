class CategoryDialog {
    constructor(translationService) {
        this.translationService = translationService;
    }

    $onInit() {
        if (this.options.data) {
            this.newCategory = angular.copy(this.options.data);
        } else {
            this.newCategory = {
                name: '',
                colorId: ''
            };
        }
    }

    setColorId(colorId) {
        this.newCategory.colorId = colorId;
    }

    create() {
        this.onOk({ data: this.newCategory });
    }
}

CategoryDialog.$inject = ['translationService'];

export default CategoryDialog;
