class CardListModelTransformer {
    constructor($filter, translationService, cardGroupModelTransformer) {
        this.$filter = $filter;
        this.translationService = translationService;
        this.cardGroupModelTransformer = cardGroupModelTransformer;
    }

    transform(meetingDtos) {
        return {
            returnButtonText: this.translationService.translate('today'),
            noDataTitle: this.translationService.translate('client_NoMeetingsYetTitle'),
            noDataDescription: this.translationService.translate('client_NoItemMatchingSearch'),
            cardGroupsData: this._getCardGroupsData(meetingDtos)
        };
    }

    _getCardGroupsData(meetingDtos) {
        let cardGroupsData = Object.values(_.groupBy(meetingDtos, (meetingDto) => this.$filter('date')(meetingDto.startDate, 'yyyy-MM-dd')));

        this._initToday(cardGroupsData);

        return this._order(cardGroupsData);
    }

    _initToday(cardGroupsData) {
        let today = cardGroupsData.find(
            (cardGroupData) => this.$filter('date')(cardGroupData[0].startDate, 'fullDate') === this.$filter('date')(new Date(), 'fullDate')
        );

        if (!today) {
            cardGroupsData.push([
                {
                    startDate: new Date(),
                    isToday: true
                }
            ]);
        }
    }

    _order(cardGroupsData) {
        return _.orderBy(cardGroupsData, (cardGroupData) =>
            !!cardGroupData[0] ? this.$filter('date')(cardGroupData[0].startDate, 'yyyy-MM-dd') : this.$filter('date')(new Date(), 'yyyy-MM-dd')
        );
    }
}
CardListModelTransformer.$inject = ['$filter', 'translationService', 'cardGroupModelTransformer'];

export default CardListModelTransformer;
