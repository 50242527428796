import template from './comingSoon.html';
import controller from './comingSoon.controller';
import './comingSoon.less';

let comingSoonComponent = {
    template,
    controller
};

export default comingSoonComponent;
