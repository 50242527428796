import template from './cardGroup.html';
import controller from './cardGroup.controller';

let component = {
    template,
    controller,
    bindings: {
        headline: '@',
        emptyText: '@',
        cardsData: '<',
        cardModelTransformer: '<',
        isHighlighted: '<',
        showPlaceholders: '<',
        onClickOnCard: '&',
        onClickOnMore: '&'
    }
};

export default component;
