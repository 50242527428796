import { User } from 'oidc-client';
import { FEATURE_TOGGLES } from '../../app.constants';

class introGuideController {
    constructor(
        $rootScope,
        $scope,
        $document,
        $q,
        eventEmitterService,
        userApiService,
        organizationApiService,
        translationService,
        userService,
        organizationService,
        stringService,
        featureToggleService
    ) {
        Object.assign(this, {
            $rootScope,
            $scope,
            $document,
            $q,
            eventEmitterService,
            userApiService,
            organizationApiService,
            translationService,
            userService,
            organizationService,
            stringService,
            featureToggleService
        });
    }

    $onInit() {
        this.eventEmitterService.subscribe('showIntroGuide', this.show.bind(this));
        this.touchEvent = this.$document.bind('touchstart', (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });

        let promises = [this.organizationService.getCurrentOrganization(), this.userService.getCurrentUser()];

        this.$q.all(promises).then((result) => {
            this.organization = result[0];
            this.user = result[1];

            this.oldUserName = this.user.name;
            this.oldOrganizationName = this.organization.name;
            this.oldPhone = this.user.phone;
        });
    }

    show() {
        this.touchEvent = this.$document.bind('touchstart', (event) => {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });

        this.isOpen = true;
        this.$rootScope.hasShownIntroGuide = true;
    }

    done() {
        this.isOpen = false;
        this._unbindListeners();

        this.onDone();
    }

    _unbindListeners() {
        this.touchEvent.unbind();
    }
}

introGuideController.$inject = [
    '$rootScope',
    '$scope',
    '$document',
    '$q',
    'eventEmitterService',
    'userApiService',
    'organizationApiService',
    'translationService',
    'userService',
    'organizationService',
    'stringService',
    'featureToggleService'
];

export default introGuideController;
