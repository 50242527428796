class VerticalWizardController {
    constructor(translationService, verticalWizardService) {
        this.buttonTitle = translationService.translate('next');
        this.verticalWizardService = verticalWizardService;
        this.isAutoStepping = true;
        this.currentStep = null;
        this.lastStep = null;
    }

    add(step) {
        if (this.lastStep) {
            step.setPreviousStep(this.lastStep);
            this.lastStep.setNextStep(step);
        }

        if (this.isAutoStepping) {
            this.activate(step);
        } else {
            this.updateIsAtLastStep();
        }

        if (!step.isValid() || step.isLastStep) {
            this.isAutoStepping = false;
        }

        this.lastStep = step;
    }

    activate(step) {
        if (this.currentStep) {
            this.currentStep.setIsActive(false);
        }

        this.currentStep = step;
        this.currentStep.setIsActive(true);

        this.updateIsAtLastStep();
    }

    updateIsAtLastStep() {
        if (this.currentStep.hasNextStep()) {
            this.isAtLastStep = false;
            this.verticalWizardService.setIsLastStep(false);
        } else {
            this.isAtLastStep = true;
            this.verticalWizardService.setIsLastStep(true);
        }
    }
}

VerticalWizardController.$inject = ['translationService', 'verticalWizardService'];

export default VerticalWizardController;
