import template from './tab.html';
import controller from './tab.controller';

let TabComponent = {
    template,
    controller,
    require: {
        tabsController: '?^rymTabs'
    },
    bindings: {
        name: '@',
        icon: '@',
        value: '@',
        showIcon: '<',
        count: '<',
        countTitle: '@',
        isSelected: '<'
    }
};

export default TabComponent;
