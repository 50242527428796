class TextDialogController {
    constructor(userService, translationService) {
        this.userService = userService;
        this.translationService = translationService;
    }

    $onInit() {
        this.message = this.options.message ? this.options.message : {};
        if (this.options.includeUserInfo) {
            this.message.supportInfo = this.userService.getUserBrowser();
            this.message.supportInfo.os = this.userService.getUserOS();
        }
    }

    send() {
        this.onOk({ data: this.message });
    }
}

TextDialogController.$inject = ['userService', 'translationService'];

export default TextDialogController;
