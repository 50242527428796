import controller from './categoryFavoriteIcon.controller';
import template from './categoryFavoriteIcon.html';

let categoryFavoriteIconComponent = {
    template,
    controller,
    bindings: {
        category: '='
    }
};

export default categoryFavoriteIconComponent;
