const stratsysTenantIdKey = 'stratsysTenantIdKey';
const stratsysLoginHintKey = 'stratsysLoginHintKey';

class PlatformService {
    constructor($location, $window, envService) {
        Object.assign(this, { $location, $window, envService });
    }

    init() {
        this.getTenantId();
        this.getLoginHint();
    }

    getStratsysAuthorityUrl() {
        let tenantId = this.getTenantId();
        if (tenantId) {
            return this.envService.STRATSYS_URL + tenantId;
        }

        return null;
    }

    getStratsysQuerystring(changeToTenantId) {
        if (changeToTenantId) {
            return '?tenantId=' + changeToTenantId;
        }

        let tenantId = this.getTenantId();
        if (tenantId) {
            return '?tenantId=' + tenantId;
        }

        return null;
    }

    isStratsys() {
        return this.getStratsysQuerystring() ? true : false;
    }

    getTenantId() {
        let tenantId = this.$location.search()['tenantId'];
        if (!tenantId) {
            tenantId = this.$window.sessionStorage.getItem(stratsysTenantIdKey);
        }

        if (tenantId) {
            this.$window.localStorage.setItem(stratsysTenantIdKey, tenantId);
            return tenantId;
        }

        return this.$window.localStorage.getItem(stratsysTenantIdKey);
    }

    getLoginHint() {
        let loginHint = this.$location.search()['login_hint'];
        if (loginHint) {
            this.$window.sessionStorage.setItem(stratsysLoginHintKey, loginHint);
            return loginHint;
        }

        return this.$window.sessionStorage.getItem(stratsysLoginHintKey);
    }
}

PlatformService.$inject = ['$location', '$window', 'envService'];

export default PlatformService;
