import { FEATURE_TOGGLES } from '../../../app.constants';

class meetingSeriesDialogController {
    constructor(
        $scope,
        $q,
        $element,
        $timeout,
        meetingApiService,
        userService,
        responsive,
        menuService,
        eventEmitterService,
        meetingSeriesService,
        translationService,
        dialogService,
        featureToggleService
    ) {
        Object.assign(this, {
            $scope,
            $q,
            $element,
            $timeout,
            meetingApiService,
            userService,
            responsive,
            menuService,
            eventEmitterService,
            meetingSeriesService,
            translationService,
            dialogService,
            featureToggleService
        });
    }

    $onInit() {
        this.meeting = this.options.meeting;
        this.selectedMeeting = this.options.meeting;
        this.meetingSeriesName = this.options.meeting.meetingName;
        this.protocolIsLocked = this.options.protocolIsLocked;

        this.meetingSeriesService.setMeetingSeriesId(this.meeting.meetingSeriesId);
        this.responsive.on(['xs', 'sm', 'md'], this.$scope, () => {
            this.isMobile = true;
            this.sideNavOpen = false;
        });
        this.responsive.on(['lg', 'xl'], this.$scope, () => {
            this.isMobile = false;
            this.sideNavOpen = true;
        });
        this.userService.getCurrentUser().then((user) => {
            this.user = user;
        });

        this.featureToggleService.isEnabled(FEATURE_TOGGLES.EVENTS).then((result) => {
            this.isEventsEnabled = result;
        });

        this.selectedTab = this.options.tab;

        this.eventEmitterService.subscribe('meetingChangedExternally', (updatedMeeting) => {
            this.meetingSeriesName = updatedMeeting.meetingName;
        });
    }

    tabLoaded(tab, clearCache) {
        const myEnum = {
            actions: 1,
            decisions: 2,
            meetings: 4,
            postponedAgendas: 8
        };

        let myflags = null;
        switch (tab) {
            case 'actions':
                myflags = myEnum.decisions | myEnum.meetings | myEnum.postponedAgendas;
                break;
            case 'decisions':
                myflags = myEnum.actions | myEnum.meetings | myEnum.postponedAgendas;
                break;
            case 'meetings':
                myflags = myEnum.actions | myEnum.decisions | myEnum.postponedAgendas;
                break;
            case 'postponedAgendas':
                myflags = myEnum.actions | myEnum.decisions | myEnum.meetings;
                break;
            default:
                myflags = myEnum.actions | myEnum.decisions | myEnum.postponedAgendas | myEnum.meetings;
                break;
        }

        if (clearCache) {
            this.meetingSeriesService.invalidateCache();
        }

        if (myflags & myEnum.actions) {
            this.meetingSeriesService.getActionsInMeetingSeries().then((actions) => {
                this.setActionIndicator(actions);
            });
        }

        if (myflags & myEnum.decisions) {
            this.meetingSeriesService.getDecisionsInMeetingSeries();
        }

        if (myflags & myEnum.meetings) {
            this.meetingSeriesService.getMeetingsInMeetingSeries();
        }

        if (myflags & myEnum.postponedAgendas) {
            this.meetingSeriesService.getPostponedAgendasInMeetingSeries().then((agendaResult) => {
                this.setPostponedAgendasIndicator(agendaResult.count);
            });
        }
    }

    export() {
        this.eventEmitterService.publishEvent('exportList');
    }

    calculateUnfinishedActions(actions) {
        return _.filter(actions, (action) => !action.isFinished).length;
    }

    setActionIndicator(actions) {
        this.actionIndicator = this.calculateUnfinishedActions(actions);
    }

    setPostponedAgendasIndicator(count) {
        this.postponedAgendasIndicator = count;
    }

    setSelectedMeeting(meeting) {
        let selectMeeting = _.find(this.meetingSeriesService.getMeetingSeries().meetings, (meet) => {
            return meeting.id === meet.id;
        });
        if (selectMeeting) {
            this.selectedMeeting = selectMeeting;
        }
    }

    setChangeMeetingFunc(func) {
        this.changeMeeting = func;
    }

    showProtocol(meeting) {
        this.dialogService.custom('rym-meeting-preview-dialog', {
            id: 'preview meeting dialog',
            meeting: meeting
        });
    }

    showMeetingMenu() {
        let el = this.$element[0].querySelector('.meeting-picker');
        let options = {
            selected: this.selectedMeeting,
            meetings: _.orderBy(this.meetingSeriesService.getMeetingSeries().meetings, ['startDate'], ['desc'])
        };

        let menu = this.menuService.meetingMenu(options, angular.element(el), false, true);

        if (menu && menu.then) {
            menu.then((data) => {
                this.selectedMeeting = data.meeting;
                this.$timeout(() => {
                    this.changeMeeting();
                });
            });
        }
    }

    handleChangeTab($event) {
        this.selectedTab = $event.value;
    }

    toggleStaticSideNav() {
        this.sideNavOpen = !this.sideNavOpen;
        this.eventEmitterService.publishEvent('parentToggleSideNav', this.sideNavOpen);
    }
}

meetingSeriesDialogController.$inject = [
    '$scope',
    '$q',
    '$element',
    '$timeout',
    'meetingApiService',
    'userService',
    'responsive',
    'menuService',
    'eventEmitterService',
    'meetingSeriesService',
    'translationService',
    'dialogService',
    'featureToggleService'
];

export default meetingSeriesDialogController;
