const INJECT = ['$state', 'eventEmitterService'];

class CategoryDetailService {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
        this.category = null;
    }

    open(category, state) {
        if (this.category !== category) {
            this.category = category;
        }

        this.state = state;

        this.$state.go(
            this.state + '.category',
            { categoryId: category.id, category: category },
            {
                notify: false,
                reload: false,
                inherit: true
            }
        );
    }

    close() {
        this.category = null;
        if (this.state) {
            this.$state.go(
                this.state,
                {},
                {
                    notify: false,
                    reload: false,
                    location: 'replace',
                    inherit: true
                }
            );
        }

        this.eventEmitterService.publishEvent('actionDetailClose');
    }

    nullCategory() {
        this.category = null;
    }

    getCategory() {
        return this.category;
    }
}

CategoryDetailService.$inject = INJECT;

export default CategoryDetailService;
