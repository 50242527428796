import template from './radioCards.html';
import controller from './radioCards.controller';

let component = {
    template,
    controller,
    bindings: {
        options: '<',
        onChange: '&'
    }
};

export default component;
