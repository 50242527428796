import template from './categoryDetail.html';
import controller from './categoryDetail.controller';
import './categoryDetail.less';

let categoryDetailComponent = {
    template,
    controller
};

export default categoryDetailComponent;
