const PAYMENTPLAN = { FREE: 'FREE', ENTERPRISE: 'ENTERPRISE' };
const PLANTYPE = { PAID: 'PAID', MEETINGS: 'MEETINGCOUNT', DAYS: 'DAYPERIOD' };

class paymentPlanService {
    constructor() {}

    isPlan(plan, type) {
        return plan ? plan.toLowerCase().startsWith(type.toLowerCase()) : false;
    }

    isFreePlan(plan) {
        return this.isPlan(plan, PAYMENTPLAN.FREE);
    }

    isEnterprisePlan(plan) {
        return this.isPlan(plan, PAYMENTPLAN.ENTERPRISE);
    }

    isLegacyTrialType(plan) {
        return this.isFreePlan(plan.id) && this.isType(plan.type, PLANTYPE.MEETINGS);
    }

    isType(planType, type) {
        return planType ? planType.toLowerCase() === type.toLowerCase() : false;
    }

    isSeatBasedSubscription(planId) {
        return planId === 'free30Days' || planId.includes('seatBased');
    }
}

paymentPlanService.$inject = [];
export default paymentPlanService;
