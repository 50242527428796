let focusOnClickDirective = () => {
    return {
        link: function (scope, element) {
            element.on('click', (event) => {
                if (!closest(event.target, 'button, input')) {
                    let focusable = element[0].querySelector('input, [contenteditable], [prevent-focus-on-click]');
                    if (focusable) {
                        focusable.focus();
                    }
                }
            });

            function closest(el, selector) {
                var matchesFn;

                // find vendor prefix
                ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                    if (typeof document.body[fn] == 'function') {
                        matchesFn = fn;
                        return true;
                    }
                    return false;
                });

                var parent;

                // traverse parents
                while (el) {
                    parent = el.parentElement;
                    if (parent && parent[matchesFn](selector)) {
                        return parent;
                    }
                    el = parent;
                }

                return null;
            }
        }
    };
};

export default focusOnClickDirective;
