import { LANGUAGES, FEATURE_TOGGLES, HTTP_STATUS_CODES } from '../app.constants';
const MAX_FILE_SIZE = 4194304;

class SettingsController {
    constructor(
        $filter,
        $q,
        $timeout,
        $window,
        translationService,
        Upload,
        authService,
        userService,
        organizationService,
        userApiService,
        dialogService,
        menuService,
        eventEmitterService,
        organizationApiService,
        toastService,
        breadcrumbsService,
        localizationService,
        featureToggleService,
        envService
    ) {
        Object.assign(this, {
            $filter,
            $q,
            $timeout,
            $window,
            translationService,
            Upload,
            authService,
            userService,
            organizationService,
            userApiService,
            dialogService,
            menuService,
            eventEmitterService,
            organizationApiService,
            toastService,
            breadcrumbsService,
            localizationService,
            featureToggleService,
            envService
        });
    }

    $onInit() {
        this.loading = true;
        this.breadcrumbsService.setLabel('settings', this.translationService.translate('client_Settings'));
        this.exampleDate = new Date(2024, 4, 22);

        let promises = [this._getUser(), this._setShowLogoutOption(), this._getTranslations(), this._getOrganizationsAndSetOrganization()];

        this._setIsTrial();

        this.$q
            .all(promises)
            .then(() => {
                this._setUserType();
                this.currentTimezone = this.user.timezone ? this.user.timezone : 'UTC';
                this._setCurrentDateFormat();
                this._updateHideOutlookMeetingsDescription();
                this._getLogo();
                this._getTimeZones();
            })
            .finally(() => (this.loading = false));
    }

    isShowingHideOutlookMeetings() {
        return this.user.hasValidOffice365Token;
    }

    isShowingEnableOffice365Integration() {
        return true;
    }

    isShowingEnableOffice365GroupMeetingIntegration() {
        return this.user.hasValidOffice365Token;
    }

    isShowingSyncOffice365GroupMeetingIntegration() {
        let shouldShow = this.isShowingEnableOffice365GroupMeetingIntegration() && this.user.isGroupMeetingsEnabled && !this.user.isExternal;
        if (shouldShow) {
            if (this.organization.mailGroupSynced) {
                let lastSyncDate = this.$filter('date')(this.organization.mailGroupSynced, 'rymDate');
                this.syncGroupMailDescription = this.translationService.translate('client_Office365GroupMeetingsSyncDescription', {
                    date: lastSyncDate
                });
            } else {
                shouldShow = false;
            }
        }

        return shouldShow;
    }

    _setCurrentDateFormat() {
        this.currentDateFormat = `${this.$filter('date')(this.exampleDate, 'shortDate')} / 
             ${this.$filter('date')(this.exampleDate, 'rymDate')} 
             (${this.localizationService.getCurrentLocale().DATETIME_FORMATS.shortDate})`;
    }

    uploadFile(file) {
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                this.dialogService
                    .alert({
                        id: 'logo exceeds size dialog',
                        title: this.translations.client_LogoToExceedsSizeTitle,
                        ok: this.translations.close,
                        description: this.translationService.translate('client_LogoExceedsSize', { filename: file.name })
                    })
                    .then(() => {});
                return;
            }

            let fileName = 'logo';
            if (!file.name && file.type.includes('image/png')) {
                fileName += '.png';
            } else {
                fileName += /\.[0-9a-z]+$/i.exec(file.name)[0];
            }

            this.Upload.rename(file, fileName);

            this.authService.getAccessToken().then((accessToken) => {
                this.Upload.upload({
                    url: `${this.envService.API}organizations/logo`,
                    data: {
                        file: file
                    },
                    headers: { Authorization: 'Bearer ' + accessToken }
                }).then(
                    (response) => {
                        this._setLogoText(response.data);
                    },
                    (err) => {
                        if (err.status === HTTP_STATUS_CODES.BADREQUEST) {
                            this.dialogService
                                .alert({
                                    id: 'logo exceeds size dialog',
                                    title: this.translationService.translate('client_NotValidFileLogo'),
                                    ok: this.translations.close,
                                    description: this.translationService.translate('client_NotValidFileLogoDescription')
                                })
                                .then(() => {});
                        }
                    }
                );
            });
        }
    }

    deleteLogo() {
        this.dialogService
            .confirm({
                id: 'delte logo dialog',
                title: this.translations.client_DeleteConfirm,
                description: this.translations.client_ConfirmDeleteLogo
            })
            .then(() => {
                this.organizationApiService.deleteLogo(this.organization.id).then(() => {
                    this._setLogoText();
                });
            });
    }

    showUserTypeDialog() {
        this.dialogService.alert({
            id: 'user type dialog',
            title: this.userType,
            description: this.userTypeDescription
        });
    }

    toggleHideOutlookMeetings() {
        let oldValue = this.user.hidePlaceholderMeetings;
        let newValue = !this.user.hidePlaceholderMeetings;
        this.user.hidePlaceholderMeetings = newValue;
        this._updateHideOutlookMeetingsDescription();

        this.userService.setHidePlaceholderMeetingsForCurrentUser(newValue).catch(() => {
            this.user.hidePlaceholderMeetings = oldValue;
            this._updateHideOutlookMeetingsDescription();
        });
    }

    _updateHideOutlookMeetingsDescription() {
        this.hideOutlookMeetingsDescription = this.user.hidePlaceholderMeetings
            ? this.translationService.translate('client_HideOutlookMeetingsDescriptionOn')
            : this.translationService.translate('client_HideOutlookMeetingsDescriptionOff');
    }

    handleOffice365MeetingGroupIntegration() {
        this.eventEmitterService.publishEvent('handleOffice365MeetingGroupIntegration');
    }

    syncMailGroups() {
        this.isMailGroupSyncDisabled = true;
        this.eventEmitterService.publishEvent('syncOffice365MeetingGroups');
    }

    showLogoMenu(event) {
        let options = {
            items: [
                {
                    id: 'menu option - change logo',
                    icon: 'swap_horiz',
                    text: this.translations.client_ChangeLogo,
                    onClick: () => {
                        document.getElementById('upload').click();
                    }
                },
                {
                    id: 'menu option - delete logo',
                    icon: 'delete_outline',
                    text: this.translations.client_RemoveLogo,
                    onClick: () => {
                        this.deleteLogo();
                    }
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element(event.currentTarget), false);
    }

    showDateFormatMenu(event) {
        let options = { items: [] },
            currentLocale = this.localizationService.getCurrentLocale(),
            locales = this.localizationService.getLocales();

        _.forEach(locales, (locale) => {
            let shortDate = locale.DATETIME_FORMATS.shortDate,
                currentShortDate = currentLocale.DATETIME_FORMATS.shortDate;

            options.items.push({
                id: 'menu option - date format',
                text: `${this.$filter('date')(this.exampleDate, shortDate)} / 
                       ${this.$filter('date')(this.exampleDate, locale.DATETIME_FORMATS.rymDate)} (${shortDate})`,
                isDefault: shortDate === currentShortDate,
                onClick: () => this.setPreferredDateFormat(locale.id)
            });
        });

        this.menuService.actionMenu(options, angular.element(event.currentTarget), false);
    }

    setPreferredDateFormat(locale) {
        return this.localizationService.setPreferredLocale(locale).then(() => {
            this._setCurrentDateFormat();
            this.userService.setPreferredCulture(locale).catch((err) => {
                this.toastService.error();
            });
        });
    }

    showTimezoneMenu(event) {
        let selected = _.find(this.timezones, (timeZone) => timeZone.id === this.currentTimezone),
            options = {
                selected: selected,
                timezones: this.timezones
            };

        let eventElement = event.currentTarget;

        this.menuService.timezoneMenu(options, angular.element(eventElement), false, false).then((data) => {
            if (data.timezone !== selected) {
                this.userApiService.putTimezoneForCurrentUser(data.timezone.id).then((user) => {
                    this.user.timezone = user.timezone;
                    this.currentTimezone = user.timezone;
                });
            }
        });
    }

    showLanguageMenu($event) {
        let lang = this.userService.getUserLanguage();
        let options = {
            items: [
                {
                    id: 'menu option - language',
                    icon: 'language',
                    isDefault: lang === LANGUAGES.ENGLISH_CODE,
                    text: 'English',
                    onClick: () => {
                        this.userService.useLanguage(LANGUAGES.ENGLISH_CODE);
                    }
                },
                {
                    id: 'menu option - language',
                    icon: 'language',
                    isDefault: lang === LANGUAGES.SWEDISH_CODE,
                    text: 'Svenska',
                    onClick: () => {
                        this.userService.useLanguage(LANGUAGES.SWEDISH_CODE);
                    }
                },
                {
                    id: 'menu option - language',
                    icon: 'language',
                    isDefault: lang === LANGUAGES.NORWEGIAN_CODE,
                    text: 'Norsk',
                    onClick: () => {
                        this.userService.useLanguage(LANGUAGES.NORWEGIAN_CODE);
                    }
                },
                {
                    id: 'menu option - language',
                    icon: 'language',
                    isDefault: lang === LANGUAGES.GERMAN_CODE,
                    text: 'Deutsch',
                    onClick: () => {
                        this.userService.useLanguage(LANGUAGES.GERMAN_CODE);
                    }
                }
            ]
        };

        this.menuService.actionMenu(options, angular.element($event.currentTarget), false);
    }

    logout() {
        this.toastService.loading({ title: this.translationService.translate('client_SigninOut') });
        this.authService.logout();
    }

    _setDomain() {
        return this.organizationService.getDomain().then((domain) => {
            this.domain = domain;
        });
    }

    _getTimeZones() {
        return this.userApiService.getTimezones().then((timezones) => (this.timezones = timezones));
    }

    _getOrganizationsAndSetOrganization() {
        return this.organizationService.getCurrentOrganization().then((result) => {
            this.organization = result;

            if (this.organization.emailDomain) {
                this.isDomainLocked = true;
            }
        });
    }

    _getLogo() {
        return this.organizationApiService.getLogo().then((logo) => this._setLogoText(logo));
    }

    _setIsTrial() {
        return this.organizationService.getIsTrial().then((isTrial) => (this.isTrial = isTrial));
    }

    _setLogoText(link) {
        if (!!link) {
            this.logo = link;
            this.logoText = this.translations.client_LogoDescriptionAdmin;
        } else {
            this.logo = null;
            this.logoText = this.user.isAdmin ? this.translations.client_NoLogoDescriptionAdmin : this.translations.client_NoLogoDescription;
        }
    }

    _setUserType() {
        if (this.user.isAdmin) {
            this.userType = this.translations.administrator;
            this.userTypeDescription = this.translations.client_UpgradeToAdministratorDescription;
        } else if (this.user.isExternal) {
            this.userType = this.translations.client_UserTypeGuest;
            this.userTypeDescription = this.translations.client_DowngradeUserDescription;
        } else {
            this.userType = this.translations.client_UserTypeUser;
            this.userTypeDescription = this.translations.client_MakeOrganizerDescription;
        }
    }

    _getUser() {
        return this.userService.refreshAndGetCurrentUser().then((user) => (this.user = user));
    }

    _getTranslations() {
        let batch = [
            'close',
            'cancel',
            'create',
            'administrator',
            'newOrganization',
            'administration_ChangePassword',
            'meetings_Participants',
            'meetings_Creator',
            'client_Change',
            'client_UpgradeToAdministratorDescription',
            'client_DowngradeUserDescription',
            '' + 'client_MakeOrganizerDescription',
            'client_LogoDescriptionAdmin',
            'client_NoLogoDescriptionAdmin',
            'client_NoLogoDescription',
            'client_LogoToExceedsSizeTitle',
            'client_ChangingOrganization',
            'client_DeleteConfirm',
            'client_ConfirmDeleteLogo',
            'client_RemoveLogo',
            'client_ChangeLogo',
            'client_Name',
            'client_CreatingOrganization',
            'client_UserTypeGuest',
            'client_UserTypeUser'
        ];

        return this.translationService.translateBatch(batch).then((translations) => (this.translations = translations));
    }

    _setShowLogoutOption() {
        return this.organizationService.isLoggedInThroughStratsys().then((result) => {
            this.showLogoutOption = !result;
        });
    }

    openOffice365IntegrationUrl() {
        this.eventEmitterService.publishEvent('toggleOffice365Integration');
    }

    _fetchData() {
        let promises = [this._getTranslations(), this._getOrganizationsAndSetOrganization(), this._setIsTrial()];

        return this.$q.all(promises);
    }
}

SettingsController.$inject = [
    '$filter',
    '$q',
    '$timeout',
    '$window',
    'translationService',
    'Upload',
    'authService',
    'userService',
    'organizationService',
    'userApiService',
    'dialogService',
    'menuService',
    'eventEmitterService',
    'organizationApiService',
    'toastService',
    'breadcrumbsService',
    'localizationService',
    'featureToggleService',
    'envService'
];

export default SettingsController;
