import template from './meetingSeriesDecisions.html';
import controller from './meetingSeriesDecisions.controller';
import './meetingSeriesDecisions.less';

let meetingSeriesDecisionsComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingSeriesDialog'
    }
};

export default meetingSeriesDecisionsComponent;
