class Menu {
    constructor(
        entryElement,
        componentName,
        options,
        customPos,
        $timeout,
        $document,
        $compile,
        $rootElement,
        $q,
        $rootScope,
        $window,
        responsive,
        domService,
        keyCode
    ) {
        this.entryElement = entryElement;

        this.$timeout = $timeout;
        this.$document = $document;
        this.$compile = $compile;
        this.$rootElement = $rootElement;
        this.$q = $q;
        this.$window = $window;
        this.$rootScope = $rootScope;
        this.responsive = responsive;
        this.domService = domService;
        this.keyCode = keyCode;
        this.deferred = this.$q.defer();
        this.promise = this.deferred.promise;
        this.customPos = customPos;
        this.blurEntryElement();

        this.template = '<' + componentName + ' options="options" on-callback="onCallback(data)" on-cancel="onCancel()"/>';

        this.scope = this.$rootScope.$new();
        this.scope.options = angular.extend(
            {
                items: []
            },
            options
        );
        this.compiled = this.$compile(this.template)(this.scope);
        this.menu = angular.element(this.compiled[0].getElementsByClassName('menu-wrapper')[0]);
        this.$rootElement.append(this.compiled);
        this._place();

        this.scope.options.isShowing = true;

        this._bindCallbacks();
        this._bindListeners();
    }

    handleEvent(event) {
        switch (event.type) {
            case 'touchstart':
                this._handleMouseDown(event);
                break;
            case 'mousedown':
                this._handleMouseDown(event);
                break;
            case 'scroll':
                this._handleScrollEvent(event);
                break;
            case 'keydown':
                this._handleKeyDown(event);
                break;
        }
    }

    _bindCallbacks() {
        this.scope.onCallback = (data) => {
            this._handleCallback(data);
        };

        this.scope.onCancel = () => {
            this._hide();
        };
    }

    _handleCallback(data) {
        if (data) {
            data.clickable = true;
            data.onClick && data.onClick();
            this.scope.options.onClick && this.scope.options.onClick(data);
        }
        this.deferred.resolve(data);
        this._hide(data.event && data.event.keyCode === this.keyCode.TAB);
    }

    _place() {
        let options = this.scope.options;
        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$rootScope, () => {
            if (this.customPos) {
                let _getComboBox = (element) => {
                    if (element.hasClass('bottomPos')) {
                        return element;
                    }

                    let parent = element.parent();

                    return _getComboBox(parent);
                };

                let el = _getComboBox(this.entryElement);
                let placement = el[0].getBoundingClientRect();
                this.menu[0].style.top = placement.top + el[0].offsetHeight - 1 + 'px';
                this.menu[0].style.left = placement.left + 1 + 'px';
                this.menu[0].style.width = el[0].offsetWidth - 2 + 'px';
                this.menu.addClass('south');
            } else {
                let forceDirection = !!options.direction;

                let pos = this.entryElement[0].getBoundingClientRect();

                let goNorth, goWest;
                if (forceDirection) {
                    goNorth = options.direction.includes('north');
                    goWest = options.direction.includes('west');
                } else {
                    goNorth = pos.top > this.$window.innerHeight / 2;
                    goWest = pos.left > this.$window.innerWidth / 2;
                }

                if (goNorth) {
                    this.menu.addClass('north');
                    this.menu[0].style.bottom = this.$window.innerHeight - pos.bottom + 'px';
                } else {
                    this.menu.addClass('south');
                    this.menu[0].style.top = pos.top + 'px';
                }

                let aside = options.aside;
                let offsetX = options.offsetX || 0;
                if (goWest) {
                    this.menu.addClass('west');
                    let right = this.$window.innerWidth - pos.right;
                    if (right < 0) {
                        right = 8;
                    }
                    this.menu[0].style.right = right + 'px';
                } else {
                    this.menu.addClass('east');
                    let left = 0;
                    if (aside) {
                        left = pos.right + offsetX;
                    } else {
                        left = pos.left;
                    }

                    this.menu[0].style.left = left + 'px';
                }
            }
        });
    }

    _bindListeners() {
        this.$timeout(
            () => {
                this.$document[0].addEventListener('mousedown', this, false);
                this.$document[0].addEventListener('touchstart', this, false);
                this.$document[0].addEventListener('keydown', this, false);

                this.scrollParent = this.domService.scrollParent(angular.element(this.entryElement));

                if (this.scrollParent) {
                    this.scrollParent[0].addEventListener('scroll', this, false);
                }
            },
            0,
            false
        );
    }

    _handleKeyDown(event) {
        if (event.which === this.keyCode.ESCAPE || (event.which === this.keyCode.TAB && !this.scope.options.allowTabs)) {
            event.preventDefault();
            this._hide(event.which === this.keyCode.TAB);
        }
    }

    _unbindListeners() {
        this.$document[0].removeEventListener('mousedown', this, false);
        this.$document[0].removeEventListener('touchstart', this, false);
        this.$document[0].removeEventListener('keydown', this, false);

        if (this.scrollParent) {
            this.scrollParent[0].removeEventListener('scroll', this, false);
        }
    }

    _handleMouseDown(event) {
        let menu = this.menu[0];
        if (menu.contains(event.target)) {
            return;
        } else if (this.entryElement[0].contains(event.target)) {
            this._hide();
        } else {
            this.openedMenu = null;
            this._hide();
        }
    }

    _handleScrollEvent() {
        this._hide();
    }

    _hide(isTab) {
        this.scope.options.onClose && this.scope.options.onClose();
        this.$timeout(() => {
            this.scope.options.isShowing = false;
        }, 0).then(() => {
            let element;
            if (isTab) {
                element = this.domService.findNextTabStop(this.entryElement);
            } else {
                element = this.entryElement[0];
            }

            this._unbindListeners();
            this.deferred.reject();
            let focusedElement = this.$document[0].querySelector(':focus');
            if (!focusedElement || focusedElement.tagName === 'BODY' || this.compiled[0].contains(focusedElement)) {
                let elementToFocus = element.querySelector('[tabindex]') || element;
                elementToFocus.focus();
            }

            this.$timeout(
                () => {
                    this.compiled.remove();
                    this.scope.$destroy();
                },
                400,
                false
            );
        });
    }

    blurEntryElement() {
        this.entryElement[0].blur();
    }
}

class MenuService {
    constructor($document, $compile, $controller, $http, $rootScope, $q, $timeout, $window, $animate, $rootElement, keyCode, responsive, domService) {
        Object.assign(this, {
            $document,
            $compile,
            $controller,
            $http,
            $rootScope,
            $q,
            $timeout,
            $window,
            $animate,
            $rootElement,
            keyCode,
            responsive,
            domService
        });

        this.entryElements = [];
    }

    actionMenu(options, element, bind) {
        return this._init('rym-menu', options, element, bind);
    }

    dateMenu(options, element, bind, bindToBottom) {
        return this._init('rym-date-menu', options, element, bind, bindToBottom);
    }

    userMenu(options, entryElement, bind) {
        options.listenToId = 'user-menu-search';
        return this._init('rym-user-menu', options, entryElement, bind);
    }

    timeMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-time-menu', options, entryElement, bind, bindToBottom);
    }

    dropdownMenu(options, entryElement) {
        return this._init('rym-drop-down', options, entryElement, false);
    }

    organizationMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-organization-menu', options, entryElement, bind, bindToBottom);
    }

    timezoneMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-timezone-menu', options, entryElement, bind, bindToBottom);
    }

    meetingMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-meeting-menu', options, entryElement, bind, bindToBottom);
    }

    countryMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-country-menu', options, entryElement, bind, bindToBottom);
    }

    currencyMenu(options, entryElement, bind, bindToBottom) {
        return this._init('rym-currency-menu', options, entryElement, bind, bindToBottom);
    }

    colorMenu(options, entryElement, bind) {
        return this._init('rym-color-menu', options, entryElement, bind);
    }

    _init(componentName, options, entryElement, bind, customPos) {
        if (bind === false) {
            return this._show(componentName, options, entryElement, customPos);
        }

        let deferred = this.$q.defer();

        entryElement.on('click', () => {
            this._handleClickEntry(componentName, options, entryElement, customPos, deferred);
        });
        entryElement.on('keydown', (event) => {
            this._handleKeyDownEntry(event, componentName, options, entryElement, customPos);
        });

        return deferred.promise;
    }

    _handleClickEntry(component, options, entryElement, customPos, deferred) {
        let promise = this._show(component, options, entryElement, customPos);
        promise &&
            promise.then((data) => {
                deferred.resolve(data);
            });
    }
    _handleKeyDownEntry(event, component, options, entryElement, customPos) {
        event.stopPropagation();

        let isValidKey = event.keyCode === this.keyCode.ENTER || event.keyCode === this.keyCode.RETURN || event.keyCode === this.keyCode.DOWN;

        if (isValidKey) {
            this._show(component, options, entryElement, customPos);
        }
    }

    _show(component, options, entryElement, customPos) {
        let index = _.findIndex(this.entryElements, (e) => {
            return e[0] === entryElement[0];
        });
        if (index !== -1) {
            return null;
        }

        this.entryElements.push(entryElement);

        return new Menu(
            entryElement,
            component,
            options,
            customPos,
            this.$timeout,
            this.$document,
            this.$compile,
            this.$rootElement,
            this.$q,
            this.$rootScope,
            this.$window,
            this.responsive,
            this.domService,
            this.keyCode
        ).promise.finally(() => {
            this.$timeout(() => {
                this.entryElements.shift();
            }, 400);
        });
    }

    isOpen() {
        return !!this.entryElements.length;
    }
}

MenuService.$inject = [
    '$document',
    '$compile',
    '$controller',
    '$http',
    '$rootScope',
    '$q',
    '$timeout',
    '$window',
    '$animate',
    '$rootElement',
    'keyCode',
    'responsive',
    'domService'
];

export default MenuService;
