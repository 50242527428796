import template from './textEditor.html';
import './textEditor.less';
import { TIME } from '../../app.constants';

let textEditorDirective = (
    $window,
    $timeout,
    $rootScope,
    $interval,
    stringService,
    keyCode,
    selectionService,
    domService,
    eventEmitterService,
    userService
) => {
    return {
        restrict: 'E',
        scope: {
            content: '=ngModel',
            onSave: '&',
            onFocus: '&',
            onBlur: '&',
            placeholder: '@',
            lock: '<',
            focusOnViewLoaded: '<',
            noToolbar: '<',
            notifyChange: '<'
        },
        require: {
            ngModelController: 'ngModel'
        },
        template: template,
        link: function (scope, element, attrs, ngModel) {
            let hasClicked = false;
            let unsavedChanges = false;
            let notesWatch;
            let numberOfIntervalSaves = 0;

            let removeTimeouts = () => {
                if (this.removeFocus) {
                    $timeout.cancel(this.removeFocus);
                }
            };

            $timeout(() => {
                const rightMouseButton = 3;
                const middleMouseButton = 2;
                let editor = element[0].querySelector('#ng-wig-editable');
                let header = document.getElementById('header');
                let textElement = angular.element(editor);
                textElement[0].tabIndex = 0;
                let toolbar = element.find('ul');
                let originalText = scope.content;

                let notifyChange = (string) => {
                    if (scope.notifyChange) {
                        $rootScope.$broadcast(string);
                    }
                };

                /* When emptying the html of element before blur chain,
                   a paragraph is created and inserted in the contenteditable div.*/

                let removeSpontaneousParagraph = () => {
                    $timeout(
                        () => {
                            textElement.html('');
                        },
                        100,
                        false
                    );
                };

                if (scope.content) {
                    scope.content = stringService.linkify(stringService.replaceLineBreaksWithBreakTags(scope.content));
                } else {
                    textElement.html('');
                }

                if (angular.isDefined(scope.placeholder)) {
                    textElement.attr('data-placeholder', scope.placeholder);
                }

                element.on('mousedown', (event) => {
                    if (toolbar[0].contains(event.target)) {
                        event.preventDefault();
                    }

                    if (event.which === rightMouseButton) {
                        this.contextMenuOpen = true;
                    } else {
                        hasClicked = true;
                    }
                });

                textElement.on('contextmenu', (event) => {
                    this.contextMenuOpen = false;
                });

                var registerLinkEvents = function () {
                    var links = editor.querySelectorAll('a');
                    _.forEach(links, (link) => {
                        let angularElement = angular.element(link);
                        angularElement.on('mousedown', (event) => {
                            if (event.ctrlKey || (event.which && event.which === middleMouseButton)) {
                                return;
                            }

                            hasClicked = true;
                            if (event.which && event.which !== rightMouseButton) {
                                let url = event.target.href ? event.target.href : 'http://' + event.target.text;
                                window.open(url, '_blank');
                            }
                            event.stopImmediatePropagation();
                            event.preventDefault();
                        });

                        angularElement.on('click', (event) => {
                            if (event.ctrlKey || (event.which && event.which === middleMouseButton)) {
                                hasClicked = true;
                                event.stopImmediatePropagation();
                                event.preventDefault();
                                let url = event.target.href ? event.target.href : 'http://' + event.target.text;
                                window.open(url, '_blank');
                            }
                        });
                    });
                };

                var _handleSave = function (content, sender) {
                    if (
                        scope.content === originalText ||
                        content === originalText ||
                        (angular.isUndefined(scope.content) && originalText.length === 0)
                    ) {
                        if (sender !== 'autosave' && numberOfIntervalSaves > 0) {
                            scope.isLoading = true;
                            numberOfIntervalSaves = 0;
                            $timeout(() => {
                                scope.isLoading = false;
                            }, 500);
                        }

                        notifyChange('RevertedChanges');
                        unsavedChanges = false;
                        return;
                    }

                    let promise = scope.onSave({ event: { value: content, sender: sender } });

                    if (promise && promise.then) {
                        if (sender !== 'autosave') {
                            scope.isLoading = true;
                        }

                        promise
                            .then(() => {
                                notifyChange('SavedChanges');
                                originalText = content;
                                unsavedChanges = false;
                                ngModel.$setPristine();
                            })
                            .finally(() => {
                                scope.isLoading = false;
                            });
                    }
                };

                textElement.on('blur', () => {
                    eventEmitterService.publishEvent('text-editor:focusChanged', false);

                    if (!scope.lock && !this.contextMenuOpen) {
                        $interval.cancel(this.autoSaveInterval);
                        removeTimeouts();

                        if (notesWatch) {
                            notesWatch();
                        }

                        let content = stringService.removeSpaces(textElement.html());
                        content = stringService.addSpaces(content);
                        content = stringService.unlinkify(content);
                        content = stringService.removeBreakTagsInListElements(content);
                        content = stringService.replaceEmptyParagraphTags(content);
                        content = stringService.removeFontTagsFromHTML(content);
                        content = stringService.removeLeadingAndTrailingBreakTags(content);
                        if (!content) {
                            scope.content = '';
                            removeSpontaneousParagraph();
                        } else {
                            textElement.html(stringService.linkify(content));
                        }
                        registerLinkEvents();
                        scope.onBlur();
                        _handleSave(content, 'blur');

                        element.removeClass('active');
                        eventEmitterService.unSubscribe('scroll');
                    }
                });

                var updateToolbarPosition = function () {
                    let headerHeigth = header.clientHeight;
                    let toolbarHeight = toolbar[0].clientHeight;
                    let offset = textElement[0].getBoundingClientRect().top;
                    let textHeight = textElement[0].clientHeight;

                    if (offset < headerHeigth + toolbarHeight) {
                        toolbar.addClass('sticky-toolbar-active');
                        if (offset < 0) {
                            if (offset > -(textHeight - headerHeigth - toolbarHeight)) {
                                toolbar.css('top', headerHeigth + Math.abs(offset) + 'px');
                            }
                        } else {
                            let top = -toolbarHeight + (toolbarHeight + headerHeigth - offset);
                            toolbar.css('top', top + 'px');
                        }
                    } else if (toolbar.hasClass('sticky-toolbar-active')) {
                        toolbar.removeClass('sticky-toolbar-active');
                        toolbar.css('top', -toolbarHeight + 'px');
                    }
                };

                if (userService.isIE11()) {
                    let preventScroll = false;
                    let scrollTop = 0;

                    let scrollParent = domService.scrollParent(textElement);
                    scrollParent.on('scroll', (event) => {
                        if (preventScroll) {
                            scrollParent[0].scrollTop = scrollTop;
                        }
                    });

                    let preventScrollFix = (event) => {
                        if (textElement[0].innerText.length < 400) {
                            return;
                        }
                        let scrollParentClone = scrollParent.clone();
                        scrollParent.parent().prepend(scrollParentClone);
                        scrollParent.css('opacity', 0);
                        scrollTop = scrollParent[0].scrollTop;
                        scrollParentClone[0].scrollTop = scrollTop;
                        preventScroll = true;
                        $timeout(() => {
                            scrollParent.css('opacity', 1);
                            preventScroll = false;
                            scrollParentClone.remove();
                        }, 300);
                    };

                    scope.handleBeforeExecCommand = () => {
                        preventScrollFix();
                    };

                    scope.handleAfterExecCommand = () => {
                        preventScrollFix();
                    };

                    textElement.on('mousedown', preventScrollFix);
                    textElement.on('mouseup', preventScrollFix);
                }

                textElement.on('focus', () => {
                    eventEmitterService.publishEvent('text-editor:focusChanged', true);

                    if (!scope.lock && !this.contextMenuOpen) {
                        this.autoSaveInterval = $interval(
                            () => {
                                if (!ngModel.ngModelController.$$parentForm.$pristine) {
                                    let content = stringService.removeSpaces(textElement.html());
                                    content = stringService.addSpaces(content);
                                    content = stringService.unlinkify(content);
                                    content = stringService.removeBreakTagsInListElements(content);
                                    content = stringService.replaceEmptyParagraphTags(content);
                                    content = stringService.removeFontTagsFromHTML(content);
                                    content = stringService.removeLeadingAndTrailingBreakTags(content);
                                    numberOfIntervalSaves++;
                                    _handleSave(content, 'autosave');
                                }
                            },
                            5000,
                            0,
                            false
                        );

                        if (scope.content === '<p></p>') {
                            removeSpontaneousParagraph();
                        }

                        let visibilityChanged = () => {
                            textElement[0].blur();
                            eventEmitterService.unSubscribe('blurNotes');
                            document.removeEventListener('visibilitychange', visibilityChanged);
                        };

                        document.addEventListener('visibilitychange', visibilityChanged, false);

                        eventEmitterService.subscribe('blurNotes', visibilityChanged);

                        if (this.removeFocus) {
                            $timeout.cancel(this.removeFocus);
                        }

                        originalText = textElement.html();
                        scope.onFocus();
                        element.addClass('active');

                        if (hasClicked !== true) {
                            selectionService.setToEnd(textElement[0]);
                        }

                        hasClicked = false;

                        notesWatch = scope.$watch(
                            () => {
                                return textElement[0].innerHTML;
                            },
                            (value) => {
                                if (
                                    stringService.replaceEmptyParagraphTags(value) !== stringService.removeSpaces(scope.content) &&
                                    stringService.replaceEmptyParagraphTags(value) !== scope.content &&
                                    !unsavedChanges
                                ) {
                                    notifyChange('UnsavedChanges');
                                    unsavedChanges = true;
                                }
                            }
                        );

                        this.removeFocus = $timeout(
                            () => {
                                textElement[0].blur();
                            },
                            TIME.REMOVE_FOCUS,
                            false
                        );

                        eventEmitterService.subscribe('scroll', updateToolbarPosition);
                    }

                    this.contextMenuOpen = false;
                    updateToolbarPosition();
                });

                textElement.on('keydown', (event) => {
                    // If user hit the tab key
                    if (!scope.lock) {
                        if (event.keyCode === keyCode.TAB) {
                            let range = window.getSelection().getRangeAt(0);
                            let parentElement = range.startContainer.parentElement;
                            let numberOfLists = 0;

                            while (true) {
                                if (!parentElement || parentElement.id === 'ng-wig-editable') {
                                    break;
                                }

                                var nodeName = parentElement.nodeName.toLowerCase();
                                if (nodeName === 'ul' || nodeName === 'ol') {
                                    numberOfLists++;
                                }

                                parentElement = parentElement.parentElement;
                            }
                        }
                    }
                });

                textElement.on('keyup', (event) => {
                    if (this.removeFocus) {
                        $timeout.cancel(this.removeFocus);
                    }

                    if (event.keyCode !== keyCode.TAB && event.keyCode !== keyCode.F5 && event.keyCode !== keyCode.SPACE) {
                        if (!unsavedChanges) {
                            notifyChange('UnsavedChanges');
                            unsavedChanges = true;
                        } else if (scope.content === originalText) {
                            notifyChange('RevertedChange');
                            unsavedChanges = false;
                        }

                        this.removeFocus = $timeout(
                            () => {
                                textElement[0].blur();
                            },
                            TIME.REMOVE_FOCUS,
                            false
                        );
                    }
                    updateToolbarPosition();
                });

                textElement.on('paste', (event) => {
                    domService.pasteAtCaret(event);
                    updateToolbarPosition();
                });

                var toolbarButtons = element[0].querySelectorAll('.nw-button');
                _.forEach(toolbarButtons, (button) => {
                    button.tabIndex = -1;
                });

                $timeout(
                    () => {
                        registerLinkEvents();
                    },
                    1,
                    false
                );

                if (scope.focusOnViewLoaded) {
                    textElement[0].focus();
                }
            }, 100);
        }
    };
};

textEditorDirective.$inject = [
    '$window',
    '$timeout',
    '$rootScope',
    '$interval',
    'stringService',
    'keyCode',
    'selectionService',
    'domService',
    'eventEmitterService',
    'userService'
];

export default textEditorDirective;
