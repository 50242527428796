class CreateActionDialog {
    constructor(stringService, userService, translationService) {
        this.stringService = stringService;
        this.userService = userService;
        this.translationService = translationService;

        this.newAction = {};
    }

    $onInit() {
        if (this.options.defaultResponsible) {
            this.userService.getCurrentUser().then((currentUser) => {
                this.newAction.responsible = currentUser;
            });
        }
    }

    create() {
        if (this.stringService.isUndefinedOrEmpty(this.newAction.name)) {
            return;
        }

        this.onOk({ data: this.newAction });
    }
}

CreateActionDialog.$inject = ['stringService', 'userService', 'translationService'];

export default CreateActionDialog;
