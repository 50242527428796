import { BASE_URLS, STRATSYS_IDSRV, STRIPE, TEAMS, APPLICATION_INSIGHTS, PLATFORM_COMMON_LOGIN } from '../../../app.constants';

class EnvService {
    constructor($q, $http, $window) {
        Object.assign(this, { $q, $http, $window });
        this.deffered = this.$q.defer();
        this.loading = false;
    }

    init() {
        if (!this.loading) {
            this.loading = true;
            this.fetchConfig().then((conf) => {
                this.RYM = conf.BASE_URLS.RYM ? conf.BASE_URLS.RYM : BASE_URLS.RYM;
                this.IDSRV = conf.BASE_URLS.IDSRV ? conf.BASE_URLS.IDSRV : BASE_URLS.IDSRV;
                this.API = conf.BASE_URLS.API ? conf.BASE_URLS.API : BASE_URLS.API;
                this.RYM_OLD = conf.BASE_URLS.RYM_OLD ? conf.BASE_URLS.RYM_OLD : BASE_URLS.RYM_OLD;
                this.STRATSYS_CLIENT_ID = conf.STRATSYS_IDSRV.CLIENT_ID ? conf.STRATSYS_IDSRV.CLIENT_ID : STRATSYS_IDSRV.CLIENT_ID;
                this.STRATSYS_URL = conf.STRATSYS_IDSRV.URL ? conf.STRATSYS_IDSRV.URL : STRATSYS_IDSRV.NEW;
                this.STRATSYS_CLIENT_SECRET = conf.STRATSYS_IDSRV.CLIENT_SECRET ? conf.STRATSYS_IDSRV.CLIENT_SECRET : STRATSYS_IDSRV.CLIENT_SECRET;
                this.STRIPE_PUBLIC_KEY = conf.STRIPE.PUBLIC_KEY ? conf.STRIPE.PUBLIC_KEY : STRIPE.PUBLIC_KEY;
                this.TEAMS_APP_ID = conf.TEAMS.APP_ID ? conf.TEAMS.APP_ID : TEAMS.APP_ID;
                this.APPLICATION_INSIGHTS_KEY = conf.APPLICATION_INSIGHTS.INSTRUMENTATIONKEY
                    ? conf.APPLICATION_INSIGHTS.INSTRUMENTATIONKEY
                    : APPLICATION_INSIGHTS.INSTRUMENTATIONKEY;
                this.PLATFORM_COMMON_LOGIN_URL = conf.PLATFORM_COMMON_LOGIN.URL ? conf.PLATFORM_COMMON_LOGIN.URL : PLATFORM_COMMON_LOGIN.URL;

                this.deffered.resolve();
            });
        }
        return this.deffered.promise;
    }

    fetchConfig() {
        const configFile = 'meetings_appsettings.json';
        const currentUrl = this.$window.location.origin;
        const response = fetch(currentUrl + '/' + configFile);
        return response.then((resp) => {
            return resp.json();
        });
    }
}

EnvService.$inject = ['$q', '$http', '$window'];

export default EnvService;
