class ToastService {
    constructor($q, $rootScope, $injector, $timeout, $document, $compile, translationService, stringService) {
        Object.assign(this, { $q, $rootScope, $injector, $timeout, $document, $compile, translationService, stringService });

        this.onShowCallbacks = [];
        this.onSuccessCallbacks = [];
    }

    hide() {
        this.$rootScope.$broadcast('rym-toast-remove');
    }

    custom(options) {
        this.$rootScope.$broadcast('rym-toast-add', options);
        this.executeCallbacks(this.onShowCallbacks);
    }

    onShow(callback) {
        this.onShowCallbacks.push(callback);
    }

    executeCallbacks(callbacks) {
        let callbacksToRemove = [];
        callbacks.forEach((callback) => {
            let removeCallback = !callback();
            if (removeCallback) {
                callbacksToRemove.push(callback);
            }
        });

        callbacksToRemove.forEach((callback) => {
            let index = this.onShowCallbacks.indexOf(callback);
            this.onShowCallbacks.splice(index, 1);
        });
    }

    onSuccess(callback) {
        this.onSuccessCallbacks.push(callback);
    }

    loading(options) {
        this.custom(
            angular.extend(
                {
                    title: this.translationService.translate('Loading'),
                    loading: true,
                    delay: 750
                },
                options
            )
        );
    }

    success(options) {
        this.custom(
            angular.extend(
                {
                    icon: 'check',
                    title: this.translationService.translate('Success'),
                    duration: 4000
                },
                options
            )
        );
        this.$timeout(() => {
            this.executeCallbacks(this.onSuccessCallbacks);
        }, 750);
    }

    rateUs() {
        let deferred = this.$q.defer(),
            element = this.$document.find('rym-toast').parent(),
            scope = this.$rootScope.$new(),
            toast = this.$compile('<rym-rating on-close="onClose()" on-submit="onSubmit(rating)" ng-if="show"/>')(scope);

        scope.onSubmit = (rating) => {
            scope.show = false;
            this.$timeout(() => {
                toast.remove();
                deferred.resolve(rating);
            }, 100);
        };

        scope.onClose = () => {
            scope.show = false;
            this.$timeout(() => {
                toast.remove();
                deferred.reject();
            }, 100);
        };

        scope.$applyAsync(() => {
            scope.show = true;
        });

        element.append(toast);

        return deferred.promise;
    }

    information(options) {
        this.custom(
            angular.extend(
                {
                    icon: 'info',
                    title: this.translationService.translate('Information'),
                    duration: 5000
                },
                options
            )
        );
    }

    error(options) {
        let contactService = this.$injector.get('contactService');

        let action = () => {
            this.hide();
            contactService.support();
        };
        this.custom(
            angular.extend(
                {
                    id: 'something went wrong',
                    icon: 'priority_high',
                    title: this.translationService.translate('administration_SomethingWentWrong'),
                    description: this.translationService.translate('client_PleaseTryAgain'),
                    duration: null,
                    actionIcon: 'clear',
                    action: true,
                    textAction: action,
                    textActionText: this.translationService.translate('administration_ContactUs')
                },
                options
            )
        );
    }

    notifyError(options) {
        let contactService = this.$injector.get('contactService');

        let action = () => {
            this.hide();
            contactService.support();
        };
        let errorTitle = this.translationService.translate(options.translateCode);

        this.custom(
            angular.extend(
                {
                    id: 'something went wrong',
                    icon: 'priority_high',
                    title: errorTitle,
                    description: options.message,
                    duration: 10000,
                    actionIcon: 'clear',
                    action: true,
                    textAction: action,
                    textActionText: this.translationService.translate('administration_ContactUs')
                },
                options
            )
        );
    }
}

ToastService.$inject = ['$q', '$rootScope', '$injector', '$timeout', '$document', '$compile', 'translationService', 'stringService'];

export default ToastService;
