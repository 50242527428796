import { HTTP_STATUS_CODES } from '../../../app.constants';

class MeetingBuildingService {
    constructor(
        $q,
        $state,
        $rootScope,
        $timeout,
        meetingApiService,
        organizationApiService,
        dialogService,
        translationService,
        actionApiService,
        applicationInsightsService,
        toastService
    ) {
        Object.assign(this, {
            $q,
            $state,
            $rootScope,
            $timeout,
            meetingApiService,
            organizationApiService,
            dialogService,
            translationService,
            actionApiService,
            applicationInsightsService,
            toastService
        });
    }

    GetMeeting(meetingId, hardReload) {
        if (hardReload) {
            this.meetingApiService.clearCache(`${meetingId}`);
        }

        this.$rootScope.isFetchingMeeting = true;
        let deferred = this.$q.defer();

        this.meetingApiService
            .get(meetingId)
            .then((fetchedMeeting) => {
                deferred.resolve(fetchedMeeting);
            })
            .catch((error) => {
                if (error.status === HTTP_STATUS_CODES.NOTFOUND || error.status === HTTP_STATUS_CODES.FORBIDDEN) {
                    this.$state.go('meetings');
                    this.dialogService
                        .alert({
                            id: 'meeting not found dialog',
                            title: this.translationService.translate('client_MeetingWasntFoundTitle'),
                            description: this.translationService.translate('client_MeetingWasntFoundDescription')
                        })
                        .then(() => {
                            deferred.reject();
                        });
                } else if (error.status === HTTP_STATUS_CODES.CHANGE_MEETING_ORG) {
                } else {
                    deferred.reject();
                    this.$state.go('meetings');
                    this.toastService.error({ description: error.data.message });
                }
            });

        return deferred.promise;
    }

    buildAgendaOnMeeting(meeting, hardReload) {
        let meetingId = meeting.id;

        if (hardReload) {
            this.meetingApiService.clearCache(`${meetingId}/agendas`);
            this.actionApiService.clearActionCache();
        }

        this.$rootScope.isFetchingMeeting = true;
        let deferred = this.$q.defer();
        let promises = [];

        promises.push(this.meetingApiService.getAgendas(meetingId));

        promises.push(this.actionApiService.getActionsForMeeting(meetingId));

        this.$q
            .all(promises)
            .then((result) => {
                let rootAgenda = _.find(result[0], (agenda) => agenda.parentId === 0);

                if (
                    (meeting.provider && (meeting.provider === 'Outlook' || meeting.provider.providerName === 'Outlook') && !meeting.office365UId) ||
                    ((meeting.office365UId || meeting.isCreatedExternally) && !meeting.isOrganizer)
                ) {
                    meeting.lockMetaData = true;
                }

                rootAgenda.children = [];
                meeting.rootAgenda = angular.copy(rootAgenda);

                this._buildAgenda(result[0], rootAgenda);
                this._addActionsToAgendas(result[0], result[1]);

                meeting.agenda = rootAgenda.children;
                deferred.resolve(angular.copy(meeting));
            })
            .finally(() => {
                this.$timeout(() => {
                    delete this.$rootScope.isFetchingMeeting;
                }, 10000);
            });

        return deferred.promise;
    }

    buildMeeting(meetingId, hardReload) {
        if (hardReload) {
            this.meetingApiService.clearCache(`${meetingId}/agendas`);
            this.actionApiService.clearActionCache();
        }

        this.$rootScope.isFetchingMeeting = true;
        let deferred = this.$q.defer();
        let promises = [];
        this.meetingApiService
            .get(meetingId)
            .then((fetchedMeeting) => {
                let meeting = fetchedMeeting;
                promises.push(this.meetingApiService.getAgendas(meetingId));

                promises.push(this.actionApiService.getActionsForMeeting(meetingId));

                this.$q
                    .all(promises)
                    .then((result) => {
                        if (
                            (meeting.provider &&
                                (meeting.provider === 'Outlook' || meeting.provider.providerName === 'Outlook') &&
                                !meeting.office365UId) ||
                            ((meeting.office365UId || meeting.isCreatedExternally) && !meeting.isOrganizer)
                        ) {
                            meeting.lockMetaData = true;
                        }

                        let rootAgenda = _.find(result[0], (agenda) => agenda.parentId === 0);
                        rootAgenda.children = [];
                        meeting.rootAgenda = angular.copy(rootAgenda);

                        this._buildAgenda(result[0], rootAgenda);
                        this._addActionsToAgendas(result[0], result[1]);

                        meeting.agenda = rootAgenda.children;
                        deferred.resolve(angular.copy(meeting));
                    })
                    .finally(() => {
                        this.$timeout(() => {
                            delete this.$rootScope.isFetchingMeeting;
                        }, 10000);
                    });
            })
            .catch((error) => {
                if (error.status === HTTP_STATUS_CODES.NOTFOUND || error.status === HTTP_STATUS_CODES.FORBIDDEN) {
                    this.$state.go('meetings');
                    this.dialogService
                        .alert({
                            id: 'meeting not found dialog',
                            title: this.translationService.translate('client_MeetingWasntFoundTitle'),
                            description: this.translationService.translate('client_MeetingWasntFoundDescription')
                        })
                        .then(() => {
                            deferred.reject();
                        });
                } else {
                    deferred.reject();
                    this.$state.go('meetings');
                    this.toastService.error({ description: error.data.message });
                }
            });

        return deferred.promise;
    }

    _buildAgenda(agendas, parent) {
        _.forEach(agendas, (x) => {
            if (parent.id === x.parentId) {
                if (!parent.children) {
                    parent.children = [];
                }

                parent.children.push(x);
                this._buildAgenda(agendas, x);
            }
        });
    }

    _addActionsToAgendas(agendas, actions) {
        _.forEach(agendas, (agenda) => {
            agenda.actions = _.filter(actions, (action) => {
                return action.agendaId === agenda.id;
            });
        });
    }
}

MeetingBuildingService.$inject = [
    '$q',
    '$state',
    '$rootScope',
    '$timeout',
    'meetingApiService',
    'organizationApiService',
    'dialogService',
    'translationService',
    'actionApiService',
    'applicationInsightsService',
    'toastService'
];

export default MeetingBuildingService;
