const TWELVE_HOURS = 12;

class Calendar {
    constructor(mondayFirstDayOfWeek, translationService) {
        this.translationService = translationService;
        this.mondayFirstDayOfWeek = mondayFirstDayOfWeek;
        this.today = new Date();
        this.start = this.today;
        this.months = [];
        this.buildMonthsArray();
    }

    buildMonthsArray() {
        this.translationService
            .translateBatch([
                'client_Jan',
                'client_Feb',
                'client_Mar',
                'client_Apr',
                'client_May',
                'client_Jun',
                'client_Jul',
                'client_Aug',
                'client_Sep',
                'client_Oct',
                'client_Nov',
                'client_Dec'
            ])
            .then((result) => {
                for (let month in result) {
                    this.months.push(result[month].substring(0, 3));
                }
            });
    }

    setSelectedDate(date) {
        if (this.selected) {
            this.selected.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        } else {
            date ? (this.selected = new Date(date)) : (this.selected = this.today);
        }

        this.start = this.selected;
        if (this.weeks && this.weeks.length) {
            _.find(this.weeks, (week) => {
                _.forEach(week.days, (day) => {
                    day.isCurrentDate = this._isCurrentDate(day.date);
                });
            });
        }
    }

    getSelectedDate() {
        return this.selected;
    }

    getMonthString() {
        return this.months[this.start.getMonth()] + ', ' + this.start.getFullYear().toString();
    }

    currentMonth() {
        this._buildMonth();
    }

    selectedMonth() {
        this._buildMonth(true);
    }

    previousMonth() {
        this.start = new Date(this.start.getFullYear(), this.start.getMonth() - 1);

        this._buildMonth();
    }

    nextMonth() {
        this.start = new Date(this.start.getFullYear(), this.start.getMonth() + 1);

        this._buildMonth();
    }

    _isToday(date) {
        return this.today.toDateString() === date.toDateString();
    }

    _getDay(date, extra) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + extra, TWELVE_HOURS);
    }

    _isCurrentDate(date) {
        return this.selected.toDateString() === date.toDateString();
    }

    _addWeek(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7);
    }

    _startingDate(date) {
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let minus = 1;
        while (firstDay.getDay() !== 0) {
            firstDay = new Date(date.getFullYear(), date.getMonth(), 1 - minus);
            minus++;
        }

        return firstDay;
    }

    _buildMonth(startAtSelectedDate) {
        this.weeks = [];
        let date = startAtSelectedDate ? this.selected : this.start;
        let start = this._startingDate(date);

        this.month = date.getMonth();

        for (let i = 0; i < 6; i++) {
            this.weeks.push({ days: this._buildWeek(start) });
            start = this._addWeek(start);
        }
    }

    _buildWeek(date) {
        const SUNDAY = 0,
            MONDAY = 1,
            DAYSOFWEEK = 7;
        let startDay = this.mondayFirstDayOfWeek ? MONDAY : SUNDAY,
            endDay = startDay + DAYSOFWEEK;

        let days = [];
        for (let i = startDay; i < endDay; i++) {
            let day = this._getDay(date, i);
            days.push({
                number: day.getDate(),
                isCurrentMonth: day.getMonth() === this.month,
                isToday: this._isToday(day),
                isCurrentDate: this._isCurrentDate(day),
                date: day
            });
        }
        return days;
    }
}

export default Calendar;
