var actionRoutes = function (routerHelper) {
    routerHelper.configureStates([
        {
            state: 'actions',
            config: {
                url: '/actions',
                template: '<rym-actions/>',
                ncyBreadcrumb: {
                    label: 'Actions'
                }
            }
        },
        {
            state: 'actions.action',
            config: {
                url: '/:actionId',
                params: {
                    action: null,
                    parentUrl: 'actions',
                    searchRoutedFromId: null
                },
                ncyBreadcrumb: {
                    skip: true
                }
            }
        }
    ]);
};
actionRoutes.$inject = ['routerHelper'];

export default actionRoutes;
