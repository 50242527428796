function Routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'meeting',
            config: {
                url: '/meeting/:id',
                params: {
                    meeting: null,
                    scrollToId: null,
                    searchRoutedFromId: null
                },
                template: '<rym-meeting></rym-meeting>',
                ncyBreadcrumb: {
                    label: '',
                    parent: 'meetings'
                }
            }
        },
        {
            state: 'meeting.action',
            config: {
                url: '/action/:actionId',
                params: {
                    action: null,
                    parentUrl: 'meeting',
                    protocolIsLocked: false
                },
                template: '<rym-action-detail/>',
                ncyBreadcrumb: {
                    skip: true
                }
            }
        },
        {
            state: 'meeting.documents',
            config: {
                url: '/agenda/:agendaId/documents',
                params: {
                    documents: null,
                    meetingId: null,
                    parentUrl: 'meeting',
                    protocolIsLocked: false
                },
                ncyBreadcrumb: {
                    skip: true
                }
            }
        }
    ]);
}

Routing.$inject = ['routerHelper'];
export default Routing;
