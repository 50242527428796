import meetingSeriesDialogComponent from './meetingSeriesDialog/meetingSeriesDialog.component';
import meetingPreviewDialogComponent from './meetingPreviewDialog/meetingPreviewDialog.component';
import meetingSeriesActionsComponent from './meetingSeriesComponents/meetingSeriesActions/meetingSeriesActions.component';
import meetingSeriesDetailsComponent from './meetingSeriesComponents/meetingSeriesDetail/meetingSeriesDetail.component';
import meetingSeriesDecisionsComponent from './meetingSeriesComponents/meetingSeriesDecisions/meetingSeriesDecisions.component';
import meetingSeriesMeetingsComponent from './meetingSeriesComponents/meetingSeriesMeetings/meetingSeriesMeetings.component';
import meetingSeriesPostponedAgendasComponent from './meetingSeriesComponents/meetingSeriesPostponedAgendas/meetingSeriesPostponedAgendas.component';
import meetingSeriesEventsComponent from './meetingSeriesComponents/meetingSeriesEvents/meetingSeriesEvents.component';

import meetingSeriesService from './meetingSeries.service';

angular
    .module('rym.series', [])
    .service('meetingSeriesService', meetingSeriesService)
    .component('rymMeetingSeriesDialog', meetingSeriesDialogComponent)
    .component('rymMeetingPreviewDialog', meetingPreviewDialogComponent)
    .component('rymMeetingSeriesActions', meetingSeriesActionsComponent)
    .component('rymMeetingSeriesDetail', meetingSeriesDetailsComponent)
    .component('rymMeetingSeriesDecisions', meetingSeriesDecisionsComponent)
    .component('rymMeetingSeriesEvents', meetingSeriesEventsComponent)
    .component('rymMeetingSeriesPostponedAgendas', meetingSeriesPostponedAgendasComponent)
    .component('rymMeetingSeriesMeetings', meetingSeriesMeetingsComponent);
