function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'templates',
            config: {
                url: '/templates',
                template: '<rym-templates></rym-templates>',
                ncyBreadcrumb: {
                    label: 'Templates'
                },
                authorization: {
                    organizer: true
                }
            }
        },
        {
            state: 'template',
            config: {
                url: '/templates/:id',
                template: '<rym-meeting></rym-meeting>',
                ncyBreadcrumb: {
                    label: '',
                    parent: 'templates'
                },
                params: {
                    templateMode: true
                },
                authorization: {
                    organizer: true
                }
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
