import template from './meetingSeriesPostponedAgendas.html';
import controller from './meetingSeriesPostponedAgendas.controller';

let meetingSeriesPostponedAgendasComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingSeriesDialog'
    }
};

export default meetingSeriesPostponedAgendasComponent;
