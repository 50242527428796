import controller from './subscriptionCardForm.controller';
import template from './subscriptionCardForm.html';

let subscriptionCardFormComponent = {
    template,
    controller,
    bindings: {
        isDisabled: '<',
        validForm: '=',
        formData: '=',
        clearForm: '<'
    }
};

export default subscriptionCardFormComponent;
