import template from './datepicker.html';
import controller from './datepicker.controller';

let datepickerComponent = {
    template,
    controller,
    bindings: {
        date: '<',
        fromDate: '<',
        onChange: '&'
    }
};

export default datepickerComponent;
