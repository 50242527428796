import service from './menu.service';
import menuComponent from './menu.component';
import dateMenuComponent from './components/dateMenu/dateMenu.component';
import userMenuComponent from './components/userMenu/userMenu.component';
import timeMenuComponent from './components/timeMenu/timeMenu.component';
import meetingMenuComponent from './components/meetingMenu/meetingMenu.component';
import countryMenuComponent from './components/countryMenu/countryMenu.component';
import currencyMenuComponent from './components/currencyMenu/currencyMenu.component';
import timezoneMenuComponent from './components/timezoneMenu/timezoneMenu.component';
import organizationMenuComponent from './components/organizationMenu/organizationMenu.component';
import dropdownComponent from './components/dropdownMenu/dropdownMenu.component';
import colorMenuComponent from './components/colorMenu/colorMenu.component';
import './menu.less';

angular
    .module('rym.menu', [])
    .service('menuService', service)
    .component('rymMenu', menuComponent)
    .component('rymUserMenu', userMenuComponent)
    .component('rymDropDown', dropdownComponent)
    .component('rymMeetingMenu', meetingMenuComponent)
    .component('rymDateMenu', dateMenuComponent)
    .component('rymTimeMenu', timeMenuComponent)
    .component('rymCountryMenu', countryMenuComponent)
    .component('rymCurrencyMenu', currencyMenuComponent)
    .component('rymTimezoneMenu', timezoneMenuComponent)
    .component('rymOrganizationMenu', organizationMenuComponent)
    .component('rymColorMenu', colorMenuComponent);
