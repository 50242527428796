require('offline-js');
import { HTTP_STATUS_CODES, OFFICE365 } from './app.constants';

let runBlock = async function (
    $rootScope,
    $timeout,
    $window,
    $state,
    $stateParams,
    translationService,
    authService,
    toastService,
    dialogService,
    userService,
    paymentService,
    signalRService,
    eventEmitterService,
    localizationService,
    applicationInsightsService,
    dataLayerService,
    featureToggleService,
    autoTrackEventService,
    teamsService,
    appcuesService,
    calendarService,
    notificationService,
    subscriptionService,
    cacheService,
    envService,
    platformService
) {
    platformService.init();

    let isAuthenticated = false,
        unsavedChanges = false,
        hasError = false,
        translations,
        lang;

    if (document.documentMode) {
        if (document.documentMode <= 10) {
            $rootScope.outdatedBrowser = true;
            return;
        }
    }

    if ($rootScope.hasError || hasError) {
        return;
    }

    translationService
        .translateBatch(['client_YouCanNowContinue', 'client_ConnectionRestored', 'client_YouHaveUnsavedChanges', 'send'])
        .then((translatedStrings) => {
            translations = translatedStrings;
        });

    $rootScope.isMACOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    $rootScope.allowNavigation = !teamsService.isTeams();

    document.documentElement.setAttribute('data-useragent', navigator.userAgent);
    $rootScope.$on('$stateChangeStart', (event, toState) => {
        applicationInsightsService.trackPage(toState.name);

        envService.init().then(
            () => {
                if (_.has(toState, 'authorization.admin')) {
                    userService.getCurrentUser().then((user) => {
                        if (!user.isAdmin) {
                            $state.go('meetings');
                        }
                    });
                }

                if (_.has(toState, 'authorization.organizer')) {
                    userService.getCurrentUser().then((user) => {
                        if (user.isExternal) {
                            $state.go('meetings');
                        }
                    });
                }
            },
            (err) => {
                console.log('Failed to read settings from json');
            }
        );
    });

    $rootScope.$on('$locationChangeSuccess', () => {
        appcuesService.page();
    });

    let setHasLoggedin = (user) => {
        let usertype = 'organizer';
        if (user.isAdmin) {
            usertype = 'administrator';
        } else if (user.isExternal) {
            usertype = 'guest';
        }

        dataLayerService.pushData({
            event: 'Trial',
            email: user.email,
            app: 'meetings',
            usertype: usertype
        });

        userService.setHasLoggedIn();
    };

    let startIntroGuide = () => eventEmitterService.publishEvent('showIntroGuide');
    let setLocale = (user) => localizationService.setLocale(lang, user.preferredCulture);
    let initializeApplicationInsights = (user) => {
        if (user) {
            applicationInsightsService.initialize(user);
        } else {
            userService.getCurrentUser().then((user) => {
                applicationInsightsService.initialize(user);
            });
        }
    };
    let initializeSignalRService = (user) => {
        signalRService.initialize().then(() => {
            var handleOfficeReLogin = function () {
                let isStratsysLoginRedirected = $window.localStorage.getItem(OFFICE365.LOGIN_REDIRECTED_FROM_STRATSYS);
                if (isStratsysLoginRedirected) {
                    $window.localStorage.removeItem(OFFICE365.LOGIN_REDIRECTED_FROM_STRATSYS);
                    signalRService
                        .reloadPage(user.id)
                        .then((result) => {
                            $window.close();
                        })
                        .catch((err) => {
                            applicationInsightsService.trackException(err);
                            $window.close();
                        });
                }
            };

            signalRService
                .startConnection()
                .then((r) => {
                    handleOfficeReLogin();
                })
                .catch((err) => {
                    applicationInsightsService.trackException(err);
                    handleOfficeReLogin();
                });
        });
    };
    let showErrorPage = () => {
        $rootScope.hasError = true;
        hasError = true;
        let el = document.querySelector('.rym-splash');
        el && el.parentNode.removeChild(el);
    };
    let checkOngoingTransferState = () => {
        let goToMeetingId = $window.localStorage.getItem('Go-To-MeetingId');
        if (goToMeetingId) {
            $state.go('meeting', { id: goToMeetingId });
            $window.localStorage.removeItem('Go-To-MeetingId');
        }

        let goToActionId = $window.localStorage.getItem('Go-To-ActionId');
        if (goToActionId) {
            $state.go('actions.action', { actionId: goToActionId });
            $window.localStorage.removeItem('Go-To-ActionId');
        }
    };

    eventEmitterService.subscribe('organizationChanged', () => {
        initializeApplicationInsights();
    });
    eventEmitterService.subscribe('rateLimitReached', () => {
        paymentService.trialExceeded();
    });
    cacheService.init();

    envService.init().then(
        () => {
            authService
                .initializeUser()
                .then((loggedIn) => {
                    unsavedChanges = false;
                    isAuthenticated = loggedIn;
                    $rootScope.isAuthenticated = isAuthenticated;

                    userService
                        .getCurrentUser()
                        .then((user) => {
                            initializeApplicationInsights(user);
                            initializeSignalRService(user);
                            appcuesService.start();

                            userService.setUserLanguage().finally(() => {
                                lang = translationService.preferredLanguage();

                                setLocale(user).then(() => {
                                    $rootScope.isTranslated = true;
                                    if (!user.timezone) {
                                        userService.setTimezone();
                                    }
                                    if (!user.hasLoggedIn) {
                                        setHasLoggedin(user);
                                    }

                                    if (!user.hasFinishedIntroGuide) {
                                        if (user.isAdmin) {
                                            window.hj && hj('trigger', 'show_intro_guide_for_admin');
                                        }
                                        $timeout(() => {
                                            startIntroGuide();
                                        }, 50);
                                    } else {
                                        if (user.isOffice365Organization && !user.hasValidOffice365Token && !user.hideOffice365IntegrationDialog) {
                                            calendarService.activateOffice365Integration();
                                        }
                                        $rootScope.hasShownIntroGuide = true;
                                        if (!user.hasAcceptedTerms) {
                                            userService.showTermsOfService();
                                        }
                                        paymentService.showTrialInformation();
                                    }
                                    if (user.isAdmin) {
                                        subscriptionService.setSubscriptionRequiresAction();
                                    }

                                    let el = document.querySelector('.rym-splash');
                                    el && el.parentNode.removeChild(el);

                                    checkOngoingTransferState();
                                });
                            });
                        })
                        .catch((error) => {
                            showErrorPage();
                        });

                    featureToggleService.getAllFeatureToggles();
                })
                .catch((error) => {
                    applicationInsightsService.trackException(error);
                    if (error.message !== 'Network Error' && error.status !== HTTP_STATUS_CODES.SERVERERROR) {
                        authService.login();
                    } else {
                        showErrorPage();
                    }
                });
        },
        (err) => {
            console.log('Failed to read settings from json');
        }
    );

    $rootScope.$on('UnsavedChanges', () => (unsavedChanges = true));
    $rootScope.$on('SavedChanges', () => (unsavedChanges = false));
    $rootScope.$on('RevertedChanges', () => (unsavedChanges = false));

    $window.addEventListener('beforeunload', (event) => {
        if (isAuthenticated && $window.self !== $window.top) {
            $window.sessionStorage.clear();
        }

        if ($state.includes('meeting')) {
            signalRService.leaveMeeting(parseInt($stateParams.id, 10));

            if (unsavedChanges) {
                let confirmation = translations.client_YouHaveUnsavedChanges;

                (event || window.event).returnValue = confirmation;
                return confirmation;
            }
        }
    });

    Offline.options = {
        checkOnLoad: false,
        interceptRequests: true,
        reconnect: {
            initialDelay: 3
        },
        requests: false,
        game: false
    };

    Offline.on('down', () => {
        $rootScope.$broadcast('connectionDown');
        toastService.hide();
        dialogService.custom('rym-offline-dialog', {});
        $rootScope.$applyAsync();
    });

    Offline.on('up', () => {
        $rootScope.$broadcast('connectionRestored');
        toastService.success({
            title: translations.client_ConnectionRestored,
            description: translations.client_YouCanNowContinue,
            duration: 4500,
            delay: 200
        });
    });

    autoTrackEventService.startAutoTrack().onEvent((data, $event) => {
        applicationInsightsService.trackEvent(data.eventType, data);
        if (data.eventId) {
            applicationInsightsService.trackEvent(`click on ${data.eventId}`, data);
        }
    });

    calendarService.init();
    notificationService.init();
};

runBlock.$inject = [
    '$rootScope',
    '$timeout',
    '$window',
    '$state',
    '$stateParams',
    'translationService',
    'authService',
    'toastService',
    'dialogService',
    'userService',
    'paymentService',
    'signalRService',
    'eventEmitterService',
    'localizationService',
    'applicationInsightsService',
    'dataLayerService',
    'featureToggleService',
    'autoTrackEventService',
    'teamsService',
    'appcuesService',
    'calendarService',
    'notificationService',
    'subscriptionService',
    'cacheService',
    'envService',
    'platformService'
];

export default runBlock;
