let config = function (ngWigToolbarProvider) {
    ngWigToolbarProvider.setButtons([]);
    ngWigToolbarProvider.addCustomButton('btn1', 'nw-btn-bold');
    ngWigToolbarProvider.addCustomButton('btn2', 'nw-btn-italic');
    ngWigToolbarProvider.addCustomButton('btn3', 'nw-btn-underline');
    ngWigToolbarProvider.addCustomButton('btn4', 'nw-btn-bullet');
    ngWigToolbarProvider.addCustomButton('btn5', 'nw-btn-number');
};

config.$inject = ['ngWigToolbarProvider'];

export default config;
