import template from './dialog.html';
import controller from './dialog.controller';
import './dialog.less';

let dialogComponent = {
    template,
    controller,
    transclude: {
        header: '?rymDialogHeader',
        content: '?rymDialogContent',
        footer: '?rymDialogFooter'
    },
    bindings: {
        onCancel: '&',
        onOk: '&',
        onSubmit: '&',
        onHide: '&',
        options: '='
    }
};

export default dialogComponent;
