class EventEmitterService {
    constructor() {
        this.observables = new Map();
    }

    subscribe(key, func, isPromise) {
        if (isPromise) {
            this.observables.set(key, func);
        } else if (this.observables.get(key)) {
            this.observables.get(key).push(func);
        } else {
            this.observables.set(key, [func]);
        }
    }

    publishEvent(key, eventValue) {
        let observable = this.observables.get(key);
        if (observable) {
            if (_.isArray(observable)) {
                return _.forEach(observable, (func) => {
                    func(eventValue);
                });
            } else {
                return observable(eventValue);
            }
        } else return null;
    }

    unSubscribe(key) {
        if (this.observables.has(key)) {
            this.observables.delete(key);
        }
    }

    isSubscribing(key) {
        return this.observables.has(key);
    }
}

export default EventEmitterService;
