import controller from './textDialog.controller';
import template from './textDialog.html';
import './textDialog.less';

let textDialogComponent = {
    controller,
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default textDialogComponent;
