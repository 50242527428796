import { PARTICIPANT_TYPES } from '../../../app.constants';

class ParticipantsService {
    constructor($q, dialogService, meetingApiService, userService, translationService, toastService) {
        Object.assign(this, { $q, dialogService, meetingApiService, userService, translationService, toastService });
        this._participants = [];
        this._sortOrder = 1;
        this._onLoadedCallbacks = [];
    }

    init(meetingId) {
        if (meetingId === this._meetingId) {
            this._onLoaded();
            return this._promise;
        }

        this._onChangeCallbacks = [];
        this._meetingId = meetingId;
        this._promise = this.reset().then((s) => {
            this._onLoaded();
        });
        this._temporaryParticipants = [];

        return this._promise;
    }

    _onLoaded() {
        this._onLoadedCallbacks.forEach((item) => {
            if (item.id == this._meetingId) {
                this.onChange((w) => item.callback(w));
            }
        });
        this._onLoadedCallbacks = [];
    }

    isCurrentEditor() {
        let currentParticipant = this._getCurrentParticipant();
        return this.isEditor(currentParticipant) || this._currentUser.email.toLowerCase() === this._organizer.email.toLowerCase();
    }

    isEditor(participant) {
        return participant && participant.type === PARTICIPANT_TYPES.EDITOR;
    }

    isOrganizer(participant) {
        return this._organizer.email === participant.email;
    }

    togglePresence(participantId) {
        let participant = _.find(this._participants, (x) => x.id === participantId);
        participant.isPresent = !participant.isPresent;
        return this.meetingApiService
            .putParticipantPresence(this._meetingId, participant.id, participant.isPresent)
            .then((result) => {})
            .finally(() => {
                this._handleChange();
            });
    }

    setParticipants(participants) {
        this._participants.forEach((participant) => {
            let newParticipant = _.find(participants, (p) => p.email.toLowerCase() === participant.email.toLowerCase());
            if (newParticipant) {
                newParticipant.sortOrder = participant.sortOrder || 0;
            }
        });

        this._originalParticipants = _.cloneDeep(participants);
        this._participants = _.cloneDeep(participants);

        this._participants.forEach((participant) => {
            let originalParticipant = _.find(this._originalParticipants, (p) => p.email.toLowerCase() === participant.email.toLowerCase());
            participant.sortOrder = (originalParticipant && originalParticipant.sortOrder) || 0;
        });

        let currentUser = this._getCurrentParticipant();
        if (currentUser) {
            currentUser.isCurrentUser = true;
        }

        this._handleChange();
    }

    reset() {
        let deferred = this.$q.defer();
        this._temporaryParticipants = [];
        this.userService.getCurrentUser().then((currentUser) => {
            this._currentUser = currentUser;
            this.meetingApiService.get(this._meetingId).then((meeting) => {
                this._meetingSeriesId = meeting.meetingSeriesId;
                this._isLimited = !meeting.isOrganizer && !!meeting.office365UId;
                this.meetingApiService.getOrganizer(this._meetingId).then((organizer) => {
                    this._organizer = organizer;
                    this.meetingApiService.getParticipantsForMeeting(this._meetingId).then((participants) => {
                        this.setParticipants(participants);
                        deferred.resolve();
                    });
                });
            });
        });

        return deferred.promise;
    }

    getUncommitedParticipants() {
        return _.cloneDeep(this._temporaryParticipants);
    }

    openRoleSettingsDialog(options = {}) {
        this._resetSortOrder();
        angular.extend(options, {
            title: this.translationService.translate('client_RoleSettings'),
            isLimited: this._isLimited
        });

        return this.dialogService.custom('rym-role-settings-dialog', options).then((selectedParticipants) => {
            this._participants.forEach((participant) => {
                if (selectedParticipants.filter((x) => x.email.toLowerCase() === participant.email.toLowerCase()).length) {
                    participant.type = PARTICIPANT_TYPES.EDITOR;
                } else {
                    participant.type = PARTICIPANT_TYPES.NON_EDITOR;
                }
            });
            this._handleChange();
        });
    }

    add(participant, type = PARTICIPANT_TYPES.EDITOR) {
        this._add(participant, this._participants, type);
    }

    addTemporarly(participant, type = PARTICIPANT_TYPES.EDITOR) {
        let exists = !!this._participants.filter((p) => p.email.toLowerCase() === participant.email.toLowerCase()).length;
        if (exists) {
            return;
        }
        this._add(participant, this._temporaryParticipants, type);
    }

    getParticipants() {
        return this._participants;
    }

    commitTemporarlyParticipants() {
        this._participants.push(...this._temporaryParticipants);
        this._temporaryParticipants = [];
        this._handleChange();
        return this;
    }

    remove(participant) {
        this._remove(participant, this._participants);
        this._remove(participant, this._temporaryParticipants);
        this._handleChange();
    }

    removeTemporarly(participant) {
        this._remove(participant, this._temporaryParticipants);
        this._handleChange();
    }

    setType(user, type) {
        let participant = _.find(this._participants, (p) => p.email.toLowerCase() === user.email.toLowerCase());
        if (!participant) {
            participant = _.find(this._temporaryParticipants, (p) => p.email.toLowerCase() === user.email.toLowerCase());
        }

        if (type === PARTICIPANT_TYPES.READER) {
            participant.isPresent = true;
        }

        if (participant) {
            participant.type = type;
        }
    }

    addToAllMeetings(user, addAsEditor) {
        let dto = { type: PARTICIPANT_TYPES.READER };
        if (addAsEditor) {
            dto.type = PARTICIPANT_TYPES.EDITOR;
        }
        return this.meetingApiService.putParticipantToAllMeetings(this._meetingSeriesId, user.id, dto);
    }

    save() {
        let deferred = this.$q.defer();

        if (this._hasUnsavedChanges()) {
            this.meetingApiService
                .putParticipants(this._meetingId, this._participants)
                .then((participants) => {
                    this.setParticipants(participants);
                    deferred.resolve();
                    this.isSaving = false;
                })
                .catch((exception) => {
                    this.reset()
                        .then((error) => {
                            this.toastService.error({ duration: 10000 });
                            deferred.reject(error);
                        })
                        .catch((exception) => {
                            this.toastService.error({ duration: 10000 });
                            deferred.reject(exception);
                        });
                });
        } else {
            deferred.resolve();
            this._handleChange();
        }
        return deferred.promise;
    }

    onChange(callback) {
        callback(this._getChangedValues());
        this._onChangeCallbacks.push(callback);
    }

    addOnLoaded(id, callback) {
        this._onLoadedCallbacks.push({ id: id, callback: callback });
    }

    commit() {
        this._handleChange();
    }

    isParticipant(user) {
        return user && (user.type === PARTICIPANT_TYPES.NON_EDITOR || user.type === PARTICIPANT_TYPES.EDITOR);
    }

    isEditor(user) {
        return user && user.type === PARTICIPANT_TYPES.EDITOR;
    }

    isReader(user) {
        return user && user.type === PARTICIPANT_TYPES.READER;
    }

    _resetSortOrder() {
        this._participants.forEach((p) => (p.sortOrder = 0));
        this._handleChange();
    }

    _remove(participant, participants) {
        _.remove(participants, (x) => {
            return x.email.toLowerCase() === participant.email.toLowerCase();
        });
    }

    _add(participant, participants, type) {
        let existingParticipant = _.find(participants, (p) => p.email.toLowerCase() === participant.email.toLowerCase());

        if (existingParticipant) {
            if (existingParticipant.type !== type) {
                existingParticipant.isPresent = true;
                existingParticipant.sortOrder = this._sortOrder++;
                this.setType(existingParticipant, type);
            }
            return;
        }

        if (!participant.name) {
            participant.name = participant.email;
        }

        participant.isPresent = true;
        participant.sortOrder = this._sortOrder++;
        participant.type = type;

        participants.push(participant);
        this._handleChange();
    }

    _handleChange() {
        this._executeCallbacks(this._onChangeCallbacks, this._getChangedValues());
    }

    _executeCallbacks(callbacks, value) {
        callbacks.forEach((callback) => {
            callback(value);
        });
    }

    _hasUnsavedChanges() {
        return (
            this._participants.length !== this._originalParticipants.length ||
            _.differenceWith(this._participants, this._originalParticipants, (a, b) => {
                return a.email.toLowerCase() === b.email.toLowerCase() && a.type === b.type;
            }).length !== 0
        );
    }

    _getChangedValues() {
        let current = this._getCurrentParticipant();
        return {
            participants: angular.copy(this._participants),
            temporarlyParticipants: angular.copy(this._temporaryParticipants),
            current: current,
            isEditor: this.isEditor(current)
        };
    }

    _getCurrentParticipant() {
        return this._participants.filter((x) => x.isRelatedToCurrentUser).sort((x) => x.type)[0];
    }
}

ParticipantsService.$inject = ['$q', 'dialogService', 'meetingApiService', 'userService', 'translationService', 'toastService'];

export default ParticipantsService;
