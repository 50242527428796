import template from './selectedUsers.html';
import controller from './selectedUsers.controller';

let userListComponent = {
    template,
    controller,
    bindings: {
        users: '<',
        onRemove: '&'
    }
};

export default userListComponent;
