class meetingSeriesMeetingsController {
    constructor(
        $filter,
        $rootScope,
        $state,
        dialogService,
        meetingApiService,
        toastService,
        menuService,
        anchorScrollService,
        meetingSeriesService,
        paymentService,
        organizationService,
        userService,
        exportService,
        translationService,
        eventEmitterService,
        stringService,
        cardListModelTransformer,
        cardGroupModelTransformer,
        cardModelTransformer
    ) {
        Object.assign(this, {
            $filter,
            $rootScope,
            $state,
            dialogService,
            meetingApiService,
            toastService,
            menuService,
            anchorScrollService,
            meetingSeriesService,
            paymentService,
            organizationService,
            userService,
            exportService,
            translationService,
            eventEmitterService,
            stringService,
            cardListModelTransformer,
            cardGroupModelTransformer,
            cardModelTransformer
        });
    }

    $onInit() {
        if (this.$state.is('meeting')) {
            this.meetingInBackground = parseInt(this.$state.params.id);
        }
        this.scrollTo = this.parent.meeting.id;
        this.parent.loading = true;
        this.meetingSeriesService
            .getMeetingsInMeetingSeries()
            .then((meetings) => {
                this.meetings = meetings;
                this.eventEmitterService.subscribe('meetingChangedExternally', (updatedMeeting) => {
                    let oldMeeting = this.meetings.find((m) => {
                        return m.id === updatedMeeting.id;
                    });

                    if (!oldMeeting) {
                        return;
                    }

                    angular.merge(oldMeeting, updatedMeeting);
                    this.meetings = angular.copy(this.meetings);
                });
            })
            .finally(() => {
                this.parent.loading = false;
                this.parent.tabLoaded('meetings');
            });

        this._getTrialExceeded();

        this.userService.getCurrentUser().then((user) => {
            this.currentUser = user;
        });
    }

    $doCheck() {
        if (this.scrollTo && !this.parent.loading && document.querySelector('#m' + this.parent.meeting.id)) {
            this.anchorScrollService.scrollTo('m' + this.scrollTo, 'meeting-series-meetings', 96, 0);
            this.scrollTo = null;
        }
    }

    handleMeetingCreated($event) {
        this.$state.go('^.meeting', { id: $event.meetingId });
        const fullscreenDialogToClose = 'meetingseries';
        this.eventEmitterService.publishEvent('closeFullscreenDialog' + fullscreenDialogToClose);
        this.toastService.hide();
    }

    showProtocol(meeting) {
        this.parent.scrollTo = null;
        this.parent.showProtocol(meeting);
    }

    showCreateFollowUpMeetingDialog(meeting) {
        this.translationService
            .translateBatch(['client_CreateNextMeeting', 'create', 'cancel', 'meetings_CreatingMeeting', 'client_CouldNotCreateMeeting'])
            .then((translations) => {
                this.userService.getCurrentUser().then((user) => {
                    if (user.isExternal) {
                        return this._showIsParticipantDialog();
                    } else if (this.trialExceeded) {
                        return this._showTrialExceeded();
                    }

                    this.options = {
                        id: 'create next meeting dialog',
                        title: translations.client_CreateNextMeeting,
                        ok: translations.create,
                        cancel: translations.cancel,
                        meeting: meeting
                    };

                    this.dialogService.custom('rym-create-meeting-dialog', this.options).then((newMeeting) => {
                        this.toastService.loading({
                            title: translations.meetings_CreatingMeeting
                        });
                        this.meetingApiService
                            .create(newMeeting)
                            .then((result) => {
                                this.handleMeetingCreated({ meetingId: result.id });
                            })
                            .catch((exception) => {
                                this.toastService.error({
                                    title: translations.client_CouldNotCreateMeeting,
                                    description: exception.data.message
                                });
                            });
                    });
                });
            });
    }

    remove(meeting) {
        this.dialogService
            .confirm({
                id: 'delete meeting dialog',
                title: this.translationService.translate('client_DeleteConfirm'),
                description: this.translationService.translate('client_DeleteItemConfirm', { itemName: meeting.meetingName })
            })
            .then(() => {
                this.meetingApiService
                    .remove(meeting.id)
                    .then(() => {
                        this.meetings = this.meetings.filter((m) => m.id !== meeting.id);
                        this._invalidateMeetingsCache();
                        this.eventEmitterService.publishEvent('meeting-deleted', meeting.id);
                        if (this.meetings.length === 0) {
                            const fullscreenDialogToClose = 'meetingseries';
                            this.eventEmitterService.publishEvent('closeFullscreenDialog' + fullscreenDialogToClose);
                        }
                    })
                    .catch((exception) => {
                        this.toastService.error({ description: exception.data.message });
                    });
            });
    }

    _showTrialExceeded() {
        this.paymentService.trialExceeded().then((adminClickUpgrade) => {
            this.userService.getCurrentUser().then((user) => {
                if (user.isAdmin) {
                    this.parent.close();
                }
            });
        });
    }

    _getTrialExceeded() {
        this.organizationService.trialExceeded().then((isExceeded) => {
            this.trialExceeded = isExceeded;
        });
    }

    _invalidateMeetingsCache() {
        this.meetingSeriesService._invalidateMeetingCache();
    }

    _invalidateActionsCache() {
        this.meetingSeriesService._invalidateActionCache();
    }

    _showIsParticipantDialog() {
        return this.paymentService.showIsParticipant();
    }

    _hasValidOffice365Token() {
        return this.currentUser && this.currentUser.hasValidOffice365Token;
    }

    handleClickOnCard($event) {
        if ($event.card.meeting.id === this.scrollTo && this.meetingInBackground && this.meetingInBackground === this.scrollTo) {
            const fullscreenDialogToClose = 'meetingseries';
            this.eventEmitterService.publishEvent('closeFullscreenDialog' + fullscreenDialogToClose);
            return;
        }

        let options = {
            id: 'preview meeting dialog',
            meeting: $event.card.meeting
        };
        this.dialogService.custom('rym-meeting-preview-dialog', options);
    }

    handleClickOnMore($event) {
        this.showMeetingMenu($event.currentTarget, $event.card.meeting);
    }

    showMeetingMenu(menuButton, meeting) {
        let options = {
            items: []
        };

        if (meeting.id) {
            let text = this.translationService.translate('client_CreateNextMeeting');

            if (meeting.isOrganizer || meeting.isCurrentUserEditor) {
                options.items.push({
                    id: 'menu option - create next meeting',
                    icon: 'add',
                    text: text,
                    onClick: () => this.showCreateFollowUpMeetingDialog(meeting)
                });
            }

            options.items.push({
                id: 'menu option - export meeting to pdf',
                icon: 'file_download',
                text: this.translationService.translate('client_ExportMinutes'),
                onClick: () => {
                    this.dialogService.custom('rym-export-meeting-dialog', { id: 'export meeting dialog', meetingId: meeting.id }).then((options) => {
                        return this.exportService.exportWithOptions(options, meeting);
                    });
                }
            });

            if (!meeting.office365UId && this._hasValidOffice365Token() && meeting.isCurrentUserEditor) {
                options.items.push({
                    id: 'menu option - export meeting to outlook',
                    mdiIcon: 'office',
                    text: this.translationService.translate('client_ExportToCalendar'),
                    onClick: () => {
                        let options = {
                            id: 'export to calendar dialog',
                            title: this.translationService.translate('client_ExportToCalendar'),
                            description: this.translationService.translate('client_ExportToCalendarDescription'),
                            placeholder: this.translationService.translate('client_Message'),
                            ok: this.translationService.translate('create'),
                            cancel: this.translationService.translate('client_Cancel')
                        };
                        this.dialogService.custom('rym-export-to-calendar-dialog', options).then((message) => {
                            let msg = angular.isUndefined(message) ? '' : message;
                            this.exportService
                                .exportMeetingToOffice365(meeting, this.stringService.replaceLineBreaksWithBreakTags(msg))
                                .then((uId) => {
                                    meeting.office365UId = uId;
                                });
                        });
                    }
                });
            }
        }

        if (meeting.isOrganizer || (meeting.isCreator && meeting.isCurrentUserEditor)) {
            options.items.push({
                id: 'menu option - delete meeting',
                icon: 'delete_outline',
                text: this.translationService.translate('client_Delete'),
                onClick: () => this.remove(meeting)
            });
        }

        this.menuService.actionMenu(options, angular.element(menuButton), false);
    }
}

meetingSeriesMeetingsController.$inject = [
    '$filter',
    '$rootScope',
    '$state',
    'dialogService',
    'meetingApiService',
    'toastService',
    'menuService',
    'anchorScrollService',
    'meetingSeriesService',
    'paymentService',
    'organizationService',
    'userService',
    'exportService',
    'translationService',
    'eventEmitterService',
    'stringService',
    'cardListModelTransformer',
    'cardGroupModelTransformer',
    'cardModelTransformer'
];

export default meetingSeriesMeetingsController;
