import controller from './guideStep.controller';
import template from './guideStep.html';

let guideStepComponent = {
    controller: controller,
    template: template,
    link: (scope, element, attrs, guideCtrl) => {
        guideCtrl.add(scope);
    },
    require: {
        guideCtrl: '^rymGuide'
    },
    bindings: {
        title: '@',
        description: '@'
    },
    transclude: { top: '?rymGuideStepTopContent', bottom: '?rymGuideStepBottomContent' }
};

export default guideStepComponent;
