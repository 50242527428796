class UserListController {
    constructor($scope, translationService, responsive) {
        this.translationService = translationService;
        this.responsive = responsive;
        this.$scope = $scope;
    }

    $onInit() {
        this.responsive.on(['xs'], this.$scope, () => {
            this.activateScrollToLastAddedUser = false;
        });

        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$scope, () => {
            this.activateScrollToLastAddedUser = true;
        });
    }

    remove($event, user) {
        $event.user = user;
        this.onRemove({ $event });
    }
}

UserListController.$inject = ['$scope', 'translationService', 'responsive'];

export default UserListController;
