import baseApiService from './baseApi.service';
import actionApiService from './actionApi.service';
import userApiService from './userApi.service';
import meetingApiService from './meetingApi.service';
import categoryApiService from './categoryApi.service';
import organizationApiService from './organizationApi.service';
import queueService from './queue.service';
import contactApiService from './contactApi.service';
import paymentApiService from './paymentApi.service';
import azureAdApiService from './azureAdApi.service';
import subscriptionApiService from './subscriptionApi.service';

import '../auth/auth.module';

angular
    .module('rym.api', ['rym.auth'])
    .service('baseApiService', baseApiService)
    .service('actionApiService', actionApiService)
    .service('userApiService', userApiService)
    .service('meetingApiService', meetingApiService)
    .service('organizationApiService', organizationApiService)
    .service('contactApiService', contactApiService)
    .service('queueService', queueService)
    .service('categoryApiService', categoryApiService)
    .service('paymentApiService', paymentApiService)
    .service('azureAdApiService', azureAdApiService)
    .service('subscriptionApiService', subscriptionApiService);
