import template from './tab.html';

let tabComponent = {
    template,
    bindings: {
        isSelected: '<',
        name: '<',
        state: '<',
        icon: '<',
        indication: '<',
        indicationColor: '@',
        hideIndicationCounter: '<',
        url: '<',
        suffixIcon: '<',
        isCollapsed: '<',
        isActive: '<',
        fontawesomeIcon: '<'
    }
};

export default tabComponent;
