const INJECT = [
    '$q',
    '$scope',
    '$interval',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'organizationService',
    'userService',
    'toastService',
    'subscriptionApiService'
];

class SubscriptionFormSummaryController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this._init();
    }

    _init() {}
}

SubscriptionFormSummaryController.$inject = INJECT;

export default SubscriptionFormSummaryController;
