import template from './userListItem.html';
import controller from './userListItem.controller';
let userListItemComponent = {
    template,
    controller,
    bindings: {
        initials: '@',
        email: '@',
        name: '@',
        isSelectable: '<',
        isExternal: '<',
        isSelected: '<',
        isDisabled: '<',
        onChange: '&'
    }
};

export default userListItemComponent;
