import controller from './decision.controller.js';
import template from './decision.html';
import './decision.less';

let decisionComponent = {
    template,
    controller,
    bindings: {
        decision: '<',
        meetingId: '<',
        onDelete: '&',
        onCreate: '&',
        onFocus: '&',
        onBlur: '&',
        onUpdate: '&',
        isDisabled: '<'
    }
};

export default decisionComponent;
