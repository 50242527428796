angular.module('rym.exceptionHandler', []).factory('$exceptionHandler', function () {
    window.jsErrors = [];
    const ignoreExceptions = ['already in progress', '[$rootScope:inprog]'];
    return function (exception) {
        let ignore = false;
        for (let i = 0; i < ignoreExceptions.length; i++) {
            if (exception && exception.message && exception.message.indexOf(ignoreExceptions[i]) >= 0) {
                ignore = true;
                break;
            }
        }

        if (!ignore && window.jsErrors.indexOf(exception.stack) < 0) {
            appInsights.trackException({ exception: exception });
            window.jsErrors.push(exception.stack);
        }

        console.error(exception.message);
    };
});
