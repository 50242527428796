class CalendarService {
    constructor(azureAdApiService, windowService, organizationApiService, eventEmitterService, dialogService, translationService) {
        Object.assign(this, { azureAdApiService, windowService, organizationApiService, eventEmitterService, dialogService, translationService });
    }

    init() {
        this.eventEmitterService.subscribe('toggleOffice365Integration', () => this.toggleOffice365Integration());
        this.eventEmitterService.subscribe('reconnectOffice365Integration', () => this.reconnectOffice365Integration());
        this.eventEmitterService.subscribe('handleOffice365MeetingGroupIntegration', () => this.handleOffice365MeetingGroupIntegration());
        this.eventEmitterService.subscribe('syncOffice365MeetingGroups', () => this.syncOffice365MeetingGroups());
    }

    toggleOffice365Integration() {
        return this.azureAdApiService.getCalendarToggleUrl().then((url) => {
            if (url) {
                this._openOffice365IntegrationUrl(url);
            } else {
                this.eventEmitterService.publishEvent('clearCacheAndReload');
            }
        });
    }

    activateOffice365Integration() {
        let options = {
            id: 'integration dialog',
            title: this.translationService.translate('client_Office365IntegrationSettingTitle'),
            ok: this.translationService.translate('client_Activate'),
            cancel: this.translationService.translate('client_Cancel'),
            hideText: this.translationService.translate('client_DontShowAgain'),
            hide: false
        };

        this.dialogService.custom('rym-integration-dialog', options).then(() => {
            return this.azureAdApiService.getCalendarUrl().then((url) => {
                this._openOffice365IntegrationUrl(url);
            });
        });
    }

    reconnectOffice365Integration() {
        this.dialogService
            .alert({
                id: 'logged out from Office365 dialog',
                title: this.translationService.translate('client_LoggedOutOffice365'),
                ok: this.translationService.translate('client_LogIn'),
                description: this.translationService.translate('client_YouHaveBeenLoggedOutOffice365')
            })
            .then(() => {
                return this.azureAdApiService.getCalendarUrl().then((url) => {
                    this._openOffice365IntegrationUrl(url);
                });
            });
    }

    handleOffice365MeetingGroupIntegration() {
        return this.azureAdApiService.getGroupToggleUrl().then((url) => {
            if (url) {
                this._openOffice365IntegrationUrl(url);
            } else {
                this.eventEmitterService.publishEvent('clearCacheAndReload');
            }
        });
    }

    syncOffice365MeetingGroups() {
        return this.azureAdApiService.getGroupUrl().then((url) => {
            this.windowService.openPopup(url, 'Office365', 600, 700, false);
        });
    }

    _openOffice365IntegrationUrl(url) {
        this.windowService.openPopup(url, 'Office365', 600, 700, true);
        this.organizationApiService.dropCacheForAllApiServices();
    }
}

CalendarService.$inject = [
    'azureAdApiService',
    'windowService',
    'organizationApiService',
    'eventEmitterService',
    'dialogService',
    'translationService'
];

export default CalendarService;
