import template from './formDatePicker.html';
import controller from './formDatePicker.controller';
import './formDatePicker.less';

let formDatePickerComponent = {
    template,
    controller,
    require: {
        ngModel: 'ngModel'
    },
    bindings: {
        placeholderText: '@',
        fromDate: '<',
        lock: '<',
        eventId: '@'
    }
};

export default formDatePickerComponent;
