import { HTTP_STATUS_CODES } from '../app.constants';

class BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService,
        resourceUrl,
        locationService
    ) {
        this.$http = $http;
        this.$q = $q;
        this.$rootScope = $rootScope;
        this.$cacheFactory = $cacheFactory;
        this.resourceUrl = resourceUrl;
        this.authService = authService;
        this.$state = $state;
        this.$window = $window;
        this.toastService = toastService;
        this.queueService = queueService;
        this.applicationInsightsService = applicationInsightsService;
        this.appcuesService = appcuesService;
        this.eventEmitterService = eventEmitterService;
        this.envService = envService;
        this.locationService = locationService;
        this.envService.init().then(
            () => {
                if (this.resourceUrl) {
                    let apiurl = this.envService.API + this.resourceUrl;
                    this.cache = this.$cacheFactory(apiurl);
                }
            },
            (err) => {}
        );
    }

    queue(method, url, data, cancellationToken) {
        return this.authService.getAccessToken().then((accessToken) => {
            return this.queueService.queueWork(
                { method: method, url: this.envService.API + url, data: data, headers: { Authorization: 'Bearer ' + accessToken }, cache: true },
                cancellationToken
            );
        });
    }

    GET(url, disableCache, ignoreErrorHandling) {
        if (disableCache) {
            return this._send('GET', url, false, null, ignoreErrorHandling);
        } else {
            return this._send('GET', url, this.cache, null, ignoreErrorHandling);
        }
    }

    GET_BUFFER(url, data) {
        return this.authService.getAccessToken().then((accessToken) => {
            return this.$http({
                method: data ? 'POST' : 'GET',
                url: this.envService.API + url,
                data: data,
                headers: { Authorization: 'Bearer ' + accessToken },
                responseType: 'arraybuffer'
            });
        });
    }

    DELETE(url, id) {
        this._track(`DELETE_${url}`, { id });
        return this._send('DELETE', `${url}/${id}`).then((response) => {
            this.cache.removeAll();
            return response;
        });
    }

    POST(url, data) {
        this._track(`POST_${url}`, { data });
        if (!angular.isObject(data)) {
            data = `"${data}"`;
        }

        return this._send('POST', url, this.cache, data).then((response) => {
            this.cache.removeAll();
            return response;
        });
    }

    PUT(url, data) {
        this._track(`PUT_${url}`, { data });
        data = JSON.stringify(data);

        return this._send('PUT', url, this.cache, data).then((response) => {
            this.cache.removeAll();
            return response;
        });
    }

    _track(path, requestData) {
        let eventName = path.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g, '*').replace(/\d+/g, '*');

        this.applicationInsightsService.trackEvent(eventName, path);
        this.appcuesService.track(eventName);
    }

    _send(verb, url, cache, data, ignoreErrorHandling) {
        let deferred = this.$q.defer();
        this.authService.getAccessToken().then((accessToken) => {
            this.$http({
                method: verb,
                url: this.envService.API + url,
                data: data,
                headers: { Authorization: 'Bearer ' + accessToken },
                cache: cache
            }).then(
                (result) => {
                    deferred.resolve(result.data);
                },
                (err) => {
                    console.log(err);
                    if (err.status) {
                        window.jsErrors.push(`status: ${err.status} method:${err.config.method} url: ${err.config.url}`);
                    }

                    if (ignoreErrorHandling) {
                        deferred.reject(err);
                    } else if (err.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
                        this.authService.renew(true).then((newAccessToken) => {
                            this.$http({
                                method: verb,
                                url: this.envService.API + url,
                                data: data,
                                headers: { Authorization: 'Bearer ' + newAccessToken },
                                cache: cache
                            }).then(
                                (result) => {
                                    deferred.resolve(result.data);
                                },
                                (err) => {
                                    console.log(err);
                                    this._logHttpError(err);
                                    this.toastService.error();
                                    deferred.reject(err);
                                }
                            );
                        });
                    } else if (err.status === HTTP_STATUS_CODES.ERR_CONNECTION || err.status === HTTP_STATUS_CODES.ERR_NETWORK_CHANGED) {
                        Offline.check();

                        let onUp = () => {
                            deferred.reject(err);
                        };
                        Offline.on('confirmed-up', onUp);

                        let onDown = () => {
                            Offline.off('confirmed-up', onUp);
                            Offline.off('confirmed-down', onDown);
                            let watch = this.$rootScope.$on('connectionRestored', () => {
                                this.$http({
                                    method: verb,
                                    url: this.envService.API + url,
                                    data: data,
                                    headers: { Authorization: 'Bearer ' + accessToken },
                                    cache: cache
                                })
                                    .then(
                                        (result) => {
                                            deferred.resolve(result.data);
                                        },
                                        (err) => {
                                            console.log(err);
                                            this.toastService.error();
                                            deferred.reject(err);
                                        }
                                    )
                                    .finally(() => watch());
                            });
                        };
                        Offline.on('confirmed-down', onDown);
                    } else if (err.status === HTTP_STATUS_CODES.SERVERERROR) {
                        this._logHttpError(err);
                        this.toastService.error({ duration: 10000 });
                        deferred.reject(err);
                    } else if (err.status === HTTP_STATUS_CODES.OFFICE365_TOKEN_ERROR) {
                        this._logHttpError(err);
                        deferred.reject(err);
                        this.eventEmitterService.publishEvent('reconnectOffice365Integration');
                    } else if (err.status === HTTP_STATUS_CODES.RATE_LIMIT_REACHED) {
                        this._logHttpError(err);
                        deferred.reject(err);
                        this.eventEmitterService.publishEvent('rateLimitReached');
                    } else if (err.status === HTTP_STATUS_CODES.NOT_ALLOWED_ACTION) {
                        this._logHttpError(err);
                        deferred.reject(err);
                        this.eventEmitterService.publishEvent('notAllowedToViewAction', err.data);
                    } else if (err.status === HTTP_STATUS_CODES.NOT_ALLOWED_MEETING) {
                        this._logHttpError(err);
                        deferred.reject(err);
                        this.eventEmitterService.publishEvent('notAllowedToViewMeeting', err.data);
                    } else if (err.status === HTTP_STATUS_CODES.CHANGE_MEETING_ORG) {
                        let orgId = err.data.organizationId;
                        let meetingId = err.data.meetingId;
                        let tenantId = err.data.tenantId;
                        let requireLogin = err.data.requireLogin;
                        this.$window.localStorage.setItem('Go-To-MeetingId', meetingId);
                        if (requireLogin) {
                            this.locationService.reload(tenantId);
                        } else {
                            this.eventEmitterService.publishEvent('changeOrganization', orgId);
                        }
                        deferred.reject(err);
                    } else if (err.status === HTTP_STATUS_CODES.CHANGE_ACTION_ORG) {
                        let orgId = err.data.organizationId;
                        let actionId = err.data.actionId;
                        let tenantId = err.data.tenantId;
                        let requireLogin = err.data.requireLogin;
                        this.$window.localStorage.setItem('Go-To-ActionId', actionId);
                        if (requireLogin) {
                            this.locationService.reload(tenantId);
                        } else {
                            this.eventEmitterService.publishEvent('changeOrganization', orgId);
                        }
                        deferred.reject(err);
                    } else if (err.status === HTTP_STATUS_CODES.BADREQUEST) {
                        this._logHttpError(err);
                        if (typeof err.data === 'string' || err.data instanceof String) {
                            this.toastService.error({ duration: 10000 });
                        } else {
                            this.toastService.notifyError(err.data);
                        }
                        deferred.reject(err);
                    } else {
                        deferred.reject(err);
                    }
                }
            );
        });
        return deferred.promise;
    }

    _logHttpError(err) {
        this.applicationInsightsService.trackException({
            status: err.status,
            method: err.config.method,
            url: err.config.url
        });
    }

    removeCache(key) {
        return this.cache.remove(`${this.envService.API + this.resourceUrl}/${key}`);
    }

    removeResourceCache() {
        let cache = this.$cacheFactory.get(`${this.envService.API + this.resourceUrl}`);
        if (cache) {
            cache.removeAll();
        }
    }

    removeSpecificResourceCache(resource) {
        let cache = this.$cacheFactory.get(`${this.envService.API + resource}`);
        if (cache) {
            cache.removeAll();
        }
    }

    removeCacheObject() {
        _.forEach(this.$cacheFactory.info(), (ob, key) => {
            if (key.startsWith(this.envService.API)) {
                this.$cacheFactory.get(key).removeAll();
            }
        });
    }
}

BaseApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$location',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService',
    'locationService'
];

export default BaseApiService;
