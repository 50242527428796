import BaseApiService from './baseApi.service';
const resource = 'payment';

class PaymentApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService`${resource}`
        );
    }

    getClientToken(planId) {
        return super.GET(`payment/clienttoken/${planId}`, true);
    }

    getPlans() {
        return super.GET(`payment/plans`);
    }

    getCustomer() {
        return super.GET('payment/customer');
    }

    getTransactions() {
        return super.GET('payment/transactions');
    }

    downloadReceipt(transactionId) {
        return super.GET_BUFFER(`payment/transactions/${transactionId}/receipt`);
    }

    subscribe(order) {
        return super.POST('payment/subscribe', order);
    }

    updateSubscription(order) {
        return super.PUT('payment/subscription', order);
    }

    addCreditCard(paymentNonce) {
        return super.POST('payment/creditcard', { paymentNonce: paymentNonce });
    }

    validateDiscount(code) {
        return super.GET(`payment/discount/${code}`);
    }
}

PaymentApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default PaymentApiService;
