import controller from './selectDialog.controller';
import template from './selectDialog.html';

let selectDialogComponent = {
    controller,
    template,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default selectDialogComponent;
