class actionStatusIconController {
    constructor($rootScope, $state, actionApiService, animationService, eventEmitterService, actionService, signalRService, userService) {
        Object.assign(this, {
            $rootScope,
            $state,
            actionApiService,
            animationService,
            eventEmitterService,
            actionService,
            signalRService,
            userService
        });
    }

    $onInit() {
        this.$rootScope.$on('toggle', (event) => {
            this.toggleStatus();
        });
    }

    toggleStatus(event) {
        if (event) {
            this._stopOtherEvents(event);
        }

        if (this.isStatusLoading) {
            return;
        }

        this.action.hasToggled = true;
        this.isStatusLoading = true;
        this.actionApiService
            .toggleStatus(this.action)
            .then((result) => {
                if (this.$state.includes('actions')) {
                    this.animationService.animate(() => {
                        this.action.isFinished = result.isFinished;
                    }, '.virtual-scroll-item');
                } else {
                    this.action.isFinished = result.isFinished;
                }
                this.signalRService.updateAction(this.action);

                if (result.isFinished) {
                    this.userService.shouldRateRYM();
                }
            })
            .finally(() => {
                this.isStatusLoading = false;
                this._notifyActionUpdate();
                this.eventEmitterService.publishEvent('toggleStatus', this.action);
                this.eventEmitterService.publishEvent(this.action.id, this.action);
                this.eventEmitterService.publishEvent('agendaActionChanged' + this.action.id, this.action);
            });
    }

    _stopOtherEvents(event) {
        // To prevent details view to open.
        event.preventDefault();
        event.stopPropagation();
    }

    _notifyActionUpdate() {
        this.actionService.notifyActionUpdate();
    }
}

actionStatusIconController.$inject = [
    '$rootScope',
    '$state',
    'actionApiService',
    'animationService',
    'eventEmitterService',
    'actionService',
    'signalRService',
    'userService'
];
export default actionStatusIconController;
