import template from './dateMenu.html';
import './dateMenu.less';

let dateMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default dateMenuComponent;
