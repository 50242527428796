import { PARTICIPANT_TYPES } from '../../../app.constants';

const SUBJECT_MAX_LENGTH = 150;

class SendMeetingDialogController {
    constructor(
        meetingApiService,
        actionApiService,
        $q,
        userService,
        $filter,
        menuService,
        $element,
        $timeout,
        keyCode,
        stringService,
        translationService,
        userApiService,
        $sce,
        dialogService,
        participantsService
    ) {
        this.meetingApiService = meetingApiService;
        this.actionApiService = actionApiService;
        this.userService = userService;
        this.$filter = $filter;
        this.menuService = menuService;
        this.$element = $element;
        this.$timeout = $timeout;
        this.keyCode = keyCode;
        this.stringService = stringService;
        this.$q = $q;
        this.translationService = translationService;
        this.userApiService = userApiService;
        this.$sce = $sce;
        this.dialogService = dialogService;
        this.participantsService = participantsService;
    }

    $onInit() {
        this.previewLoading = true;
        this.validSubject = true;
        this.mailingList = [];
        if (
            !this.options.isProtocol &&
            (this.options.meeting.protocolIsLocked ||
                this.options.meeting.lockMetaData ||
                (this.options.meeting.provider && this.options.meeting.provider === 'Outlook'))
        ) {
            this.lockParticipantInput = true;
        } else {
            this.lockParticipantInput = false;
        }

        this._getPreview();
        this.userService.getCurrentUser().then((user) => {
            this.participantsService.init(this.options.meeting.id).then(() => {
                let participants = this.participantsService.getParticipants();
                this.mailingList = participants.filter((p) => p.type !== PARTICIPANT_TYPES.READER);
            });

            let dateString = this.$filter('date')(this.options.meeting.startDate, 'fullDate');
            let meetingName = this.options.meeting.meetingName;

            this.mailSubject = this.options.mailSubject || this.buildSubjectString(meetingName, dateString);
            this.mailMessage = this.options.mailMessage || this.buildMessageString(meetingName, dateString, user.name);
        });
    }

    openRoleSettingsDialog() {
        this.participantsService.openRoleSettingsDialog();
    }

    buildMessageString(meetingName, dateString, userName) {
        if (this.options.isProtocol) {
            return this.translationService.translate('client_SendMinutesMessage', { meetingName: meetingName, date: dateString, name: userName });
        } else {
            return this.translationService.translate('client_SendAgendaMessage');
        }
    }

    buildSubjectString(meetingName, dateString) {
        let langKey = this.options.isProtocol ? 'client_SendMinutesSubject' : 'client_SendAgendaSubject';

        let subject = this.translationService.translate(langKey, { meetingName: meetingName });
        let subjectWithDate = `${subject} (${dateString})`;

        if (subjectWithDate.length <= SUBJECT_MAX_LENGTH) {
            return subjectWithDate;
        }

        let sufix = '...';
        let lengthOfExtraText = subjectWithDate.length - meetingName.length;
        let shortenedMeetingName = `${meetingName.substring(0, SUBJECT_MAX_LENGTH - (sufix.length + 1) - lengthOfExtraText)}${sufix}`;
        return this.buildSubjectString(shortenedMeetingName, dateString);
    }

    $doCheck() {
        if (!this.listenersInitialized && this._message() && this._getCharCount() > 0) {
            this.listenersInitialized = true;
            this._validateSubject();
            this._initListeners();
        }
    }

    _getPreview() {
        this.meetingApiService.getPreview(this.options.meeting.id).then((html) => {
            this.preview = this.$sce.trustAsHtml(html.replace(/<style>(.|\n|\r\n)*?<\/style>/g, ''));
            this.previewLoading = false;
        });
    }

    send() {
        let data = {
            recipients: this.mailingList,
            subject: this._subject().textContent,
            message: this.stringService.replaceLineBreaksWithBreakTags(this._message().innerText),
            extendedInfo: true
        };
        let uncommitedParticipants = this.participantsService.getUncommitedParticipants();
        let hasNoNewRecipients = uncommitedParticipants.length === 0;
        let hasOnlyNewRecipients = this.mailingList.length === uncommitedParticipants.length;
        let hasNeverSentAgenda = !this.options.meeting.agendaSent;
        let isSendingProtocol = this.options.isProtocol;
        let shouldSendToAll = hasNeverSentAgenda || hasOnlyNewRecipients || hasNoNewRecipients;

        if (isSendingProtocol) {
            this.participantsService.reset();
            this.onOk({ data });
        } else if (shouldSendToAll) {
            this._saveParticipantsAndSend(data);
        } else {
            this._showSelectSendingOptionsDialog(data);
        }
    }

    _showSelectSendingOptionsDialog(data) {
        this.dialogService
            .select({
                id: 'select recievers dialog',
                title: this.translationService.translate('client_SendTo'),
                description: this.translationService.translate('client_SendToDescription'),
                items: [
                    { name: this.translationService.translate('client_SendOnlyToAdded'), isDefault: true },
                    { name: this.translationService.translate('client_SendToAll') }
                ],
                ok: this.translationService.translate('send'),
                cancel: this.translationService.translate('client_Cancel')
            })
            .then((result) => {
                if (result.isDefault) {
                    data.recipients = this.participantsService.getUncommitedParticipants();
                }
                this._saveParticipantsAndSend(data);
            });
    }

    _saveParticipantsAndSend(data) {
        this.participantsService
            .commitTemporarlyParticipants()
            .save()
            .then(() => {
                this.onOk({ data });
            });
    }

    handleCancel() {
        this.participantsService.reset();
        this.onCancel({
            $event: {
                mailMessage: this.mailMessage,
                mailSubject: this.mailSubject
            }
        });
    }

    removeRecipient($event) {
        this.participantsService.removeTemporarly($event.user);

        _.remove(this.mailingList, (recipient) => {
            return recipient.email === $event.user.email;
        });
    }

    addRecipient($event) {
        let participant = $event.item;
        this.mailingList.push(participant);
        this.participantsService.addTemporarly(participant);
    }

    _initListeners() {
        this._subject().addEventListener('keydown', (event) => {
            if (!this._allowedKeys(event) && this._subject().textContent.length - 1 >= SUBJECT_MAX_LENGTH) {
                event.preventDefault();
                return;
            }

            this._validateSubject();
        });
    }

    _validateSubject() {
        this.$timeout(() => {
            let subjectLength = this._subject().textContent.trim().length;
            this.validSubject = subjectLength > 0;
            this.$element[0].querySelector('#subjectCount').textContent = `${subjectLength}/${SUBJECT_MAX_LENGTH}`;
        }, 50);
    }

    _getCharCount() {
        return this._message().textContent.length;
    }

    _message() {
        return this.$element[0].querySelector('#message');
    }

    _subject() {
        return this.$element[0].querySelector('#subject');
    }

    _allowedKeys(event) {
        let keyCode = event.which;

        return (
            keyCode === this.keyCode.BACKSPACE ||
            keyCode === this.keyCode.DELETE ||
            keyCode === this.keyCode.RIGHT ||
            keyCode === this.keyCode.LEFT ||
            keyCode === this.keyCode.UP ||
            keyCode === this.keyCode.DOWN ||
            (keyCode === this.keyCode.A && event.ctrlKey)
        );
    }

    validForm() {
        let subject = this._subject();
        if (!subject) {
            return false;
        }
        let subjectLength = subject.textContent.trim().length;
        return this.mailingList && this.mailingList.length > 0 && subjectLength > 0 && subjectLength <= SUBJECT_MAX_LENGTH;
    }
}

SendMeetingDialogController.$inject = [
    'meetingApiService',
    'actionApiService',
    '$q',
    'userService',
    '$filter',
    'menuService',
    '$element',
    '$timeout',
    'keyCode',
    'stringService',
    'translationService',
    'userApiService',
    '$sce',
    'dialogService',
    'participantsService'
];

export default SendMeetingDialogController;
