class UserListItemController {
    constructor(translationService) {
        Object.assign(this, { translationService });
    }

    $onInit() {}

    toggleSelect($event) {
        if (this.isDisabled) {
            return;
        }
        this.isSelected = !this.isSelected;
        this.onChange({
            $event: {
                isSelected: this.isSelected
            }
        });
    }
}

UserListItemController.$inject = ['translationService'];
export default UserListItemController;
