class TemplatesController {
    constructor(
        $state,
        $scope,
        $element,
        meetingApiService,
        menuService,
        dialogService,
        toastService,
        translationService,
        breadcrumbsService,
        userService,
        responsive
    ) {
        Object.assign(this, {
            $state,
            $scope,
            $element,
            meetingApiService,
            menuService,
            dialogService,
            toastService,
            translationService,
            breadcrumbsService,
            userService,
            responsive
        });
        this.loading = true;
    }

    $onInit() {
        this.orderBy = 'name';
        this.reverseOrder = false;
        this.searchTerm = '';
        this.filteredTemplates = [];
        this.loading = true;
        this.breadcrumbsService.setLabel('templates', this.translationService.translate('administration_Templates'));

        this.meetingApiService.getTemplates().then((templates) => {
            this.templates = templates;
            this.filteredTemplates = templates;
            this._updateNoHitsState();
            this.loading = false;
        });

        this.responsive.on(['xs'], this.$scope, () => {
            this.isShowingDivider = true;
        });

        this.responsive.on(['sm', 'md', 'lg', 'xl'], this.$scope, () => {
            this.isShowingDivider = false;
        });
    }

    goTo(template) {
        this.$state.go('template', { id: template.id });
    }

    showMenu($event, template) {
        let menuButton = $event.currentTarget;
        $event.stopImmediatePropagation();

        this.userService.getCurrentUser().then((user) => {
            let shouldBeDisabled = user.id !== template.createdById;
            let remove = {
                id: 'menu option - remove template',
                icon: 'delete_outline',
                text: this.translationService.translate('client_Delete'),
                onClick: () => this.deleteTemplate(template),
                disabled: shouldBeDisabled,
                disabledTooltip: this.translationService.translate('client_OnlyCreatorCanDeleteTemplate'),
                class: shouldBeDisabled ? 'disabled(on)' : ''
            };

            let options = {
                items: [remove]
            };

            this.menuService.actionMenu(options, angular.element(menuButton), false);
        });
    }

    changeOrderBy(column) {
        this.templates.forEach((template) => {
            template.sortOrder = 0;
        });

        if (this.orderBy.replace('-', '') === column) {
            this.reverseOrder = !this.reverseOrder;
        }
        this.orderBy = this.reverseOrder ? `-${column}` : column;

        this.$element[0].querySelector('.rym-scroll-content').scrollTop = 0;
    }

    performSearch($event) {
        if ($event) {
            this.searchTerm = $event.searchTerm;
        }
        this.filteredTemplates = this.templates.filter(this.templateFilter());
        this._updateNoHitsState();
    }

    templateFilter() {
        return (template) => {
            return (
                !this.searchTerm ||
                template.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                (template.description && template.description.toLowerCase().includes(this.searchTerm.toLowerCase())) ||
                template.createdBy.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        };
    }

    _updateNoHitsState() {
        if (this.templates.length) {
            this.noDataTitle = this.translationService.translate('client_NoTemplatesFound');
            this.noDataDescription = this.translationService.translate('client_NoItemMatchingSearch');
            this.noDataIcon = 'search';
        } else {
            this.noDataTitle = this.translationService.translate('client_NothingHere');
            this.noDataDescription = this.translationService.translate('client_NoTemplatesAvailable');
            this.noDataIcon = 'face';
        }
    }

    deleteTemplate(template) {
        this.translationService
            .translateBatch(['client_DeleteConfirm', 'client_DeleteItemConfirm', 'client_TemplateRemoved'], { itemName: template.name })
            .then((translations) => {
                this.dialogService
                    .confirm({
                        id: 'delete template dialog',
                        title: translations.client_DeleteConfirm,
                        description: translations.client_DeleteItemConfirm
                    })
                    .then((onOk) => {
                        this.meetingApiService
                            .deleteTemplate(template.id)
                            .then(() => {
                                this.toastService.information({ title: translations.client_TemplateRemoved });
                                let index = _.findIndex(this.templates, (temp) => temp.id === template.id);
                                this.templates.splice(index, 1);
                                this.performSearch();
                            })
                            .catch((error) => {
                                this.toastService.error({ title: error.data.message });
                            });
                    });
            });
    }

    createTemplate() {
        let options = {
            id: 'create template dialog',
            title: this.translationService.translate('administration_TemplateName'),
            placeholder: this.translationService.translate('name') + ' *',
            ok: this.translationService.translate('create')
        };

        this.dialogService.prompt(options).then((name) => {
            let template = {
                name: name
            };

            this.meetingApiService.postTemplate(template).then((createdTemplate) => {
                this.templates.push(createdTemplate);
                this.$state.go('template', { id: createdTemplate.id });
            });
        });
    }
}

TemplatesController.$inject = [
    '$state',
    '$scope',
    '$element',
    'meetingApiService',
    'menuService',
    'dialogService',
    'toastService',
    'translationService',
    'breadcrumbsService',
    'userService',
    'responsive'
];

export default TemplatesController;
