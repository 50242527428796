class ExportService {
    constructor($q, $filter, meetingApiService, fileService, translationService, toastService, actionApiService) {
        Object.assign(this, { $q, $filter, meetingApiService, fileService, translationService, toastService, actionApiService });
    }

    exportWithOptions(options, meeting) {
        let fileName = `${meeting.meetingName} - ${this.$filter('date')(meeting.startDate, 'shortDate')}.pdf`;

        this.toastService.loading({ title: this.translationService.translate('client_Exporting') });
        this.meetingApiService
            .exportWithOptions(meeting.id, options)
            .then((response) => {
                this._downloadBlob(response, 'application/pdf', fileName);
                this._downloadCompleted();
            })
            .catch(() => {
                this.toastService.error();
            });
    }

    exportDecisions(context) {
        let fileName = `${this.translationService.translate('client_Decisions')} - ${this.$filter('date')(new Date(), 'shortDate')}.pdf`;

        this.toastService.loading({ title: this.translationService.translate('client_Exporting') });
        this.meetingApiService
            .exportDecisions(context)
            .then((response) => {
                this._downloadBlob(response, 'application/pdf', fileName);
                this._downloadCompleted();
            })
            .catch(() => {
                this.toastService.error();
            });
    }

    exportActions(context) {
        let fileName = `${this.translationService.translate('client_Actions')} - ${this.$filter('date')(new Date(), 'shortDate')}.pdf`;

        this.toastService.loading({ title: this.translationService.translate('client_Exporting') });
        this.actionApiService
            .export(context)
            .then((response) => {
                this._downloadBlob(response, 'application/pdf', fileName);
                this._downloadCompleted();
            })
            .catch(() => {
                this.toastService.error();
            });
    }

    _downloadBlob(result, contentType, fileName) {
        let byteArray = new Uint8Array(result.data),
            contentSize = result.data.byteLength,
            success = false;

        success = this.fileService.tryDownloadUsingBlob([byteArray], contentType, contentSize, fileName);

        if (!success) {
            success = this.fileService.tryDownloadUsingLink(byteArray, contentType, contentSize, fileName);
        }

        if (!success) {
            success = this.fileService.useLink(byteArray, fileName);
        }

        if (!success) {
            this.fileService.tryDownloadBase64Data(byteArray, contentType);
        }
    }

    exportMeetingToOffice365(meeting, message) {
        let deferred = this.$q.defer();

        this.toastService.loading({ title: this.translationService.translate('client_ExportingToCalendar') });
        this.meetingApiService
            .exportToCalendar(meeting.id, message)
            .then((createdEvent) => {
                this.toastService.success({ title: this.translationService.translate('client_MeetingExported') });
                deferred.resolve(createdEvent.office365UId);
            })
            .catch(() => {
                this.toastService.error();
                deferred.reject();
            });

        return deferred.promise;
    }

    _downloadCompleted() {
        this.toastService.success({
            title: this.translationService.translate('client_DownloadComplete'),
            description: this.translationService.translate('client_DownloadCompleteDescription')
        });
    }
}

ExportService.$inject = ['$q', '$filter', 'meetingApiService', 'fileService', 'translationService', 'toastService', 'actionApiService'];

export default ExportService;
