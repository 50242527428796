import template from './timezoneMenu.html';
import './timezoneMenu.less';

let timezoneMenuComponent = {
    template,
    bindings: {
        options: '=',
        onCallback: '&'
    }
};

export default timezoneMenuComponent;
