class CardModelTransformer {
    constructor($filter, timeService, translationService, stringService) {
        Object.assign(this, { $filter, timeService, translationService, stringService });
    }

    transform(meetingDto) {
        return {
            headline: meetingDto.meetingName,
            moreIcon: this._getMoreIcon(meetingDto),
            moreTitle: this._getMoreTitle(meetingDto),
            icon1: 'timer',
            iconText1: this._getDuration(meetingDto),
            icon2: 'place',
            iconText2: meetingDto.location,
            tileColor: this._getTileColor(meetingDto),
            startDate: this._getStartDate(meetingDto),
            tileText: this._getStartTime(meetingDto),
            isPlaceholder: this._isPlaceholder(meetingDto),
            placeholderButtonText: this.translationService.translate('client_CreateAgenda'),
            meeting: meetingDto
        };
    }

    _getMoreTitle(meetingDto) {
        return this._isPlaceholder(meetingDto)
            ? this.translationService.translate('client_Office365MeetingInfo')
            : this.translationService.translate('client_More');
    }

    _getMoreIcon(meetingDto) {
        return this._isPlaceholder(meetingDto) ? 'outlook' : 'more_vert';
    }

    _isPlaceholder(meetingDto) {
        return meetingDto.isPlaceholder;
    }

    _getTileColor(meetingDto) {
        return !!meetingDto.colorId ? `category-${meetingDto.colorId}` : 'category-0';
    }

    _getStartDate(meetingDto) {
        return this.$filter('date')(meetingDto.startDate, 'yyyy-MM-dd');
    }

    _getStartTime(meetingDto) {
        return this.$filter('date')(meetingDto.startDate, 'HH:mm');
    }

    _getDuration(meetingDto) {
        return this.timeService.getDuration(meetingDto.startDate, meetingDto.endDate);
    }
}
CardModelTransformer.$inject = ['$filter', 'timeService', 'translationService', 'stringService'];

export default CardModelTransformer;
