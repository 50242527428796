import template from './meetingSeriesEvents.html';
import controller from './meetingSeriesEvents.controller';

let meetingSeriesEventsComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingSeriesDialog'
    }
};

export default meetingSeriesEventsComponent;
