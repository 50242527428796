class VerticalWizardStepController {
    constructor($scope, $timeout, translationService) {
        this.$scope = $scope;
        this.$timeout = $timeout;
        this.nextStep = null;
        this.previousStep = null;
        this.translationService = translationService;
    }

    $postLink() {
        this.$timeout(() => {
            this.number = this.verticalWizardCtrl.add(this);
        });
    }

    $onDestroy() {
        if (this.previousStep) {
            this.previousStep.nextStep = this.nextStep;
        }

        if (this.nextStep) {
            this.nextStep.setPreviousStep(this.nextPreviousStep);
        }
    }

    setNextStep(step) {
        this.nextStep = step;
    }

    setPreviousStep(step) {
        this.previousStep = step;
    }

    next() {
        this.verticalWizardCtrl.activate(this.nextStep);
    }

    activate() {
        if (!this.previousStep || this.previousStep.isValid()) {
            this.verticalWizardCtrl.activate(this);
        }
    }

    setIsActive(isActive) {
        if (isActive) {
            this.timeout = this.$timeout(() => {
                this.isActive = isActive;
            }, 300);
        } else {
            this.$timeout.cancel(this.timeout);
            this.isActive = isActive;
        }
    }

    isActivateable() {
        return this.previousStep().isValid() && !this.isActive;
    }

    isValid() {
        return !this.stepForm.$invalid;
    }

    hasPreviousStep() {
        return !!this.previousStep;
    }

    hasNextStep() {
        return !!this.nextStep;
    }

    getNumber() {
        let count = 1;
        let step = this;
        while (step.hasPreviousStep()) {
            step = step.previousStep;
            count++;
        }

        return count;
    }
}

VerticalWizardStepController.$inject = ['$scope', '$timeout', 'translationService'];

export default VerticalWizardStepController;
