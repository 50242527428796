class GuideController {
    constructor($interval, $timeout, responsive) {
        this.$interval = $interval;
        this.$timeout = $timeout;
        this.responsive = responsive;
        this.steps = [];
        this.currentStep = 0;
        this.isGoingForward = true;
        this._isCompleted = false;
    }

    $onInit() {}

    done() {
        this.onDone();
    }

    add(step) {
        this.steps.push(step);
        if (this.steps.length === 1) {
            step.isFirst = true;
            this.activateCurrentStep();
        }
    }

    remove(step) {
        let index = this.steps.indexOf(step);
        this.steps.splice(index, 1);
    }

    next() {
        if (this.isAtLastStep()) {
            return;
        }

        this.isGoingForward = true;
        this.updatePreviousStep();
        this.currentStep++;

        this.activateCurrentStep();
    }

    previous() {
        if (this.isAtFirstStep()) {
            return;
        }
        this.isGoingForward = false;
        this.updatePreviousStep();
        this.currentStep--;

        this.activateCurrentStep();
    }

    updatePreviousStep() {
        let newPreviousStep = this.steps[this.currentStep];
        newPreviousStep.isActive = false;
        this.previousStep = newPreviousStep;
    }

    activateCurrentStep() {
        if (this.isAtLastStep() && this.steps.length > 1) {
            this._isCompleted = true;
        }

        this.steps[this.currentStep].isActive = true;
    }

    isAtLastStep() {
        return this.currentStep + 1 === this.steps.length;
    }

    isAtFirstStep() {
        return this.currentStep === 0;
    }

    canClose() {
        return this.isCompleted;
    }
}

GuideController.$inject = ['$interval', '$timeout', 'responsive'];

export default GuideController;
