class HelpService {
    constructor(dialogService, translationService) {
        Object.assign(this, { dialogService, translationService });
    }

    showTutorialVideoDialog() {
        this.translationService.translateBatch(['client_TutorialVideos', 'client_Done']).then((translations) => {
            let options = {
                id: 'help video dialog',
                title: translations.client_TutorialVideos,
                cancel: translations.client_Done
            };

            this.dialogService.custom('rym-tutorial-video-dialog', options);
        });
    }
}

HelpService.$inject = ['dialogService', 'translationService'];

export default HelpService;
