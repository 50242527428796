import template from './meetingPreviewDialog.html';
import controller from './meetingPreviewDialog.controller';

let meetingPreviewDialogComponent = {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};

export default meetingPreviewDialogComponent;
