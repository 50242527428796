class meetingSeriesDecisionsController {
    constructor(
        $state,
        $timeout,
        $filter,
        meetingSeriesService,
        translationService,
        menuService,
        dialogService,
        meetingApiService,
        eventEmitterService,
        exportService
    ) {
        Object.assign(this, {
            $state,
            $timeout,
            $filter,
            meetingSeriesService,
            translationService,
            menuService,
            dialogService,
            meetingApiService,
            eventEmitterService,
            exportService
        });
    }

    $onInit() {
        this.parent.isLoading = true;
        this.filter = {
            searchTerm: ''
        };

        this.filteredDecisions = [];

        this.meetingSeriesService
            .getDecisionsInMeetingSeries()
            .then((decisions) => {
                this.decisions = decisions;
                this._initDecisionGroups(this.decisions);
            })
            .finally(() => {
                this.parent.isLoading = false;
                this.parent.tabLoaded('decisions');
            });

        this.eventEmitterService.subscribe('exportList', this._export.bind(this));
    }

    $onDestroy() {
        this.eventEmitterService.unSubscribe('exportList');
    }

    _export() {
        let options = {
            id: 'export decisions dialog',
            title: this.translationService.translate('client_Decisions'),
            ok: this.translationService.translate('client_Export'),
            cancel: this.translationService.translate('cancel'),
            message: {
                subject: this.translationService.translate('client_Decisions'),
                message: this.translationService.translate('client_ExportDecisionDescription', { date: this.$filter('date')(new Date(), 'rymDate') })
            }
        };

        this.dialogService.custom('rym-text-dialog', options).then((message) => {
            let exportContext = {
                title: message.subject,
                description: message.message,
                itemIds: this.$filter('filter')(this.decisions, this.isMatchingFilter()).map((a) => a.id)
            };

            return this.exportService.exportDecisions(exportContext);
        });
    }

    _initDecisionGroups(decisions) {
        decisions = this.$filter('orderBy')(decisions, '-fromDate');
        let decisionGroups = _.groupBy(decisions, (decision) => decision.meetingId);
        let decisionGroupsArray = Object.keys(decisionGroups).map((key) => {
            return decisionGroups[key];
        });
        this.decisionGroups = _.sortBy(decisionGroupsArray, (decisionGroup) => {
            return decisionGroup[0].fromDate;
        }).reverse();
    }

    meetingHasDecisions(group) {
        return this.$filter('filter')(group, this.isMatchingFilter()).length > 0;
    }

    isMatchingFilter() {
        return (decision) => {
            return this.isMatchingSearchTerm(decision);
        };
    }

    isMatchingSearchTerm(decision) {
        let query = this.filter.searchTerm;
        return !query || decision.text.toLowerCase().includes(query.toLowerCase());
    }

    showDetail(decision) {
        this.selectedDecision = null;
        this.fromAgendaRow = null;

        if (this.parent.sideNavOpen) {
            this.parent.sideNavOpen = !this.parent.sideNavOpen;
        }

        this.$timeout(() => {
            this.selectedDecision = decision;
            this.decisionDetailOpen = true;
            this.fromAgendaRow = {
                title: this.translationService.translate('client_AgendaItem'),
                icon: 'format_list_numbered',
                description: decision.fromAgenda,
                meetingId: decision.meetingId
            };
        });
    }

    closeDetail() {
        this.decisionDetailOpen = false;
        this.selectedDecision = null;
    }

    closeSideNav() {
        if (this.decisionDetailOpen) {
            this.closeDetail();
        }

        if (this.parent.sideNavOpen) {
            this.parent.sideNavOpen = !this.parent.sideNavOpen;
        }
    }

    goToAgenda() {
        if (this.$state.is('meeting') && this.$state.params.id == this.selectedDecision.meetingId) {
            const fullscreenDialogToClose = 'meetingseries';
            this.eventEmitterService.publishEvent('closeFullscreenDialog' + fullscreenDialogToClose);
            return;
        }

        this.parent.scrollTo = 'p' + this.selectedDecision.agendaId;
        let meeting = _.find(this.meetingSeriesService.getMeetingSeries().meetings, (meet) => meet.id === this.selectedDecision.meetingId);
        this.parent.showProtocol(meeting);
    }

    remove(decision) {
        this.translationService.translateBatch(['client_DeleteConfirm', 'client_DeleteDecision']).then((translations) => {
            this.dialogService
                .confirm({
                    id: 'delete decision dialog',
                    title: translations.client_DeleteConfirm,
                    description: translations.client_DeleteDecision
                })
                .then(() => {
                    this.meetingApiService
                        .removeDecision(decision.meetingId, decision.id)
                        .then(() => {
                            let decisionsGroup = _.find(this.decisionGroups, (decisionGroup) => {
                                return decisionGroup.includes(decision);
                            });

                            let decisionIndex = this.decisions.indexOf(decision);
                            this.decisions.splice(decisionIndex, 1);

                            let index = _.findIndex(decisionsGroup, (de) => de.id === decision.id);

                            if (decisionsGroup && index !== -1) {
                                decisionsGroup.splice(index, 1);
                                if (decisionsGroup.length === 0) {
                                    let index = this.decisionGroups.indexOf(decisionsGroup);
                                    this.decisionGroups.splice(index, 1);
                                }
                            }

                            this._notifyPossibleCopies(decision);
                            this.closeDetail();
                        })
                        .catch((exception) => {
                            this.toastService.error({ description: exception.data.message });
                        });
                });
        });
    }

    _notifyPossibleCopies(decision) {
        this.eventEmitterService.publishEvent('agendaDecisionRemoved' + decision.agendaId, decision);
    }
}

meetingSeriesDecisionsController.$inject = [
    '$state',
    '$timeout',
    '$filter',
    'meetingSeriesService',
    'translationService',
    'menuService',
    'dialogService',
    'meetingApiService',
    'eventEmitterService',
    'exportService'
];

export default meetingSeriesDecisionsController;
