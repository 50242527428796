import { PARTICIPANT_TYPES } from '../../../app.constants';

class roleSettingsDialogController {
    constructor($timeout, participantsService, translationService, menuService, dialogService, userApiService, toastService) {
        Object.assign(this, { $timeout, participantsService, translationService, menuService, dialogService, userApiService, toastService });
    }

    $onInit() {
        this.isLimited = this.options.isLimited;
        this.participantsService.onChange((changes) => {
            this.users = _.concat(changes.participants, changes.temporarlyParticipants);
        });
    }

    showUserMenu($event, user) {
        let options = {
            items: []
        };

        options.items.push({
            id: 'menu option - add participant to all meetings',
            icon: 'playlist_add',
            text: this.translationService.translate('client_AddToAllMeetings'),
            onClick: () => this._addToAllMeetings(user),
            disabled: true
        });

        if (!this.participantsService.isOrganizer(user) && (!this.isLimited || this.participantsService.isReader(user))) {
            options.items.push({
                id: 'menu option - remove participant',
                icon: 'close',
                text: this.translationService.translate('client_Remove'),
                onClick: () => this._remove(user)
            });
        }

        this.menuService.actionMenu(options, angular.element($event.currentTarget), false, false);
    }

    _addToAllMeetings(user) {
        this.dialogService
            .confirm({
                id: 'add participant dialog',
                title: this.translationService.translate('client_AddToAllMeetings'),
                description: this.translationService.translate('client_AddToAllMeetingsDescription', {
                    name: `<span class="text(body-md)">${user.name}</span>`
                }),
                shouldConfirm: this.isLimited ? false : true,
                confirm: false,
                confirmTitle: this.translationService.translate('client_AddParticipantAsEditorToFutureMeetings'),
                ok: this.translationService.translate('client_AddToAll'),
                cancel: this.translationService.translate('client_Cancel')
            })
            .then((options) => {
                this.toastService.loading({
                    title: this.translationService.translate('client_AddToAllLoading')
                });
                this.participantsService.addToAllMeetings(user, options.confirm).then(() => {
                    this.toastService.success({
                        title: this.translationService.translate('client_AddToAllSuccess')
                    });
                });
            });
    }

    _remove(user) {
        this.participantsService.remove(user);
        this.participantsService.save();
    }

    selectEditor(user) {
        this.participantsService.setType(user, PARTICIPANT_TYPES.EDITOR);
        user.isLoadingEditorCheckbox = true;
        this.participantsService.save().then(() => {
            user.isLoadingEditorCheckbox = false;
        });
    }

    unselectEditor(user) {
        this.participantsService.setType(user, PARTICIPANT_TYPES.NON_EDITOR);
        user.isLoadingEditorCheckbox = true;
        this.participantsService.save().then(() => {
            user.isLoadingEditorCheckbox = false;
        });
    }

    selectParticipant(user) {
        this.participantsService.setType(user, PARTICIPANT_TYPES.NON_EDITOR);
        user.isLoadingParticipantCheckbox = true;
        this.participantsService.save().then(() => {
            user.isLoadingParticipantCheckbox = false;
        });
    }

    unselectParticipant(user) {
        this.participantsService.setType(user, PARTICIPANT_TYPES.READER);
        user.isLoadingParticipantCheckbox = true;
        this.participantsService.save().then(() => {
            user.isLoadingParticipantCheckbox = false;
        });
    }

    isOrganizer(participant) {
        return this.participantsService.isOrganizer(participant);
    }

    isParticipant(participant) {
        return this.participantsService.isParticipant(participant);
    }

    isEditor(participant) {
        return this.participantsService.isEditor(participant);
    }

    isReader(participant) {
        return this.participantsService.isReader(participant);
    }

    openAddUsersDialog() {
        this.userApiService.getSuggestedParticipants().then((suggestedParticipants) => {
            let options = {
                title: this.translationService.translate('client_AddUsers'),
                usersToIgnore: this.users,
                suggestedUsers: suggestedParticipants,
                ok: this.translationService.translate('client_Add'),
                cancel: this.translationService.translate('client_Cancel')
            };

            this.dialogService.custom('rym-select-multiple-users-dialog', options).then((data) => {
                data.forEach((user) => {
                    this.participantsService.add(user, PARTICIPANT_TYPES.READER);
                });
                this.participantsService.save();
            });
        });
    }
}

roleSettingsDialogController.$inject = [
    '$timeout',
    'participantsService',
    'translationService',
    'menuService',
    'dialogService',
    'userApiService',
    'toastService'
];

export default roleSettingsDialogController;
