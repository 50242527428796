class selectionService {
    constructor() {}

    setToEnd(contentEditable) {
        var range = document.createRange();
        range.selectNodeContents(contentEditable);
        range.collapse(false);
        var sel = window.getSelection();
        if (sel.anchorOffset < 1) {
            sel.removeAllRanges();
            sel.addRange(range);
            contentEditable.scrollLeft = contentEditable.scrollWidth;
        }
    }
}

selectionService.$inject = [];
export default selectionService;
