const INJECT = [
    '$q',
    '$scope',
    '$interval',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'organizationService',
    'userService',
    'toastService',
    'subscriptionApiService',
    'subscriptionService'
];

class SubscriptionCardFormController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this._init();
    }

    $onChanges() {
        this._toggleForm();
        if (this.clearForm) {
            this._clearForm();
        }
    }

    _init() {
        this.elements = this.subscriptionService.getStripe().elements();
        this._startPoll();
        this.initialized = true;
    }

    _startPoll() {
        this.checkForDomElement = this.$interval(
            () => {
                this.$scope.$evalAsync(() => {
                    if (document.getElementById('card-element')) {
                        this._loadStripe();
                        this._toggleForm();
                        this._cancelPoll();
                    }
                });
            },
            200,
            0,
            false
        );
    }

    _cancelPoll() {
        this.$interval.cancel(this.checkForDomElement);
    }

    _toggleForm() {
        if (this.initialized) {
            if (this.isDisabled) {
                this.cardElement.update({ disabled: false });
                this.cardExpiry.update({ disabled: false });
                this.cardCvc.update({ disabled: false });
                this.cardElement.focus();
            } else if (this.isDisabled === false) {
                this.cardElement.update({ disabled: true });
                this.cardExpiry.update({ disabled: true });
                this.cardCvc.update({ disabled: true });
            }
        }
    }

    _clearForm() {
        if (this.initialized) {
            this.cardElement.clear();
            this.cardCvc.clear();
            this.cardExpiry.clear();
            this.cardElement.focus();
        }
    }

    _loadStripe() {
        const style = {
            base: {
                color: '#32325d',
                fontSmoothing: 'antialiased',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '14px',
                '::placeholder': {
                    color: '#7F7F7F'
                },
                iconColor: '#36BDBB'
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        if (!this.cardElement) {
            this.cardElement = this.elements.create('cardNumber', {
                showIcon: true,
                style: style,
                iconStyle: 'solid',
                disabled: this.isDisabled ? true : false
            });
        }
        this.cardCvc = this.elements.create('cardCvc', {
            placeholder: this.translationService.translate('client_SecurityCode'),
            style: style,
            disabled: this.isDisabled ? true : false
        });
        this.cardExpiry = this.elements.create('cardExpiry', {
            placeholder: this.translationService.translate('client_ExpirationDateMmYy'),
            style: style,
            disabled: this.isDisabled ? true : false
        });
        this.cardElement.mount('#card-element');
        this.cardCvc.mount('#card-cvc');
        this.cardExpiry.mount('#card-expiry');
        this.cardElement.on('change', (event) => {
            this.validCard = false;
            if (event.complete) {
                this.validCard = true;
                this._setFormData();
            }
            this._validForm();
            this._displayCardError(event);
        });
        this.cardCvc.on('change', (event) => {
            this.validCvc = false;
            if (event.complete) {
                this.validCvc = true;
                this._setFormData();
            }
            this._validForm();
            this._displayCardError(event);
        });
        this.cardExpiry.on('change', (event) => {
            this.validExpiry = false;
            if (event.complete) {
                this.validExpiry = true;
                this._setFormData();
            }
            this._validForm();
            this._displayCardError(event);
        });
    }

    _displayCardError(event) {
        const displayError = document.getElementById('card-errors');

        if (event.error) {
            displayError.textContent = event.error.message;
        } else {
            displayError.textContent = '';
        }
    }

    _validForm() {
        this.validForm = this.validCard && this.validCvc && this.validExpiry;
    }

    updateFormData() {
        this._setFormData();
    }

    _setFormData() {
        this.formData = {
            cardElement: this.cardElement,
            cardName: this.cardName
        };
    }

    showCvcInfo() {
        this.translationService.translateBatch(['client_WhatIsThis', 'close', 'client_SecurityCodeInformationText']).then((translations) => {
            this.dialogService.alert({
                title: translations.client_WhatIsThis,
                ok: translations.close,
                description: translations.client_SecurityCodeInformationText
            });
        });
    }
}

SubscriptionCardFormController.$inject = INJECT;

export default SubscriptionCardFormController;
