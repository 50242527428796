class TimeService {
    constructor($filter) {
        this.$filter = $filter;
    }

    isTimeAfter(start, end) {
        let date;
        if (end instanceof Date) {
            date = end;
        } else {
            let h = parseInt(end.slice(0, 2));
            let m = parseInt(end.slice(3, 5));
            date = angular.copy(start);
            date.setHours(h);
            date.setMinutes(m);
        }

        return date > start;
    }

    getLastSecondOfDayAsDate(date) {
        if (!date) {
            return null;
        }

        const jsDate = new Date(date);

        return new Date(jsDate.getFullYear(), jsDate.getMonth(), jsDate.getDate(), 23, 59, 59);
    }

    isTimeSameDay(start, end) {
        let date;
        if (end instanceof Date) {
            date = end;
        } else {
            date = new Date(start.getTime() + end * 60000);
        }

        return date.getDay() > start.getDay();
    }

    getMeetingTimesArray(selected) {
        let hours = 24,
            minutes = 60,
            interval = 15;
        let array = Array.from({ length: (hours * minutes) / interval }, (v, i) => {
            let h = Math.floor((i * interval) / minutes);
            let m = i * interval - h * minutes;
            return this.createMeetingTime(h, m, selected);
        });
        return array;
    }

    createMeetingTime(h, m, selected) {
        let time = (h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m);

        return {
            time: time,
            isSelected: selected ? this.getTimeAsString(selected) === time : false,
            hours: h,
            minutes: m
        };
    }

    getMeetingDurationArray() {
        let durationArray = [
            { time: '15 min', duration: 15 },
            { time: '30 min', duration: 30 },
            { time: '45 min', duration: 45 }
        ];

        for (let i = 1; i < 24; i++) {
            for (let y = 0; y < 2; y++) {
                let min = y * 30;
                durationArray.push({ time: `${i}h ${y === 1 ? '30m' : ''}`, duration: i * 60 + min });
            }
        }

        durationArray.push({ time: '24h', duration: 1440 });

        return durationArray;
    }

    findClosestIndexInDurationArray(durationString, array) {
        let h2 = parseInt(durationString.slice(0, 2));
        let m2 = parseInt(durationString.slice(3, 5));
        let t2 = h2 * 60 + m2;

        let duration = _.findLast(array, (d) => {
            let h = parseInt(d.time.slice(0, 2));
            let m = parseInt(d.time.slice(3, 5));
            let t = h * 60 + m;

            return t <= t2;
        });

        return duration;
    }

    getTimeAsString(date) {
        return this.$filter('date')(new Date(date), 'HH:mm');
    }

    setTimeFromString(date, string) {
        let h = string.slice(0, 2);
        let m = string.slice(3, 5);
        let newDate = angular.copy(date);
        newDate.setHours(h);
        newDate.setMinutes(m);

        return newDate;
    }

    parseDurationFromString(startDate, durationString) {
        let hours = /(\d+)\s?(?=h)/g.exec(durationString),
            mins = /(\d+)\s?(?=m)/g.exec(durationString),
            endTime = {
                hours: hours ? hours[0] : 0,
                minutes: mins ? mins[0] : 0
            };

        return new Date(startDate.getTime() + (parseInt(endTime.hours * 60) + parseInt(endTime.minutes)) * 60000);
    }

    getDuration(startDate, endDate) {
        let diff = new Date(endDate) - new Date(startDate);

        let diffHours = Math.floor(diff / 3600000),
            diffMinutes = Math.round((diff % 3600000) / 60000);

        return `${diffHours > 0 ? diffHours + 'h ' : ''}${diffMinutes > 0 ? diffMinutes + 'm' : ''}`;
    }
}

TimeService.$inject = ['$filter'];

export default TimeService;
