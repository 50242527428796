class FilterTabController {
    constructor($element, anchorScrollService) {
        this.$element = $element;
        this.anchorScrollService = anchorScrollService;
    }

    $onInit() {
        if (!this.filterTabsCtrl) {
            return;
        }

        this.isInitialSelect = true;

        this.filterTabsCtrl.add(this);

        if (this.value === null && this.filterTabsCtrl.model === null) {
            this.filterTabsCtrl.select(this);
        }

        if (this.isDefault) {
            this.filterTabsCtrl.default = this;

            if (this.isDefault && !angular.isDefined(this.filterTabsCtrl.model)) {
                this.filterTabsCtrl.select(this);
            }
        }

        if (this.value && this.filterTabsCtrl.model === this.value) {
            this.filterTabsCtrl.select(this);
        }

        delete this.isInitialSelect;
    }

    $postLink() {}

    $onDestroy() {
        this.filterTabsCtrl.remove(this);
    }

    select($event) {
        if (this.$element[0].hasAttribute('disabled')) {
            return;
        }
        let menu = this.$element.find('rym-filter-tab-secondary-action')[0];
        if (!menu || !menu.contains($event.target)) {
            this.filterTabsCtrl.select(this);
        }
    }

    scrollTo() {
        let heightOfFilterTab = 48;
        let offset = 30;
        this.anchorScrollService.scrollToElement(this.$element, null, heightOfFilterTab + offset, 0);
    }

    isSelected() {
        return this.filterTabsCtrl.selected === this;
    }
}

FilterTabController.$inject = ['$element', 'anchorScrollService'];

export default FilterTabController;
