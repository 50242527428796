import template from './tabs.html';
import controller from './tabs.controller';

let TabsComponent = {
    template,
    controller,
    transclude: {
        tab: 'rymTab'
    },
    bindings: {
        selected: '<',
        onSelect: '&'
    }
};

export default TabsComponent;
