const INJECT = [];

class RadioCardsController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onChanges(changes) {
        if (changes.options) {
            this.options = angular.copy(this.options);
        }
    }

    select(option) {
        this.options.filter((x) => x.isSelected)[0].isSelected = false;
        option.isSelected = true;
        this.onChange({
            $event: {
                options: this.options
            }
        });
    }
}

RadioCardsController.$inject = INJECT;

export default RadioCardsController;
