class RowController {
    constructor($element, $scope, rowService) {
        this.$element = $element;
        this.$scope = $scope;
        this.rowService = rowService;
    }

    $onInit() {
        if (this.row && !this.row.isDisabled) {
            this.rowService.bindClickFunction(this.$element);
        }
    }

    handleMainActionEvent($event) {
        if (this.isDisabled) {
            return;
        }

        if (this.row && this.row.inlineEdit) {
            angular.element($event.currentTarget).find('rym-inline-edit')[0].children[0].focus();
        }
        this.onMainAction({ $event: $event });
    }

    handleSecondaryActionEvent($event) {
        this.onSecondaryAction({ $event: $event });
    }

    handleOnChangeEvent() {
        return this.onChange({
            $event: {
                value: this.row.description
            }
        });
    }
}

RowController.$inject = ['$element', '$scope', 'rowService'];

export default RowController;
