var groupByFilter = function () {
    return _.memoize(
        function (items, field) {
            return _.values(_.groupBy(items, field));
        },
        function (items, field) {
            var key = '';
            _.each(items, function (item) {
                key += item.id + item.meetingName + item.startDate.substring(0, 10);
            });
            return key;
        }
    );
};

export default groupByFilter;
