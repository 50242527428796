class UserListController {
    constructor(userApiService, userService, stringService, translationService, $filter) {
        Object.assign(this, { userApiService, userService, stringService, translationService, $filter });
    }

    $onInit() {
        this.userService.getCurrentUser().then((user) => {
            this.canInvite = !user.isExternal;
        });

        this.suggestedUsers = _.differenceWith(this.suggestedUsers, this.usersToIgnore, (a, b) => {
            return a.email.toLowerCase() === b.email.toLowerCase();
        });
    }

    $onChanges(changes) {
        if (changes.selectedUsers) {
            this.selectedUsers = angular.copy(this.selectedUsers);
        }
    }

    isSelected(user) {
        return !!(angular.isDefined(this.selectedUsers) && this.selectedUsers.filter((selectedUser) => selectedUser.email === user.email).length);
    }

    toggleSelect($event, user) {
        $event.user = user;
        if (this.isSelected(user)) {
            this.selectedUsers && _.remove(this.selectedUsers, (selected) => selected.email === $event.user.email);
            this.onUnselect({ $event: $event });
        } else {
            this.selectedUsers && this.selectedUsers.push($event.user);
            this.onSelect({ $event: $event });
        }
    }

    isEmail(searchTerm) {
        return this.stringService.isEmail(searchTerm);
    }

    invite() {
        if (this.isEmail(this.searchTerm) && this.isValid()) {
            this.selectedUsers && this.selectedUsers.push({ email: this.searchTerm, name: this.searchTerm });
            this.onInvite({ term: this.searchTerm });
            this.searchTerm = '';
        }
    }

    search() {
        let val = this.searchTerm;
        this.isValidEmail = this.stringService.isEmail(val);

        if (val.length < 3) {
            this.users = [];
            this.filteredUsers = [];
            this.hasResult = false;
            return;
        }

        let tempFilterUser = this.$filter('user')(this.users, val);
        if (tempFilterUser.length || this.isSearching) {
            this.filteredUsers = tempFilterUser;
            return;
        }

        this.isLoading = true;

        this.hasResult = false;
        this.isSearching = true;
        this.userApiService
            .search(val)
            .then((result) => {
                if (this.usersToIgnore) {
                    result = _.differenceWith(result, this.usersToIgnore, (a, b) => {
                        return a.email.toLowerCase() === b.email.toLowerCase();
                    });
                }

                this.users = result.map((user) => {
                    if (!user.name) {
                        user.name = user.firstName;
                    }
                    return user;
                });

                if (!this.enableMultipleSelection) {
                    this.users = _.differenceWith(this.users, this.selectedUsers, (a, b) => {
                        return a.email.toLowerCase() === b.email.toLowerCase();
                    });
                }

                this.filteredUsers = this.users;
            })
            .finally(() => {
                this.isLoading = false;
                this.hasResult = this.filteredUsers.length > 0;
                this.isSearching = false;
            });
    }

    hasCompleteMatch() {
        return _.containsEmail(this.filteredUsers, this.searchTerm);
    }

    _isAlreadySelected() {
        if (!this.enableMultipleSelection) {
            return _.containsEmail(this.selectedUsers, this.searchTerm);
        }
        return false;
    }

    _isIgnored() {
        if (this.usersToIgnore) {
            return (
                this.usersToIgnore.filter((user) => {
                    return user.email.toLowerCase() === this.searchTerm.toLowerCase();
                }).length !== 0
            );
        }
        return false;
    }

    isValid() {
        return this.isValidEmail && !this.hasCompleteMatch() && !this._isAlreadySelected() && !this._isIgnored();
    }

    selectMultiple() {
        return this.onSecondarySelect && this.onSecondarySelect();
    }
}

UserListController.$inject = ['userApiService', 'userService', 'stringService', 'translationService', '$filter'];

export default UserListController;
