const SKIP_TRIAL_EXCEEDED = true;

class meetingCreationService {
    constructor(
        $state,
        $q,
        meetingApiService,
        dialogService,
        translationService,
        paymentService,
        userService,
        organizationService,
        toastService,
        locationService
    ) {
        Object.assign(this, {
            $state,
            $q,
            meetingApiService,
            dialogService,
            translationService,
            paymentService,
            userService,
            organizationService,
            toastService,
            locationService
        });
    }

    showCreateMeetingDialog(meeting, template) {
        let deferred = this.$q.defer();
        this.userService.getCurrentUser().then((user) => {
            if (user.isExternal) {
                deferred.resolve();
                return this._showIsParticipantDialog();
            }

            this._getTrialExceeded().then((isExceeded) => {
                if (isExceeded) {
                    deferred.resolve();
                    return this._showTrialExceeded();
                } else {
                    if (this._shouldCreateMeetingDirectly(meeting)) {
                        this._createMeeting(meeting);
                    } else {
                        this.options = {
                            id: 'create meeting dialog',
                            ok: this.translationService.translate('create'),
                            cancel: this.translationService.translate('cancel'),
                            title: this.translationService.translate('client_Meeting'),
                            meeting: meeting,
                            template: template
                        };

                        this.dialogService
                            .custom('rym-create-meeting-dialog', this.options)
                            .then((newMeeting) => {
                                this._createMeeting(newMeeting);
                            })
                            .catch((e) => {
                                deferred.resolve();
                            });
                    }
                }
            });
        });
        return deferred.promise;
    }

    _showTrialExceeded() {
        return this.paymentService.trialExceeded();
    }

    _showIsParticipantDialog() {
        return this.paymentService.showIsParticipant();
    }

    _getTrialExceeded() {
        return this.organizationService.trialExceeded();
    }

    _goToMeeting(meeting) {
        this.locationService.goToMeeting(meeting.id);

        this.paymentService.showTrialInformation(SKIP_TRIAL_EXCEEDED).then((hasShown) => {
            if (!hasShown) {
                this.toastService.hide();
            }
        });
    }

    _shouldCreateMeetingDirectly(meeting) {
        if (meeting && meeting.isPlaceholder && meeting.office365MeetingSeriesId) {
            meeting.meetingSeriesId = meeting.office365MeetingSeriesId;
            meeting.categoryId = meeting.office365MeetingCategoryId;
            return true;
        }

        return false;
    }

    _createMeeting(newMeeting) {
        this.toastService.loading({
            title: this.translationService.translate('meetings_CreatingMeeting')
        });
        this.meetingApiService
            .create(newMeeting)
            .then((result) => {
                this._goToMeeting(result);
            })
            .catch((exception) => {
                deferred.resolve();
                this.toastService.error({
                    title: this.translationService.translate('client_CouldNotCreateMeeting'),
                    description: exception.data.message
                });
            });
    }
}

meetingCreationService.$inject = [
    '$state',
    '$q',
    'meetingApiService',
    'dialogService',
    'translationService',
    'paymentService',
    'userService',
    'organizationService',
    'toastService',
    'locationService'
];
export default meetingCreationService;
