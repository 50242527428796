import template from './progressCircle.html';
import './progressCircle.less';

let progressCircleComponent = {
    template,
    bindings: {
        duration: '<'
    }
};

export default progressCircleComponent;
