import template from './meetingSeriesDetail.html';
import controller from './meetingSeriesDetail.controller';
import './meetingSeriesDetail.less';

let meetingSeriesDetailComponent = {
    template,
    controller,
    require: {
        parent: '^rymMeetingPreviewDialog'
    }
};

export default meetingSeriesDetailComponent;
