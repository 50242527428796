import template from './selectAgendaTemplateDialog.html';
import controller from './selectAgendaTemplateDialog.controller';

export default {
    template,
    controller,
    bindings: {
        onOk: '&',
        onCancel: '&',
        options: '<'
    }
};
