import controller from './meeting.controller';
import template from './meeting.html';
import './meeting.less';

let meetingComponent = {
    template,
    controller,
    bindings: {
        meetingId: '<'
    }
};

export default meetingComponent;
