function routing(routerHelper) {
    routerHelper.configureStates([
        {
            state: 'developers',
            config: {
                url: '/developers',
                template: '<rym-developers></rym-developers>',
                ncyBreadcrumb: {
                    label: 'Developers'
                },
                authorization: {
                    organizer: true
                }
            }
        }
    ]);
}

routing.$inject = ['routerHelper'];

export default routing;
