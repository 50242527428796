const INJECT = ['$element', '$scope', '$document'];

class SliderController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });

        this._eventListeners = [];
        this._pattern = /\d+/;
    }

    $onChanges(changes) {
        if (changes.value.isFirstChange()) {
            this._init();
        }

        if (changes.value && this._value != changes.value.currentValue) {
            if (this._pattern.test(changes.value.currentValue)) {
                this._setValue(changes.value.currentValue);
            } else {
                this._setValue(this.minValue);
            }

            this._commit();
            this._render();
        }
    }

    $onDestroy() {
        this._inactivateEventListeners();
    }

    _init() {
        this._initElementHooks();
        this._initEventListeners();
        this._activateEventListeners();
        this._initBoundaries();
    }

    _setValue(value) {
        this._value = _.clamp(parseInt(value, 10), this.minValue, this.maxValue);
    }

    _render() {
        let share = this._value / this.maxValue;
        this.spacerElement.style.width = parseInt(this.maxWidth * share, 10) + 'px';
    }

    _commit() {
        this.onChange({
            $event: {
                value: this._value
            }
        });
    }

    _initElementHooks() {
        this.$body = angular.element(this.$document[0].querySelector('body'));
        this.$handleElement = angular.element(this.$element[0].querySelector('[data-hook="slider-handle"]'));
        this.barElement = this.$element[0].querySelector('[data-hook="slider-bar"]');
        this.barContainerElement = this.$element[0].querySelector('[data-hook="slider-bar-container"]');
        this.spacerElement = this.$element[0].querySelector('[data-hook="slider-spacer"]');
    }

    _initEventListeners() {
        this._addEventListener(this.$body, 'mousemove touchmove', (event) => this._handleMouseMove(event));
        this._addEventListener(this.$body, 'mouseup touchend', (event) => this._handleMouseUp(event));
        this._addEventListener(this.$handleElement, 'mousedown touchstart', (event) => this._handleMouseDown(event));
    }

    _addEventListener(element, event, callback) {
        this._eventListeners.push({ element, event, callback });
    }

    _activateEventListeners() {
        this._eventListeners.forEach((eventListener) => {
            eventListener.element.on(eventListener.event, eventListener.callback);
        });
    }

    _inactivateEventListeners() {
        this._eventListeners.forEach((eventListener) => {
            eventListener.element.off(eventListener.event, eventListener.callback);
        });
    }

    _initBoundaries() {
        this.maxWidth = this._calcMaxWidth();
        this.minWidth = this._calcMinWidth();
    }

    _calcMinWidth() {
        return parseInt(this._calcMaxWidth() * (this.minValue / this.maxValue), 10);
    }

    _calcMaxWidth() {
        return this.barContainerElement.offsetWidth;
    }

    _setWidth(width) {
        this._width = _.clamp(parseInt(width, 10), this.minWidth, this.maxWidth);
        this.spacerElement.style.width = this._width + 'px';
    }

    _getEventX(event) {
        if (event.x || event.targetTouches) {
            return event.x || event.targetTouches[0].pageX;
        }
        return null;
    }

    _handleMouseUp(event) {
        this.isDragging = false;
    }

    _handleMouseDown(event) {
        this.isDragging = true;
        this.initialX = this._getEventX(event);
        this.initialWidth = this.spacerElement.offsetWidth;
    }

    _handleMouseMove(event) {
        let x = this._getEventX(event);

        if (x === null || !this.isDragging) {
            return;
        }

        this._setWidth(this.initialWidth - this.initialX + x);

        let share = this._width / this.maxWidth;
        this._setValue(parseInt(share * this.maxValue, 10));

        this.$scope.$applyAsync(() => {
            this._commit();
        });
    }
}

SliderController.$inject = INJECT;

export default SliderController;
