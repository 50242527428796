class QueueService {
    constructor($q, $http) {
        this.queue = [];
        this.$http = $http;
        this.$q = $q;
        this.queueId = 1;
    }

    executeNext() {
        let task = this.queue[0];
        if (task.cancellationToken && task.cancellationToken.isCancelled) {
            task.deferred.reject('Cancelled');
            this.queue.shift();
            if (this.queue.length > 0) {
                this.executeNext();
            }
        } else {
            this.$http(task.options)
                .then(
                    (data) => {
                        task.deferred.resolve(data);
                    },
                    (error) => {
                        task.deferred.reject(error);
                    }
                )
                .finally(() => {
                    this.queue.shift();
                    if (this.queue.length > 0) {
                        this.executeNext();
                    }
                });
        }
    }

    executeNextInBatch() {
        let tasks = this.queue.slice(0, 6);
        let promises = [];
        for (let i = 0; i < tasks.length; i++) {
            let task = tasks[i];
            if (task.cancellationToken && task.cancellationToken.isCancelled) {
                task.deferred.reject('Cancelled');
            } else {
                promises.push({ id: i, promise: this.$http(task.options) });
            }
        }

        let vm = this;
        if (promises.length) {
            var suppress = function (x) {
                return x.catch(function () {});
            };
            this.$q.all(promises.map((s) => s.promise).map(suppress)).then(function (responses) {
                for (let x = 0; x < promises.length; x++) {
                    let id = promises[x].id;
                    if (responses[x] == null) {
                        tasks[id].deferred.reject('Not found');
                    } else {
                        tasks[id].deferred.resolve(responses[x]);
                    }
                }

                vm._checkForRerun(tasks);
            });
        } else {
            vm._checkForRerun(tasks);
        }
    }

    _checkForRerun(handledTasks) {
        var queueIds = handledTasks.map((s) => s.id);
        this.queue = this.queue.filter(function (obj) {
            return !queueIds.includes(obj.id);
        });
        if (this.queue.length > 0) {
            this.executeNextInBatch();
        }
    }

    queueWork(options, cancellationToken) {
        let deferred = this.$q.defer();
        this.queue.push({
            id: this.queueId,
            options: options,
            deferred: deferred,
            cancellationToken: cancellationToken
        });
        this.queueId++;
        if (this.queue.length === 1) {
            this.executeNextInBatch();
        }

        return deferred.promise;
    }
}

QueueService.$inject = ['$q', '$http'];

export default QueueService;
