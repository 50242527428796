class FilterTabsController {
    constructor($timeout, $document) {
        this.$timeout = $timeout;
        this.$document = $document;
        this.tabs = [];
        this.selected = null;
        this.default = null;
    }

    $onInit() {
        if (this.shouldSearch) {
            let debounceSearch = _.debounce((key) => this.searchTab(key), 200);
            this.debounceClear = _.debounce(() => (this.searchText = ''), 550);
            this.searchText = '';

            this.$document.on('keypress', ($event) => {
                this.searchText = this.searchText + $event.key;
                debounceSearch(this.searchText);
            });
        }
    }

    $postLink() {
        if (this.shouldScroll) {
            this.$timeout(
                () => {
                    this.selected && this.selected.scrollTo();
                },
                0,
                false
            );
        }
    }

    add(tab) {
        this.tabs.push(tab);
    }

    searchTab(key) {
        let tabs = _.filter(this.tabs, (tab) => {
            return _.startsWith(tab.text.toLowerCase(), key);
        });

        if (tabs && tabs.length > 0) {
            this.select(tabs[0]);
            this.selected.scrollTo();
        }

        this.debounceClear();
    }

    remove(tab) {
        var index = this.tabs.indexOf(tab);
        this.tabs.splice(index, 1);
    }

    select(tab) {
        this.selected = tab;

        if (!this.allowEqual && this.model === this.selected.value) {
            return;
        }

        this.model = this.selected.value;

        if (this.onChange && !tab.isInitialSelect) {
            this.$timeout(() => {
                this.onChange({ value: this.model });
            });
        }
    }
}

FilterTabsController.$inject = ['$timeout', '$document'];

export default FilterTabsController;
