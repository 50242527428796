class breadcrumbsService {
    constructor($breadcrumb) {
        this.$breadcrumb = $breadcrumb;
    }

    setLabel(stateName, label) {
        let states = this.$breadcrumb.getStatesChain();
        let state = _.find(states, (item) => {
            return item.name === stateName;
        });

        if (state && state.ncyBreadcrumb) {
            state.ncyBreadcrumb.label = label;
        }
    }
}

breadcrumbsService.$inject = ['$breadcrumb'];
export default breadcrumbsService;
