import { COUNTRIES, NUMBER_OF_FREE_LICENSES, FEES, SWEDISH_TAX, HTTP_STATUS_CODES } from '../../app.constants';

const INJECT = [
    '$q',
    '$scope',
    '$interval',
    '$timeout',
    'JsVatFactory',
    'menuService',
    'translationService',
    'contactService',
    'dialogService',
    'organizationService',
    'userService',
    'toastService',
    'subscriptionApiService',
    'subscriptionService'
];

class SubscriptionCheckoutController {
    constructor() {
        let obj = {};

        INJECT.forEach((injected, i) => {
            obj[injected] = arguments[i];
        });

        Object.assign(this, { ...obj });
    }

    $onInit() {
        this._init();
    }

    $onChanges() {
        this._update();
    }

    _init() {
        this.finalStep = false;
        this._setContactAndBillingFormOptions();
        this._update();
    }

    _update() {
        this._updateCurrencyTexts();
    }

    _setContactAndBillingFormOptions() {
        this.contactFormData = {
            email: this.subscriptionInfo.email,
            name: this.subscriptionInfo.name
        };
    }

    _updateCurrencyTexts() {
        this.extraLicensesText = `x ${this.subscriptionInfo.licenses - NUMBER_OF_FREE_LICENSES}`;
        let fees = this.subscriptionInfo.currency === 'USD' ? FEES.USD : FEES.SEK;

        this.starterPackFeeText = this.subscriptionService.getPaymentText(this.subscriptionInfo.currency, 'month', fees.START_FEE);

        this.extraLicensesFeeText = this.subscriptionService.getPaymentText(
            this.subscriptionInfo.currency,
            'month',
            fees.MONTHLY_LICENSE_FEE * (this.subscriptionInfo.licenses - NUMBER_OF_FREE_LICENSES)
        );

        let taxAmount = this.subscriptionInfo.totalCost * SWEDISH_TAX;
        let totalCost = this.subscriptionInfo.totalCost;
        if (this.contactFormData && this.contactFormData.hasOwnProperty('country')) {
            if (this.contactFormData.country.country === 'Sweden') {
                totalCost = this.subscriptionInfo.totalCost + taxAmount;
            }
        }

        this.totalCostText = this.subscriptionService.getPaymentText(this.subscriptionInfo.currency, this.subscriptionInfo.interval, totalCost);

        this.discountText = this.subscriptionService.getDiscountText(
            this.subscriptionInfo.currency,
            this.subscriptionInfo.interval === 'year' ? true : false,
            this.subscriptionInfo.licenses,
            'year'
        );

        this.salesTaxText = this.subscriptionService.getPaymentText(this.subscriptionInfo.currency, this.subscriptionInfo.interval, taxAmount);
        this.translationService.translate(this.subscriptionInfo.currency.toLowerCase() === 'usd' ? 'client_PriceInUsd' : 'client_PriceInSek', {
            price: Math.abs(taxAmount)
        });
    }

    _updateContactAndBillingFormSummary() {
        this.contactAndBillingFormSummary = [
            {
                title: this.translationService.translate('emailAddress'),
                text: this.contactFormData.email,
                testHook: 'Email'
            },
            {
                title: this.translationService.translate('client_OrganizationName'),
                text: this.contactFormData.name,
                testHook: 'OrganizationName'
            },
            {
                title: this.translationService.translate('administration_Address'),
                text: this.contactFormData.address,
                testHook: 'Address'
            },
            {
                title: this.translationService.translate('country'),
                text: this.contactFormData.country.country,
                testHook: 'Country'
            }
        ];
    }

    updateLicenses(change) {
        if (change === '+') {
            this.onChange({
                $event: {
                    value: this.subscriptionInfo.licenses + 1
                }
            });
        } else if (change === '-') {
            this.onChange({
                $event: {
                    value: this.subscriptionInfo.licenses - 1
                }
            });
        }
    }

    subscribe() {
        this.toastService.loading({ title: this.translationService.translate('client_CompletingCheckout') });
        this.isLoading = true;
        if (this.retryStatus === 'requires_new_paymentMethod') {
            this.subscriptionService
                .retryInvoiceWithNewPaymentMethod(this.latestInvoiceId, this.formData)
                .then(() => {
                    this.$timeout(() => {
                        this._success();
                    }, 5000);
                })
                .catch((res) => {
                    this._setRetryData(res);
                });
        } else {
            let customer = {
                email: this.contactFormData.email,
                name: this.contactFormData.name,
                country: this.contactFormData.country.countryShort,
                city: this.contactFormData.city,
                address: this.contactFormData.address,
                postalCode: this.contactFormData.postalCode,
                taxId: this.contactFormData.vat
            };
            let subscriptionDto = {
                paymentInterval: this.subscriptionInfo.interval,
                currency: this.subscriptionInfo.currency,
                quantity: this.subscriptionInfo.licenses
            };
            this.subscriptionService
                .subscribe(customer, subscriptionDto, this.formData)
                .then(() => {
                    this.$timeout(() => {
                        this._success();
                    }, 5000);
                })
                .catch((res) => {
                    if (res.status && res.status === HTTP_STATUS_CODES.SERVERERROR) {
                        this.clearCardForm = true;
                        this.isLoading = false;
                    } else {
                        this._setRetryData(res);
                    }
                });
        }
    }

    _success() {
        this.toastService.success({
            title: this.translationService.translate('client_SubscriptionCompleteText')
        });
        this.refresh();
    }

    _setRetryData(res) {
        this.clearCardForm = true;
        this.isLoading = false;
        this.toastService.error({
            title: this.translationService.translate('client_CardUpdateFailed'),
            duration: 10000
        });
        this.latestInvoiceId = res.latestInvoiceId;
        this.retryStatus = 'requires_new_paymentMethod';
    }

    proceed() {
        this._updateContactAndBillingFormSummary();
        this.finalStep = !this.finalStep;
    }
}

SubscriptionCheckoutController.$inject = INJECT;

export default SubscriptionCheckoutController;
