import template from './breadcrumbs.html';
import controller from './breadcrumbs.controller';
import './breadcrumbs.less';

let breadcrumbsComponent = {
    template,
    controller
};

export default breadcrumbsComponent;
