let discountValidationDirective = (paymentApiService, $q) => {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            cost: '<',
            onStartLoading: '&',
            onStopLoading: '&',
            onValid: '&',
            onInvalid: '&'
        },
        link: function (scope, element, attr, ctrl) {
            ctrl.$asyncValidators.validDiscount = (modelValue, viewValue) => {
                if (!viewValue || viewValue === '') {
                    ctrl.$setValidity('discount', true);
                    return $q.resolve();
                }

                scope.onStartLoading();
                return paymentApiService
                    .validateDiscount(viewValue)
                    .then((discount) => {
                        if (scope.cost > discount && discount > 0) {
                            ctrl.$setValidity('discount', true);

                            scope.onValid({
                                value: discount
                            });
                        } else {
                            ctrl.$setValidity('discount', false);

                            scope.onInvalid();
                        }
                    })
                    .catch(() => {
                        scope.onInvalid();
                    })
                    .finally(() => {
                        scope.onStopLoading();
                    });
            };
        }
    };
};

discountValidationDirective.$inject = ['paymentApiService', '$q'];

export default discountValidationDirective;
