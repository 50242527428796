class PaymentService {
    constructor($q, $state, dialogService, toastService, organizationService, userService, translationService, subscriptionService) {
        Object.assign(this, { $q, $state, dialogService, toastService, organizationService, userService, translationService, subscriptionService });
    }

    _showMoreInformationDialog(title, description) {
        return this.organizationService.administrators().then((admins) => {
            let adminString = '';
            for (let i = 0; i < admins.length; i++) {
                adminString =
                    adminString +
                    `<a class="mail-to-link" href="mailTo:${admins[i].email}">${admins[i].name}</a>` +
                    (admins.length - 1 === i ? '' : '<br/>');
            }
            let options = {
                id: 'user limit exeeded dialog',
                title: title,
                ok: this.translationService.translate('client_Ok'),
                cancel: null,
                description: description + `${adminString}`
            };

            return this.dialogService.confirm(options);
        });
    }

    userlimitExceeded(isAdmin) {
        return this.organizationService.getCurrentOrganization().then((currentOrg) => {
            return this.translationService
                .translateBatch([
                    'client_NoLicencesLeft',
                    'administration_RequireUpgrade',
                    'administration_ContactAdmin',
                    'administration_RequireUpgrade',
                    'administration_Upgrade',
                    'client_More'
                ])
                .then((translations) => {
                    let startString = translations.client_NoLicencesLeft;
                    let options = {
                        id: 'require upgrade',
                        title: isAdmin
                            ? `${startString}, ${translations.administration_RequireUpgrade.toLowerCase()}`
                            : `${startString}, ${translations.administration_ContactAdmin.toLowerCase()}`,
                        action: true,
                        description: null,
                        actionIcon: 'clear',
                        duration: null
                    };

                    let description = `<p>${translations.administration_RequireUpgrade}</p><br/>`;

                    let action = () => {
                        this.toastService.hide();
                        if (isAdmin) {
                            this.subscriptionService.goToSubscriptionView();
                        } else {
                            this._showMoreInformationDialog(startString, description);
                        }
                    };

                    if (isAdmin) {
                        options.textAction = action;
                        options.textActionText = translations.administration_Upgrade;
                    } else if (!isAdmin) {
                        options.textAction = action;
                        options.textActionText = translations.client_More;
                    }

                    return this.toastService.information(options);
                });
        });
    }

    trialExceeded() {
        return this.userService.getCurrentUser().then((user) => {
            return this.translationService
                .translateBatch(['client_TrialPeriodOver', 'administration_Upgrade', 'client_ToCreateMoreMeetings', 'client_IfYouWishToUpgrade'])
                .then((translations) => {
                    let options = {
                        id: 'trial period over dialog',
                        title: translations.client_TrialPeriodOver,
                        ok: translations.administration_Upgrade,
                        description: translations.client_ToCreateMoreMeetings
                    };

                    if (user.isAdmin) {
                        this.toastService.hide();
                        return this.dialogService.confirm(options).then((onAdminClickUpgrade) => this.subscriptionService.goToSubscriptionView());
                    } else {
                        let description = `<p>${options.description}</p><br><p>${translations.client_IfYouWishToUpgrade}</p><br/>`;
                        return this._showMoreInformationDialog(options.title, description);
                    }
                });
        });
    }

    trialActive(trialStatus, isAdmin) {
        this.translationService
            .translateBatch(
                [
                    'client_TrialPeriodActive',
                    'client_FreeMeetingsRemaning',
                    'administration_Upgrade',
                    'client_More',
                    'client_CurrenlyYouHave',
                    'client_ToCreateMoreMeetings',
                    'client_IfYouWishToUpgrade'
                ],
                { freeMeetings: trialStatus }
            )
            .then((translations) => {
                let options = {
                    id: 'trial period active',
                    title: translations.client_TrialPeriodActive,
                    description: translations.client_FreeMeetingsRemaning,
                    action: true,
                    actionIcon: 'clear',
                    duration: null
                };

                let action = () => {
                    this.toastService.hide();
                    if (isAdmin) {
                        this.subscriptionService.goToSubscriptionView();
                    } else {
                        let description =
                            `<p>${translations.client_CurrenlyYouHave} <strong>${translations.client_FreeMeetingsRemaning}.</strong></p><br/>` +
                            `<p>${translations.client_ToCreateMoreMeetings}</p><br><p>${translations.client_IfYouWishToUpgrade}</p><br/>`;

                        this._showMoreInformationDialog(options.title, description);
                    }
                };

                options.textAction = action;
                options.textActionText = isAdmin ? translations.administration_Upgrade : translations.client_More;

                this.toastService.information(options);
            });
    }

    showTrialInformation(skipTrialExceeded) {
        let deferred = this.$q.defer(),
            hasShownInfo = false;

        let promises = [
            this.userService.getCurrentUser(),
            this.organizationService.getMeetingsRemaining(),
            this.organizationService.trialExceeded(),
            this.organizationService.getIsTrial()
        ];

        this.$q.all(promises).then((results) => {
            const THREE_MEETINGS_OR_LESS_REMAINING = 3;

            let user = results[0],
                trialStatus = results[1],
                trialExceeded = results[2],
                isTrial = results[3];

            if (trialExceeded && !user.isExternal && !skipTrialExceeded) {
                hasShownInfo = true;
                this.trialExceeded();
                deferred.resolve(hasShownInfo);
            } else if (isTrial && trialStatus <= THREE_MEETINGS_OR_LESS_REMAINING && !user.isExternal) {
                hasShownInfo = true;
                if (trialStatus < 0) {
                    trialStatus = 0;
                }

                this.trialActive(trialStatus, user.isAdmin);
                deferred.resolve(hasShownInfo);
            } else {
                deferred.resolve(hasShownInfo);
            }
        });

        return deferred.promise;
    }

    showIsParticipant() {
        return this.translationService
            .translateBatch([
                'client_YouDoNotHavePermissionToDoThis',
                'client_YouHaveBeenAddedAsParticipant',
                'client_YouHaveToBeUpgradedToOrganizer'
            ])
            .then((translations) => {
                let title = translations.client_YouDoNotHavePermissionToDoThis,
                    description = `<p>${translations.client_YouHaveBeenAddedAsParticipant}<br/><br/>${translations.client_YouHaveToBeUpgradedToOrganizer}:</p><br/>`;

                return this._showMoreInformationDialog(title, description);
            });
    }
}

PaymentService.$inject = [
    '$q',
    '$state',
    'dialogService',
    'toastService',
    'organizationService',
    'userService',
    'translationService',
    'subscriptionService'
];

export default PaymentService;
