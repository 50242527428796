import './keyboardNavigation.less';

let keyboardNavigationDirective = ($document, $timeout, $parse, keyCode, anchorScrollService, stringService) => {
    return {
        restrict: 'A',
        priority: 1001,
        link: (scope, element, attr) => {
            var keydownEvent;
            var itemElements;
            var listItemsElement;

            var activeClass = 'active';
            var itemClass = 'navigationItem';
            var scroller = attr.keyboardNavigation;
            let listenTo = attr.listenTo;

            init();

            function init() {
                if (attr.activate) {
                    scope.$watch(attr.activate, (value) => {
                        if (value === true) {
                            activate();
                        } else {
                            deactivate();
                        }
                    });
                } else {
                    activate();
                }
            }

            function activate() {
                scope.$evalAsync(() => {
                    bindEvents();

                    let element = getActiveElement();
                    if (element[0]) {
                        let offset = getListItemsElement()[0].getBoundingClientRect().bottom / 3;
                        anchorScrollService.scrollToElement(element, getListItemsElement(), offset, attr.scrollSpeed || 0);
                    }
                });
            }

            function deactivate() {
                unbindEvents();
                listItemsElement = null;
                itemElements = null;
            }

            function bindEvents() {
                keydownEvent = $document.bind('keydown', (event) => {
                    if (!keydownEvent) {
                        return;
                    }

                    // Tab key
                    if (event.keyCode === keyCode.TAB && attr.clickOnTab) {
                        onEnterkey(event);
                    }

                    // Enter key
                    if (event.keyCode === keyCode.ENTER) {
                        onEnterkey(event);
                    }

                    // Up arrow key
                    if (event.keyCode === keyCode.UP) {
                        onUpKey();
                    }

                    // Down arrow key
                    if (event.keyCode === keyCode.DOWN) {
                        onDownKey();
                    }
                });

                if (listenTo) {
                    $timeout(
                        () => {
                            bindInputListener();
                        },
                        200,
                        false
                    );
                }
            }

            function getListItemsElement() {
                if (!listItemsElement || !listItemsElement[0]) {
                    listItemsElement = angular.element(element[0].querySelector('.rym-scroll-content'));

                    if (!listItemsElement[0]) {
                        listItemsElement = element;
                    }
                }

                return listItemsElement;
            }

            function getItemElements(fresh) {
                if (fresh === true || !itemElements || !itemElements[0]) {
                    itemElements = angular.element(element[0].querySelectorAll('.' + itemClass));
                }

                return itemElements;
            }

            function getActiveElement() {
                var activeElement = angular.element(element[0].querySelector('.' + activeClass));

                if (!activeElement[0]) {
                    getItemElements(true);

                    return angular.element();
                }

                return activeElement;
            }

            function isElementInViewport(el) {
                if (attr.noScroll) {
                    return true;
                }

                let rect = el.getBoundingClientRect();
                let listItemsElementRect = getListItemsElement()[0].getBoundingClientRect();

                let paddingTop = parseInt(getComputedStyle(getListItemsElement()[0]).paddingTop, 10);

                return listItemsElementRect.top + paddingTop <= rect.top && rect.bottom <= listItemsElementRect.bottom - 30;
            }

            function bindInputListener() {
                let element = $document[0].getElementById(listenTo);
                if (element) {
                    setFirstElementToBeActive();
                    element.addEventListener('keydown', (event) => {
                        $timeout(
                            () => {
                                if (
                                    event.keyCode !== keyCode.ENTER &&
                                    event.keyCode !== keyCode.UP &&
                                    event.keyCode !== keyCode.DOWN &&
                                    event.keyCode !== keyCode.TAB
                                ) {
                                    setFirstElementToBeActive();
                                }
                            },
                            300,
                            false
                        );
                    });
                }
            }

            function onUpKey() {
                scope.$evalAsync(() => {
                    let element = setPreviousElementToBeActive();
                    if (element[0]) {
                        let isVisible = isElementInViewport(element[0]);

                        if (!isVisible) {
                            var listItemsElement = getListItemsElement();
                            let listItemsElementStyles = getComputedStyle(listItemsElement[0]);
                            let offset = parseInt(listItemsElementStyles.paddingTop, 10);
                            anchorScrollService.scrollToElement(element, getListItemsElement(), offset, 0);
                        }
                    }
                });
            }

            function onDownKey() {
                scope.$evalAsync(() => {
                    let element = setNextElementToBeActive();
                    if (element[0]) {
                        let isVisible = isElementInViewport(element[0]);

                        if (!isVisible) {
                            var listItemsElement = getListItemsElement();
                            let listItemsElementStyles = getComputedStyle(listItemsElement[0]);
                            let paddingBottom = parseInt(listItemsElementStyles.paddingBottom, 10);
                            let listHeight = parseInt(listItemsElementStyles.height, 10);
                            let listItemHeight = element[0].clientHeight;
                            let offset = paddingBottom + listHeight - listItemHeight;
                            anchorScrollService.scrollToElement(element, listItemsElement, offset, 0);
                        }
                    }
                });
            }

            function onEnterkey(event) {
                scope.$evalAsync(() => {
                    if (!stringService.isUndefinedOrEmpty(attr.onEnter)) {
                        let fn = $parse(attr.onEnter);
                        fn(scope, { $event: event });
                    }

                    var activeElement = getActiveElement();

                    let ngClick = activeElement.attr('ng-click');

                    if (stringService.isUndefinedOrEmpty(ngClick)) {
                        angular.element(activeElement).triggerHandler('click');
                    } else {
                        let fn = $parse(ngClick);
                        fn(activeElement.scope(), { $event: event });
                    }
                });
            }

            function setPreviousElementToBeActive() {
                return setElementToBeActive(false);
            }

            function setNextElementToBeActive() {
                return setElementToBeActive(true);
            }

            function setFirstElementToBeActive(count) {
                count = count || 0;

                scope.$applyAsync(() => {
                    let active = getActiveElement();
                    let first = getItemElements(true)[0];

                    if (active) {
                        active.removeClass(activeClass);
                    }

                    if (first) {
                        angular.element(first).addClass(activeClass);
                    } else if (count < 10) {
                        $timeout(() => {
                            setFirstElementToBeActive(count + 1);
                        }, 100);
                    }
                });
            }

            function setElementToBeActive(isNext) {
                let activeElement = getActiveElement();
                let items = getItemElements(isNext);

                let index = _.findIndex(items, (item) => {
                    return item == activeElement[0];
                });

                if (isNext) {
                    index = index + 1;
                } else {
                    index = Math.max(index - 1, 0);
                }

                if (index >= items.length) {
                    items = getItemElements(true);
                }

                index = Math.min(index, items.length);

                let element = angular.element(items[index]);

                if (element[0]) {
                    activeElement.removeClass(activeClass);
                    element.addClass(activeClass);
                }

                return element;
            }

            function unbindEvents() {
                if (keydownEvent) {
                    keydownEvent.unbind();
                }
            }

            scope.$on('$destroy', function () {
                deactivate();
            });
        }
    };
};

keyboardNavigationDirective.$inject = ['$document', '$timeout', '$parse', 'keyCode', 'anchorScrollService', 'stringService'];

export default keyboardNavigationDirective;
