import BaseApiService from './baseApi.service';

class AzureAdApiService extends BaseApiService {
    constructor(
        $cacheFactory,
        $http,
        $q,
        $rootScope,
        $state,
        $window,
        authService,
        toastService,
        queueService,
        applicationInsightsService,
        appcuesService,
        eventEmitterService,
        envService
    ) {
        super(
            $cacheFactory,
            $http,
            $q,
            $rootScope,
            $state,
            $window,
            authService,
            toastService,
            queueService,
            applicationInsightsService,
            appcuesService,
            eventEmitterService,
            envService,
            'azuread'
        );
    }

    getCalendarToggleUrl() {
        return super.GET(`azuread/toggles/calendar`);
    }

    getGroupToggleUrl() {
        return super.GET(`azuread/toggles/groups`);
    }

    getCalendarUrl() {
        return super.GET(`azuread/urls/calendar`);
    }

    getGroupUrl() {
        return super.GET(`azuread/urls/groups`);
    }
}

AzureAdApiService.$inject = [
    '$cacheFactory',
    '$http',
    '$q',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'toastService',
    'queueService',
    'applicationInsightsService',
    'appcuesService',
    'eventEmitterService',
    'envService'
];

export default AzureAdApiService;
